import { ProgramCheckpointId } from '@formbio/api';
import {
  Buildings as BuildingsIcon,
  GearSix as GearSixIcon,
  Syringe as SyringeIcon,
  TestTube as TestTubeIcon,
  PencilSimpleLine as PencilSimpleLineIcon,
} from '@formbio/ui/components/Icons';

function assertUnreachable(checkpointId: never): never {
  throw new Error('Unknown checkpointId: ' + checkpointId);
}

export default function CheckpointIcon({
  checkpointId,
  color,
}: {
  checkpointId: ProgramCheckpointId;
  color?: string;
}) {
  switch (checkpointId) {
    case ProgramCheckpointId.Design:
      return <PencilSimpleLineIcon color={color} />;
    case ProgramCheckpointId.Optimize:
      return <GearSixIcon color={color} />;
    case ProgramCheckpointId.InVitro:
      return <TestTubeIcon color={color} />;
    case ProgramCheckpointId.InVivo:
      return <SyringeIcon color={color} />;
    case ProgramCheckpointId.Manufacturing:
      return <BuildingsIcon color={color} />;
    default:
      assertUnreachable(checkpointId);
  }
}
