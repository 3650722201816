import { forwardRef } from 'react';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';

export type ChipProps = Omit<MuiChipProps, 'sx'>;

export const Chip = forwardRef<HTMLDivElement, ChipProps>(function Chip(
  props,
  ref,
) {
  return <MuiChip {...props} ref={ref} />;
});
