import { useState, useEffect } from 'react';

import { styled, InputLabel, Stack, Grid } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import { Button } from '@formbio/ui/components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@formbio/ui/components/Dialog';
import AddMembersDialog from '@formbio/ui/components/Dialog/AddMembersDialog';
import { DialogLinearProgress } from '@formbio/ui/components/LinearProgress';
import { TextField } from '@formbio/ui/components/TextField';
import { CloseIconButton } from '@formbio/ui/components/Button/CloseIconButton';

import { Role, AwaitingInvitee, Member } from '@formbio/ui/types/member';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOrgProjectValidator } from '@formbio/ui/utils/yupHelper';

/*
 * styled
 */
export const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    paddingBottom: theme.spacing(4),
  },
  '& .count': {
    position: 'absolute',
    bottom: theme.spacing(0.75),
    left: theme.spacing(1.75),
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.body2?.fontSize,
    color: theme.palette.primary[500],
  },
  '& .count-selection': {
    color: theme.palette.primary[900],
  },
}));

const ActionButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const ActionButtonsItem = styled(Grid)({
  justifyContent: 'right',
});

const StyledTextFieldContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const StyledTextFieldGroup = styled(Stack)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

/*
 * form
 */
export type ProjectDialogFormData = {
  name: string;
  description?: string;
};

const schema = yup
  .object({
    name: getOrgProjectValidator(),
    description: yup.string().max(200, 'Must be 200 characters or less'),
  })
  .required();

/*
 * component props
 */
type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ProjectDialogFormData) => Promise<void>;
  roles: Role[];
  isLoading: boolean;
  handleValidation?: (awaitingInvitee: AwaitingInvitee) => Promise<void>;
  project?: {
    name: string;
    description: string;
  };
  isEditing?: boolean;
  isOrgAdmin?: boolean;
  orgMembers?: Member[];
  invitees?: AwaitingInvitee[];
  onInviteesChange?: (invitees: AwaitingInvitee[]) => void;
};

const ProjectDialog = ({
  open = false,
  onClose,
  onSubmit,
  handleValidation,
  roles,
  project,
  isLoading,
  isEditing,
  isOrgAdmin,
  orgMembers,
  invitees,
  onInviteesChange,
}: Props) => {
  // state
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [descCount, setDescCount] = useState<number>(0);

  // form
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset: resetForm,
  } = useForm<ProjectDialogFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: project,
  });

  // var
  const projectName = watch('name');
  const projectDescription = watch('description');
  const formFieldsInit: boolean =
    projectName === undefined && projectDescription === undefined;
  const validationFailed: boolean =
    projectName?.length === 0 || !!errors.name || !!errors.description;

  useEffect(() => {
    // triggers a reset when the dialog is opened/closed
    resetForm(project || { name: '', description: '' });
  }, [project, open]);

  // hooks
  useEffect(() => {
    setDescCount(projectDescription?.length || 0);
  }, [projectDescription]);

  const handleClose = () => {
    resetForm({ name: '', description: '' });
    onClose();
  };

  const isDisabled = isLoading || validationFailed;

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={handleClose}>
      {(() => {
        switch (stepNumber) {
          // 1st step: project name and description
          case 1:
            return (
              <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                  {isEditing
                    ? 'Edit Project Name and Description'
                    : 'Create a New Project'}
                  <CloseIconButton onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                  <StyledTextFieldContainer>
                    <StyledTextFieldGroup>
                      <InputLabel htmlFor='project-name'>
                        Project Name
                      </InputLabel>
                      <TextField
                        id='project-name'
                        placeholder='Type New Name...'
                        type='text'
                        required
                        {...register('name')}
                      />
                    </StyledTextFieldGroup>
                    {errors.name?.message && (
                      <Typography color='error'>
                        {errors.name?.message}
                      </Typography>
                    )}

                    <StyledTextFieldGroup>
                      <InputLabel htmlFor='project-description'>
                        Description
                      </InputLabel>
                      <StyledTextField
                        id='project-description'
                        placeholder='Type a Description of the new project...'
                        multiline
                        rows={5}
                        {...register('description')}
                        InputProps={{
                          endAdornment: (
                            <span className='count'>
                              <span className='count-selection'>
                                {descCount}
                              </span>{' '}
                              / 200
                            </span>
                          ),
                        }}
                      />
                    </StyledTextFieldGroup>
                    {errors.description?.message && (
                      <Typography color='error'>
                        {errors.description?.message}
                      </Typography>
                    )}
                  </StyledTextFieldContainer>
                  {isLoading && <DialogLinearProgress />}
                </DialogContent>
                <DialogActions>
                  <Button
                    type='button'
                    variant='outlined'
                    color='primary'
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {isEditing ? (
                    <Button
                      type={'submit'}
                      variant='contained'
                      color='primary'
                      disabled={isLoading}
                    >
                      Confirm Changes
                    </Button>
                  ) : isOrgAdmin ? (
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={formFieldsInit || isDisabled}
                      onClick={() => setStepNumber(2)}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={isDisabled}
                    >
                      Create Project
                    </Button>
                  )}
                </DialogActions>
              </form>
            );
          // 2nd step: inviting members
          case 2:
            return (
              <AddMembersDialog
                title='Add New Members'
                isLoading={isLoading}
                onClose={onClose}
                roles={roles}
                // sending custom action buttons to the dialog
                // no need for onSubmit since we are using the form in the parent component
                onSubmit={() => {
                  return;
                }}
                onValidateMemberCanBeInvited={handleValidation}
                onInviteesChange={invitees =>
                  onInviteesChange && onInviteesChange(invitees)
                }
                initInvitees={invitees}
                hideDialogComponent
                projectWizard
                members={orgMembers}
                dialogActionButtons={
                  <ActionButtonsContainer container>
                    <ActionButtonsItem item xs container>
                      <Button
                        type='button'
                        variant='outlined'
                        color='primary'
                        onClick={() => setStepNumber(1)}
                      >
                        Back
                      </Button>
                      <Button
                        type='button'
                        onClick={handleSubmit(onSubmit)}
                        variant='contained'
                        color='primary'
                        disabled={isDisabled}
                        form='send-invitations'
                      >
                        Create a Project
                      </Button>
                    </ActionButtonsItem>
                  </ActionButtonsContainer>
                }
              />
            );

          default:
            return null;
        }
      })()}
    </Dialog>
  );
};

export default ProjectDialog;
