import { SchemaParamValues } from '@formbio/workflow-schema';
import { ResponseActor } from './actor';

export enum ContainerAppInstanceStatus {
  PENDING = 'PENDING',
  STOPPED = 'STOPPED',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  REMOVED = 'REMOVED',
  STOPPING = 'STOPPING',
  FAILED = 'FAILED',
}

export interface ContainerAppInstance {
  id: string;
  name: string;
  displayName: string;
  status: ContainerAppInstanceStatus;
  createdAt: string;
  updatedAt: string;
  actor: ResponseActor;
  endsAt: string;
  errorLog: string;
  errorMessage: string;
}

export interface CreateContainerAppConfig extends SchemaParamValues {
  frn: string;
}

export interface ContainerAppInstanceCreate {
  oid: string;
  pid: string;
  config: CreateContainerAppConfig;
}

export interface ContainerAppInstanceCrud {
  oid: string;
  pid: string;
  id: string;
}

export interface ContainerAppInstancePatch extends ContainerAppInstanceCrud {
  timeAdded: string;
}
