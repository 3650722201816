import config from '@/config';
import { http, HttpResponse, delay } from 'msw';
import { installations, installedRepos } from './data/github-auth';

const baseUrl = `${config.apiUrl}/api/${config.banthaApiVersion}`;

const getGitHubAuth = http.get(`${baseUrl}/gh/auth`, async () => {
  await delay(1000);
  return HttpResponse.json({ authorized: false });
});

const setGitHubAuth = http.post(`${baseUrl}/gh/auth`, async () => {
  await delay(1000);
  return HttpResponse.json({ authorized: true });
});

const getGitHubInstallations = http.get(
  `${baseUrl}/gh/installations`,
  async () => {
    await delay(1000);
    return HttpResponse.json(installations);
  },
);

const getGitHubInstalledRepos = http.get(
  `${baseUrl}/orgs/:orgId/:pid/gh/installations/:installation/repos`,
  async () => {
    await delay(1000);
    return HttpResponse.json(installedRepos);
  },
);

export const gitHubAuthHandlers = [
  getGitHubAuth,
  setGitHubAuth,
  getGitHubInstallations,
  getGitHubInstalledRepos,
];
