import { styled } from '@mui/material';
import { PanelResizeHandle } from 'react-resizable-panels';
export { PanelResizeHandle } from 'react-resizable-panels';

const StyledDiv = styled(PanelResizeHandle, {
  shouldForwardProp: prop =>
    prop !== 'backgroundColor' && prop !== 'handleColor',
})<{ backgroundColor?: string; handleColor?: string }>(({
  theme,
  backgroundColor,
  handleColor,
}) => {
  return {
    display: 'flex',
    width: theme.spacing(1),
    //same color as note background
    backgroundColor: backgroundColor || theme.palette.grey[100],
    borderLeft: `2px solid ${backgroundColor || theme.palette.grey[100]}`,
    transition: `border-color 0.2s linear`,
    '.handle': {
      // -7px is: handle-width (12px) / 2 + border-width (2px) / 2 = 14px / 2 = 7px
      margin: theme.spacing('auto', 'auto', 'auto', '-7px'),
      transition: `background-color 0.2s linear`,
      width: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
      height: '100px',
      zIndex: theme.zIndex.appBar + 1,
    },
    ':hover .handle': {
      backgroundColor: handleColor || theme.palette.info.light,
      border: `1px solid ${theme.palette.divider}`,
    },
    ':hover': {
      borderLeftColor: handleColor || theme.palette.info.light,
    },
  };
});

export function ResizeHandle({
  handleDragging,
  backgroundColor,
  handleColor,
  onDoubleClick,
}: {
  handleDragging?: (isDragging: boolean) => void;
  backgroundColor?: string;
  handleColor?: string;
  onDoubleClick?: () => void;
}) {
  return (
    <StyledDiv
      onDragging={handleDragging}
      onDoubleClick={onDoubleClick}
      backgroundColor={backgroundColor}
      handleColor={handleColor}
    >
      <div className='handle' />
    </StyledDiv>
  );
}
