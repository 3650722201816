import config from '@/config';
import { http, HttpResponse } from 'msw';
import { containerAppInstances, containerApps } from './data/container-apps';

const baseUrl = `${config.apiUrl}/api/${config.banthaApiVersion}`;

const fetchAllContainerApps = http.get(
  `${baseUrl}/orgs/:orgId/:projectId/apps`,
  () => HttpResponse.json(containerApps),
);

const fetchOneContainerApp = http.get(
  `${baseUrl}/orgs/:orgId/:projectId/apps/:appName`,
  ({ params }) =>
    HttpResponse.json(
      containerApps.find(container => container.appName === params.appName),
    ),
);

const startContainerApp = http.post(
  `${baseUrl}/orgs/:oid/:pid/app-instances/:appName`,
  () => HttpResponse.json({ success: true }),
);

const fetchAllContainerAppInstances = http.get(
  `${baseUrl}/orgs/:oid/:pid/app-instances`,
  () => HttpResponse.json(containerAppInstances),
);

export const containerAppHandlers = [
  fetchAllContainerApps,
  fetchOneContainerApp,
  startContainerApp,
  fetchAllContainerAppInstances,
];
