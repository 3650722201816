import { HTMLInputTypeAttribute, ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Typography } from '@formbio/ui/components/Typography';
import ErrorMessage from '../../Error/ErrorMessage';
import { SxProps, styled, Stack } from '@mui/material';
import {
  TextField,
  TextFieldProps,
  InputBaseProps,
} from '@formbio/ui/components/TextField';
import { InputLabel } from '@formbio/ui/components/InputLabel';

const HelpTextTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body3.fontSize,
}));

/**
 *
 * Manages the layout of the label, input and error
 * in a form
 */
export default function FormInputItem({
  label,
  defaultValue,
  placeholder,
  type = 'text',
  errorMessage,
  registerProps,
  required,
  disabled = false,
  description,
  helpText,
  sx = {},
  inputProps = {},
  autoFocus = false,
  multiline = false,
  InputProps = {},
  size = 'medium',
}: {
  label: string;
  defaultValue?: string | null;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
  errorMessage?: string;
  registerProps: UseFormRegisterReturn;
  required?: boolean;
  disabled?: boolean;
  description?: ReactNode;
  helpText?: string;
  sx?: SxProps;
  inputProps?: InputBaseProps['inputProps'];
  autoFocus?: boolean;
  multiline?: boolean;
  InputProps?: TextFieldProps['InputProps'];
  size?: 'small' | 'medium';
}) {
  const id = `${label.replaceAll(' ', '-')}-input`;
  return (
    <Stack spacing={1} sx={sx}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      {description}
      <TextField
        id={id}
        placeholder={placeholder}
        type={type}
        required={required}
        disabled={disabled}
        defaultValue={defaultValue}
        inputProps={inputProps}
        autoFocus={autoFocus}
        multiline={multiline}
        size={size}
        InputProps={InputProps}
        {...registerProps}
      />
      {helpText && <HelpTextTypography>{helpText}</HelpTextTypography>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Stack>
  );
}
