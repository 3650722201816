import { Button as MuiButton, Stack } from '@formbio/ui';

export default function FormStack(
  props: React.ComponentPropsWithoutRef<typeof MuiButton> &
    React.ComponentPropsWithoutRef<'form'>,
) {
  return (
    <Stack
      component='form'
      sx={{ gap: 2, flexDirection: 'column' }}
      {...props}
    />
  );
}
