import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  fetchAll,
  patch,
  start,
  stop,
} from '../../services/container-app-instance';
import {
  ContainerAppInstance,
  ContainerAppInstanceCrud,
  CreateContainerAppConfig,
} from '../../types';
import useInvalidation from '../useInvalidation';

export const CONTAINER_APP_INSTANCE_KEY = 'form-benches';

export function useContainerAppInstances(
  params: Parameters<typeof fetchAll>[0],
  options: Omit<
    UseQueryOptions<ContainerAppInstance[]>,
    'queryKey' | 'queryFn'
  > = {},
) {
  return useQuery<ContainerAppInstance[]>({
    queryKey: [CONTAINER_APP_INSTANCE_KEY, params],
    queryFn: () => fetchAll(params),
    ...options,
  });
}

export function useStartContainerApp(
  oid: string,
  pid: string,
  options: UseMutationOptions<
    ContainerAppInstance,
    unknown,
    CreateContainerAppConfig
  > = {},
) {
  const invalidate = useInvalidation(CONTAINER_APP_INSTANCE_KEY);

  return useMutation<ContainerAppInstance, unknown, CreateContainerAppConfig>({
    mutationKey: [CONTAINER_APP_INSTANCE_KEY],
    mutationFn: (config) => start({ oid, pid, config }),
    ...{
      ...options,
      onSuccess: (...args) => {
        invalidate({ oid, pid });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  });
}

export function useStopContainerApp(
  params: ContainerAppInstanceCrud,
  options: UseMutationOptions = {},
) {
  const invalidate = useInvalidation(CONTAINER_APP_INSTANCE_KEY);

  return useMutation({
    mutationKey: [CONTAINER_APP_INSTANCE_KEY],
    mutationFn: () => stop(params),
    ...{
      ...options,
      onSuccess: (...args) => {
        invalidate({ oid: params.oid, pid: params.pid });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  });
}

export function usePatchContainerApp(
  params: ContainerAppInstanceCrud,
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(CONTAINER_APP_INSTANCE_KEY);

  return useMutation<unknown, unknown, string>({
    mutationKey: [CONTAINER_APP_INSTANCE_KEY],
    mutationFn: (timeAdded: string) => patch({ ...params, timeAdded }),
    ...{
      ...options,
      onSuccess: (...args) => {
        invalidate({ oid: params.oid, pid: params.pid });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  });
}
