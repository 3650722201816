import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

export const GOOGLE_ANALYTICS_EVENTS = {
  // ISA Landing Page
  SESSION_START: 'session_start',
  // Uploaded vector
  VIEW_ITEM: 'view_item',
  // Quick Insights
  ADD_TO_CART: 'add_to_cart',
  // Order Assay
  BEGIN_CHECKOUT: 'begin_checkout',
  // Stripe purchase successful
  PURCHASE: 'purchase',
};

export let clientId = '';
let isInitialized = false;
let trackingId = '';

const initializeGoogleAnalytics = (id: string) => {
  if (!isInitialized) {
    ReactGA.initialize(id);
    isInitialized = true;
    trackingId = id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    ReactGA.ga(tracker => {
      clientId = tracker.get('clientId');
    });
  }
};

const useGoogleAnalytics = () => {
  useEffect(() => {
    if (!isInitialized && trackingId) {
      if (process.env.NODE_ENV === 'production') {
        initializeGoogleAnalytics(trackingId);
      }
    }
  }, []);
  /**
          // Given
          const eventName = "screen_view";
          const eventParams = {
            app_name: "myAppName",
            screen_name: "Home",
          };
    
          // When
          GA4.event(eventName, eventParams);
           */

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const trackEvent = (objOrName: UaEventOptions | string, params?: any) => {
    ReactGA.event(objOrName, params);
  };
  /** 
   *    // Given
        const hitType = "pageview";
        const path = "/location-pathname";
        const title = "title value";

      // With path parameter
      GA4.send({ hitType, page: path });
   * 
  */
  const trackPageView = (path: string) => {
    ReactGA.send({ hitType: 'pageview', path });
  };

  return { trackEvent, trackPageView };
};

export { useGoogleAnalytics, initializeGoogleAnalytics };
