import { Color } from '@mui/material';

export const error: Partial<Color> = {
  [700]: '#8F0000',
  [600]: '#AD0000',
  [500]: '#D60000',
  [400]: '#EB4747',
  [300]: '#F5A3A3',
  [200]: '#FCE3E3',
  [100]: '#FDF1F1',
};
