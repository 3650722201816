import {
  Label,
  ProvenanceEventType,
  ProvenanceOriginType,
  VaultItem,
} from '@formbio/api';

export const existingLabels: Label[] = [
  { title: 'no-label', group: 'Ungrouped' },
  {
    title: 'Mammoth',
    group: 'Species',
  },
  {
    title: 'Thylacine',
    group: 'Species',
  },
  {
    title: 'Tasmanian Tiger',
    group: 'Species',
  },
  {
    title: 'BRCA2',
    group: 'Gene',
  },
  {
    title: 'KRAS',
    group: 'Gene',
  },
  {
    title: 'IGV',
    group: 'Viz Tool',
  },
  {
    title: 'Molstar',
    group: 'Viz Tool',
  },
  {
    title: 'v1.2.0',
    group: 'WF Version',
  },
  {
    title: 'v1.3.0',
    group: 'WF Version',
  },
  {
    title: 'Spotify',
    group: 'Music',
  },
  {
    title: 'Brain',
    group: 'Tissue',
  },
  {
    title: 'Gene Molecular Evolution',
    group: 'Workflow',
  },
];

export const mockVaultItems: VaultItem[] = [
  {
    createdAt: '2023-09-20T12:11:11.409Z',
    updatedAt: '2023-09-20T12:11:11.409Z',
    metadata: null,
    size: 26,
    contentType: '',
    name: 'hello.bam',
    bucket: 'colossal-dev-2fee3211-cd2d-4025-b104-d61acc9e69b9',
    crc32c: 1179842892,
    labels: [
      {
        title: 'Mammoth',
        group: 'Species',
      },
      {
        title: 'Gene Molecular Evolution',
        group: 'Workflow',
      },
      {
        title: 'v1.2.0',
        group: 'WF Version',
      },
    ],
    provenance: {
      events: [
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.WorkflowRunInput,
          actor: '391aebf2-f21d-4ced-b5ba-424872ba8251',
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Written,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Deleted,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
      ],
      origin: {
        id: '391aebf2-f21d-4ced-b5ba-424872ba8251',
        type: ProvenanceOriginType.WorkflowRun,
      },
      validations: null,
    },
  },
  {
    createdAt: '2023-07-10T16:06:07.723Z',
    updatedAt: '2023-07-10T16:06:07.723Z',
    metadata: null,
    size: 37194871,
    contentType: 'application/octet-stream',
    name: 'microglia.h5ad',
    bucket: 'colossal-dev-2fee3211-cd2d-4025-b104-d61acc9e69b9',
    crc32c: 3715578633,
    labels: [
      {
        title: 'Brain',
        group: 'Tissue',
      },
    ],
    provenance: {
      events: [
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.WorkflowRunInput,
          actor: '391aebf2-f21d-4ced-b5ba-424872ba8251',
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Written,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Deleted,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
      ],
      origin: {
        id: 'maxfield@formbio.com',
        type: ProvenanceOriginType.User,
      },
      validations: null,
    },
  },
  {
    createdAt: '2023-09-15T15:16:40.256Z',
    updatedAt: '2023-09-15T15:16:40.256Z',
    metadata: null,
    size: 309,
    contentType: '',
    name: 'spotify.html',
    bucket: 'colossal-dev-2fee3211-cd2d-4025-b104-d61acc9e69b9',
    crc32c: 1349829872,
    labels: [
      {
        title: 'Spotify',
        group: 'Music',
      },
      {
        title: 'v1.2.0',
        group: 'WF Version',
      },
    ],
    provenance: {
      events: [
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.WorkflowRunInput,
          actor: '391aebf2-f21d-4ced-b5ba-424872ba8251',
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Written,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Deleted,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
      ],
      origin: {
        type: ProvenanceOriginType.Unknown,
      },
      validations: null,
    },
  },
  {
    createdAt: '2023-09-20T15:29:05.26Z',
    updatedAt: '2023-09-20T15:29:05.26Z',
    metadata: {
      gcsfuse_mtime: '2023-09-20T15:29:04.978773875Z',
    },
    size: 253,
    contentType: 'text/x-log; charset=utf-8',
    name: 'stdout.log',
    bucket: 'colossal-dev-2fee3211-cd2d-4025-b104-d61acc9e69b9',
    crc32c: 2754533221,
    provenance: {
      events: [
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.WorkflowRunInput,
          actor: '391aebf2-f21d-4ced-b5ba-424872ba8251',
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Written,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
        {
          createdAt: '2023-09-15T15:16:40.256Z',
          eventType: ProvenanceEventType.Deleted,
          crc32c: 1349829872,
          size: 309,
          generation: 123456,
        },
      ],
      origin: {
        type: ProvenanceOriginType.Unknown,
      },
      validations: null,
    },
  },
  {
    metadata: null,
    size: 0,
    contentType: '',
    name: 'toolrun-outputs/',
    bucket: 'colossal-dev-2fee3211-cd2d-4025-b104-d61acc9e69b9',
    crc32c: 0,
  },
  {
    metadata: null,
    size: 0,
    contentType: '',
    name: 'workflowSpreadsheetFiles/',
    bucket: 'colossal-dev-2fee3211-cd2d-4025-b104-d61acc9e69b9',
    crc32c: 0,
  },
];
