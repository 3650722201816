import { getHref, navRoutes } from '@/utils/routes';
import { useProjects } from '@formbio/api';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAuth } from '../auth';

/**
 * A hook that intercepts the domain assay.formbio.com and redirects any page not already in the `synth-assays` paths to the `synth-assays-welcome` page.
 * If user is on a project that is an ISA project, they will also be redirected to the `synth-assays-welcome` page if that's their only project.
 *
 * The goal is that if users are on sbx.assays.formbio.com or the staging and prod equivalents, they can only access synth-assays pages.
 */
export default function useSubdomainRedirect() {
  const router = useRouter();
  const { user } = useAuth();
  const { data: projects } = useProjects({ enabled: !!user });
  useEffect(() => {
    const isIsaProject =
      projects?.length &&
      // if users have multiple projects, redirect only if they are all ISA projects
      projects.every(project => project.isIsa);
    if (
      (window.location.origin.includes('assays.formbio.com') ||
        window.location.origin.includes('vectorflow.formbio.com') ||
        isIsaProject) &&
      !router.pathname.startsWith(`/404`) &&
      !router.pathname.startsWith(navRoutes.synthAssays.pathname) &&
      !router.pathname.startsWith(navRoutes.caas.pathname) &&
      !router.pathname.startsWith(navRoutes.synthAssaysLanding.pathname) &&
      !router.pathname.startsWith('/auth/')
    ) {
      router.push(getHref(router, navRoutes.synthAssaysLanding));
    }
  }),
    [router.pathname, window.location.href];
}
