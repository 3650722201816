import { useEffect } from 'react';
import {
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  createOrgApp,
  deleteOrgApp,
  editOrgApp,
  fetchAllAvailable,
  fetchAllOrgApps,
  fetchOneAvailable,
  fetchOneOrgApp,
} from '../../services/container-apps';
import { ContainerApp, CreateOrgAppPayload } from '../../types';
import useInvalidation from '../useInvalidation';

const AVAILABLE_KEY = 'available-apps';

// want vm specs to default to large on production and xs everywhere else
function updateEnvSpecificDefault(app: ContainerApp) {
  const isProd = process.env.NEXT_PUBLIC_STAGE === 'production';
  const isRStudio = app.appName === 'rstudio';
  const isJupyter = app.appName === 'jupyterLab';

  if (isRStudio || isJupyter) {
    const vmSpec = app.schema.inputs.vmSpec;
    const isValid = !!vmSpec && 'default' in vmSpec;
    if (isProd && isValid) {
      vmSpec.default = 'l';
    } else if (isValid) {
      vmSpec.default = 'xs';
    }
  }

  return app;
}

function updateEnvSpecificDefaults(apps: ContainerApp[]) {
  return apps.map(updateEnvSpecificDefault);
}

export function useAvailableContainerApps(
  oid: string,
  pid: string,
  options: Omit<UseQueryOptions<ContainerApp[]>, 'queryKey' | 'queryFn'> = {},
) {
  const client = new QueryClient();
  const query = useQuery<ContainerApp[]>({
    queryKey: [AVAILABLE_KEY, { oid, pid }],
    queryFn: () => fetchAllAvailable({ oid, pid }),
    ...{
      ...options,
      select: updateEnvSpecificDefaults,
    },
  });

  useEffect(() => {
    if (query.isSuccess) {
      query.data?.forEach((container) => {
        client.setQueriesData(
          {
            queryKey: [AVAILABLE_KEY, { oid, pid, appName: container.appName }],
          },
          container,
        );
      });
    }
  }, [query.isSuccess]);

  return query;
}

export function useAvailableContainerApp(
  oid: string,
  pid: string,
  ownerOrg: string,
  appName: string,
  options: Omit<UseQueryOptions<ContainerApp>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<ContainerApp>({
    queryKey: [AVAILABLE_KEY, { oid, pid, appName }],
    queryFn: () => fetchOneAvailable({ oid, pid, ownerOrg, appName }),
    ...{
      ...options,
      select: updateEnvSpecificDefault,
    },
  });
}

const ORG_KEY = 'org-apps';

export function useOrgContainerApps(oid: string, options = {}) {
  return useQuery<ContainerApp[]>({
    queryKey: [ORG_KEY, { oid }],
    queryFn: () => fetchAllOrgApps({ oid }),
    ...options,
  });
}

export function useOrgContainerApp(oid: string, appName: string, options = {}) {
  return useQuery<ContainerApp>({
    queryKey: [ORG_KEY, { oid, appName }],
    queryFn: () => fetchOneOrgApp({ oid, appName }),
    ...options,
  });
}

export function useCreateOrgContainerApp(
  oid: string,
  pid: string,
  options = {},
) {
  const invalidateOrg = useInvalidation(ORG_KEY);
  const invalidateAvailable = useInvalidation(AVAILABLE_KEY);
  return useMutation({
    mutationKey: [ORG_KEY, { oid }],
    mutationFn: async (payload: CreateOrgAppPayload) => {
      await createOrgApp({ oid, payload });
      invalidateOrg({ oid });
      invalidateAvailable({ oid, pid });
    },
    ...options,
  });
}

export function useEditOrgContainerApp(
  oid: string,
  pid: string,
  appName?: string,
  options = {},
) {
  const invalidateOrg = useInvalidation(ORG_KEY);
  const invalidateAvailable = useInvalidation(AVAILABLE_KEY);
  return useMutation({
    mutationKey: [ORG_KEY, { oid, appName }],
    mutationFn: async (payload: CreateOrgAppPayload) => {
      await editOrgApp({ oid, payload });
      invalidateOrg({ oid });
      invalidateOrg({ oid, appName: payload.appName });
      invalidateAvailable({ oid, pid });
      invalidateAvailable({ oid, pid, appName: payload.appName });
    },
    ...options,
  });
}

export function useDeleteOrgContainerApp(
  oid: string,
  pid: string,
  appName: string,
  options = {},
) {
  const invalidateOrg = useInvalidation(ORG_KEY);
  const invalidateAvailable = useInvalidation(AVAILABLE_KEY);
  return useMutation({
    mutationKey: [ORG_KEY, { oid, appName }],
    mutationFn: async () => {
      await deleteOrgApp({ oid, appName });
      invalidateOrg({ oid });
      invalidateOrg({ oid, appName });
      invalidateAvailable({ oid, pid });
      invalidateAvailable({ oid, pid, appName });
    },
    ...options,
  });
}
