import {
  AdminWorkflowVersion,
  WorkflowSchemaStatus,
  WorkflowSchemaVisibility,
} from '../../../types';
import { admin_api } from '../../../utils/admin-api';

export async function fetchAllAdmin({ deleted }: { deleted: boolean }) {
  const { data } = await admin_api.get<{
    workflowVersions: AdminWorkflowVersion[];
  }>(`/workflows-deprecated?deleted=${deleted}`);
  return data.workflowVersions;
}

export interface WorkflowVersionPatch {
  visibility?: WorkflowSchemaVisibility;
  status?: WorkflowSchemaStatus;
}

export async function adminPatchWorkflowVersion(
  versionId: string,
  patchPayload: WorkflowVersionPatch,
) {
  const { data } = await admin_api.patch<AdminWorkflowVersion>(
    `/workflows-deprecated/versions/${versionId}`,
    patchPayload,
  );
  return data;
}

export function adminDeleteWorkflowVersion(versionId: string) {
  return admin_api.delete(`/workflows-deprecated/versions/${versionId}`);
}

export function adminUndeleteWorkflowVersion(versionId: string) {
  return admin_api.patch(
    `/undelete/workflows-deprecated/versions/${versionId}`,
  );
}
