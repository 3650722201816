import {
  CircularProgress as MuiCircularProgress,
  circularProgressClasses,
} from '@mui/material';
import { Box, styled, useTheme } from '@formbio/ui/components';

// styled
const BackgroundCircularProgress = styled(MuiCircularProgress, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<{
  backgroundColor?: string;
}>(({ theme, backgroundColor }) => {
  return {
    color: backgroundColor || theme.palette.info[400],
  };
});
const ForegroundCircularProgress = styled(MuiCircularProgress)<{
  foregroundcolor?: string;
}>(({ theme, foregroundcolor }) => {
  return {
    'svg circle': { stroke: 'url(#loading-gradient)' },
    position: 'absolute',
    left: 0,
    color: foregroundcolor || theme.palette.info[700],
    animationDuration: '1500ms',
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
      // about a quarter of the full circle
      strokeDasharray: '30px,200px',
    },
  };
});

const StyledSvgWrapper = styled('svg')({
  position: 'absolute',
});

const StyledBox = styled(Box)({
  position: 'relative',
});

export const CircularLoading = ({
  size = 35,
  thickness = 6,
  backgroundColor,
  foregroundColor,
}: {
  size?: number;
  thickness?: number;
  backgroundColor?: string;
  foregroundColor?: string;
}) => {
  const theme = useTheme();

  const bgColor =
    backgroundColor || theme.palette.mode === 'light'
      ? theme.palette.primary[400]
      : '#FFFFFF00';
  const fgColor =
    foregroundColor || theme.palette.mode === 'light'
      ? theme.palette.primary[800]
      : theme.palette.info[700];

  return (
    <>
      <StyledBox>
        <BackgroundCircularProgress
          variant='determinate'
          size={size}
          thickness={thickness}
          value={100}
          backgroundColor={bgColor}
        />
        <StyledSvgWrapper>
          <defs>
            <linearGradient
              id='loading-gradient'
              x1='0%'
              y1='0%'
              x2='0%'
              y2='100%'
            >
              <stop offset='0%' stopColor={bgColor} />
              <stop offset='40%' stopColor={bgColor} />
              <stop offset='100%' stopColor={fgColor} />
            </linearGradient>
          </defs>
        </StyledSvgWrapper>
        <ForegroundCircularProgress
          variant='indeterminate'
          size={size}
          thickness={thickness}
          disableShrink
          foregroundcolor={fgColor}
        />
      </StyledBox>
    </>
  );
};
