import { Skeleton } from '@formbio/ui';
import { styled } from '@mui/material';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export default function DataGridLoading({
  rowCount = 10,
  rowHeight = '50px',
}: {
  rowCount?: number;
  rowHeight?: number | string;
}) {
  return (
    <StyledContainer>
      {Array.from({ length: rowCount }, (_, idx) => (
        <Skeleton height={rowHeight} key={idx} variant='rounded' />
      ))}
    </StyledContainer>
  );
}
