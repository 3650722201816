import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material';

type SkeletonProps = Omit<MuiSkeletonProps, 'sx'>;

export const Skeleton = ({ ...props }: SkeletonProps) => (
  <MuiSkeleton {...props} />
);
