import { forwardRef } from 'react';
import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  styled,
} from '@mui/material';
import { CaretDown as CaretDownIcon } from '../Icons';

export type SelectProps = Omit<MuiSelectProps, 'classes' | 'sx' | 'ref'>;

export const Select = forwardRef(
  (props: SelectProps, ref?: React.Ref<HTMLElement>) => {
    const { children, ...rest } = props;
    return (
      <MuiSelect ref={ref} {...rest}>
        {children}
      </MuiSelect>
    );
  },
);

Select.displayName = 'Select';

/**
 * When building a select, use this component as the `IconComponent` prop.
 */
export const IconComponent = styled(CaretDownIcon)(({ theme }) => ({
  width: theme.spacing(2),
  marginRight: theme.spacing(0.4), // looks slightly better centered then with margin: auto
}));
