import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CaretDownFilledBackgroundRoundedIcon(
  props: SvgIconProps,
) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' {...props}>
      <rect width='20' height='20' rx='4' fill='#F9F8F9' />
      <path
        d='M15.4628 7.80625C15.4246 7.71525 15.3603 7.63761 15.278 7.58311C15.1957 7.52861 15.0991 7.49969 15.0003 7.5H5.00034C4.90163 7.49969 4.80503 7.52861 4.72273 7.58311C4.64042 7.63761 4.57609 7.71525 4.53784 7.80625C4.50182 7.89861 4.49268 7.99927 4.51148 8.09661C4.53029 8.19394 4.57626 8.28396 4.64409 8.35625L9.64409 13.3563C9.73966 13.4487 9.8674 13.5003 10.0003 13.5003C10.1333 13.5003 10.261 13.4487 10.3566 13.3563L15.3566 8.35625C15.4244 8.28396 15.4704 8.19394 15.4892 8.09661C15.508 7.99927 15.4989 7.89861 15.4628 7.80625V7.80625Z'
        fill='#6F6E77'
      />
    </SvgIcon>
  );
}
