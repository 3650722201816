import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiOutlinedInput: CustomComponent<'MuiOutlinedInput'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.primary[800],

      '& label.Mui-focused': {
        color: theme.palette.secondary[600],
      },
      '& input': {
        backgroundColor: theme.palette.primary[800],
        '&:autofill': {
          boxShadow: `0 0 0 100px ${theme.palette.info[700]} inset`,
        },
      },
      '& fieldset': {
        borderColor: theme.palette.primary[400],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary[500],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[600],
        borderWidth: '1px',
        boxShadow: `0px 0px 0px 4px ${theme.palette.secondary[300]}`,
      },
    }),
  },
};
