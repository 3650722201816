import { featureFlagIds } from '@/config';
import { isDateAfter } from '@/utils/datetime';
import { useNotificationContext } from '../context/notificationContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useEffect } from 'react';
import { formatDateAndTime } from '@formbio/ui/utils/datetime';

const MAINTENANCE_START_TIME_KEY = 'FB__MAINTENANCE_START_TIME';

type MaintenanceFlag = {
  startTime?: string;
  endTime?: string;
  message?: string;
};

export default function useMaintenanceAlert() {
  const flags = useFlags();
  const { setSnackbar } = useNotificationContext();
  const maintenanceAlert =
    flags[featureFlagIds.enableMaintenanceTimeWindowAlert];

  const [maintenanceTimestamp, setMaintenanceTimestamp] = useLocalStorage({
    key: MAINTENANCE_START_TIME_KEY,
    initialValue: '',
  });

  function setAlertSnackbar({ startTime, endTime, message }: MaintenanceFlag) {
    setSnackbar({
      autoHideDuration: 10000,
      variant: 'warning',
      children: (
        <>
          {message ||
            `The Form Bio Platform will be temporarily down for maintenance ${
              endTime
                ? `approximately from ${formatDateAndTime(
                    startTime,
                  )} to ${formatDateAndTime(endTime)}`
                : `starting approximately at ${formatDateAndTime(startTime)}`
            }.`}
        </>
      ),
    });
  }

  useEffect(() => {
    const maintenanceAlertHasValues =
      maintenanceAlert &&
      Object.values(maintenanceAlert).filter(value => !!value).length;

    if (maintenanceAlert && maintenanceAlertHasValues) {
      const { startTime } = maintenanceAlert;

      const now = new Date();

      // If either user hasn't had timestamp set to local storage, or that timestamp is > 24 hours old, show alert.
      // (Assumes startTime has been formatted with timezone as prescribed in Launch Darkly description.)
      const shouldSetSnackbar =
        startTime &&
        typeof startTime === 'string' &&
        !isDateAfter(now, new Date(startTime)) &&
        (!maintenanceTimestamp ||
          isDateAfter(
            new Date(maintenanceTimestamp),
            new Date(now.setDate(now.getDate() + 1)),
          ));

      if (shouldSetSnackbar) {
        setAlertSnackbar(maintenanceAlert);

        setMaintenanceTimestamp(now.toString());
      }
    }
  }, [flags[featureFlagIds.enableMaintenanceTimeWindowAlert]]);
}
