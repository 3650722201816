import { Paper, styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import { ReactNode } from 'react';

const StyledEmptyPrompt = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.primary[100],
  border: `1px solid ${theme.palette.primary[400]}`,
  borderRadius: 4,
  marginTop: theme.spacing(1),
  boxShadow: 'none',
}));

const Message = styled(Typography)({
  fontWeight: 500,
});

const EmptyPrompt = ({ message }: { message: string | ReactNode }) => {
  return (
    <StyledEmptyPrompt>
      <Message>{message}</Message>
    </StyledEmptyPrompt>
  );
};

export default EmptyPrompt;
