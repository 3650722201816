import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  CreateJourneyRunData,
  DeletePhaseRunData,
  create,
  deletePhaseRun,
  fetchAll,
  fetchOne,
} from '../../services/journey-runs';
import { JourneyRun } from '../../types';
import useInvalidation from '../useInvalidation';

const KEY = 'journey-runs';

export function useJourneyRuns(options?: UseQueryOptions<JourneyRun[]>) {
  return useQuery<JourneyRun[]>({
    queryKey: [KEY],
    queryFn: () => fetchAll(),
    ...options,
  });
}

export function useJourneyRun(
  params: Parameters<typeof fetchOne>[0],
  options = {},
) {
  return useQuery({
    queryKey: [KEY, params],
    queryFn: () => fetchOne(params),
    ...{
      enabled: !!params.journeyRunId,
      ...options,
    },
  });
}

export function useAddJourneyRun(
  options?: UseMutationOptions<JourneyRun, unknown, CreateJourneyRunData>,
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<JourneyRun, unknown, CreateJourneyRunData>({
    mutationFn: (data: CreateJourneyRunData) => create(data),
    ...{
      ...options,
      onSuccess: (data, variables, context) => {
        invalidate();
        if (options?.onSuccess) {
          invalidate();
          return options.onSuccess(data, variables, context);
        }
        return invalidate();
      },
    },
  });
}

export function useDeletePhaseRun(
  options: UseMutationOptions<unknown, unknown, DeletePhaseRunData> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<
    unknown,
    unknown,
    { journeyRunId: string; phaseRunId: string }
  >({
    mutationFn: (params: DeletePhaseRunData) => deletePhaseRun(params),
    ...{
      ...options,
      onSuccess: async (data, variables, context) => {
        if (options?.onSuccess) {
          await invalidate();
          return options.onSuccess(data, variables, context);
        }
        return invalidate();
      },
    },
  });
}
