import { formatDatacoreParams } from '../../utils/datacore';
import {
  DatacoreParams,
  DatacorePayload,
  DatacoreRelationshipDataPayload,
  DatacoreRelationshipTypesPayload,
  DatacoreResponse,
  DatacoreResponseWithDataArray,
  DatacoreResponseWithDataObject,
} from '../../types';
import { api } from '../../utils/api';

export async function fetchAllDatacoreEntities(
  oid: string,
  pid: string,
  entityType: string,
  params?: DatacoreParams,
): Promise<DatacoreResponseWithDataArray> {
  return api
    .get<DatacoreResponseWithDataArray>(
      `/orgs/${oid}/${pid}/datacore/entities/${entityType}?${formatDatacoreParams(
        params,
      )}`,
    )
    .then(({ data }) => data);
}

export async function fetchDatacoreEntity(
  oid: string,
  pid: string,
  entityType: string,
  entityId: string,
  params?: DatacoreParams,
): Promise<DatacoreResponse> {
  return api
    .get<DatacoreResponse>(
      `/orgs/${oid}/${pid}/datacore/entities/${entityType}/${entityId}?${formatDatacoreParams(
        params,
      )}`,
    )
    .then(({ data }) => data);
}

export async function fetchAllDatacoreTypes(
  oid: string,
  params?: DatacoreParams,
): Promise<DatacoreResponse> {
  return api
    .get<DatacoreResponse>(
      `/orgs/${oid}/datacore/types?${formatDatacoreParams(params)}`,
    )
    .then(({ data }) => data);
}

export async function fetchAllProjectDatacoreTypes(
  oid: string,
  pid: string,
  params?: DatacoreParams,
): Promise<DatacoreRelationshipTypesPayload> {
  return api
    .get<DatacoreRelationshipTypesPayload>(
      `/orgs/${oid}/${pid}/relationships/datacore/types?${formatDatacoreParams(
        params,
      )}`,
    )
    .then(({ data }) => data);
}

/**
 * Updates direct attributes of an entity
 * like name
 */
export async function updateDatacoreEntity({
  orgId,
  pid,
  entityType,
  entityId,
  payload,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  entityId: string;
  payload: DatacorePayload;
}) {
  return api.post<DatacoreResponse>(
    `/orgs/${orgId}/${pid}/datacore/entities/${entityType}/${entityId}`,
    payload,
  );
}

export async function createDatacoreEntity({
  orgId: oid,
  pid,
  entityType,
  data,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  data: DatacorePayload;
}) {
  return api
    .post<DatacoreResponse>(
      `/orgs/${oid}/${pid}/datacore/entities/${entityType}`,
      data,
    )
    .then(({ data }) => data);
}

export async function updateDatacoreEntityRelationships({
  orgId,
  pid,
  entityType,
  entityId,
  payload,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  entityId: string;
  payload: DatacoreRelationshipDataPayload;
}) {
  return api
    .post<DatacoreResponse>(
      `/orgs/${orgId}/${pid}/datacore/entities/${entityType}/${entityId}/relationships/data`,
      payload,
    )
    .then(({ data }) => data);
}

export async function fetchDatacoreType(
  oid: string,
  entityTypeId: string,
  params?: DatacoreParams,
): Promise<DatacoreResponseWithDataObject> {
  return api
    .get<DatacoreResponseWithDataObject>(
      `/orgs/${oid}/datacore/types/${entityTypeId}?${formatDatacoreParams(
        params,
      )}`,
    )
    .then(({ data }) => data);
}

export async function fetchDatacoreTypeVersions(
  oid: string,
  entityTypeId: string,
  params?: DatacoreParams,
): Promise<DatacoreResponseWithDataArray> {
  return api
    .get<DatacoreResponseWithDataArray>(
      `/orgs/${oid}/datacore/types/${entityTypeId}/versions?${formatDatacoreParams(
        params,
      )}`,
    )
    .then(({ data }) => data);
}

export async function deleteDatacoreEntity(
  oid: string,
  pid: string,
  datacoreType: string,
  datacoreEntityId: string,
) {
  //TODO: Currently the API returns no response when a delete is successful
  return api.delete<DatacoreResponse>(
    `/orgs/${oid}/${pid}/datacore/entities/${datacoreType}/${datacoreEntityId}`,
  );
}
