import { Box, styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import { LinearProgress } from '@formbio/ui/components/LinearProgress';

// loading bar styles that will remove jumping effect
const StyledLinearProgress = styled(LinearProgress)({
  height: 4,
  marginTop: '10vh',
  marginBottom: '1em',
});

export default function LoadingPageProgress({
  text = 'Loading...',
}: {
  text?: string;
}) {
  return (
    <Box textAlign='center' paddingX='2em'>
      <StyledLinearProgress />
      <Typography>{text}</Typography>
    </Box>
  );
}
