import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiLink: CustomComponent<'MuiLink'> = {
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary[500],
    }),
  },
};
