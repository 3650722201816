import { delay, http, HttpResponse } from 'msw';

const fetchAllGenomes = http.get(`*/api/v1/genomes`, async () => {
  delay(3000);
  return HttpResponse.json(null, {
    status: 500,
    statusText: 'Cannot fetch genomes',
  });
});

const fetchAllGenomesMetadata = http.get(
  `*/api/v1/orgs/:oid/:pid/genomes/metadata`,
  async ({ request }) => {
    const url = new URL(request.url);
    if (url.searchParams.get('igv') === 'true') {
      return HttpResponse.json(null, {
        status: 500,
        statusText: 'Cannot fetch IGV genomes metadata',
      });
    }
    delay(3000);
    return HttpResponse.json([]);
  },
);

const fetchIGVGenome = http.get(
  `*/api/v1/orgs/:oid/:pid/genomes/metadata/:genomeId`,
  () =>
    HttpResponse.json(null, {
      status: 500,
      statusText: 'Cannot fetch IGV genome',
    }),
);

export const genomeHandlers = [
  fetchAllGenomes,
  fetchAllGenomesMetadata,
  fetchIGVGenome,
];
