/** Pulled from MUI's recipe:
 * https://github.com/mui/material-ui/blob/a773cc69ea7f626ab87b68234e058fe33e659018/examples/nextjs-with-typescript/src/Link.tsx
 * */
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { forwardRef } from 'react';
import { LinkProps } from '..';
import { Link as MuiLink } from '..';

type NextLinkComposedProps = Omit<NextLinkProps, 'href'> & {
  _href: NextLinkProps['href'];
};

const NextLinkComposed = forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  function NextLinkComposed({ _href, ...props }, ref) {
    return <NextLink {...props} href={_href} ref={ref} />;
  },
);

type CombinedLinkProps = LinkProps<typeof NextLink>;
export type NextMuiLinkProps = Omit<CombinedLinkProps, 'href'> & {
  nextHref: NextLinkProps['href'];
  activeClassName?: string;
  disabled?: boolean;
};

const NextMuiLink = forwardRef<HTMLAnchorElement, NextMuiLinkProps>(
  function NextMuiLink({ nextHref, className, disabled, ...props }, ref) {
    // use _href props of CustomNextLink to set the href
    return (
      <MuiLink
        component={NextLinkComposed}
        className={className}
        disabled={disabled}
        _href={nextHref}
        ref={ref}
        {...props}
      />
    );
  },
);

export default NextMuiLink;
