import { faker } from '@faker-js/faker';
import { WorkflowRun, WorkflowRunStatus } from '@formbio/api';

export const workflowRuns: WorkflowRun[] = [
  {
    createdAt: '2022-01-01T17:57:25.845623Z',
    id: 'cc3e2fe5-a420-41b2-93bb-3840d3b3eba9',
    name: 'Fake Workflow',
    outputDirectory:
      'formbio://my-organization/my-project/d32e2f06-987a-4d2c-a249-9015624f631a/',
    nextflowVersion: '22.05.0-edge',
    params: {},
    repository: 'formbio/nf-example-pipeline',
    repositoryVersion: 'main',
    canVisualizeWithRShiny: false,
    executions: [
      {
        id: '25608ffd-976c-450f-8650-10ff7b7d54a4',
        workflowRunId: 'cc3e2fe5-a420-41b2-93bb-3840d3b3eba9',
        userName: 'John Doe',
        userEmail: 'john+doe@formbio.com',
        status: WorkflowRunStatus.Completed,
        createdAt: '2022-01-01T17:57:25.845623Z',
        updatedAt: '2022-01-01T18:09:44.942137Z',
        workflowStatus: '',
      },
    ],
    executionEngine: 'nextflow',
    workflowDisplayName: 'Fake Workflow',
    usage: {
      calculatedAt: faker.date.recent().toISOString(),
      isComplete: true,
      totalUsage: 0.45356,
      workflowRunId: 'cc3e2fe5-a420-41b2-93bb-3840d3b3eba9',
    },
  },
  {
    createdAt: '2023-01-01T10:57:25.845623Z',
    id: 'ffffff-a420-41b2-93bb-38fff3b3fff',
    name: 'Fake Workflow',
    outputDirectory:
      'formbio://my-organization/my-project/d32e2f06-987a-4d2c-a249-9015624f631a/',
    nextflowVersion: '22.05.0-edge',
    params: {},
    repository: 'formbio/nf-example-pipeline',
    repositoryVersion: 'main',
    canVisualizeWithRShiny: false,
    executions: [
      {
        id: 'ffffffff-976c-450f-8650-10ff7b7d54a4',
        workflowRunId: 'ffffff-a420-41b2-93bb-38fff3b3fff',
        userName: 'Jane Doe',
        userEmail: 'jane+doe@formbio.com',
        status: WorkflowRunStatus.CompletedWithWarning,
        createdAt: '2023-01-01T10:57:25.845623Z',
        updatedAt: '2023-01-01T11:09:44.942137Z',
        workflowStatus: '',
      },
    ],
    executionEngine: 'nextflow',
    workflowDisplayName: 'Fake Workflow 2',
    usage: {
      calculatedAt: faker.date.recent().toISOString(),
      isComplete: true,
      totalUsage: 0.123456,
      workflowRunId: 'ffffff-a420-41b2-93bb-38fff3b3fff',
    },
  },
];
