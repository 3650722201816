import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { bulkUploadVectors } from '../../services/vectors';
import { VectorBulkUpload, VectorBulkUploadResponse } from '../../types';

export function useBulkuploadVectors(
  options?: UseMutationOptions<
    AxiosResponse<VectorBulkUploadResponse>,
    AxiosError<VectorBulkUploadResponse>,
    VectorBulkUpload
  >,
) {
  return useMutation<
    AxiosResponse<VectorBulkUploadResponse>,
    AxiosError<VectorBulkUploadResponse>,
    VectorBulkUpload
  >({
    mutationFn: (data: VectorBulkUpload) => bulkUploadVectors(data),
    ...options,
  });
}
