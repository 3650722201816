import { useState } from 'react';
import { Role } from '@formbio/ui/types/member';
import { styled, Stack, FormControl } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import { InputLabel } from '@formbio/ui/components/InputLabel';
import { Button } from '@formbio/ui/components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@formbio/ui/components/Dialog';
import { DialogHeader } from '@formbio/ui/components/Dialog/DialogHeader';
import { Select } from '@formbio/ui/components/Select';
import { MenuItem } from '@formbio/ui/components/Menu';
import { Alert } from '@formbio/ui/components/Alert';
import { DialogLinearProgress } from '@formbio/ui/components/LinearProgress';
import { AvatarSizes } from '@formbio/ui/components/Avatar/TextAvatar';
import { Spacer } from '@formbio/ui/components/Layout';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AvatarPicture from '../../Avatar/AvatarPicture';

/*
 * styled
 */
const RoleName = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  textTransform: 'capitalize',
  color: 'inherit',
}));

const UserContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const UserAvatar = styled(Stack)({});

const UserName = styled(Stack)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flex: 1,
}));

const UserRole = styled(Stack)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flex: 1,
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: theme.typography.body1?.fontSize,
  fontWeight: 700,
  color: theme.palette.primary[700],
  lineHeight: 1,
}));

type ChangeRoleDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: ({ role }: { role: Role }) => void;
  user: {
    name: string;
    email: string;
    role: Role;
  };
  roles: Role[];
  isLoading?: boolean;
};

type FormData = {
  role: string;
};

const schema = yup.object().shape({
  role: yup.string().required('Role is required'),
});

const ChangeRoleDialog = ({
  user,
  roles,
  isLoading,
  onClose,
  onSubmit,
  open,
}: ChangeRoleDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      role: user.role,
    },
    resolver: yupResolver(schema),
  });

  const role = watch('role');

  const handleFormSubmit = () => {
    onSubmit({ role: role as Role });
    setIsSubmitting(true);
  };

  const isDisabled =
    isLoading || isSubmitting || role == user.role || !!errors.role;

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogHeader title='Change Role' onClose={onClose} />
        <DialogContent>
          {errors.role ? (
            <>
              <Alert severity='error'>{errors.role?.message}</Alert>
              <Spacer size='l' />
            </>
          ) : null}
          <UserContainer>
            <UserAvatar>
              <AvatarPicture
                avatar={{ displayName: user.name, email: user.email }}
                size={AvatarSizes.large}
              />
            </UserAvatar>
            <UserName>
              <Typography variant='body1'>
                <b>{user.name}</b>
              </Typography>
              <Typography variant='body2'>{user.email}</Typography>
            </UserName>
            <UserRole>
              <StyledInputLabel id='role-label'>Role</StyledInputLabel>

              <FormControl>
                <Controller
                  control={control}
                  name='role'
                  render={({ field }) => (
                    <Select
                      aria-label={`Role for ${user.email}`}
                      disabled={isLoading}
                      {...field}
                    >
                      {roles.map(role => (
                        <MenuItem value={role} key={role}>
                          <RoleName>{role}</RoleName>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </UserRole>
          </UserContainer>
          {isLoading ? <DialogLinearProgress /> : null}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Close
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={isDisabled}
            type='submit'
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { ChangeRoleDialog };
