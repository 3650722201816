import { JourneySchema } from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(): Promise<JourneySchema[]> {
  return api.get<JourneySchema[]>(`/journeys`).then(({ data }) => data);
}

export function fetchOne({ slug }: { slug: string }) {
  return api.get<JourneySchema>(`/journeys/${slug}`).then(({ data }) => data);
}
