import { VadrScores } from '../../types/vadr-scores';
import { api } from '../../utils/api';

export async function fetchAll(
  oid: string,
  pid: string,
  vectorIds?: string[],
): Promise<VadrScores[]> {
  const params = new URLSearchParams();
  if (vectorIds?.length) {
    params.append('filter[vector]', vectorIds.join(','));
  }
  return api
    .get<VadrScores[]>(`/orgs/${oid}/${pid}/vadr/scores?${params.toString()}`)
    .then(({ data }) => data);
}
