import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  WarningCircle as WarningCircleIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
} from '@formbio/ui/components/Icons';

export type AlertProps = Omit<MuiAlertProps, 'square'>;

export const Alert = ({ ...rest }: AlertProps) => (
  <MuiAlert
    {...rest}
    square={true}
    iconMapping={{
      success: <CheckCircleIcon />,
      info: <InfoIcon />,
      warning: <WarningIcon weight='fill' />,
      error: <WarningCircleIcon />,
    }}
  />
);
