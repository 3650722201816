import { HttpResponse, http } from 'msw';

const evalX = http.get('https://app.launchdarkly.com/sdk/evalx/*', () =>
  HttpResponse.json({
    'formbio-theme': {
      flagVersion: 34,
      trackEvents: false,
      value: true,
      variation: 0,
      version: 182,
    },
  }),
);

export const ldHandlers = [evalX];
