import { CustomComponent } from '@formbio/ui/theme/type-extension';

const themeShadows12 =
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)';

export const MuiTooltip: CustomComponent<'MuiTooltip'> = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.primary[50],
      color: theme.palette.primary[900],
      boxShadow: themeShadows12,
      fontSize: theme.typography.body2?.fontSize,
      lineHeight: theme.typography.body2?.lineHeight,
      fontWeight: theme.typography.body2?.fontWeight,
    }),
  },
};
