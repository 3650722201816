import {
  CreateIndicationParams,
  DATACORE_INDICATION_TYPE,
  DatacoreResponse,
} from '../../types';
import { api } from '../../utils/api';

export function createIndication({
  orgId,
  pid,
  indicationName,
}: CreateIndicationParams) {
  return api.post<DatacoreResponse>(
    `/orgs/${orgId}/${pid}/datacore/entities/${DATACORE_INDICATION_TYPE}`,
    {
      data: {
        type: 'entities',
        attributes: {
          name: indicationName,
          typeName: DATACORE_INDICATION_TYPE,
        },
      },
    },
  );
}
