import type { WorkflowMarkdown } from '../../types';
import { api } from '../../utils/api';

export function fetchOne({
  oid,
  pid,
  wid,
  repo,
  version,
  uploadedOrg,
  uploadedProj,
}: {
  oid: string;
  pid: string;
  wid: string;
  repo: string;
  version: string | undefined;
  uploadedOrg: string | undefined;
  uploadedProj: string | undefined;
}) {
  let url = `/orgs/${oid}/${pid}/workflows/docs/${uploadedOrg}/${uploadedProj}/${repo}/${wid}`;
  version && (url += `?version=${version}`);

  return api.get<WorkflowMarkdown>(url).then(({ data }) => data);
}
