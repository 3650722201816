import { http, HttpResponse, delay, PathParams } from 'msw';
import { allJourneys } from './data/journeys';
import { JourneyRun } from '@formbio/api';
import {
  CreateJourneyRunData,
  DeletePhaseRunData,
} from '@formbio/api/src/services/journey-runs';
import { allJourneyRuns } from './data/journey-runs';
import {
  timelapseJourneyRun,
  timelapsePhaseRuns,
} from './data/journey-run-timelapse';
import { findIndex } from 'lodash';

let mutableJourneyRuns: JourneyRun[] = JSON.parse(
  JSON.stringify(allJourneyRuns),
);

let timelapseFetchCounter = 0;

const fetchAllJourneyRuns = http.get(`*/api/v1/journey-runs`, () =>
  HttpResponse.json(mutableJourneyRuns),
);

const fetchOneJourneyRun = http.get(
  `*/api/v1/journey-runs/:slug`,
  async ({ params }) => {
    const { slug } = params;
    // a special journey run handling which simulates a timelapse
    // of a journey run
    // each call to this endpoint will return the next phase run
    // then cycle back to the first phase run
    if (slug === 'timelapse-journey-run') {
      timelapseFetchCounter++;
      if (timelapseFetchCounter >= timelapsePhaseRuns.length) {
        timelapseFetchCounter = 0;
      }
      return HttpResponse.json({
        ...timelapseJourneyRun,
        phaseRuns: timelapsePhaseRuns[timelapseFetchCounter],
      });
    }
    await delay(1000);
    return HttpResponse.json(mutableJourneyRuns.find(run => run.slug === slug));
  },
);

const addJourneyRun = http.post<PathParams, CreateJourneyRunData>(
  `*/orgs/:oid/:pid/journey-runs`,
  async ({ request }) => {
    const data = await request.json();
    const journey = allJourneys.find(
      journey => journey.slug === data.journeyId,
    );
    if (journey) {
      const newJourneyRun: JourneyRun = {
        journey,
        id: `journey-run${mutableJourneyRuns.length + 1}`,
        slug: data.runName.replaceAll(' ', '-'),
        phaseRuns: [],
        runName: data.runName,
      };
      mutableJourneyRuns.push(newJourneyRun);
      await delay(500);
      return HttpResponse.json(newJourneyRun, { status: 201 });
    }
    return HttpResponse.json(null, {
      status: 500,
      statusText: 'Could not find journey: ' + data.journeyId,
    });
  },
);

const deletePhaseRun = http.delete<DeletePhaseRunData>(
  `*/journey-runs/:journeyRunId/:phaseRunId`,
  async ({ params }) => {
    const { journeyRunId, phaseRunId } = params;
    const journeyRunToEdit = mutableJourneyRuns.find(
      journeyRun => journeyRun.slug === journeyRunId,
    );
    if (journeyRunToEdit) {
      const updatedJourneyRun: JourneyRun = {
        ...journeyRunToEdit,
        phaseRuns: journeyRunToEdit.phaseRuns.slice(
          0,
          findIndex(
            journeyRunToEdit.phaseRuns,
            phaseRun => phaseRun.id === phaseRunId,
          ),
        ),
      };
      mutableJourneyRuns = [
        ...mutableJourneyRuns.filter(
          journeyRun => journeyRun.id !== updatedJourneyRun.id,
        ),
        updatedJourneyRun,
      ];
      await delay(500);
      return HttpResponse.json(updatedJourneyRun, { status: 201 });
    }
    return HttpResponse.json(null, {
      status: 500,
      statusText: 'Could not find journey run: ' + journeyRunId,
    });
  },
);

export const journeyRunHandlers = [
  fetchAllJourneyRuns,
  fetchOneJourneyRun,
  addJourneyRun,
  deletePhaseRun,
];
