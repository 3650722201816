import { useState, ReactNode, createContext, useContext } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query';
import { useOnNetworkError } from '@/context/notificationContext';

type QueryOptions = {
  pageSize: number;
};

type QueryOptionsContextProps = {
  queryOptions: QueryOptions;
  setQueryOptions: (options: QueryOptions) => void;
};

export const defaultQueryOptions: QueryOptions = {
  pageSize: 10,
};

const QueryOptionsContext = createContext<QueryOptionsContextProps | null>(
  null,
);
export const useQueryOptions = () => {
  const context = useContext(QueryOptionsContext);

  if (!context) {
    throw new Error(
      `useQueryOptions() was called outside of a <QueryOptionsContext /> provider`,
    );
  }

  return context;
};

export function QueryProvider({ children }: { children: ReactNode }) {
  const [queryOptions, setQueryOptions] = useState(defaultQueryOptions);
  const onNetWorkError = useOnNetworkError();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: 'always',
            staleTime: 60 * 1000, // 1 Minute
            networkMode: 'offlineFirst',
          },
        },
        queryCache: new QueryCache({
          onError: error => {
            onNetWorkError(error);
          },
        }),
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <QueryOptionsContext.Provider value={{ queryOptions, setQueryOptions }}>
        {children}
      </QueryOptionsContext.Provider>
    </QueryClientProvider>
  );
}
