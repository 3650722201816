import { DEFAULT_MARGIN } from '@formbio/ui/theme/constants';
import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiTab: CustomComponent<'MuiTab'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary[600],
      padding: 0,
      minWidth: 0,
      marginLeft: DEFAULT_MARGIN,
      marginRight: DEFAULT_MARGIN,
      '&.Mui-selected': {
        color: theme.palette.primary[900],
      },
    }),
  },
};

export const MuiTabs: CustomComponent<'MuiTabs'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary[900],
        borderRadius: '4px 4px 0px 0px',
      },
    }),
  },
};
