import { faker } from '@faker-js/faker';
import {
  DATACORE_ENTITY_DATA_TYPE,
  DATACORE_ENTITY_SAMPLE,
  DATACORE_ENTITY_SAMPLE_SET,
  DATACORE_ENTITY_TYPE,
  DATACORE_REF_ENTITIES_TYPE,
  Sample,
  SampleSet,
} from '@formbio/api';
import { sampleIds, samples } from './samples';

export const sampleSetIds = Array.from({ length: 5 }).map(() =>
  faker.string.uuid(),
);

export const sampleSets: SampleSet[] = sampleSetIds.map(id => ({
  id,
  name: faker.lorem.words(2),
  createdAt: faker.date.past().toISOString(),
  samples: samples
    .slice(faker.number.int({ min: 0, max: sampleIds.length }))
    .map(({ id, frn, name }) => ({ id, frn, name })),
}));

export let mutableSampleSets: SampleSet[] = JSON.parse(
  JSON.stringify(sampleSets),
);

export function createDatacoreSampleSets() {
  return {
    data: mutableSampleSets.map(sampleSet => ({
      // changing from `entities` to `core` to avoid
      // a bug in the deserialization of the response
      // see: https://form-bio.slack.com/archives/C06S9SVKK51/p1721754676735169
      type: 'core',
      id: sampleSet.id,
      attributes: {
        name: sampleSet.name,
        createdAt: sampleSet.createdAt,
        type: DATACORE_ENTITY_SAMPLE_SET,
        updatedAt: sampleSet.createdAt,
      },
      relationships: {
        [DATACORE_REF_ENTITIES_TYPE]: {
          data:
            sampleSet.samples?.map(sample => ({
              type: DATACORE_ENTITY_TYPE,
              id: sample.id,
            })) || [],
        },
      },
    })),
    included: [
      ...(mutableSampleSets
        .map(sampleSet => sampleSet.samples)
        .flat()
        .map(sample => {
          if (sample) {
            const entitySample = mutableSamples.find(
              samp => samp.id === sample.id,
            );
            return (
              entitySample && {
                type: DATACORE_ENTITY_TYPE,
                id: entitySample.id,
                attributes: {
                  name: entitySample.name,
                  createdAt: entitySample.createdAt,
                  type: DATACORE_ENTITY_SAMPLE,
                  updatedAt: entitySample.createdAt,
                },
                relationships: {
                  data: {
                    data: {
                      type: DATACORE_ENTITY_DATA_TYPE,
                      id: entitySample.id + '-data',
                    },
                  },
                },
              }
            );
          }
        })
        .filter(sample => sample !== undefined) || []),
      ...(mutableSampleSets
        .map(sampleSet => sampleSet.samples)
        .flat()
        .map(sample => {
          if (sample) {
            const entitySample = mutableSamples.find(
              samp => samp.id === sample.id,
            );
            return (
              entitySample && {
                type: DATACORE_ENTITY_DATA_TYPE,
                id: entitySample.id + '-data',
                attributes: {
                  createdAt: entitySample.createdAt,
                  data: {
                    file: entitySample.filePath,
                  },
                },
              }
            );
          }
        })
        .filter(sample => sample !== undefined) || []),
    ],
  };
}

export function createDatacoreSampleSet(sampleSet: SampleSet) {
  return {
    data: {
      // changing from `entities` to `core` to avoid
      // a bug in the deserialization of the response
      // see: https://form-bio.slack.com/archives/C06S9SVKK51/p1721754676735169
      type: 'core',
      id: sampleSet.id,
      attributes: {
        name: sampleSet.name,
        createdAt: sampleSet.createdAt,
        type: DATACORE_ENTITY_SAMPLE_SET,
        updatedAt: sampleSet.createdAt,
      },
      relationships: {
        [DATACORE_REF_ENTITIES_TYPE]: {
          data:
            sampleSet.samples?.map(sample => ({
              type: DATACORE_ENTITY_TYPE,
              id: sample.id,
            })) || [],
        },
      },
    },
    included: [
      ...(sampleSet.samples?.map(sample => {
        if (sample) {
          const entitySample = mutableSamples.find(
            samp => samp.id === sample.id,
          );
          return (
            entitySample && {
              type: DATACORE_ENTITY_TYPE,
              id: entitySample.id,
              attributes: {
                name: entitySample.name,
                createdAt: entitySample.createdAt,
                type: DATACORE_ENTITY_SAMPLE,
                updatedAt: entitySample.createdAt,
              },
              relationships: {
                data: {
                  data: {
                    type: DATACORE_ENTITY_DATA_TYPE,
                    id: entitySample.id + '-data',
                  },
                },
              },
            }
          );
        }
      }) || []),
      ...(sampleSet.samples?.map(sample => {
        if (sample) {
          const entitySample = mutableSamples.find(
            samp => samp.id === sample.id,
          );
          return (
            entitySample && {
              type: DATACORE_ENTITY_DATA_TYPE,
              id: entitySample.id + '-data',
              attributes: {
                createdAt: entitySample.createdAt,
                data: {
                  file: entitySample.filePath,
                },
              },
            }
          );
        }
      }) || []),
    ],
  };
}

export let mutableSamples: Sample[] = samples.map(sample => ({
  ...sample,
  sampleSets: sampleSets
    .filter(
      sampleSet => sampleSet?.samples?.some(samp => samp.id === sample.id),
    )
    .map(sampleSet => ({
      id: sampleSet.id,
      name: sampleSet.name,
    })),
}));

export function updateMutableSamples(newMutableSamples: Sample[]) {
  mutableSamples = newMutableSamples;
}

export function updateMutableSampleSets(newMutableSampleSets: SampleSet[]) {
  mutableSampleSets = newMutableSampleSets;
}

export function updateMutableSampleSetSampleFrns(updatedSampleSet: SampleSet) {
  const newSamples = mutableSamples.map(sample => {
    if (
      updatedSampleSet.samples?.find(
        sampleSetSample => sampleSetSample.id === sample.id,
      )
    ) {
      return {
        ...sample,
        sampleSets: [
          ...sampleSets.filter(
            sampleSet => sampleSet.id !== updatedSampleSet.id,
          ),
          {
            id: updatedSampleSet.id,
            name: updatedSampleSet.name,
          },
        ],
      };
    } else {
      return sample;
    }
  });
  updateMutableSamples(newSamples);
}
