import {
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableBody as MuiTableBody,
  TableBodyProps as MuiTableBodyProps,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  TableHead as MuiTableHead,
  TableHeadProps as MuiTableHeadProps,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from '@mui/material';

export type TableProps = Omit<MuiTableProps, 'sx'>;
export type TableBodyProps = Omit<MuiTableBodyProps, 'sx'>;
export type TableHeadProps = Omit<MuiTableHeadProps, 'sx'>;
export type TableRowProps = Omit<MuiTableRowProps, 'sx'>;
export type TableCellProps = Omit<MuiTableCellProps, 'sx'>;

export const Table = ({ ...rest }: TableProps) => <MuiTable {...rest} />;
export const TableHead = ({ ...rest }: MuiTableHeadProps) => (
  <MuiTableHead {...rest} />
);
export const TableBody = ({ ...rest }: MuiTableBodyProps) => (
  <MuiTableBody {...rest} />
);
export const TableRow = ({ ...rest }: MuiTableRowProps) => (
  <MuiTableRow {...rest} />
);
export const TableCell = ({ ...rest }: MuiTableCellProps) => (
  <MuiTableCell {...rest} />
);
