import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { forwardRef } from 'react';

export type CheckboxProps = Omit<
  MuiCheckboxProps,
  | 'classes'
  | 'sx'
  | 'centerRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'onFocusVisible'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'LinkComponent'
  | 'action'
  | 'ref'
>;

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  function Checkbox(props, ref) {
    return <MuiCheckbox {...props} ref={ref} />;
  },
);
