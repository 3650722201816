import { getAuth, getIdToken, AuthErrorCodes } from 'firebase/auth';
import { app } from '@/lib/firebase';
import { FirebaseError } from '@firebase/util';

export function getErrorMessage(error: FirebaseError): string {
  if (!error.code) {
    return '';
  }

  switch (error.code) {
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'Please provide current password.';
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return 'Too many attempts. Please try again later.';
    case AuthErrorCodes.MFA_REQUIRED:
      return 'Multi-Factor Authentication (MFA) is required.';
    default:
      return error.message
        .replaceAll('Firebase:', '')
        .replaceAll(`(${error.code})`, '');
  }
}

export async function getAuthToken() {
  const auth = getAuth(app);
  if (auth.currentUser) {
    return await getIdToken(auth.currentUser);
  }
  return null;
}
