import { Control, useController } from 'react-hook-form';
import { styled } from '@formbio/ui/components/';
import { FieldError } from '@formbio/ui/components/FieldError';
import { InputLabel } from '@formbio/ui/components/InputLabel';
import { TextField } from '@formbio/ui/components/TextField';
import { CreateOrgData } from '.';

export interface FieldProps {
  control: Control<CreateOrgData>;
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
}

export const StyledInputContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 3),
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export default function DialogField({
  label,
  control,
  name,
  placeholder,
  required,
}: FieldProps) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <StyledInputContainer>
      <StyledInputLabel htmlFor={name}>{label}</StyledInputLabel>
      <TextField
        fullWidth
        id={name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={value ?? ''}
      />
      {error && <FieldError error={error.message?.toString()} />}
    </StyledInputContainer>
  );
}
