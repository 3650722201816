import { useEffect } from 'react';
import { FieldValues, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Organization } from '@formbio/api';
import { Button } from '@formbio/ui/components/Button';
import { Checkbox } from '@formbio/ui/components/Checkbox';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@formbio/ui/components/Dialog';
import DialogHeader from '@formbio/ui/components/Dialog/DialogHeader';
import DialogField from './DialogField';
import { Divider } from '@formbio/ui/components/Layout/Divider';
import { FormControlLabel } from '@formbio/ui/components';
import { LinearProgress } from '@formbio/ui/components/LinearProgress';
import { styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import {
  getOrgProjectValidator,
  getEmailValidator,
  yupBillingId,
} from '@formbio/ui/utils/yupHelper';

type DialogProps = {
  isLoading: boolean;
  open: boolean;
  organizations: Organization[] | undefined;
  onCheck?: () => void;
  onClose: () => void;
  onSubmit: ({ name, billingId, ownerEmail }: CreateOrgData) => void;
};

export interface CreateOrgData extends FieldValues {
  billingId?: string;
  ownerEmail: string;
  name: string;
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledHorizontalRuleBg = styled('div')(({ theme }) => ({
  // Hiding until `Create new Google Billing ID` API is ready
  display: 'none',
  // display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 3),
  position: 'relative',
  '&:before': {
    backgroundColor: theme.palette.primary[400],
    content: '""',
    height: '1px',
    position: 'absolute',
    top: '50%',
    width: '100%',
    zIndex: 0,
  },
  span: {
    background: theme.palette.primary[50],
    padding: theme.spacing(0, 2),
    position: 'relative',
    zIndex: 1,
  },
}));

const StyledCheckContainer = styled('div')(({ theme }) => ({
  // Hiding until `Create new Google Billing ID` API is ready
  display: 'none',
  alignItems: 'center',
  backgroundColor: theme.palette.primary[200],
  borderRadius: theme.spacing(1),
  // display: 'flex',
  height: theme.spacing(9),
  justifyContent: 'center',
}));

const StyledCheckbox = styled(FormControlLabel)(({ theme }) => ({
  height: theme.spacing(3),
  marginLeft: `${theme.spacing(1.5)} !important`,
  '.MuiTypography-root': {
    cursor: 'pointer',
  },
}));

export default function CreateOrgDialog({
  isLoading,
  open,
  onCheck,
  onClose,
  onSubmit,
  organizations,
}: DialogProps) {
  const schema = yup.object().shape({
    name: getOrgProjectValidator(organizations),
    ownerEmail: getEmailValidator('Must be a valid email.').required(
      'An email is required.',
    ),
    billingId: yupBillingId(),
  });

  const formProps = useForm<CreateOrgData>({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, reset } = formProps;
  const { isValid } = useFormState({ control });
  const isDisabled = isLoading || !isValid;

  const handleFormSubmit = (data: CreateOrgData) => {
    const { name, billingId, ownerEmail } = data;
    onSubmit({ name, billingId, ownerEmail });
  };

  useEffect(() => {
    if (open) {
      reset({ name: '', ownerEmail: '', billingId: '' });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogHeader title='Create New Organization' onClose={onClose} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <DialogField
            label='Organization Name'
            name='name'
            control={control}
            placeholder='Enter Organization Name'
          />
          <DialogField
            label='Invite Organization Owner by Email'
            name='ownerEmail'
            control={control}
            placeholder='Enter Owner Email'
          />
          <StyledDivider />
          <DialogField
            label='Enter Existing Google Billing ID'
            name='billingId'
            control={control}
            placeholder='Enter Google Billing ID...'
          />
          <StyledHorizontalRuleBg>
            <span>or</span>
          </StyledHorizontalRuleBg>
          <StyledCheckContainer>
            <StyledCheckbox
              label={
                <Typography
                  component='label'
                  variant='body2'
                  htmlFor='newBillingId'
                >
                  Create new Google Billing ID
                </Typography>
              }
              control={
                <Checkbox
                  disabled
                  disableTouchRipple
                  name='newBillingId'
                  onChange={onCheck}
                />
              }
            />
          </StyledCheckContainer>
          {isLoading && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            disabled={isDisabled}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isDisabled}
          >
            Create Organization
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
