import {
  fetchAllByProcess,
  fetchOneByProcessAndId,
} from '../../services/process-executions';
import { ProcessExecution } from '../../types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

const KEY = 'process-executions';

export function useProcessExecutionsByProcess(
  slug: string,
  oid: string,
  pid: string,
  options: Omit<
    UseQueryOptions<ProcessExecution[]>,
    'queryKey' | 'queryFn'
  > = {},
) {
  return useQuery<ProcessExecution[]>({
    queryKey: [KEY, slug],
    queryFn: () => fetchAllByProcess({ slug, oid, pid }),
    ...{
      retry: 1,
      ...options,
    },
  });
}

export function useProcessExecutionByProcessAndId(
  processSlug: string,
  executionId: string,
  oid: string,
  pid: string,
  options: Omit<UseQueryOptions<ProcessExecution>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<ProcessExecution>({
    queryKey: [KEY, processSlug, executionId],
    queryFn: () =>
      fetchOneByProcessAndId({ processSlug, executionId, oid, pid }),
    ...{
      retry: 1,
      ...options,
    },
  });
}
