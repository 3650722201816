interface EmailNotificationPreferences {
  [key: string]: boolean;
}

interface User {
  created_at: string;
  updated_at: string;
  email: string;
  name: string;
  email_notification_preferences: EmailNotificationPreferences;
}

export interface Member {
  user: User;
  role: Role;
  createdAt: string;
  scopes?: string[];
}

export enum Role {
  Editor = 'editor',
  Admin = 'admin',
  Owner = 'owner',
  Viewer = 'viewer',
  Member = 'member',
}

export interface AwaitingInvitee {
  email: string;
  role: Role;
}

export type AddMembersFormData = {
  invites: AwaitingInvitee[];
};
