import {
  createCustomerJourney,
  CustomerJourneyData,
  fetchAllAdmin,
  fetchOneAdmin,
} from '../../../services/admin/projects';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useInvalidation from '../../useInvalidation';
import { Project } from '../../../types';

const KEY = 'projects';

export function useProjectsAdmin({
  oid,
  options = {},
}: {
  oid?: string;
  options?: Omit<UseQueryOptions<Project[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<Project[]>({
    queryKey: [KEY, oid],
    queryFn: () => fetchAllAdmin(oid),
    ...options,
  });
}

export function useProjectAdmin({
  oid,
  pid,
  options = {},
}: {
  oid: string;
  pid: string;
  options?: Omit<UseQueryOptions<Project>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<Project>({
    queryKey: [KEY, { oid, pid }],
    queryFn: () => {
      return fetchOneAdmin(oid, pid);
    },
    ...{
      enabled: !!oid && !!pid,
      ...options,
    },
  });
}

export const useCustomerJourneyProjectAdmin = () => {
  const invalidate = useInvalidation(KEY);
  return useMutation({
    mutationFn: (data: CustomerJourneyData) =>
      createCustomerJourney(data.oid, data.projectName),
    ...{
      onSuccess: () => {
        return invalidate();
      },
    },
  });
};
