import NextMuiLink from '@formbio/ui/components/Link/NextMuiLink';
import LinkTooltip from '@formbio/ui/components/Tooltip/LinkTooltip';
import { URL_REGEXP } from '@formbio/ui/utils/formatting';
import { Tooltip } from '@mui/material';

/**
 * Formats the value of a cell in the VectorProfileTable or VADR Table.
 *
 * Handles external links (opens in new tab)
 *
 */
export default function VectorValueCell({ value }: { value?: string }) {
  if (value) {
    const isLink = value.match(URL_REGEXP);
    const fixedLink =
      isLink && value.startsWith('www.') ? `https://${value}` : value;
    if (isLink) {
      return (
        <LinkTooltip href={value}>
          <NextMuiLink nextHref={fixedLink}>{value}</NextMuiLink>
        </LinkTooltip>
      );
    }
    return (
      <Tooltip title={value}>
        <span>{value}</span>
      </Tooltip>
    );
  }
  return value;
}
