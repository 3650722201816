import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  fetchAllByProject,
  fetchAllByWorkflow,
  fetchAllByWorkflowVersion,
  fetchOne,
} from '../../services/check-suites';
import { CheckSuite } from '../../types';

const KEY = 'check-suites';

type QueryOptionCheckSuites = Omit<
  UseQueryOptions<CheckSuite[]>,
  'queryKey' | 'queryFn'
>;

export function useCheckSuitesByProject(
  params: Parameters<typeof fetchAllByProject>[0],
  options: QueryOptionCheckSuites = {},
) {
  return useQuery<CheckSuite[]>({
    queryKey: [KEY, params],
    queryFn: () => fetchAllByProject(params),
    ...options,
  });
}

export function useCheckSuitesByWorkflow(
  params: Parameters<typeof fetchAllByWorkflow>[0],
  options: QueryOptionCheckSuites = {},
) {
  return useQuery<CheckSuite[]>({
    queryKey: [KEY, params],
    queryFn: () => fetchAllByWorkflow(params),
    ...{
      retry: 1,
      ...options,
    },
  });
}

export function useCheckSuitesByWorkflowVersion(
  params: Parameters<typeof fetchAllByWorkflowVersion>[0],
  options: QueryOptionCheckSuites = {},
) {
  return useQuery<CheckSuite[]>({
    queryKey: [KEY, params],
    queryFn: () => fetchAllByWorkflowVersion(params),
    ...{
      retry: 1,
      ...options,
    },
  });
}

export function useCheckSuite(
  params: Parameters<typeof fetchOne>[0],
  options: Omit<UseQueryOptions<CheckSuite>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<CheckSuite>({
    queryKey: [KEY, params],
    queryFn: () => fetchOne(params),
    ...options,
  });
}
