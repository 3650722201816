import { useEffect, useRef, useState } from 'react';

/**
 * A simple hook to get and set a value in localStorage
 * @param {String} key The key to set in localStorage for this value
 * @param {Object} initialValue The value to use if it is not already in localStorage
 */
export function useLocalStorage<Type>({
  key,
  initialValue,
}: {
  key: string;
  initialValue: Type;
}): [Type, React.Dispatch<React.SetStateAction<Type>>] {
  const [state, setState] = useState<Type>(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      return JSON.parse(valueInLocalStorage);
    }
    return initialValue;
  });

  const prevKeyRef = useRef(key);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
  }, [key]);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
