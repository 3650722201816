import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiListItemButton: CustomComponent<'MuiListItemButton'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      '.MuiListItemText-root': {
        color: theme.palette.primary[900],
      },
      '&.MuiListItemButton-root.Mui-selected': {
        backgroundColor: theme.palette.info[100],
        color: theme.palette.primary[900],
        '.MuiListItemText-root': {
          color: theme.palette.primary[900],
        },
      },
      ':hover': {
        backgroundColor: theme.palette.info[100],
      },
      '&.Mui-focusVisible': {
        background: 'none',
        boxShadow: theme.shadows[20],
      },
    }),
  },
};
