import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';

export type SwitchProps = Omit<
  MuiSwitchProps,
  | 'classes'
  | 'sx'
  | 'centerRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'onFocusVisible'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'LinkComponent'
  | 'action'
  | 'ref'
>;

export const Switch = ({ ...rest }: SwitchProps) => <MuiSwitch {...rest} />;
