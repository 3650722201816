export const CHART_WIDTH = 540;
export const CHART_HEIGHT = 460;

/**
 * Spacing
 */
export const DEFAULT_MARGIN = '24px';
export const DEFAULT_PADDING = '12px';
export const HALF_PADDING = '6px';

/**
 * Forms
 */
// can be used to adjust buttons to
// have the same height as inputs
export const INPUT_HEIGHT = '56px';

/**
 * Navigation & App Bars/Toolbars
 */
export const JOURNEY_TOOLBAR_HEIGHT = '112px';

/**
 * Layouts
 */
export const NAV_DRAWER_WIDTH = 240;
// a bit arbitrary but it makes the icons centered
// without having to have special logic on
// the nav item with or without text.
export const NAV_DRAWER_MINIED = 58;

/**
 * Tables & Data Grid
 */
export const TABLE_COL_MIN_WIDTH = 125;
export const TABLE_COL_MIN_WIDTH_LARGE = 300;

/**
 * This is a shortcut to calculate a character width
 * based on Inter 14px 400
 */
export const AVG_CHAR_WIDTH = 7.62;

export const SCROLLBAR_SIZE = '10px';
export const SCROLLBAR_RADIUS = '6px';

export const PANEL_TRANSITION_DURATION_MS = 300;

// DataGrid should always have a width > 0
export const DATA_GRID_MIN_WIDTH = '500px';

// Since the Vector Map requires a fixed width, this makes the other components align correctly
export const VMP_MAX_WIDTH = 900;

// SeqViz needs a height to render properly
export const SEQ_VIZ_MIN_HEIGHT = 400;
