/**
 * Utils functions to manipulate dates, format date and times,
 * format time to ETA, etc.
 *
 */

import {
  add,
  addMilliseconds,
  differenceInSeconds,
  format,
  formatDistance,
  parseISO,
  isAfter,
  isToday,
  isYesterday,
  formatDistanceToNow,
} from 'date-fns';

const monthDayYear = 'LLL d, Y';
const monthDayShort = 'L/d';
//locale time format
const timeFormat = 'p';

export const dateTimeFormatShortYear = 'MM/dd/yy, HH:mm';

export function formatMonthDayYear(datestring: string | number | Date): string {
  return format(new Date(datestring), monthDayYear);
}

/**
 * Creates a human readable phrase to inform users to how long
 * it will take to accomplish a task (eg. file uploads)
 * @param etaInMillis number of milliseconds between now and then
 * @returns a string with human readable ETA (eg. about 1h)
 */
export function formatTimeLeft(
  etaInMillis: number | undefined,
  prefix?: string,
) {
  const now = new Date();
  const builtPrefix = prefix ? `${prefix} ` : '';
  return `${builtPrefix}${formatDistance(
    now,
    addMilliseconds(now, etaInMillis || 0),
    {
      includeSeconds: true,
    },
  )}`;
}

export function timeDiffInSeconds(earlierDate: Date, laterDate: Date): number {
  return differenceInSeconds(earlierDate, laterDate);
}

export function getLaterDate(fromDate: Date, duration: Duration) {
  return add(fromDate, duration);
}

export function parseStringISODate(stringDate: string) {
  return parseISO(stringDate);
}

export function isDateAfter(laterDate: Date, earlierDate: Date): boolean {
  return isAfter(laterDate, earlierDate);
}

export function formatTodayYesterdayOrDistance(datestring: string) {
  const date = new Date(datestring);
  if (isToday(date)) return 'Today';
  if (isYesterday(date)) return 'Yesterday';
  return `${formatDistanceToNow(date)} ago`;
}

export function formatTime(datestring: string): string {
  return format(new Date(datestring), timeFormat);
}

export function formatMonthDay(datestring?: string): string {
  return datestring
    ? format(new Date(datestring), monthDayShort)
    : format(new Date(), monthDayShort);
}
