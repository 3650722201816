import { AdminOrderViewModel, AdminRefundViewModel } from '../../../types';
import { admin_api } from '../../../utils/admin-api';

export async function fetchAllOrdersAdmin() {
  const { data } = await admin_api.get<AdminOrderViewModel[]>('/stripe-orders');
  return data;
}

export async function fetchAllRefundsAdmin() {
  const { data } = await admin_api.get<AdminRefundViewModel[]>('/refunds');
  return data;
}
