import { Updater, useQueryClient } from '@tanstack/react-query';

function useSetQueryData(KEY: string) {
  const queryClient = useQueryClient();
  return function <Data>(
    setData: Updater<Data | undefined, Data>,
    additionalKey?: Record<string, string>,
  ) {
    const key = additionalKey ? [KEY, additionalKey] : [KEY];
    queryClient.setQueryData<Data>(key, setData);
  };
}

export default useSetQueryData;
