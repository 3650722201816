import { styled, Box } from '@formbio/ui/components';
import React from 'react';
import FormBioLogo from './FormBioLogo';
import FormBioLogoLetter from './FormBioLogoLetter';

const TITLE = 'Link to Form Bio Home';
// Formbio logo can only be of the following colors:
export enum LogoColors {
  white = 'rgb(255,255,255)',
  black = 'rgb(0,0,0)',
  greenSafe = 'rgb(204,255,51)',
  green = 'rgb(179,250,38)',
}

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'logoLetter' && prop !== 'logoColor',
})<{ logoLetter: boolean; logoColor?: LogoColors }>(({
  logoLetter,
  theme,
  logoColor,
}) => {
  // to keep menu from not jumping when drawer is open/closed
  const logoStyle = logoLetter
    ? {
        marginTop: 3,
        marginBottom: 3,
        color: LogoColors.green,
      }
    : {};

  return {
    marginTop: logoLetter ? theme.spacing(4) : theme.spacing(3),
    marginBottom: logoLetter ? theme.spacing(4) : theme.spacing(3),
    paddingLeft: theme.spacing(1.5),
    display: 'flex',
    color: logoColor || LogoColors.white,
    '& svg': logoStyle,
  };
});

export type AppLogoProps = {
  letter?: boolean;
  customWidth?: number;
  customHeight?: number;
  logoColor?: LogoColors;
  title?: string;
} & React.ComponentProps<typeof Box>;

const AppLogo = ({
  letter = false,
  title,
  customWidth,
  customHeight,
  ...props
}: AppLogoProps) => {
  return (
    <StyledBox {...props} logoLetter={letter}>
      {letter ? (
        <FormBioLogoLetter title={title || TITLE} />
      ) : (
        <FormBioLogo
          title={title || TITLE}
          customHeight={customHeight}
          customWidth={customWidth}
        />
      )}
    </StyledBox>
  );
};

export { AppLogo };
