import { createFilterOptions } from '@mui/material';
import { Autocomplete } from '../Autocomplete';
import { TextField } from '../TextField';
import { HTMLAttributes, useEffect, useState } from 'react';

type Option = {
  title: string;
  inputValue?: string;
};

const filter = createFilterOptions<Option>();

/**
 * An Autocomplete component that allows for free text entry.
 * https://mui.com/material-ui/react-autocomplete/#creatable
 */
export default function AutocompleteFreeSolo({
  className,
  size = 'small',
  id,
  options,
  onChange,
  defaultValue,
  disabled,
  placeholder,
}: {
  className?: string;
  size?: 'small' | 'medium';
  id: string;
  options: string[];
  onChange: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
}) {
  const [value, setValue] = useState<Option | null>(
    defaultValue
      ? {
          title: defaultValue,
        }
      : null,
  );
  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue({ title: defaultValue });
    }
  }, [defaultValue]);

  function handleChange(newValue: Option | null) {
    if (!newValue) return;
    setValue(newValue);
    onChange(newValue.title);
  }

  return (
    <Autocomplete
      className={className}
      id={id}
      size={size}
      freeSolo
      options={options.map(option => ({ title: option, inputValue: option }))}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => {
        const { key, ...otherProps } =
          props as HTMLAttributes<HTMLLIElement> & { key: string };
        return (
          <li {...otherProps} key={key}>
            {option.title}
          </li>
        );
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const cleanInput = inputValue.trim();
        // Suggest the creation of a new value
        const isExisting = options.some(option => cleanInput === option.title);
        if (cleanInput !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${cleanInput}"`,
          });
        }

        return filtered;
      }}
      renderInput={params => (
        <TextField {...params} placeholder={placeholder} />
      )}
      value={value}
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') {
          handleChange({ title: newValue });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          handleChange({ title: newValue.inputValue });
        } else {
          handleChange(newValue);
        }
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      disabled={disabled}
    />
  );
}
