import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiAccordion: CustomComponent<'MuiAccordion'> = {
  styleOverrides: {
    root: ({ theme }) => {
      const {
        palette,
        palette: { mode },
      } = theme;
      return {
        backgroundImage: 'none',
        boxShadow: 'none',
        color: palette.text.primary,
        '.MuiAccordionSummary-root': {
          backgroundColor:
            mode === 'light' ? palette.primary[50] : palette.primary[800],
          border: mode === 'light' ? 'solid 1px' : 'none',
          borderColor: mode === 'light' ? theme.palette.primary[500] : 'none',
          padding: 0,
        },
        '&:first-of-type .MuiAccordionSummary-root': {
          borderTopRightRadius: theme.spacing(1),
          borderTopLeftRadius: theme.spacing(1),
        },
        '&:last-of-type .MuiAccordionSummary-root': {
          borderBottomRightRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1),
        },
        '.MuiAccordionSummary-content': {
          margin: 0,
          '> *': {
            width: '100%',
          },
        },
        '.MuiCollapse-root': {
          padding: theme.spacing(0, 0.75),
        },
        '.MuiCollapse-wrapperInner': {
          backgroundColor: palette.background.default,
          borderRight: mode === 'light' ? 'solid 1px' : 'solid 2px',
          borderLeft: mode === 'light' ? 'solid 1px' : 'solid 2px',
          borderRightColor:
            mode === 'light'
              ? theme.palette.primary[500]
              : palette.primary[800],
          borderLeftColor:
            mode === 'light'
              ? theme.palette.primary[500]
              : palette.primary[800],
          padding: theme.spacing(1, 0, 0.5),
        },
        '&:last-of-type .MuiCollapse-wrapperInner': {
          borderBottom: mode === 'light' ? 'solid 1px' : 'solid 2px',
          borderBottomColor:
            mode === 'light'
              ? theme.palette.primary[500]
              : palette.primary[800],
          borderBottomRightRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1),
        },
        '&:before': {
          display: 'none',
        },
      };
    },
  },
};
