import { AdminOrderViewModel, AdminRefundViewModel } from '../../../types';
import {
  fetchAllOrdersAdmin,
  fetchAllRefundsAdmin,
} from '../../../services/admin/stripe-orders';
import { useQuery } from '@tanstack/react-query';

const ORDERS_KEY = 'stripe-orders';
const REFUNDS_KEY = 'refunds';

export function useOrdersAdmin() {
  const ordersQuery = useQuery({
    queryKey: [ORDERS_KEY],
    queryFn: fetchAllOrdersAdmin,
  });
  const allOrders: AdminOrderViewModel[] | undefined = ordersQuery.data;

  return {
    allOrders,
    isLoading: ordersQuery.isLoading,
    isError: ordersQuery.isError,
  };
}

export function useRefundsAdmin() {
  const refundsQuery = useQuery({
    queryKey: [REFUNDS_KEY],
    queryFn: fetchAllRefundsAdmin,
  });
  const data: AdminRefundViewModel[] | undefined = refundsQuery.data;

  return {
    data,
    isLoading: refundsQuery.isLoading,
    isError: refundsQuery.isError,
  };
}
