import { forwardRef } from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';
import { styled } from '@formbio/ui/components';

export type IconButtonProps = Omit<
  MuiIconButtonProps,
  | 'sx'
  | 'classes'
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'LinkComponent'
  | 'onFocusVisible'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'disableFocusRipple'
  | 'ref'
> & {
  pushRight?: boolean;
};

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: prop => prop !== 'pushRight',
})<IconButtonProps>(({ pushRight }) => ({
  marginLeft: pushRight ? 'auto' : undefined,
}));

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => <StyledIconButton {...props} ref={ref} />,
);
IconButton.displayName = 'IconButton';

export { IconButton };
