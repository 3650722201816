import { ExtendedTypography } from './type-extension';
import { primary } from './palette/primary';

const SEMI_BOLD_FONT_WEIGHT = 600;
const DEFAULT_FONT_COLOR = primary[900];
const BODY_FONT_COLOR = primary[700];
const DEFAULT_FONT_COLOR_DARK = primary[50];

export interface FontFamilies {
  hFontFamily: string;
  contentFontFamily: string;
  codeFontFamily: string;
}

export const defaultFontFamilies: FontFamilies = {
  codeFontFamily: 'Fira Code',
  contentFontFamily: 'Inter',
  hFontFamily: 'Roobert',
};

function createTypography({
  hColor,
  bodyColor,
  hFontFamily,
  contentFontFamily,
  codeFontFamily,
}: {
  hColor: string | undefined;
  bodyColor: string | undefined;
} & FontFamilies): ExtendedTypography {
  const typography: ExtendedTypography = {
    h1: {
      fontSize: '2.5rem',
      lineHeight: 1.2,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: hFontFamily,
      fontVariantLigatures: 'none',
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 1.25,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: hFontFamily,
      fontVariantLigatures: 'none',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: hFontFamily,
      fontVariantLigatures: 'none',
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: hFontFamily,
      fontVariantLigatures: 'none',
    },
    h5: {
      fontSize: '0.875rem',
      lineHeight: 24 / 14,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: hFontFamily,
      fontVariantLigatures: 'none',
    },
    h6: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: hFontFamily,
      fontVariantLigatures: 'none',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      color: bodyColor,
      fontFamily: contentFontFamily,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 20 / 14,
      fontWeight: 400,
      color: bodyColor,
      fontFamily: contentFontFamily,
    },
    body3: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: 400,
      color: bodyColor,
      fontFamily: contentFontFamily,
    },
    caption1: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: bodyColor,
      fontFamily: contentFontFamily,
    },
    caption2: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: bodyColor,
      textTransform: 'uppercase',
      fontFamily: contentFontFamily,
    },
    button1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      textTransform: 'none',
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: contentFontFamily,
    },
    button2: {
      fontSize: '0.875rem',
      lineHeight: 20 / 14,
      textTransform: 'none',
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: contentFontFamily,
    },
    code1: {
      fontSize: '0.875rem',
      lineHeight: 20 / 14,
      fontWeight: 400,
      color: hColor,
      fontFamily: codeFontFamily,
    },
    code2: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: 400,
      color: hColor,
      fontFamily: codeFontFamily,
    },
  };
  typography.caption = typography.caption1;
  typography.button = typography.button2;
  return typography;
}

type Typography = (fontFamilies?: FontFamilies) => ExtendedTypography;

const typography: Typography = (fontFamilies = defaultFontFamilies) =>
  createTypography({
    hColor: DEFAULT_FONT_COLOR,
    bodyColor: BODY_FONT_COLOR,
    ...fontFamilies,
  });

export const typographyDark: Typography = (
  fontFamilies = defaultFontFamilies,
) =>
  createTypography({
    hColor: DEFAULT_FONT_COLOR_DARK,
    bodyColor: DEFAULT_FONT_COLOR_DARK,
    ...fontFamilies,
  });

export default typography;
