import { Account } from '../../../types';
import { admin_api } from '../../../utils/admin-api';

export async function fetchAllAdmin() {
  const { data } = await admin_api.get<Account[]>('/accounts');
  return data;
}

export async function fetchOneAdmin(accountId: string) {
  const { data } = await admin_api.get<Account>(`/account/${accountId}`);
  return data;
}

export async function approveEntitlementChange(entitlementId: string) {
  const { data } = await admin_api.put(
    `/entitlements/change/${entitlementId}/approve`,
  );

  return data;
}

export async function rejectEntitlementChange(
  entitlementId: string,
  message?: string,
) {
  const { data } = await admin_api.put(
    `/entitlements/change/${entitlementId}/reject`,
    message ? { message } : undefined,
  );

  return data;
}
