import { buildHandlers } from '@/data/mock/handlers';
import config, { featureFlagIds } from '@/config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SetupWorker } from 'msw/browser';
import { ReactNode, createContext, useEffect, useState } from 'react';

const MockDataContext = createContext({});

const mockStages = ['sbx', 'local'];

/**
 * This context handles starting and stopping MSW from LaunchDarkly.
 * Set the `mockDataHandlers` feature flag to an array of strings
 * that correspond to the names of the handlers you want to use.
 * See existing Variations to create your own.
 */
export default function MockDataContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const flags = useFlags();
  const mockDataHandlers = flags[featureFlagIds.mockDataHandlers] as string[];
  const [mswWorker, setMswWorker] = useState<SetupWorker>();

  async function initMSWWorker(mockHandlerNames: string[]) {
    if (mswWorker) {
      mswWorker.resetHandlers(...buildHandlers(mockHandlerNames));
      return mswWorker;
    }
    if (typeof window !== 'undefined') {
      const { setupWorker } = await import('msw/browser');
      setMswWorker(setupWorker(...buildHandlers(mockHandlerNames)));
      return mswWorker;
    }
  }

  // this useEffect handles starting and stopping MSW
  // from LaunchDarkly.
  useEffect(() => {
    async function starWorker() {
      const worker = await initMSWWorker(mockDataHandlers);
      worker?.start();
    }
    if (config.stage && mockStages.includes(config.stage)) {
      if (mockDataHandlers?.length) {
        starWorker();
      } else {
        mswWorker?.stop();
      }
    } else {
      // in case something changed and the mswWorker should no longer be running
      mswWorker?.stop();
    }
  }, [mockDataHandlers, mswWorker, initMSWWorker]);

  return (
    <MockDataContext.Provider value={{}}>{children}</MockDataContext.Provider>
  );
}
