import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchAllTiers } from '../../services/marketplace';
import { MarketplaceTier } from '../../types/accounts';

export const TIERS_KEY = 'tiers';

export function useMarketplaceTiers(
  options?: Omit<UseQueryOptions<MarketplaceTier[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<MarketplaceTier[]>({
    queryKey: [TIERS_KEY, 'tiers'],
    queryFn: fetchAllTiers,
    ...options,
  });
}
