import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import useInvalidation from '../useInvalidation';
import { CreateData, create } from '../../services/validator-runs';

const KEY = 'validator-runs';

export function useCreateValidatorRun(
  options?: UseMutationOptions<unknown, unknown, CreateData>,
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<unknown, unknown, CreateData>({
    mutationFn: (data: CreateData) => create(data),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      onError: (err) => {
        return err;
      },
      ...options,
    },
  });
}
