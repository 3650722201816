import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiDialog: CustomComponent<'MuiDialog'> = {
  defaultProps: {
    maxWidth: 'xl',
  },
};

export const MuiDialogTitle: CustomComponent<'MuiDialogTitle'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
      // font settings should match <h4> styling, unfortunately cannot just apply that here as an override
      fontSize: '18px',
      lineHeight: theme.spacing(3),
      fontWeight: 'bold',
    }),
  },
};

export const MuiDialogContent: CustomComponent<'MuiDialogContent'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(3, 3, 0.5),
      paddingTop: '5px !important', // this helps ensure input overlaid input labels do not get covered since material automatically removes the top padding if a dialog title is present
    }),
  },
};

export const MuiDialogActions: CustomComponent<'MuiDialogActions'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '.MuiButtonBase-root:not(:first-of-type)': {
        marginLeft: theme.spacing(2),
      },
      padding: theme.spacing(3),
    }),
  },
};
