import { delay, http, HttpResponse, PathParams } from 'msw';
import { existingLabels, mockVaultItems } from './data/vault';
import { Label, VaultItem } from '@formbio/api';

let mutableMockItems: VaultItem[] = JSON.parse(JSON.stringify(mockVaultItems));
let mutableLabels: Label[] = JSON.parse(JSON.stringify(existingLabels));

const vault = http.get('*/api/v1/orgs/:oid/:pid/vault', () =>
  HttpResponse.json(mutableMockItems),
);

const vaultItemGet = http.get(
  '*/api/v1/orgs/:oid/:pid/vault/object',
  ({ request }) => {
    const url = new URL(request.url);
    const objectName = url.searchParams.get('objectName');
    return HttpResponse.json(
      mutableMockItems.find(item => item.name === objectName),
    );
  },
);

const vaultItemUpdateLabels = http.put<
  PathParams,
  { path: string; labels: Label[] }
>('*/api/v1/orgs/:oid/:pid/vault/labels', async ({ request }) => {
  const { path, labels } = await request.json();
  const itemToUpdate = mutableMockItems.find(item => item.name === path);
  if (itemToUpdate) {
    itemToUpdate.labels = labels;
    mutableMockItems = [
      ...mutableMockItems.filter(item => item.name !== itemToUpdate.name),
      itemToUpdate,
    ];
  } else {
    // console.log('itemToUpdate not found', path, labels, mutableMockItems);
  }
  await delay(500);
  return HttpResponse.json(itemToUpdate);
});

const vaultOrgLabels = http.get('*/api/v1/orgs/:oid/labels', () => {
  return HttpResponse.json(mutableLabels);
});

const vaultUpdateOrgLabels = http.put<PathParams, { labels: Label[] }>(
  '*/api/v1/orgs/:oid/labels',
  async ({ request }) => {
    const { labels } = await request.json();
    mutableLabels = labels;
    await delay(250);
    return HttpResponse.json(mutableLabels);
  },
);

// const vaultItemDelete = http.delete('*/api/v1/orgs/:oid/:pid/vault', () =>
//   HttpResponse.json(null, { status: 500, statusText: 'Cannot delete item' }),
// );

// const vaultItemRename = http.put('*/api/v1/orgs/:oid/:pid/vault/rename', () =>
//   HttpResponse.json(null, { status: 500, statusText: 'Cannot rename item' }),
// );
// const vaultItemCopy = http.post('*/api/v1/orgs/:oid/:pid/vault/copy', () =>
//   HttpResponse.json(null, { status: 500, statusText: 'Cannot copy item' }),
// );

// const vaultItemOpen = http.get('*/api/v1/orgs/:oid/:pid/vault/download/*', () =>
//   HttpResponse.json(null, { status: 500, statusText: 'Cannot open item' }),
// );

export const vaultHandlers = [
  vault,
  // vaultItemDelete,
  // vaultItemRename,
  // vaultItemCopy,
  // vaultItemOpen,
  vaultItemGet,
  vaultItemUpdateLabels,
  vaultOrgLabels,
  vaultUpdateOrgLabels,
];
