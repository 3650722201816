import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiBreadcrumbs: CustomComponent<'MuiBreadcrumbs'> = {
  styleOverrides: {
    root: ({ theme }) => {
      const notActiveFontColor =
        theme.palette.mode === 'light'
          ? theme.palette.primary[500]
          : theme.palette.primary[400];
      const font = {
        fontSize: theme.typography.h5?.fontSize,
        fontWeight: theme.typography.h5?.fontWeight,
        fontFamily: theme.typography.h5?.fontFamily,
        color:
          theme.palette.mode === 'light'
            ? theme.palette.primary[700]
            : theme.palette.primary[200],
      };
      return {
        ...font,
        '& .MuiBreadcrumbs-separator': {
          color: notActiveFontColor,
        },
        '& .MuiTypography-body1': {
          ...font,
          color: notActiveFontColor,
        },
      };
    },
  },
};
