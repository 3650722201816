import { info } from '@formbio/ui/theme/palette/info';
import { primary } from '@formbio/ui/theme/palette/primary';
import { CustomComponent } from '@formbio/ui/theme/type-extension';
import { Theme } from '@formbio/ui/components';
import { Checkbox } from '@formbio/ui/components/Checkbox';
import { LinearProgress } from '@formbio/ui/components/LinearProgress';

export const MuiDataGrid: CustomComponent<'MuiDataGrid'> = {
  styleOverrides: {
    root: {
      border: 'none',
      visibility: 'visible',
      /**
       * Fixes a bug in Datagrid where overlays are behind the virtual scroller
       *
       * Convoluted but necessary selector to enable interactions
       * with the overlay (such as NoRowsOverlay)
       * The overlay (when present) is the 1st child of .MuiDataGrid-main
       * When not present, the first child is .MuiDataGrid-columnHeaders
       */
      '.MuiDataGrid-main > div:nth-of-type(1):not(.MuiDataGrid-columnHeaders)':
        {
          //replaces inline styling position: absolute
          position: 'relative !important',
        },
      '.MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus': {
        outline: 'none',
      },
      '.MuiDataGrid-cell:focus-within,.MuiDataGrid-columnHeader:focus-within': {
        // prevent double focus outline
        a: {
          outline: 'none',
        },
      },
      '.MuiDataGrid-cell:focus-visible,.MuiDataGrid-columnHeader:focus-visible':
        // mimic default focus outline
        {
          outline: `1px solid rgba(0,0,0,0.5)`,
          outlineWidth: '1px',
          outlineOffset: '-1px',
        },
    },
    columnHeaderTitle: ({ theme }) => {
      return {
        ...(theme as Theme).typography.button2,
      };
    },
    columnSeparator: {
      visibility: 'hidden',
    },
    selectedRowCount: {
      visibility: 'hidden',
    },
    checkboxInput: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      padding: '5px',
      color: primary[400],
      fontSize: '1rem !important',
      '&.Mui-checked': {
        color: primary[900],
      },
      '&.MuiSvgIcon': {
        fontSize: '1rem',
      },
    }),
    cell: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    row: {
      '&.Mui-hovered': {
        backgroundColor: primary[100],
      },
      '&.Mui-selected': {
        backgroundColor: info[100],
      },
      '&.Mui-selected:hover': {
        backgroundColor: info[100],
      },
    },
    pinnedColumns: {
      boxShadow: 'none',
    },
    pinnedColumnHeaders: {
      boxShadow: 'none',
    },
  },
  defaultProps: {
    components: {
      LoadingOverlay: LinearProgress,
      BaseCheckbox: Checkbox,
    },
    componentsProps: {
      baseCheckbox: { size: 'small' },
    },
  },
};
