import { api } from '../../../utils/api';

import {
  NotificationTopicsConfig,
  EmailNotificationPreferences,
  MemberUser,
} from '@formbio/api';

export async function getNotificationTopics(): Promise<NotificationTopicsConfig> {
  return await api.get(`/email-notification-topics`).then(({ data }) => data);
}

export async function getUserNotificationPreference(): Promise<EmailNotificationPreferences> {
  return api
    .get<MemberUser>(`/user`)
    .then(({ data }) => data.email_notification_preferences);
}

export async function updateUserNotificationPreference(
  payload: EmailNotificationPreferences,
): Promise<MemberUser> {
  return api
    .patch<MemberUser>('/user', {
      email_notification_preferences: payload,
    })
    .then(({ data }) => data);
}
