import {
  WorkflowRunVisualization,
  type WorkflowRun,
  type WorkflowRunPost,
  type WorkflowRunPostResponse,
} from '../../types/workflow-run';
import { api } from '../../utils/api';
import { admin_api } from '../../utils/admin-api';

export function fetchAll(oid: string, pid: string) {
  return api
    .get<WorkflowRun[]>(`/orgs/${oid}/${pid}/workflow-runs`)
    .then(({ data }) => data);
}

export function fetchAllAdmin(oid: string, pid: string) {
  return admin_api
    .get<WorkflowRun[]>(`/organizations/${oid}/projects/${pid}/workflow-runs`)
    .then(({ data }) => data);
}

export function fetchOne(id: string) {
  return api.get<WorkflowRun>(`/workflow-run/${id}`).then(({ data }) => data);
}

export function remove(id: string, params?: { removeOutput?: boolean }) {
  return api.delete(`/workflow-run/${id}`, { params });
}

export function create(oid: string, pid: string, data: WorkflowRunPost) {
  const url = `/orgs/${oid}/${pid}/workflow-runs`;
  return api.post<WorkflowRunPostResponse>(url, data).then((resp) => resp.data);
}

export function fetchWorkflowRunVisualization(id: string) {
  return api
    .get<WorkflowRunVisualization>(`/workflow-run/${id}/visualizations`)
    .then(({ data }) => data);
}
