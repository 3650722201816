import { Color } from '@mui/material';

export const primary: Partial<Color> = {
  [900]: '#000000',
  [800]: '#1C1B1D',
  [700]: '#4F4E55',
  [600]: '#6F6E77',
  [500]: '#908E96',
  [400]: '#C8C7CB',
  [300]: '#E3E2E4',
  [200]: '#F0EFF1',
  [100]: '#F9F8F9',
  [50]: '#FFFFFF',
};
