import { Color } from '@mui/material';

export const secondary: Partial<Color> = {
  [800]: '#33470A',
  [700]: '#90D506',
  [600]: '#B6ED12',
  [500]: '#CCFF33',
  [400]: '#DDFF75',
  [300]: '#EEFFB3',
  [200]: '#F6FFD6',
  [100]: '#FBFFEB',
};
