import { ReactNode } from 'react';
import { styled, Toolbar } from '@formbio/ui/components';
import { AppBar } from '@formbio/ui/components/AppBar';
import { AppLogo } from '@formbio/ui/components/AppLogo';
import { Typography } from '@formbio/ui/components/Typography';

/*
 * types
 */

type AppHeaderItem = {
  items: ReactNode[];
};

/*
 * styled components
 */
export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: propName => propName !== 'backgroundColor',
})<{
  backgroundColor: string;
}>(({ theme, backgroundColor, position }) => ({
  backgroundColor,
  width: '100%',
  left: 0,
  zIndex: position === 'fixed' ? theme.zIndex.drawer + 1 : undefined,
}));

const TopStyled = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const AppLogoStyled = styled(AppLogo)({
  marginTop: 0,
  marginBottom: 0,
});

export const AppHeaderTitle = ({ title }: { title: ReactNode }) => (
  <Typography variant='h4' color='primary.contrastText'>
    {title}
  </Typography>
);

const AppHeader = ({
  backgroundColor,
  position = 'static',
  sections,
  className,
  children,
}: {
  backgroundColor?: string;
  position?: 'static' | 'fixed';
  title?: string;
  sections?: AppHeaderItem[];
  className?: string;
  children?: ReactNode;
}) => {
  return (
    <>
      <StyledAppBar
        position={position}
        backgroundColor={backgroundColor || ''}
        elevation={0}
        className={className}
      >
        <Toolbar>
          <TopStyled>
            <AppLogoStyled
              title='Form Bio'
              customHeight={46}
              customWidth={88}
            />
            {sections?.map(section => section.items.map(item => item))}
          </TopStyled>
        </Toolbar>
        {/* additional content below the toolbar content like a divider */}
        {children}
      </StyledAppBar>
      {/* ensures the page content is not under the toolbar */}
      {position === 'fixed' && <Toolbar />}
    </>
  );
};

export { AppHeader };
