// this component allows for globally subscription of alerts.
// currently only utilized for the maintenance alert,
// but the pattern can be extended as other use cases arise

import useMaintenanceAlert from '@/hooks/useMaintenanceAlert';
import { ReactNode } from 'react';

export default function AlertWrapper({ children }: { children: ReactNode }) {
  useMaintenanceAlert();

  return <>{children}</>;
}
