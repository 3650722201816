import { forwardRef } from 'react';
import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
} from '@mui/material';
import { OmitComponentProp } from '@formbio/ui/types';

export type MenuProps = Omit<
  MuiMenuProps,
  'BackdropProps' | 'BackdropComponent'
>;

export const Menu = ({ ...rest }: MenuProps) => <MuiMenu {...rest} />;

export const MenuList = ({ ...rest }: MuiMenuListProps) => (
  <MuiMenuList {...rest} />
);
export const MenuItem = forwardRef(
  <C extends React.ElementType>(
    props: OmitComponentProp<MenuItemProps<C, { component?: C }>>,
    ref?: React.Ref<HTMLLIElement>,
  ) => {
    const { children, ...rest } = props;
    return (
      <MuiMenuItem ref={ref} {...rest}>
        {children}
      </MuiMenuItem>
    );
  },
);

MenuItem.displayName = 'MenuItem';
