import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { fetchAllQuotas, fetchQuotaUsage } from '../../services/account';
import {
  Account,
  FeatureUsage,
  Quotas,
  UsageFeatures,
} from '../../types/accounts';
import { fetchOneAdmin } from '../../services/admin/accounts';

export const ACCOUNT_KEY = 'account';

export function useAccountAdmin(accountId: string) {
  const accountQuery = useQuery({
    queryKey: [ACCOUNT_KEY, accountId],
    queryFn: () => fetchOneAdmin(accountId),
  });

  const account: Account | undefined = accountQuery.data;

  return {
    account,
    isLoading: accountQuery.isLoading,
    isError: accountQuery.isError,
    error: accountQuery.error,
  };
}

export function useAccountQuotas(
  oid = '',
  options?: Omit<UseQueryOptions<Quotas | null>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<Quotas | null>({
    queryKey: [ACCOUNT_KEY, 'quotas', { oid }],
    queryFn: () => fetchAllQuotas(oid),
    ...{
      enabled: !!oid,
      ...options,
    },
  });
}

export function useInvalidateQuotasUsage() {
  const queryClient = useQueryClient();

  return (oid: string) =>
    queryClient.invalidateQueries({
      queryKey: [ACCOUNT_KEY, 'quota-usage', { oid }],
    });
}

export function useAccountQuotasUsage(
  oid = '',
  features: UsageFeatures[],
  options?: Omit<
    UseQueryOptions<FeatureUsage[] | null>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<FeatureUsage[] | null>({
    queryKey: [ACCOUNT_KEY, 'quota-usage', { oid }, features],
    queryFn: () => fetchQuotaUsage(oid, features),
    ...{
      enabled: !!oid,
      ...options,
    },
  });
}
