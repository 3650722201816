import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchOne } from '../../services/workflow-markdowns';
import { WorkflowMarkdown } from '../../types';

const KEY = 'workflow-markdowns';

export function useWorkflowMarkdown(
  params: {
    oid: string;
    pid: string;
    wid: string;
    repo: string;
    version: string | undefined;
    uploadedOrg: string;
    uploadedProj: string;
  },
  options?: UseQueryOptions<WorkflowMarkdown>,
) {
  return useQuery<WorkflowMarkdown>({
    queryKey: [KEY, params],
    queryFn: () => fetchOne(params),
    ...options,
  });
}
