import { styled } from '@mui/material';

export const IFRAME_MIN_HEIGHT = '60vh';

export const NoBorderIFrame = styled('iframe', {
  shouldForwardProp(propName) {
    return propName !== 'visible';
  },
})<{ visible: boolean }>(({ visible }) => ({
  visibility: visible ? 'visible' : 'hidden',
  border: 0,
  display: 'flex',
  flex: 1,
  overflow: 'auto',
}));
