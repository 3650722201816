import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
} from '@mui/material';

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
  <MuiAutocomplete {...rest} />
);
