import { forwardRef, Ref } from 'react';
import {
  ButtonGroup as MuiButtonGroup,
  ButtonGroupProps as MuiButtonGroupProps,
} from '@mui/material';

export type ButtonGroupProps = Omit<
  MuiButtonGroupProps,
  | 'sx'
  | 'classes'
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'LinkComponent'
  | 'TouchRippleProps'
  | 'onFocusVisible'
  | 'tabIndex'
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'touchRippleRef'
>;

const ButtonGroup = forwardRef(function ButtonGroup(
  { ...props }: ButtonGroupProps,
  ref: Ref<HTMLDivElement>,
) {
  return <MuiButtonGroup {...props} ref={ref} />;
});

export { ButtonGroup };
