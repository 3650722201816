import { forwardRef } from 'react';
import { Typography as MuiTypography, TypographyProps } from '@mui/material';
import { OmitComponentProp } from '@formbio/ui/types';

export const Typography = forwardRef(
  <C extends React.ElementType>(
    props: OmitComponentProp<TypographyProps<C, { component?: C }>>,
    ref?: React.Ref<HTMLElement>,
  ) => {
    const { children, ...rest } = props;
    return (
      <MuiTypography ref={ref} {...rest}>
        {children}
      </MuiTypography>
    );
  },
);

Typography.displayName = 'Typography';
