import useDragAndDrop from '@formbio/ui/hooks/useDragAndDrop';
import { Paper, styled } from '@formbio/ui/components';
import { DragEvent, ReactNode } from 'react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: '1',
  height: '100%',
  borderWidth: '2px',
  borderStyle: 'dashed',
  padding: theme.spacing(2),
  '&.drag': {
    borderColor: theme.palette.info[500],
    backgroundColor: theme.palette.info[100],
  },
  '&.rest': {
    borderColor: theme.palette.neutral[400],
  },
}));

export default function FileDragAndDrop({
  children,
  onDrop,
  className = '',
}: {
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  children: ReactNode;
  className?: string;
}) {
  const {
    isDragActive,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
  } = useDragAndDrop(onDrop);

  return (
    <StyledPaper
      variant='outlined'
      className={`${className} ${isDragActive ? 'drag' : 'rest'}`}
    >
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {children}
      </div>
    </StyledPaper>
  );
}
