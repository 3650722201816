import {
  CreateISAVectorParams,
  DATACORE_VECTOR_TYPE,
  ISACreateResponse,
  ISAVector,
  UpdateISAVectorParams,
} from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(oid: string, pid: string): Promise<ISAVector[]> {
  return api
    .get<ISAVector[]>(`/orgs/${oid}/${pid}/isa-vectors`)
    .then(({ data }) => data);
}

export function fetchOne({
  id,
  oid,
  pid,
}: {
  id: string;
  oid: string;
  pid: string;
}) {
  return api
    .get<ISAVector>(`/orgs/${oid}/${pid}/isa-vector/${id}`)
    .then(({ data }) => data);
}

export function createISAVector({
  orgId,
  pid,
  name,
  vectorType,
  file,
  vaultFile,
  indication,
  gene,
}: CreateISAVectorParams) {
  const body = new FormData();
  body.append('name', name);
  body.append('vectorType', vectorType);
  if (file) {
    body.append('file', file);
  } else if (vaultFile) {
    body.append('vaultFile', vaultFile);
  }
  if (indication?.id) {
    body.append('indicationId', indication.id);
  } else if (indication?.label) {
    body.append('newIndicationName', indication.label);
  }
  if (gene?.id) {
    body.append('goiId', gene.id);
  } else if (gene?.label) {
    body.append('newGoiName', gene.label);
  }
  return api.post<ISACreateResponse>(`/orgs/${orgId}/${pid}/isa-vector`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function updateISAVector({
  orgId,
  pid,
  name,
  indication,
  gene,
  isaVectorId,
}: UpdateISAVectorParams) {
  return api.put<{ id: string }>(
    `/orgs/${orgId}/${pid}/isa-vector/${isaVectorId}`,
    {
      name,
      indicationId: indication?.id || undefined,
      newIndicationName: (!indication?.id && indication?.label) || undefined,
      goiId: gene?.id || undefined,
      newGoiName: (!gene?.id && gene?.label) || undefined,
    },
  );
}

export async function updateVectorEntityMetadata(
  oid: string,
  pid: string,
  datacoreVectorId: string,
  vectorName: string,
  version = 'v1.0.0',
  metadata?: { [key: string]: string },
) {
  return api
    .post(
      `/orgs/${oid}/${pid}/datacore/entities/${DATACORE_VECTOR_TYPE}/${datacoreVectorId}/relationships/data`,
      {
        data: {
          type: 'entityData',
          attributes: {
            data: {
              name: vectorName,
            },
            metadata,
            typeSchemaVersionName: version,
          },
        },
      },
    )
    .then((res) => {
      return res.data;
    });
}

export async function createDatacoreVectorFisaRuns(
  oid: string,
  pid: string,
  datacoreVectorIds: string[],
) {
  return api
    .post(`/orgs/${oid}/${pid}/isa-vectors/fisa`, {
      vectors: datacoreVectorIds,
    })
    .then((res) => {
      return res.data;
    });
}

export async function fetchVectorFisaRunStatus(
  oid: string,
  pid: string,
  vectorId: string,
) {
  return api
    .get(`/orgs/${oid}/${pid}/isa-vector/${vectorId}/fisa-status`)
    .then((res) => {
      return res.data;
    });
}
