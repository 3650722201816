import { DatacoreParams } from '../../types';

export function formatDatacoreParams(params?: DatacoreParams) {
  const urlParams = new URLSearchParams();
  if (params?.include) {
    params.include.forEach((include) => {
      urlParams.append('include', include);
    });
  }
  return urlParams.toString();
}
