import { ReactNode, useState } from 'react';
import { styled } from '@formbio/ui/components';
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@formbio/ui/components/Dialog';
import { DialogHeader } from '@formbio/ui/components/Dialog/DialogHeader';
import { Button, ButtonProps } from '@formbio/ui/components/Button';
import { LinearProgress } from '@formbio/ui/components/LinearProgress';

const StyledDialogContent = styled(DialogContent)(({ theme }) => {
  return {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  };
});

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  'button + button': {
    marginLeft: theme.spacing(2),
  },
}));

type ConfirmDialogProps = {
  bodyText: ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: ButtonProps['color'];
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: () => Promise<unknown> | void;
  open: boolean;
  title?: string;
};

export default function ConfirmationDialog({
  bodyText,
  cancelButtonText = 'Cancel',
  confirmButtonColor = 'primary',
  confirmButtonText = 'Confirm',
  isLoading,
  onClose,
  onConfirm,
  open,
  title = 'Are you sure?',
}: ConfirmDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  function handleClose() {
    onClose?.();
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    await onConfirm?.();
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogHeader title={title} onClose={onClose} />
      <StyledDialogContent>{bodyText}</StyledDialogContent>
      <StyledDialogActions>
        {isLoading ? <LinearProgress /> : null}
        <Button
          variant='outlined'
          color='primary'
          onClick={handleClose}
          disabled={isSubmitting}
        >
          {cancelButtonText}
        </Button>
        <Button
          variant='contained'
          color={confirmButtonColor}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {confirmButtonText}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}
