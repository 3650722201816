import Head from 'next/head';
import { Container, Stack, styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import {
  PlugsConnected as PlugsConnectedIcon,
  Plugs as PlugsIcon,
} from '@formbio/ui/components/Icons';
import { keyframes } from '@mui/system';

const fadeInOut = keyframes`
    0%,100% { opacity: 0 };
    15%,80% { opacity: 1 };
`;

const fadeOutIn = keyframes`
    0%,100% { opacity: 1 };
    15%,80% { opacity: 0 };
`;

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  textAlign: 'center',
}));

const StyledStack = styled(Stack)(() => ({
  maxWidth: '1100px',
  textWrap: 'balance',
  lineHeight: '5rem',
  textAlign: 'center',
}));

const ICON_STYLES = {
  fontSize: 120,
};

const IconContainer = styled(Container)(() => ({
  display: 'block',
  margin: '40px auto',
}));

const StyledPlugIcon = styled(PlugsIcon)(() => ({
  ...ICON_STYLES,
  opacity: 0,
  animation: `${fadeInOut} 7s ease-out 1s infinite`,
  position: 'absolute',
}));

const StyledPlugsConnectedIcon = styled(PlugsConnectedIcon)(() => ({
  ...ICON_STYLES,
  // Even though there's no fill on the svg, it's being set by the default MUI props
  fill: 'none',
  stroke: 'currentColor',
  opacity: 1,
  animation: `${fadeOutIn} 7s ease-out 1s infinite`,
}));

export default function MaintenancePage() {
  return (
    <>
      <Head>
        <title>Temporarily Down for Maintenance - Form Bio</title>
      </Head>
      <StyledContainer>
        <StyledStack>
          <Typography variant='h2'>
            The Form Bio Platform is temporarily down for maintenance.
          </Typography>
          <IconContainer>
            <StyledPlugIcon />
            <StyledPlugsConnectedIcon />
          </IconContainer>
          <Typography variant='body2'>
            {`We'll be back shortly, and will redirect you automatically once maintenance is complete.`}
          </Typography>
          <hr />
          <Typography variant='body1'>
            We apologize for this inconvenience and appreciate your patience.
          </Typography>
        </StyledStack>
      </StyledContainer>
    </>
  );
}
