import { useRouter } from 'next/router';

import { NextApiRequest } from 'next';
import { NextRouter } from 'next/dist/client/router';
import { QueryParams } from '@/utils/routes';

export function getQueryParams(
  routerReq: NextRouter | NextApiRequest,
  param: string,
): string {
  const queryParam = routerReq.query[param];
  if (!queryParam) {
    return '';
  }
  if (Array.isArray(queryParam)) {
    return queryParam[queryParam.length - 1];
  } else {
    return String(queryParam);
  }
}

export function getAllQueryParams(routerReq: NextRouter | NextApiRequest): {
  [key in QueryParams]: string;
} {
  const paramKeys = Object.keys(QueryParams);
  const params: { [key in QueryParams]: string } = {} as {
    [key in QueryParams]: string;
  };
  paramKeys.forEach(key => {
    params[key as QueryParams] = getQueryParams(routerReq, key);
  });
  return params;
}

export default function useUrlParams() {
  const router = useRouter();
  return getAllQueryParams(router);
}
