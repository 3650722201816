import { styled } from '@formbio/ui/components';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  alignItems: 'flex-start',
  paddingTop: theme.spacing(3),
  height: '100%',
}));

const TabContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <StyledContainer className={className}>{children}</StyledContainer>;

export default TabContainer;
