import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  addNote,
  CreateNoteData,
  fetchAll,
  fetchDraft,
  removeNote,
  updateNote,
} from '../../services/notes';
import { Note } from '../../types';
import useInvalidation from '../useInvalidation';

const KEY = 'notes';
const DRAFT_KEY = 'draftNote';

export function useNotes(
  params?: Parameters<typeof fetchAll>[0],
  options: Omit<UseQueryOptions<Note[]>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<Note[]>({
    queryKey: [KEY, params],
    queryFn: () => fetchAll(params),
    ...{
      keepPreviousData: true,
      ...options,
    },
  });
}

export function useDraftNote(options?: UseQueryOptions<Note>) {
  return useQuery<Note>({
    queryKey: [DRAFT_KEY],
    queryFn: () => fetchDraft(),
    ...{
      keepPreviousData: true,
      ...options,
    },
  });
}

export function useRemoveNote(
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<unknown, unknown, string>({
    mutationFn: (id: string) => removeNote(id),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

/**
 * If a note is published we want to invalidate the timeline
 * as well as the draft note.
 * This only applies a draft note. Already published notes
 * should use `useUpdateNote` instead.
 */
export function usePublishNote(
  options: UseMutationOptions<unknown, unknown, Note> = {},
) {
  const invalidateKey = useInvalidation(KEY);

  return useMutation<unknown, unknown, Note>({
    mutationFn: updateNote,
    ...{
      onSuccess: () => {
        return invalidateKey();
      },
      ...options,
    },
  });
}
export function useUpdateNote(
  options: UseMutationOptions<unknown, unknown, Note> = {},
) {
  const invalidate = useInvalidation(KEY);
  return useMutation<unknown, unknown, Note>({
    mutationFn: updateNote,
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

// Distinct from `useUpdateNote` because we need to do
// an optimistic update on the draft note so the note
// stays consistent between pages without having to refetch it.
interface PreviousNoteContext {
  previousNote?: Note;
}

export function useUpdateDraftNote(
  options: Pick<
    UseMutationOptions<unknown, unknown, Note, PreviousNoteContext>,
    'onError'
  > = {},
) {
  const queryClient = useQueryClient();
  return useMutation<Note, unknown, Note, PreviousNoteContext>({
    mutationFn: updateNote,
    ...{
      ...options,
      onMutate: async (newNote: Note) => {
        await queryClient.cancelQueries({ queryKey: [DRAFT_KEY] });
        const previousNote = queryClient.getQueryData<Note>([DRAFT_KEY]);

        queryClient.setQueryData([DRAFT_KEY], newNote);

        return { previousNote };
      },
      onError: (error, newNote, context) => {
        if (context?.previousNote) {
          queryClient.setQueryData([DRAFT_KEY], context?.previousNote);
          options.onError?.(error, newNote, context);
        }
      },
    },
  });
}

export function useAddNote(
  options: UseMutationOptions<unknown, unknown, CreateNoteData> = {},
) {
  const invalidate = useInvalidation(DRAFT_KEY);

  return useMutation<unknown, unknown, CreateNoteData>({
    mutationFn: (data: CreateNoteData) => addNote(data),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
