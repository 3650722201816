import { CustomComponent } from '@formbio/ui/theme/type-extension';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    neutral: true;
  }
}

export const MuiButtonBase: CustomComponent<'MuiButtonBase'> = {
  defaultProps: {
    color: 'primary',
  },
};

export const MuiButton: CustomComponent<'MuiButton'> = {
  styleOverrides: {
    root: ({ theme }) => {
      const smallSpY = theme.spacing(1);
      const smallSpX = theme.spacing(2);
      const mediumSpY = theme.spacing(1.2);
      const mediumSpX = theme.spacing(3);
      const largeSpY = theme.spacing(1.4);
      const largeSpX = theme.spacing(5);
      return {
        // ensure that the background color is not overwritten
        // eg. JupyterNotebookPreview uses a global tailwind css style that makes contained buttons transparent
        '&.MuiButton-containedPrimary': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.MuiButton-containedSecondary': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&.MuiButton-containedError': {
          backgroundColor: theme.palette.error.main,
        },
        '&.MuiButton-containedSuccess': {
          backgroundColor: theme.palette.success.main,
        },
        '&.MuiButton-containedInfo': {
          backgroundColor: theme.palette.info.main,
        },
        '&.MuiButton-containedWarning': {
          backgroundColor: theme.palette.warning.main,
        },
        '&.MuiButton-sizeSmall': {
          padding: `${smallSpY} ${smallSpX}`,
        },
        '&.MuiButton-sizeMedium': {
          padding: `${mediumSpY} ${mediumSpX}`,
        },
        '&.MuiButton-sizeLarge': {
          padding: `${largeSpY} ${largeSpX}`,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.primary[100],
          color: theme.palette.primary[400],
        },
        '&.Mui-focusVisible ': {
          borderColor: 'currentColor',
          borderWidth: '1px',
          boxShadow: theme.shadows[20],
        },
        '&.MuiButton-outlined.MuiButton-sizeLarge': {
          paddingTop: `calc(${largeSpY} - 1px)`,
          paddingBottom: `calc(${largeSpY} - 1px)`,
        },
        // ensures that the outlined button
        // has the same height as the medium contained
        // when adjusting for the border
        '&.MuiButton-outlined.MuiButton-sizeMedium': {
          paddingTop: `calc(${mediumSpY} - 1px)`,
          paddingBottom: `calc(${mediumSpY} - 1px)`,
        },
        // ensures that the outlined button
        // has the same height as the small contained
        // when adjusting for the border
        '&.MuiButton-outlined.MuiButton-sizeSmall': {
          paddingTop: `calc(${smallSpY} - 1px)`,
          paddingBottom: `calc(${smallSpY} - 1px)`,
        },
        boxShadow: 'none',
        // otherwise color is 0.5 opacity
        borderColor: 'currentColor',
        // prevent multiword labels from wrapping
        width: 'max-content',
      };
    },
  },
};
