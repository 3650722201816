import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  adminDeleteWorkflowVersion,
  fetchAllAdmin,
  adminPatchWorkflowVersion,
  adminUndeleteWorkflowVersion,
  WorkflowVersionPatch,
} from '../../../services/admin/workflows';
import type { AdminWorkflowVersion } from '../../../types/workflow-version';
import useInvalidation from '../../useInvalidation';

const KEY = 'workflows';

export const useWorkflowsAdmin = (params: { deleted: boolean }) => {
  const workflowsQuery = useQuery({
    queryKey: [KEY, params],
    queryFn: () => fetchAllAdmin(params),
  });
  const allWorkflowVersions: AdminWorkflowVersion[] | undefined =
    workflowsQuery.data;

  return {
    allWorkflowVersions,
    isLoading: workflowsQuery.isLoading,
  };
};

export function useAdminWorkflowVersionPatch(versionId: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationKey: [KEY, versionId],
    mutationFn: (payload: WorkflowVersionPatch) =>
      adminPatchWorkflowVersion(versionId, payload),
    ...{
      onSuccess: () => invalidate(),
    },
  });
}

export function useAdminWorkflowVersionDelete(
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationFn: (versionId: string) => adminDeleteWorkflowVersion(versionId),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useAdminWorkflowVersionUndelete(
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationFn: (versionId: string) => adminUndeleteWorkflowVersion(versionId),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
