import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps,
  styled,
} from '@mui/material';

export const LinearProgress = ({ ...rest }: LinearProgressProps) => (
  <MuiLinearProgress {...rest} />
);

export const DialogLinearProgress = styled(LinearProgress)(({ theme }) => ({
  margin: theme.spacing(1, 1, 0),
}));

export const LinearProgressFullWidth = styled(LinearProgress)(() => ({
  width: '100%',
}));

export type { LinearProgressProps };
