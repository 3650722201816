import {
  fetchAll as fetchVaultItems,
  fetchFileContent,
  fetchSignedURL,
  fetchOne as fetchOneVaultItem,
} from '../storage';
import {
  VaultItem,
  ProjectBucketDataParams,
  ProjectBucketDataRecordParams,
} from '../../types';

function getPrefix(namespace: string) {
  return `.formbio/data/${namespace}`;
}

export function getDocumentName(namespace: string, documentId: string) {
  return `${getPrefix(namespace)}/${documentId}.json`;
}

async function convertObjectsToDocuments<T>(
  oid: string,
  pid: string,
  vaultObjs: VaultItem[],
) {
  const signedUrls = await Promise.all(
    vaultObjs.map((obj) => {
      return fetchSignedURL({
        oid,
        pid,
        path: obj.name,
      });
    }),
  );
  const contents = await Promise.all(
    signedUrls.map(async (url) => {
      return fetchFileContent(url);
    }),
  );
  return contents.reduce<T[]>((acc, content) => {
    try {
      const parsed = JSON.parse(content);
      return [...acc, parsed];
    } catch (_err) {
      return acc;
    }
  }, []);
}

export async function fetchAllByNamespace<T>({
  oid,
  pid,
  namespace,
}: ProjectBucketDataParams) {
  const fetchParams = {
    oid,
    pid,
    prefix: getPrefix(namespace),
  };
  const vaultObjs = await fetchVaultItems(fetchParams);
  // only want files that aren't empty
  const files = vaultObjs.filter((obj) => obj.size > 0);
  return convertObjectsToDocuments<T>(oid, pid, files);
}

export async function fetchOneByNamespace<T>({
  documentId,
  namespace,
  oid,
  pid,
}: ProjectBucketDataRecordParams) {
  const vaultObj = await fetchOneVaultItem({
    oid,
    pid,
    objectName: getDocumentName(namespace, documentId),
  });
  const documents = await convertObjectsToDocuments<T>(oid, pid, [vaultObj]);
  return documents[0];
}
