import { Skeleton } from '@formbio/ui';
import { styled } from '@formbio/ui/components';

const IGV_TOOLBAR_HEIGHT = 30;
const IGV_CHROMOSOME_HEIGHT = 50;
const IGV_TRACK_HEIGHT = 60;

const StyledLoader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  margin: theme.spacing(2, 1),
  '.chromosomes': {
    margin: theme.spacing(0, 6),
  },
  '.tracks': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    margin: theme.spacing(0, 3),
  },
}));

export default function IGVBrowserLoading() {
  return (
    <StyledLoader>
      <Skeleton height={IGV_TOOLBAR_HEIGHT} variant='rounded' />
      <Skeleton
        height={IGV_CHROMOSOME_HEIGHT}
        variant='rounded'
        className='chromosomes'
      />
      <div className='tracks'>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} height={IGV_TRACK_HEIGHT} variant='rounded' />
        ))}
      </div>
    </StyledLoader>
  );
}
