import { faker } from '@faker-js/faker';
import { JourneyPhaseRun, JourneyRun } from '@formbio/api';
import { allJourneys } from './journeys';

/*****
 * A special experiment to go through all phases of a journey run in a timelapsed fashion.
 *
 */

const startedAt1 = faker.date.recent({ days: 15 }).toISOString();
const completedAt1 = faker.date.recent({ days: 13 }).toISOString();

function createTimelapseRunningProgress(phaseIndex: number): JourneyPhaseRun[] {
  return Array.from({ length: 4 }).map((_, index) => ({
    id: `timelapsed-phase-run-${allJourneys[0].phases[phaseIndex].id}`,
    slug: `timelapsed-phase-run-${allJourneys[0].phases[phaseIndex].id}`,
    phase: allJourneys[0].phases[phaseIndex],
    startedAt: startedAt1,
    eta: faker.date.recent({ days: 12 - index * 2 }).toISOString(),
    pctCompleted: 15 + index * 20,
  }));
}

function createTimelapseCompleted(phaseIndex: number) {
  return {
    id: `timelapsed-phase-run-${allJourneys[0].phases[phaseIndex].id}`,
    slug: `timelapsed-phase-run-${allJourneys[0].phases[phaseIndex].id}`,
    phase: allJourneys[0].phases[phaseIndex],
    pctCompleted: 100,
    startedAt: startedAt1,
    completedAt: completedAt1,
  };
}

const timelapsePhaseRun1Completed = createTimelapseCompleted(0);
const timelapsePhaseRun2Completed = createTimelapseCompleted(1);
const timelapsePhaseRun3Completed = createTimelapseCompleted(2);
const timelapsePhaseRun4Completed = createTimelapseCompleted(3);
const timelapsePhaseRun5Completed = createTimelapseCompleted(4);

/**
 * Each array of phase runs represents the progress of a journey run.
 * An MSW handler will increment the index of the array to simulate the progress of a journey run.
 */
export const timelapsePhaseRuns: JourneyPhaseRun[][] = [
  // phase 1
  [],
  ...createTimelapseRunningProgress(0).map(phaseRun => [phaseRun]),
  [timelapsePhaseRun1Completed],
  // phase 2
  ...createTimelapseRunningProgress(1).map(phaseRun => [
    timelapsePhaseRun1Completed,
    phaseRun,
  ]),
  [timelapsePhaseRun1Completed, timelapsePhaseRun2Completed],
  // phase 3
  ...createTimelapseRunningProgress(2).map(phaseRun => [
    timelapsePhaseRun1Completed,
    timelapsePhaseRun2Completed,
    phaseRun,
  ]),
  [
    timelapsePhaseRun1Completed,
    timelapsePhaseRun2Completed,
    timelapsePhaseRun3Completed,
  ],
  // phase 4
  ...createTimelapseRunningProgress(3).map(phaseRun => [
    timelapsePhaseRun1Completed,
    timelapsePhaseRun2Completed,
    timelapsePhaseRun3Completed,
    phaseRun,
  ]),
  [
    timelapsePhaseRun1Completed,
    timelapsePhaseRun2Completed,
    timelapsePhaseRun3Completed,
    timelapsePhaseRun4Completed,
  ],
  // phase 5
  ...createTimelapseRunningProgress(4).map(phaseRun => [
    timelapsePhaseRun1Completed,
    timelapsePhaseRun2Completed,
    timelapsePhaseRun3Completed,
    timelapsePhaseRun4Completed,
    phaseRun,
  ]),
  [
    timelapsePhaseRun1Completed,
    timelapsePhaseRun2Completed,
    timelapsePhaseRun3Completed,
    timelapsePhaseRun4Completed,
    timelapsePhaseRun5Completed,
  ],
];

export const timelapseJourneyRun: JourneyRun = {
  id: `timelapse-journey-run`,
  slug: `timelapse-journey-run`,
  journey: allJourneys[0],
  phaseRuns: [],
  runName: 'Timelapse Journey Run',
};
