import { SvgIcon, SvgIconProps } from '@mui/material';

export default function SpinnerIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <path
        fill='currentColor'
        d='M8,4C7.6,4,7.2,3.7,7.2,3.2V0.8C7.2,0.3,7.6,0,8,0s0.8,0.3,0.8,0.8v2.5C8.8,3.7,8.4,4,8,4z'
      />
      <path
        fill='currentColor'
        d='M5.2,5.2c-0.3,0.3-0.8,0.3-1.1,0L2.4,3.4c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l1.8,1.8
	C5.5,4.4,5.5,4.9,5.2,5.2z'
      />
      <path
        fill='currentColor'
        d='M4,8c0,0.4-0.3,0.8-0.8,0.8H0.8C0.4,8.7,0,8.4,0,8c0-0.4,0.3-0.8,0.8-0.8h2.5C3.7,7.2,4,7.6,4,8z'
      />
      <path
        fill='currentColor'
        d='M5.2,10.8c0.3,0.3,0.3,0.8,0,1.1l-1.8,1.8c-0.3,0.3-0.8,0.3-1.1,0c-0.3-0.3-0.3-0.8,0-1.1l1.8-1.8
	C4.4,10.5,4.9,10.5,5.2,10.8z'
      />
      <path
        fill='currentColor'
        d='M8,12c0.4,0,0.8,0.3,0.8,0.8v2.5C8.8,15.6,8.4,16,8,16s-0.8-0.3-0.8-0.8v-2.5C7.3,12.3,7.6,12,8,12z'
      />
      <path
        fill='currentColor'
        d='M10.8,10.8c0.3-0.3,0.8-0.3,1.1,0l1.8,1.8c0.3,0.3,0.3,0.8,0,1.1s-0.8,0.3-1.1,0l-1.8-1.8
	C10.5,11.6,10.5,11.1,10.8,10.8z'
      />
      <path
        fill='currentColor'
        d='M12,8c0-0.4,0.3-0.8,0.8-0.8h2.5C15.7,7.2,16,7.6,16,8c0,0.4-0.3,0.8-0.8,0.8h-2.5C12.3,8.7,12,8.4,12,8z'
      />
      <path
        fill='currentColor'
        d='M10.8,5.2c-0.3-0.3-0.3-0.8,0-1.1l1.8-1.8c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1l-1.8,1.8
	C11.6,5.5,11.1,5.5,10.8,5.2z'
      />
    </SvgIcon>
  );
}
