import { DataGridPro, GridColDef } from '@formbio/ui/components';
import NoRowsOverlay from '@formbio/ui/components/Table/NoRowsOverlay';
import { styled } from '@formbio/ui/components';

const StyledGrid = styled(DataGridPro)(({ theme }) => ({
  height: '100%',
  width: '100%',
  minHeight: '200px',
  flex: 1,
  '.no-rows': {
    height: '100%',
  },
  '.MuiDataGrid-cellContent': {
    padding: theme.spacing(0, 1),
  },
}));

export type GenericTableData = {
  rows: Record<string, string>[];
  columns: string[];
};

export default function GenericDataTable({ data }: { data: GenericTableData }) {
  const columns: GridColDef[] = data.columns.map((column, index) => ({
    field: column,
    headerName: column,
    flex: 1,
    minWidth: index === 0 ? 150 : 50,
    sortingOrder: ['asc', 'desc'],
  }));
  const rows = data.rows.map((row, index) => ({
    ...row,
    id: row.id ? row.id : index,
  }));

  return (
    <StyledGrid
      columns={columns}
      rows={rows}
      hideFooter
      density='compact'
      disableColumnFilter
      disableColumnMenu
      isRowSelectable={() => false}
      slots={{
        noRowsOverlay: () => (
          <div className='no-rows'>
            <NoRowsOverlay message='Nothing to display.'></NoRowsOverlay>
          </div>
        ),
      }}
    />
  );
}
