/**
 * The default template for creating dialogs (see opening files with IGV)
 *
 * The titled is style to match other components like BasicDrawer
 *
 * There is a default "cancel" button to close the dialog.
 *
 * actions: list of other elements (like buttons) displayed at the bottom of the dialog
 */

import { styled, useMediaQuery, useTheme } from '@formbio/ui/components';
import React, { ReactElement, ReactNode } from 'react';
import { Button } from '@formbio/ui/components/Button';
import { LinearProgress } from '@formbio/ui/components/LinearProgress';
import {
  DialogProps,
  Dialog,
  DialogActions,
  DialogContent,
} from '@formbio/ui/components/Dialog';
import DialogHeader from '@formbio/ui/components/Dialog/DialogHeader';

interface BasicDialogProps extends DialogProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  titleIcon?: ReactNode;
  children: React.ReactNode;
  //an array of buttons, outlined
  actions?: ReactElement[];
  // an array of buttons, outlined, can be empty array to prevent cancel button from being rendered
  cancelButtonText?: string;
  isAlert?: boolean;
  // disallows user from closing dialog by clicking on backdrop/pressing esc key
  permanent?: boolean;
  loading?: boolean;
  headerCentered?: boolean;
  actionBar?: ReactNode;
  hideFooter?: boolean;
}

export default function BasicDialog({
  open,
  onClose,
  title,
  titleIcon,
  children,
  actions,
  cancelButtonText,
  isAlert = false,
  permanent = false,
  loading = false,
  actionBar,
  hideFooter = false,
  ...dialogProps
}: BasicDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function handleClose(_: unknown, reason: 'backdropClick' | 'escapeKeyDown') {
    if (permanent && reason) {
      console.error(reason);
    } else if (onClose) {
      onClose();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      {...dialogProps}
    >
      <DialogHeader
        title={title}
        titleIcon={titleIcon}
        onClose={onClose}
        isAlert={isAlert}
      />
      <Content content={children} />
      {!hideFooter && (
        <Footer
          actions={actions}
          onClose={onClose}
          cancelText={cancelButtonText}
          loading={loading}
          actionBar={actionBar}
        />
      )}
    </Dialog>
  );
}

function Content({ content }: { content: React.ReactNode }) {
  if (content) {
    return <DialogContent>{content}</DialogContent>;
  }
  return null;
}

function CancelButton({
  cancelText = 'Cancel',
  onClose,
  disabled,
}: {
  cancelText: string | undefined;
  onClose: React.MouseEventHandler;
  disabled: boolean | undefined;
}) {
  return (
    <Button variant='outlined' onClick={onClose} disabled={disabled}>
      {cancelText}
    </Button>
  );
}

function Actions({
  actions,
  onClose,
  cancelText,
  loading,
  // replace the content of the DialogActions
  actionBar,
}: {
  actions: ReactElement[] | undefined;
  onClose: React.MouseEventHandler | undefined;
  cancelText: string | undefined;
  loading: boolean | undefined;
  actionBar?: ReactNode;
}) {
  // override all buttons
  if (actionBar) {
    return <DialogActions>{actionBar}</DialogActions>;
  }
  return (
    <DialogActions>
      {onClose ? (
        <CancelButton
          cancelText={cancelText}
          onClose={onClose}
          disabled={loading}
        />
      ) : null}
      {actions}
    </DialogActions>
  );
}

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  margin: theme.spacing(1, 2),
}));

const StyledFooter = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

function Footer({
  actions,
  onClose,
  cancelText,
  loading,
  // replace the content of the DialogActions
  actionBar,
}: {
  actions: ReactElement[] | undefined;
  onClose: React.MouseEventHandler | undefined;
  cancelText: string | undefined;
  loading: boolean | undefined;
  actionBar?: ReactNode;
}) {
  if (actions || onClose || actionBar) {
    return (
      <StyledFooter>
        {loading && <StyledLinearProgress />}
        <Actions
          actions={actions}
          onClose={onClose}
          cancelText={cancelText}
          loading={loading}
          actionBar={actionBar}
        />
      </StyledFooter>
    );
  }
  return null;
}
