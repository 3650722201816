import { User } from '../../../types';
import {
  fetchAllAdmin,
  fetchOneAdmin,
  updateUserVerification,
} from '../../../services/admin/users';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import useInvalidation from '../../../hooks/useInvalidation';

const KEY = 'users';

export function useUsersAdmin({
  options,
}: {
  options?: UseQueryOptions<User[]>;
} = {}) {
  const usersQuery = useQuery<User[]>({
    queryKey: [KEY],
    queryFn: fetchAllAdmin,
    ...options,
  });
  const allUsers: User[] | undefined = usersQuery.data;

  return {
    allUsers,
    isLoading: usersQuery.isLoading,
  };
}

export function useUserAdmin({
  email,
  id,
  options,
}: {
  email?: string;
  id?: string;
  options?: UseQueryOptions<User>;
} = {}) {
  return useQuery<User>({
    queryKey: [KEY, email, id],
    queryFn: () => fetchOneAdmin({ email, id }),
    ...{
      enabled: !!email || !!id,
      ...options,
    },
  });
}

export function useVerifyUser() {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationFn: ({
      userEmail,
      verifiedStatus,
    }: {
      userEmail: string;
      verifiedStatus: boolean;
    }) => updateUserVerification(userEmail, verifiedStatus),
    onSuccess: () => invalidate(),
  });
}
