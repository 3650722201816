import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http, passthrough } from 'msw';
import { aFlowRun, aVector, flowRuns, vectors } from './data/assays';

/**
 * Mock handler for fetching an ISA Vector
 * returns a report if the assay as been purchased (orders.length > 0)
 */
const getAVector = http.get(
  '*/api/v1/orgs/:oid/:pid/isa-vector/:id',
  async () => {
    return HttpResponse.json(aVector);
  },
);

const allVectors = http.get(
  '*/api/v1/orgs/:oid/:pid/isa-vectors',
  // async ({ request }) => {
  // const vectors: ISAVector[] = await fetch(bypass(request)).then(response =>
  //   response.json(),
  // );
  // },
  async () => {
    return HttpResponse.json(vectors);
  },
);

const signedUrl = http.get(
  '*/api/v1/orgs/:oid/:pid/vault/*/:path',
  ({ params }) => {
    const path = params.path as string;
    if (path.includes('case-study.pdf')) {
      return HttpResponse.json({
        signedURL: `https://storage.googleapis.com/formbio-public-cms/case-study.pdf`,
      });
    }
    return passthrough();
  },
);

const createFlowRun = http.post(
  '*/api/v1/orgs/:oid/:pid/flow-run',
  async () => {
    await delay(500);
    return HttpResponse.json({ id: faker.string.uuid() });
  },
);

const flowRunInsights = http.post(
  '*/api/v1/orgs/:oid/:pid/flow-run/:flowRunId/vector-insights',
  async () => {
    await delay(500);
    return HttpResponse.json(aFlowRun);
  },
);

const getAFlowRun = http.get(
  '*/api/v1/orgs/:oid/:pid/flow-run/:id',
  async ({ params }) => {
    const id = params.id as string;
    await delay(500);
    return HttpResponse.json(
      flowRuns.find(run => run.id === id) ||
        // when testing reloading the launcher page for example, the uuid won't exist anymore. Tweak which flow run to return based on what you want to test (eg. testing a flow run that should not reach the launcher page with run.status !== 'CREATED')
        flowRuns.find(run => run.status !== 'CREATED'),
    );
  },
);
const getAllFlowRuns = http.get(
  '*/api/v1/orgs/:oid/:pid/flow-runs',
  async () => {
    await delay(500);
    return HttpResponse.json(flowRuns);
  },
);

export const assayHandlers = [
  getAVector,
  allVectors,
  signedUrl,
  createFlowRun,
  flowRunInsights,
  getAFlowRun,
  getAllFlowRuns,
];
