import useInvalidation from '../../../hooks/useInvalidation';
import {
  approveEntitlementChange,
  fetchAllAdmin,
  rejectEntitlementChange,
} from '../../../services/admin/accounts';
import { Account } from '../../../types';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

export const ACCOUNTS_KEY = 'accounts';

export function useAccountsAdmin() {
  const accountsQuery = useQuery({
    queryKey: [ACCOUNTS_KEY],
    queryFn: fetchAllAdmin,
  });
  const accounts: Account[] | undefined = accountsQuery.data;

  return {
    accounts,
    isLoading: accountsQuery.isLoading,
    isError: accountsQuery.isError,
    error: accountsQuery.error,
  };
}

export function useApproveEntitlementChange(
  options?: UseMutationOptions<unknown, unknown, { entitlementId: string }>,
) {
  const invalidate = useInvalidation(ACCOUNTS_KEY);

  return useMutation<unknown, unknown, { entitlementId: string }>({
    mutationFn: ({ entitlementId }: { entitlementId: string }) =>
      approveEntitlementChange(entitlementId),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useRejectEntitlementChange(
  options?: UseMutationOptions<
    unknown,
    unknown,
    { entitlementId: string; message?: string }
  >,
) {
  const invalidate = useInvalidation(ACCOUNTS_KEY);

  return useMutation<
    unknown,
    unknown,
    { entitlementId: string; message?: string }
  >({
    mutationFn: ({
      entitlementId,
      message,
    }: {
      entitlementId: string;
      message?: string;
    }) => rejectEntitlementChange(entitlementId, message),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
    ...options,
  });
}
