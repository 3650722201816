import { useRouter } from 'next/router';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import useInvalidation from '../../useInvalidation';
import {
  fetchOneAdmin,
  patchOrgAdmin,
} from '../../../services/admin/organizations';

const KEY = 'organization';

export function useOrganizationAdmin() {
  const router = useRouter();
  const oid = router.query.oid as string;

  const { data: organization, isLoading } = useQuery({
    queryKey: [KEY + oid],
    queryFn: () => fetchOneAdmin(oid),
  });

  return { organization, isLoading, oid };
}

export function useUpdateOrganizationName(
  oid: string,
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY + oid);

  return useMutation({
    mutationFn: (name: string) => patchOrgAdmin(oid, { name }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useUpdateBillingId(
  oid: string,
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY + oid);

  return useMutation({
    mutationFn: (billingId: string) => patchOrgAdmin(oid, { billingId }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
