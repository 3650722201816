import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiDivider: CustomComponent<'MuiDivider'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '::before, ::after': {
        borderColor: theme.palette.primary[700],
      },
      color: theme.palette.primary[50],
    }),
  },
};
