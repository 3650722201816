export enum CheckRunStatus {
  Completed = 'completed',
  Failed = 'failure',
  Queued = 'queued',
  InProgress = 'in_progress',
}

export interface CheckRun {
  checkSuiteId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  status: CheckRunStatus;
  outputPath?: string;
  ghCheckRunId?: number;
  ghUrl?: string;
  id: string;
}
