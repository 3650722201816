export default function Watermark({
  children,
  text = 'Demo',
}: {
  children: React.ReactNode;
  text?: string;
}) {
  return (
    <div>
      {children}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'rgba(0, 0, 0, 0.1)',
          fontSize: '5rem',
          fontWeight: 'bold',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {text}
      </div>
    </div>
  );
}
