import { AvatarProps } from '@formbio/ui/types';
import { Avatar } from '@formbio/ui/components/Avatar';
import TextAvatar, {
  AvatarSizes,
} from '@formbio/ui/components/Avatar/TextAvatar';

export default function AvatarPicture({
  avatar,
  size = AvatarSizes.medium,
  border = false,
}: {
  avatar: AvatarProps;
  size?: AvatarSizes;
  border?: boolean;
}) {
  if (avatar.image) {
    return (
      <Avatar
        alt={avatar.displayName}
        src={avatar.image}
        width={size}
        height={size}
      />
    );
  }
  return (
    <TextAvatar
      text={avatar.displayName}
      size={size}
      border={border}
      uniqId={avatar.email || avatar.displayName}
    ></TextAvatar>
  );
}
