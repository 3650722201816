import { styled } from '@formbio/ui/components';
import { Tooltip } from '@formbio/ui/components/Tooltip';
import { Typography } from '@formbio/ui/components/Typography';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
} from '@formbio/ui/components/Icons';
import { ReactNode } from 'react';

const StyledDiv = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5),
  wordBreak: 'break-all',
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  '.title': {
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '.warning': {
    color: theme.palette.warning.main,
  },
  '.success': {
    color: theme.palette.success.main,
  },
}));

export default function LinkTooltip({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  const isSameOrigin = href?.startsWith(window.location.origin);

  return (
    <Tooltip
      title={
        <StyledDiv>
          {isSameOrigin ? (
            <Typography variant='body2' className='title'>
              <CheckCircleIcon className='success' weight='fill' />
              Formbio Link:
            </Typography>
          ) : (
            <Typography variant='body2' className='title'>
              <WarningIcon className='warning' weight='fill' />
              External Link:
            </Typography>
          )}
          <Typography variant='body2' className='link'>
            {href}
          </Typography>
        </StyledDiv>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
}
