import { ChartType } from '../types';
import { VadrAttribute, VadrScores } from '../types/vadr-scores';

/** A temporary function while we transition from the old
 * to the new contract for VADR scores.
 */
export function getVadrScoreColumns({
  vadrScores,
  isNewVadrScoreContract,
  chartType,
}: {
  vadrScores: VadrScores;
  isNewVadrScoreContract?: boolean;
  chartType: ChartType;
}): VadrAttribute[] {
  if (isNewVadrScoreContract) {
    return vadrScores[chartType]?.columns || [];
  }
  return vadrScores.heatmapConfig?.columnScores || [];
}

/** A temporary function while we transition from the old
 * to the new contract for VADR scores.
 */
export function getVadrScoreSortColumn({
  vadrScores,
  isNewVadrScoreContract,
  chartType,
}: {
  vadrScores: VadrScores;
  isNewVadrScoreContract?: boolean;
  chartType: ChartType;
}): string {
  if (isNewVadrScoreContract) {
    return vadrScores[chartType]?.sortColumn || '';
  }
  return vadrScores.heatmapConfig?.defaultSortScore.scoreAttribute || '';
}
