import { Typography, styled } from '@mui/material';
import FileDragAndDrop from '..';
import { FileArrowUp as FileArrowUpIcon } from '@phosphor-icons/react';
import { UseFormRegisterReturn } from 'react-hook-form';

const StyledFileDragAndDrop = styled(FileDragAndDrop)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'transparent',
  height: '100%',
  overflow: 'hidden',
  // padding to separate the border from the content
  // margin to keep the content aligned with the rest
  padding: theme.spacing(1),
  margin: theme.spacing(-1),
  transition: `all 1s ease`,
  '> div': {
    height: '100%',
    overflow: 'hidden',
  },
  '&.drag': {
    backgroundColor: theme.palette.info[100],
    // add more components here that needs to be hidden
    '.MuiTextField-root': {
      backgroundColor: 'transparent',
    },
    button: {
      visibility: 'hidden',
    },
    '.drag-drop-info': {
      top: '50%',
    },
    // don't apply the disabled color to the info text
    '*:not(.drag-drop-info *)': {
      color: theme.palette.action.disabled,
    },
  },
  '&.rest': {
    borderColor: 'transparent',
  },
  '.drag-drop-info': {
    transition: `all 0.5s ease`,
    position: 'absolute',
    // push down the element so it's not visible
    // even when zooming in/out
    top: '120vh',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.info[400],
    borderRadius: theme.shape.borderRadius * 2,
    textAlign: 'center',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    'p, svg': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default function FileDnDWrapper<FieldName extends string>({
  children,
  onDrop,
  registerProps,
  fileOrFolder = 'file',
}: {
  children: React.ReactNode;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  registerProps: UseFormRegisterReturn<FieldName>;
  fileOrFolder?: 'file' | 'folder';
}) {
  const { ref: _ref, ...rest } = registerProps;
  return (
    <StyledFileDragAndDrop onDrop={onDrop} {...rest}>
      {children}
      <div className='drag-drop-info'>
        <Typography variant='body1' fontWeight='500'>
          Drop your {fileOrFolder} here
        </Typography>
        <FileArrowUpIcon />
      </div>
    </StyledFileDragAndDrop>
  );
}
