import { Organization } from './organization';

export enum EntitlementState {
  STATE_UNSPECIFIED = 'ENTITLEMENT_STATE_UNSPECIFIED',
  ACTIVATION_REQUESTED = 'ENTITLEMENT_ACTIVATION_REQUESTED',
  ACTIVE = 'ENTITLEMENT_ACTIVE',
  PENDING_CANCELLATION = 'ENTITLEMENT_PENDING_CANCELLATION',
  CANCELLED = 'ENTITLEMENT_CANCELLED',
  PENDING_PLAN_CHANGE = 'ENTITLEMENT_PENDING_PLAN_CHANGE',
  PENDING_PLAN_CHANGE_APPROVAL = 'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL',
  SUSPENDED = 'ENTITLEMENT_SUSPENDED',
}

export enum PlanTiers {
  INDIVIDUAL = 'individual',
  BASIC = 'basic',
  PRO = 'pro',
}

export type Account = {
  id: string;
  accountId: string;
  status: EntitlementState;
  plan: PlanTiers;
  pendingPlan?: string;
  organizations?: Organization[];
  tier: Tier;
  accountQuotas?: FeatureQuota[];
  // temporary until the API is updated
  account: {
    external_account_id: string;
    external_account_provider: string;
  };
};

export interface Tier {
  id: string;
  name: string;
  displayName: string;
  description: string;
}

export enum FeatureNames {
  CLOUD_NATIVE = 'cloud_native',
  ADDITIONAL_USER_SEATS = 'additional_user_seats',
  ORGANIZATION_MANAGEMENT = 'organization_management',
  ORGANIZATION_NUMBER = 'organization_number',
  DATA_MANAGEMENT = 'data_management',
  DATA_TRANSFER = 'data_transfer',
  GENOME_REPO = 'genome_repo',
  BASIC_WORKFLOWS = 'basic_workflows',
  NFCORE_WORKFLOWS = 'nfcore_workflows',
  DEVELOPER_TOOLS = 'developer_tools',
  NOTES = 'notes',
  BATCH_EXT = 'batch_ext',
  BASIC_FORMBENCH = 'basic_formbench',
  FORM_SHELL = 'form_shell',
  BASIC_COMPUTE = 'basic_compute',
  DATA_BACKUP_RETENTION = 'data_backup_retention',
  SUPPORT = 'support',
  INCLUDED_USER_SEATS = 'included_user_seats',
  MAX_ADDITIONAL_USER_SEAT_COUNT = 'max_additional_user_seat_count',
  ADDITIONAL_USER_SEAT_PRICE = 'additional_user_seat_price',
  PROJECT_NUMBER = 'project_number',
  PRICE = 'price',
  USAGE_QUOTA = 'usage_quota',
  WORKFLOW_MANAGEMENT = 'workflow_management',
  STANDARD_WORKFLOWS = 'standard_workflows',
  COMMUNITY_WORKFLOWS = 'community_workflows',
  CUSTOM_WORKFLOWS = 'custom_workflows',
  CUSTOM_FORMBENCH = 'custom_formbench',
  PREMIUM_COMPUTE = 'premium_compute',
  GPU = 'gpu',
  ONBOARDING = 'onboarding',
  USER_WORKFLOWS = 'user_workflows',
  DATA_PROVENANCE = 'data_provenance',
}

export type UsageFeatures =
  | FeatureNames.ADDITIONAL_USER_SEATS
  | FeatureNames.INCLUDED_USER_SEATS
  | FeatureNames.USAGE_QUOTA
  | FeatureNames.PROJECT_NUMBER;

export const ALL_USAGE_FEATURES: UsageFeatures[] = [
  FeatureNames.ADDITIONAL_USER_SEATS,
  FeatureNames.INCLUDED_USER_SEATS,
  FeatureNames.PROJECT_NUMBER,
  FeatureNames.USAGE_QUOTA,
];

export type Feature = {
  id: string;
  name: FeatureNames;
  displayName: string;
  description: string;
  quota: number;
};

export interface FeatureQuota extends Feature {
  featureId: string;
}

export type MarketplaceTier = {
  id: string;
  name: string;
  displayName: string;
  description: string;
  tierQuotas: FeatureQuota[];
};

export interface Quotas {
  accountId: string;
  tier: Tier;
  features: Feature[];
}

export interface FeatureUsage extends Feature {
  totalQuota: number;
  quotaUsed: number;
  timeStart?: string;
  timeEnd?: string;
}
