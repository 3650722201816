import { faker } from '@faker-js/faker';

export function createMockUser() {
  return {
    created_at: faker.date.past().toDateString(),
    updated_at: faker.date.past().toDateString(),
    email: faker.internet.email(),
    name: `${faker.person.firstName()} ${faker.person.lastName()}`,
    email_notification_preferences: {
      somePreference: true,
    },
    is_verified: true,
    last_login: faker.date.past().toDateString(),
  };
}
