import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchAll, fetchOne } from '../../services/journeys';
import { JourneySchema } from '../../types';

const KEY = 'journeys';

export function useJourneys(options?: UseQueryOptions<JourneySchema[]>) {
  return useQuery<JourneySchema[]>({
    queryKey: [KEY],
    queryFn: () => fetchAll(),
    ...options,
  });
}

export function useJourney(
  params: Parameters<typeof fetchOne>[0],
  options?: UseQueryOptions<JourneySchema>,
) {
  return useQuery<JourneySchema>({
    queryKey: [KEY, params],
    queryFn: () => fetchOne(params),
    ...{
      enabled: !!params.slug,
      ...options,
    },
  });
}
