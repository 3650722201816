import { useQueryClient } from '@tanstack/react-query';

export function useInvalidateAll() {
  const queryClient = useQueryClient();
  return async () => {
    if (queryClient.getQueryCache()) {
      await queryClient.removeQueries();
      await queryClient.invalidateQueries();
    }
  };
}
