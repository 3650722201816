import { Stack, styled, useTheme } from '@formbio/ui/components';
import { Tooltip } from '@formbio/ui/components/Tooltip';
import StatusChip from '@formbio/ui/components/Chip/StatusChip';
import {
  CheckCircle as CheckCircleIcon,
  Question as QuestionIcon,
  ClockCounterClockwise as ClockCounterClockwiseIcon,
  PlayCircle as PlayCircleIcon,
} from '@formbio/ui/components/Icons';
import { ProgramStatusValues } from '@formbio/ui/types/program-status';

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  display: 'flex',
  '.icon': {
    fontSize: 'large',
    color: theme.palette.warning.main,
  },
}));

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
}));

function getStatusIcon(type: ProgramStatusValues) {
  switch (type) {
    case ProgramStatusValues.IN_PROGRESS:
      return <ClockCounterClockwiseIcon />;
    case ProgramStatusValues.COMPLETED:
      return <CheckCircleIcon />;
    case ProgramStatusValues.READY:
      return <PlayCircleIcon />;
    default:
      return <QuestionIcon />;
  }
}

function useProgramRunColors(type: ProgramStatusValues) {
  const theme = useTheme();
  switch (type) {
    case ProgramStatusValues.IN_PROGRESS:
      return {
        backgroundColor: theme.palette.info[200],
        contrastColor: theme.palette.info[600],
      };
    case ProgramStatusValues.COMPLETED:
      return {
        backgroundColor: theme.palette.success[200],
        contrastColor: theme.palette.success[600],
      };
    case ProgramStatusValues.READY:
      return {
        backgroundColor: theme.palette.neutral[200],
        contrastColor: theme.palette.neutral[600],
      };
    default:
      return {
        backgroundColor: theme.palette.neutral[200],
        contrastColor: theme.palette.neutral[600],
      };
  }
}

export default function ProgramRunStatusChip({
  type,
  clickable = false,
}: {
  type: ProgramStatusValues;
  clickable?: boolean;
}) {
  return (
    <StyledStack direction='row' spacing={1}>
      <StatusChip
        icon={getStatusIcon(type)}
        clickable={clickable}
        colors={useProgramRunColors(type)}
        labelText={type}
      />
    </StyledStack>
  );
}
