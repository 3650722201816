import { Color } from '@mui/material';

export const success: Partial<Color> = {
  [700]: '#006611',
  [600]: '#008516',
  [500]: '#00AD1D',
  [400]: '#5CD670',
  [300]: '#ADEBB8',
  [200]: '#E3FCE7',
  [100]: '#F1FDF3',
};
