import { VectorHeatMapDatum } from '../types';

export function isString(val: unknown): val is string {
  return typeof val === 'string';
}

export function isFileArray(val: unknown): val is File[] {
  return Array.isArray(val) && val.every(eachVal => eachVal instanceof File);
}

export function isNumber(val: unknown): val is number {
  return typeof val === 'number';
}

export function isVectorHeatMapDatum(val: unknown): val is VectorHeatMapDatum {
  return (
    typeof val === 'object' &&
    !!val &&
    'x' in val &&
    'y' in val &&
    'attribute' in val &&
    'attributeCategory' in val
  );
}
