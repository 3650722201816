import { useMemo } from 'react';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  MemberData,
  CreateOrgData,
  addMembers,
} from '../../services/organizations';
import {
  addMember,
  createOrganization,
  fetchAll,
  fetchOne,
  deleteOrganization,
  removeMember,
  updateMember,
  unDeleteOrganization,
} from '../../services/organizations';
import { useProjects } from '../projects';
import useInvalidation from '../useInvalidation';
import { Role, Project, Organization } from '../../types';
import includes from 'lodash/includes';
import { useInvalidateQuotasUsage } from '../account';

export const KEY = 'organizations';

interface RemoveOrganizationParams {
  email: string;
  params?: { deleteProjectMembership?: boolean };
}

export function useOrganizations(
  options: Omit<UseQueryOptions<Organization[]>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<Organization[]>({
    queryKey: [KEY],
    queryFn: fetchAll,
    ...{
      retry: 1,
      ...options,
    },
  });
}

export function useOrganization(
  oid: string,
  options: Omit<UseQueryOptions<Organization>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<Organization>({
    queryKey: [KEY, oid],
    queryFn: () => fetchOne(oid),
    ...{
      enabled: !!oid,
      ...options,
    },
  });
}

export function useRemoveOrganizationMember(
  oid: string,
  options: UseMutationOptions<unknown, unknown, RemoveOrganizationParams> = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation({
    mutationFn: ({ email, params }: RemoveOrganizationParams) =>
      removeMember(oid, email, params),
    ...{
      onSuccess: () => {
        invalidate();
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

export function useUpdateOrganizationMember(
  oid: string,
  options: UseMutationOptions<unknown, unknown, MemberData> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationFn: (data: MemberData) => updateMember(oid, data),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useAddOrganization(
  options: UseMutationOptions<unknown, unknown, CreateOrgData> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationFn: (data: CreateOrgData) => createOrganization(data),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useDeleteOrganization(
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationFn: (oid: string) => deleteOrganization(oid),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useUndeleteOrganization(
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY);

  return useMutation({
    mutationFn: (oid: string) => unDeleteOrganization(oid),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useAddOrganizationMember(
  oid: string,
  options: UseMutationOptions<unknown, unknown, MemberData> = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation({
    mutationFn: (data: MemberData) => addMember(oid, data),
    ...{
      onSuccess: () => {
        invalidate();
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

export function useAddOrganizationMembers(
  oid: string,
  options: UseMutationOptions<unknown, unknown, MemberData[]> = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation({
    mutationFn: (data: MemberData[]) => addMembers(oid, data),
    ...{
      onSuccess: () => {
        invalidate();
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

/**
 * Returns a list of all the organizations a user is a member
 * of and organizations whose projects they belong to, but are
 * not a member of.
 */

export function useAllOrganizations(
  projectOptions?: Omit<UseQueryOptions<Project[]>, 'queryKey' | 'queryFn'>,
  orgOptions?: Omit<UseQueryOptions<Organization[]>, 'queryKey' | 'queryFn'>,
) {
  const projectsQuery = useProjects(projectOptions);
  const organizationsQuery = useOrganizations(orgOptions);

  const allOrganizations = useMemo(() => {
    const organizations = organizationsQuery.data
      ? projectsQuery.data
        ? projectsQuery.data.reduce(
            (acc, project) => {
              if (!acc.find((org) => org.id === project.organization.id)) {
                acc.push(project.organization);
              }
              return acc;
            },
            [...organizationsQuery.data],
          )
        : organizationsQuery.data
      : [];

    return organizations.sort((a, b) => a.name.localeCompare(b.name));
  }, [organizationsQuery.data, projectsQuery.data]);
  return {
    allOrganizations,
    isLoading: organizationsQuery.isLoading || projectsQuery.isLoading,
    isErrorOrgs: organizationsQuery.isError,
    isErrorProjects: projectsQuery.isError,
    errorProjects: projectsQuery.error,
    errorOrgs: organizationsQuery.error,
  };
}

// list all admins + owners
export function useOrganizationAdmins(oid: string) {
  const { data } = useOrganization(oid);
  return useMemo(
    () =>
      data
        ? data.members
            ?.filter((member) =>
              includes([Role.Admin, Role.Owner], member.role),
            )
            .map((member) => member.user.email)
        : [],
    [data],
  );
}
