import { ANALYTICS_EVENT, useAnalytics } from '@/utils/analytics';
import { signOut } from 'firebase/auth';
import { useAuth } from './useAuth';
import { useInvalidateAll } from '@formbio/api';

export function useLogout(callback?: () => void) {
  const { auth } = useAuth();
  const analytics = useAnalytics();
  const resetAllQueries = useInvalidateAll();

  return () => {
    signOut(auth).then(() => {
      analytics.track(ANALYTICS_EVENT.LOGOUT);
      localStorage.clear();
      resetAllQueries();
      if (callback) {
        callback();
      }
    });
  };
}
