import {
  VaultItem,
  FetchSignedURLParams,
  UpdateFileLabelsParams,
  Label,
} from '../../types';
import { api } from '../../utils/api';

export function updateFileLabels(
  params: FetchSignedURLParams & UpdateFileLabelsParams,
) {
  const { oid, pid, path, labels } = params;
  return api
    .put<VaultItem>(`/orgs/${oid}/${pid}/vault/labels`, {
      path,
      labels,
    })
    .then(({ data }) => data);
}

export function fetchAllOrgLabels(oid: string) {
  return api.get<Label[]>(`/orgs/${oid}/labels`).then(({ data }) => data);
}

export function updateOrgLabels(oid: string, labels: Label[]) {
  return api
    .put<Label[]>(`/orgs/${oid}/labels`, { labels })
    .then(({ data }) => data);
}
