import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  SimplePaletteColorOptions,
} from '@mui/material';
import { styled } from '@formbio/ui/components';
import { AvatarSizes } from '@formbio/ui/components/Avatar/TextAvatar';

export type { SimplePaletteColorOptions };

export type AvatarProps = Omit<MuiAvatarProps, 'classes' | 'ref' | 'sx'> & {
  width?: AvatarSizes;
  height?: AvatarSizes;
};

const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: propName =>
    !['width', 'height'].includes(String(propName)),
})(({ width, height }: AvatarProps) => ({
  width,
  height,
}));

export const Avatar = ({ ...rest }: AvatarProps) => <StyledAvatar {...rest} />;
