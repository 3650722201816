import { fetchAll } from '../../services/configs';
import { IndicationConfigs } from '../../types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

const KEY = 'configs';

export function useConfigs({
  orgId,
  pid,
  options,
}: {
  orgId: string;
  pid: string;
  options?: Omit<UseQueryOptions<IndicationConfigs>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<IndicationConfigs>({
    queryKey: [KEY, orgId, pid],
    queryFn: () => fetchAll(orgId, pid),
    enabled: !!orgId && !!pid,
    ...options,
  });
}
