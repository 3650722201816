export enum ProgramStatusValues {
  IN_PROGRESS = 'In Progress',
  FAILED = 'Failed',
  COMPLETED = 'Completed',
  READY = 'Ready',
}

export type InputProgressStatus = {
  status: ProgramStatusValues;
  completedCount: number;
  totalCount: number;
};
