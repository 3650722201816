import { CheckoutSessionParams } from '../../types/checkout-session';
import { api } from '../../utils/api';

export function checkoutSession({
  vectorId,
  orgId,
  pid,
}: CheckoutSessionParams): Promise<string> {
  return api
    .post(`/orgs/${orgId}/${pid}/checkout-session`, {
      vectorId,
    })
    .then(({ data }) => data.checkoutUrl);
}
