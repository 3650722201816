import React, { useState } from 'react';
import { DotsThreeOutline as DotsThreeOutlineIcon } from '@formbio/ui/components/Icons';
import { IconButton } from '@formbio/ui/components/IconButton';
import { Menu } from '@formbio/ui/components/Menu';

function ActionsMenu({ children }: { children: React.ReactNode }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClose = (event: React.MouseEvent) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DotsThreeOutlineIcon weight='fill' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        {children}
      </Menu>
    </>
  );
}

export default ActionsMenu;
