import { GenomeMetadata, IGVGenome } from '../../types/genome';

import { api } from '../../utils/api';

export function fetchAllMetadata(orgId: string, projectId: string) {
  return api
    .get<GenomeMetadata[]>(`/orgs/${orgId}/${projectId}/genomes/metadata`)
    .then(({ data }) => data);
}

export function fetchAllIGVGenomes(orgId: string, projectId: string) {
  const params = new URLSearchParams([['igv', 'true']]);

  return api
    .get<IGVGenome[]>(`/orgs/${orgId}/${projectId}/genomes/metadata`, {
      params,
    })
    .then(({ data }) => data);
}

export function fetchIGVGenome(
  orgId: string,
  projectId: string,
  genomeId: string,
) {
  const params = new URLSearchParams([['igv', 'true']]);

  return api
    .get<IGVGenome[]>(
      `/orgs/${orgId}/${projectId}/genomes/metadata/${genomeId}`,
      {
        params,
      },
    )
    .then(({ data }) => data);
}
