import dynamic from 'next/dynamic';

const HeatMap = dynamic(
  () => import('@nivo/heatmap').then(nivo => nivo.ResponsiveHeatMap),
  {
    ssr: false,
  },
);

export default HeatMap;
