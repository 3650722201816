import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { start, RShinyStartResponse } from '../../services/rshiny';

export function useRShinySession({
  oid,
  pid,
  runid,
  options = {},
}: {
  oid: string;
  pid: string;
  runid: string;
  options: Omit<
    UseMutationOptions<RShinyStartResponse, unknown, unknown>,
    'mutationFn'
  >;
}) {
  return useMutation<RShinyStartResponse, unknown, unknown>({
    mutationFn: () => start(oid, pid, runid),
    ...options,
  });
}
