import { styled } from '@formbio/ui/components';
import DebouncedSearchBox from '@formbio/ui/components/TextField/DebouncedSearchBox';

const StyledLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  width: '100%',
  flexWrap: 'wrap',
  '.search-wrapper': {
    flex: 1,
  },
  '.search': {
    padding: 0,
    minWidth: '25ch',
  },
}));

export default function SearchLayoutHeader({
  onSearch,
  children,
}: {
  onSearch: (value: string) => void;
  children?: React.ReactNode;
}) {
  function handleChange(value: string) {
    onSearch(value);
  }

  return (
    <StyledLayout>
      <div className='search-wrapper'>
        <DebouncedSearchBox
          onDebouncedSearchCallback={handleChange}
          className='search'
        />
      </div>
      {children}
    </StyledLayout>
  );
}
