import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import useInvalidation from '../../hooks/useInvalidation';
import { passwordResetLink, sendVerificationEmail } from '../../services/users';
import {
  PasswordRequest,
  EmailVerificationRequest,
  EmailVerificationResponse,
} from '../../types/user';

const KEY = 'users';

export function usePasswordReset(
  options?: UseMutationOptions<unknown, unknown, PasswordRequest>,
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<unknown, unknown, PasswordRequest>({
    mutationKey: [KEY],
    mutationFn: (payload: PasswordRequest) => passwordResetLink(payload),
    ...{
      ...options,
      onSuccess: () => invalidate(),
    },
  });
}

export function useVerificationEmail(
  options?: UseMutationOptions<
    EmailVerificationResponse,
    unknown,
    EmailVerificationRequest
  >,
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<
    EmailVerificationResponse,
    unknown,
    EmailVerificationRequest
  >({
    mutationKey: [KEY],
    mutationFn: (payload: EmailVerificationRequest) =>
      sendVerificationEmail(payload),
    ...{
      ...options,
      onSuccess: () => invalidate(),
    },
  });
}
