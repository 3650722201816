import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiChip: CustomComponent<'MuiChip'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 20,
      textTransform: 'capitalize',
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.body2?.fontSize,
      lineHeight: theme.typography.body2?.lineHeight,
      fontWeight: 600,
      border: 0,
      '> .MuiChip-iconSmall': {
        fontSize: 15,
      },
      '> .MuiChip-iconMedium': {
        fontSize: 20,
      },
    }),
    outlined: ({ theme }) => ({
      backgroundColor: theme.palette.primary[200],
      color: theme.palette.primary[900],
      '&.MuiChip-colorSuccess': {
        backgroundColor: theme.palette.success[500],
        color: theme.palette.primary[100],
      },
      '&.MuiChip-colorError': {
        backgroundColor: theme.palette.error[500],
        color: theme.palette.primary[100],
      },
      '&.MuiChip-colorInfo': {
        backgroundColor: theme.palette.info[500],
        color: theme.palette.primary[100],
      },
      '&.MuiChip-colorWarning': {
        backgroundColor: theme.palette.warning[500],
        color: theme.palette.primary[100],
      },
      '> .MuiChip-iconColorDefault': {
        color: theme.palette.primary[900],
      },
      '> .MuiChip-iconColorPrimary': {
        color: theme.palette.primary[900],
      },
      '> .MuiChip-iconColorSecondary': {
        color: theme.palette.primary[900],
      },
      '> .MuiChip-iconColorSuccess': {
        color: theme.palette.primary[100],
      },
      '> .MuiChip-iconColorError': {
        color: theme.palette.primary[100],
      },
      '> .MuiChip-iconColorInfo': {
        color: theme.palette.primary[100],
      },
      '> .MuiChip-iconColorWarning': {
        color: theme.palette.primary[100],
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary[300],
    }),
    outlinedSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.secondary[500],
    }),
    filled: ({ theme }) => ({
      backgroundColor: theme.palette.primary[900],
      color: theme.palette.primary[50],
      '&.MuiChip-colorSuccess': {
        backgroundColor: theme.palette.success[200],
        color: theme.palette.success[700],
      },
      '&.MuiChip-colorError': {
        backgroundColor: theme.palette.error[200],
        color: theme.palette.error[700],
      },
      '&.MuiChip-colorInfo': {
        backgroundColor: theme.palette.info[200],
        color: theme.palette.info[700],
      },
      '&.MuiChip-colorWarning': {
        backgroundColor: theme.palette.warning[200],
        color: theme.palette.warning[700],
      },
      '> .MuiChip-iconColorDefault': {
        color: theme.palette.primary[100],
      },
      '> .MuiChip-iconColorPrimary': {
        color: theme.palette.primary[100],
      },
      '> .MuiChip-iconColorSecondary': {
        color: theme.palette.primary[100],
      },
      '> .MuiChip-iconColorSuccess': {
        color: theme.palette.success[700],
      },
      '> .MuiChip-iconColorError': {
        color: theme.palette.error[700],
      },
      '> .MuiChip-iconColorInfo': {
        color: theme.palette.info[700],
      },
      '> .MuiChip-iconColorWarning': {
        color: theme.palette.warning[700],
      },
    }),
    filledPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary[700],
    }),
    filledSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.green.light,
    }),
    label: ({ theme }) => ({
      padding: theme.spacing(0, 0.5),
    }),
    avatar: {},
    icon: ({ theme }) => ({
      margin: 0,
      color: theme.palette.primary[900],
    }),
  },
};
