import {
  AuthError,
  GoogleAuthProvider,
  signInWithPopup,
  User,
} from 'firebase/auth';
import { useState } from 'react';
import { useAuth } from './useAuth';

export function useSignInWithGoogle() {
  const { auth } = useAuth();
  const [error, setError] = useState<AuthError>();
  const [user, setLoggedInUser] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const request = async () => {
    setIsLoading(true);
    setError(undefined);

    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      setLoggedInUser(userCredential.user);
    } catch (err) {
      setError(err as AuthError);
    } finally {
      setIsLoading(false);
    }
  };

  return { request, loading: isLoading, error, user };
}
