import { styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import { Chip } from '@formbio/ui/components/Chip';
import { ChipColor } from '@formbio/ui/types';
import { ReactElement } from 'react';

export const StyledChip = styled(Chip, {
  shouldForwardProp: propName =>
    propName !== 'colors' && propName !== 'clickable',
})<{ colors: ChipColor; clickable?: boolean }>(
  ({ theme, colors, clickable }) => ({
    backgroundColor: colors.backgroundColor,
    '&.no-transform': {
      textTransform: 'none',
    },
    '&.medium-width': {
      maxWidth: '15ch',
    },
    '& .MuiTypography-root': {
      color: colors.contrastColor,
    },
    '& .MuiChip-icon': {
      color: colors.contrastColor,
      fontSize: '1rem',
    },
    ':hover': clickable
      ? {
          backgroundColor: colors.contrastColor,
          '& .MuiChip-icon': {
            color: 'white',
          },
          '& .MuiTypography-root': {
            color: 'white',
          },
        }
      : {},
    svg: {
      width: theme.spacing(2),
    },
  }),
);

export default function StatusChip({
  clickable = false,
  colors,
  icon,
  labelText,
  className,
  size = 'small',
  onDelete,
}: {
  clickable?: boolean;
  colors: ChipColor;
  icon?: ReactElement;
  labelText?: string;
  className?: string;
  size?: 'small' | 'medium';
  onDelete?: () => void;
}) {
  return (
    <StyledChip
      colors={colors}
      icon={icon}
      size={size}
      label={
        labelText && <Typography variant='caption1'>{labelText}</Typography>
      }
      clickable={clickable}
      className={className}
      onDelete={onDelete}
    />
  );
}
