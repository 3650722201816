import {
  fetchAllAdmin,
  fetchOneAdmin,
  updateApprovalStatus,
} from '../../../services/admin/isa-vectors';
import {
  useMutation,
  useQuery,
  UseMutationOptions,
} from '@tanstack/react-query';
import { AdminISAVector, ISAVector } from '../../../types';
import useInvalidation from '../../../hooks/useInvalidation';

const KEY = 'isa-vectors';

export function useIsaVectorsAdmin() {
  const isaVectorsQuery = useQuery({
    queryKey: [KEY],
    queryFn: fetchAllAdmin,
  });
  const allVectors: ISAVector[] | undefined = isaVectorsQuery.data;

  return {
    allVectors,
    isLoading: isaVectorsQuery.isLoading,
    isError: isaVectorsQuery.isError,
  };
}

export function useIsaVectorAdmin({ vectorId }: { vectorId: string }) {
  const isaVectorQuery = useQuery({
    queryKey: [KEY, vectorId],
    queryFn: () => fetchOneAdmin({ vectorId }),
  });
  const vector: AdminISAVector | undefined = isaVectorQuery.data;

  return {
    vector,
    isLoading: isaVectorQuery.isLoading,
    isError: isaVectorQuery.isError,
  };
}

export function useIsaVectorApprovalStatusUpdate(
  options?: UseMutationOptions<
    unknown,
    unknown,
    { approvalStatus: string; approvalNotes: string; orderId: string }
  >,
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<
    unknown,
    unknown,
    {
      approvalStatus: string;
      approvalNotes: string;
      orderId: string;
      vectorId: string;
    }
  >({
    mutationFn: ({
      approvalStatus,
      approvalNotes,
      orderId,
      vectorId,
    }: {
      approvalStatus: string;
      approvalNotes: string;
      orderId: string;
      vectorId: string;
    }) =>
      updateApprovalStatus({
        approvalStatus,
        approvalNotes,
        orderId,
        vectorId,
      }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
