import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiSwitch: CustomComponent<'MuiSwitch'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: theme.spacing(5.5),
      height: theme.spacing(3),
      padding: 0,
      margin: theme.spacing(0, 2, 0, 1.5),
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: theme.spacing(0.5),
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(20px)',
          color: theme.palette.primary[50],
          '& + .MuiSwitch-track': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primary[900]
                : theme.palette.primary[800],
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: theme.palette.primary[900],
          border: `6px solid ${theme.palette.primary[50]}`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
      '& .MuiSwitch-track': {
        borderRadius: theme.spacing(1.5),
        backgroundColor:
          theme.palette.mode === 'light'
            ? theme.palette.primary[400]
            : theme.palette.primary[500],
        opacity: 1,
      },
    }),
  },
};
