import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiTableHead: CustomComponent<'MuiTableHead'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiTableCell-root .MuiTypography-root': {
        color: theme.palette.primary[900],
      },
    }),
  },
};
