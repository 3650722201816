import {
  ContainerAppInstance,
  ContainerAppInstanceCreate,
  ContainerAppInstanceCrud,
  ContainerAppInstancePatch,
} from '../../types';
import { api } from '../../utils/api';

export function fetchAll({ oid, pid }: { oid: string; pid: string }) {
  return api
    .get<ContainerAppInstance[]>(`/orgs/${oid}/${pid}/app-instances`)
    .then(({ data }) => data);
}

export function fetchOne({
  oid,
  pid,
  id,
}: {
  oid: string;
  pid: string;
  id: string;
}) {
  return api
    .get<ContainerAppInstance>(`/orgs/${oid}/${pid}/app-instances/${id}`)
    .then(({ data }) => data);
}

export function start({ oid, pid, config }: ContainerAppInstanceCreate) {
  return api
    .post<ContainerAppInstance>(`/orgs/${oid}/${pid}/app-instances`, config)
    .then(({ data }) => data);
}

export function stop({ oid, pid, id }: ContainerAppInstanceCrud) {
  return api.delete(`/orgs/${oid}/${pid}/app-instances/${id}`);
}

export function patch({ oid, pid, id, ...fields }: ContainerAppInstancePatch) {
  return api
    .patch<ContainerAppInstance>(`/orgs/${oid}/${pid}/app-instances/${id}`, {
      ...fields,
    })
    .then(({ data }) => data);
}
