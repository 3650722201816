import type { Organization } from '../../../types/organization';
import { admin_api } from '../../../utils/admin-api';

export async function fetchAllAdmin() {
  const { data } = await admin_api.get<Organization[]>('/organizations');
  return data;
}

export async function fetchOneAdmin(oid: string) {
  const { data } = await admin_api.get<Organization>(`/organizations/${oid}`);
  return data;
}

export function patchOrgAdmin(
  oid: string,
  { name, billingId }: { name?: string; billingId?: string },
) {
  return admin_api.patch(`/organizations/${oid}`, { name, billingId });
}
