import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CaretDownFilledBackgroundIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='22' height='22' viewBox='1 1 22 22' {...props}>
      <rect x='1' y='1' width='22' height='22' fill='#F0EFF1' />
      <path
        d='M17.4625 9.80625C17.4243 9.71525 17.3599 9.63761 17.2776 9.58311C17.1953 9.52861 17.0987 9.49969 17 9.5H7.00001C6.90129 9.49969 6.8047 9.52861 6.72239 9.58311C6.64009 9.63761 6.57576 9.71525 6.53751 9.80625C6.50148 9.89861 6.49235 9.99927 6.51115 10.0966C6.52995 10.1939 6.57593 10.284 6.64376 10.3563L11.6438 15.3563C11.7393 15.4487 11.8671 15.5003 12 15.5003C12.1329 15.5003 12.2607 15.4487 12.3563 15.3563L17.3563 10.3563C17.4241 10.284 17.4701 10.1939 17.4889 10.0966C17.5077 9.99927 17.4985 9.89861 17.4625 9.80625V9.80625Z'
        fill='#6F6E77'
      />
    </SvgIcon>
  );
}
