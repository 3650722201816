export interface CheckoutSessionParams {
  vectorId: string;
  clientId?: string;
  orgId: string;
  pid: string;
}

export enum StripeStatus {
  SUCCESS = 'SUCCESS',
  CANCEL = 'CANCEL',
}
