import { forwardRef } from 'react';
import { styled } from '@formbio/ui/components';
import { Button } from '@formbio/ui/components/Button';
import { UrlObject } from 'url';
import { NextMuiLink } from '@formbio/ui';
import {
  CaretDoubleLeft as CaretDoubleLeftIcon,
  CaretLeft as CaretLeftIcon,
} from '@formbio/ui/components/Icons';

const StyledBackButton = styled(Button)(({ theme }) => ({
  '&, &:hover': {
    borderColor: theme.typography.body1?.color,
  },
  color: theme.typography.body1?.color,
  height: theme.spacing(5),
  width: theme.spacing(5),
  padding: 0,
  minWidth: theme.spacing(6),
  svg: {
    width: theme.spacing(2),
  },
}));

const BackButton = forwardRef<
  HTMLButtonElement,
  { icon?: React.ReactNode; className?: string; onClick?: () => void }
>(({ icon, className, onClick }, ref) => (
  <StyledBackButton
    variant='outlined'
    size='small'
    ref={ref}
    className={className}
    onClick={onClick}
    data-testid='back-button'
  >
    {icon ? icon : <CaretLeftIcon />}
  </StyledBackButton>
));
BackButton.displayName = 'BackButton';

const DoubleBackButton = forwardRef<
  HTMLButtonElement,
  { icon?: React.ReactNode; className?: string; onClick?: () => void }
>(({ icon, className, onClick }, ref) => (
  <StyledBackButton
    variant='outlined'
    size='small'
    ref={ref}
    className={className}
    onClick={onClick}
  >
    {icon ? icon : <CaretDoubleLeftIcon />}
  </StyledBackButton>
));
DoubleBackButton.displayName = 'DoubleBackButton';

function BackButtonLink({
  href,
  icon,
  className,
}: {
  href: UrlObject;
  icon?: React.ReactNode;
  className?: string;
}) {
  return (
    // since button already gets a tab index this prevents an extra tab
    <NextMuiLink nextHref={href} tabIndex={-1}>
      <BackButton icon={icon} className={className} />
    </NextMuiLink>
  );
}

export { BackButton, BackButtonLink, DoubleBackButton };
