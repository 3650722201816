export const DATACORE_ENTITY_SAMPLE = 'datacore.formbio.ngs-sample';
export const DATACORE_ENTITY_SAMPLE_SET = 'datacore.formbio.ngs-sample-set';

export interface DatacoreRelationships {
  data: {
    type: typeof DATACORE_ENTITY_DATA_TYPE;
    id: string;
  };
}
export interface DatacoreRelationshipArray {
  data: {
    type: typeof DATACORE_ENTITY_TYPE;
    id: string;
  }[];
}

export interface DatacoreLinks {
  self: string;
}

export interface DatacoreData {
  type: string;
  id: string;
  attributes: Record<string, unknown>;
  relationships?: {
    [DATACORE_DATA_TYPE]?: DatacoreRelationships;
    childEntities?: DatacoreRelationshipArray;
    [DATACORE_REF_ENTITIES_TYPE]?: DatacoreRelationshipArray;
  };
  links?: DatacoreLinks;
}

export interface DatacoreResponse {
  data: DatacoreData | DatacoreData[];
  included?: DatacoreData[];
}

// TODO WIP
export interface DatacorePayload {
  data: {
    attributes: Record<string, unknown>;
  };
}

export interface DatacoreRelationshipDataPayload {
  data: {
    type: 'entityData';
    attributes: {
      data: Record<string, unknown>;
      typeSchemaVersionName: string;
    };
  };
}

export interface DatacoreRelationshipTypesPayload {
  data: {
    type: 'types';
    attributes: {
      address: string;
      createdAt: string;
      description: string;
      name: string;
      updatedAt: string;
    };
  }[];
}

// TODO continue improving this type
// to include more options
export interface DatacoreResponseWithDataObject {
  data: DatacoreData;
  included?: DatacoreData[];
}

export interface DatacoreResponseWithDataArray {
  data: DatacoreData[];
  included?: DatacoreData[];
}
export interface DatacoreParams {
  include?: Array<
    | 'data'
    | 'childEntities'
    | 'childEntities.data'
    | 'schema'
    | 'referencedEntities'
    | 'parentEntity'
    | 'parentEntity.parentEntity'
  >;
}

/**
 * Editable fields of an entity
 */
export interface DatacoreEntity {
  id: string;
  name: string;
}

export const DATACORE_ENTITY_TYPE = 'entities';
export const DATACORE_ENTITY_DATA_TYPE = 'entityData';
export const DATACORE_REF_ENTITIES_TYPE = 'referencedEntities';
export const DATACORE_DATA_TYPE = 'data';
export const DATACORE_VADR_TYPE = 'vadr';
export const DATACORE_VECTOR_TYPE = 'datacore.formbio.vector';
export const DATACORE_VECTOR_RISK_TYPE = 'datacore.formbio.vector-risk';
export const DATACORE_INDICATION_TYPE = 'datacore.formbio.indication';
export const DATACORE_GENE_TYPE = 'datacore.formbio.gene-of-interest';
export const DATACORE_VADR_VALUE_TYPE = 'datacore.formbio.vadr-value';

export enum CUSTOM_ENTITY_TYPES {
  VECTOR = 'vector',
  SAMPLE = 'NGS sample',
  SAMPLE_SET = 'NGS sample set',
}

export const ADVANCED_ENTITY_TYPES = [
  CUSTOM_ENTITY_TYPES.VECTOR,
  CUSTOM_ENTITY_TYPES.SAMPLE,
  CUSTOM_ENTITY_TYPES.SAMPLE_SET,
];
