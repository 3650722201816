import { WorkflowRun } from '../../types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  fetchAllByNamespace,
  fetchOneByNamespace,
} from '../../services/project-bucket-data';

const KEY = 'program-runs';
const NAMESPACE = 'program_runs';

export function useProgramRuns({
  oid,
  pid,
  indicationId,
  options = {},
}: {
  oid: string;
  pid: string;
  indicationId: string;
  options?: Omit<UseQueryOptions<WorkflowRun[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<WorkflowRun[]>({
    queryKey: [KEY, { oid, pid, indicationId }],
    queryFn: async () => {
      const runs = await fetchAllByNamespace<WorkflowRun>({
        oid,
        pid,
        namespace: NAMESPACE,
      });
      return runs.filter((run) => run.workflowVersionID === indicationId);
    },
    ...{
      enabled: !!oid && !!pid && !!indicationId,
      ...options,
    },
  });
}

export function useProgramRun({
  oid,
  pid,
  programRunId,
  options = {},
}: {
  oid: string;
  pid: string;
  programRunId: string;
  options?: Omit<UseQueryOptions<WorkflowRun>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<WorkflowRun>({
    queryKey: [KEY, { oid, pid, programRunId }],
    queryFn: () => {
      return fetchOneByNamespace({
        oid,
        pid,
        documentId: programRunId,
        namespace: NAMESPACE,
      });
    },
    ...{
      enabled: !!oid && !!pid && !!programRunId,
      ...options,
    },
  });
}
