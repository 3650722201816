import StatusChip from '@formbio/ui/components/Chip/StatusChip';
import { ProgramCheckpointId } from '@formbio/api';
import CheckpointIcon from '../../Icons/CheckpointIcon';
import { styled } from '@mui/material';

export const StyledStatusChip = styled(StatusChip)<{
  colors: { backgroundColor: string; contrastColor: string };
}>(({ colors }) => ({
  border: `1px solid ${colors?.contrastColor}`,
  // chip border radius
  borderRadius: '15px',
}));

export default function CheckpointStatusChip({
  backgroundColor,
  contrastColor,
  checkpointId,
  noText,
  onDelete,
}: {
  backgroundColor: string;
  contrastColor: string;
  checkpointId: ProgramCheckpointId;
  noText?: boolean;
  onDelete?: () => void;
}) {
  return (
    <StyledStatusChip
      icon={
        <CheckpointIcon checkpointId={checkpointId} color={contrastColor} />
      }
      colors={{
        backgroundColor,
        contrastColor,
      }}
      labelText={noText ? undefined : checkpointId}
      onDelete={onDelete}
    />
  );
}
