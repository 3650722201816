import { http, HttpResponse } from 'msw';

const verify = http.post('*/api/v1/user/verify', () =>
  HttpResponse.json(
    {
      errorMessage: `Too many requests, please try again later.`,
    },
    { status: 429 },
  ),
);

export const authHandlers = [verify];
