export const proQuotas = {
  accountId: 'b4f303e7-3cdf-4cf8-8c63-0502b377e19c',
  tier: {
    name: 'pro',
    displayName: '',
    description: 'Pro Plan',
  },
  features: [
    {
      id: 'ee5fc69e-8034-4a5c-9dbf-b465f117769b',
      name: 'cloud_native',
      displayName: 'Cloud native, fully managed solution',
      description: 'Cloud native, fully managed solution',
      quota: 1,
    },
    {
      id: '7c672c8c-dafe-4276-869d-961cc031c8de',
      name: 'additional_user_seats',
      displayName: 'Additional Seats',
      description: 'Additional seats purchased',
      quota: 0,
    },
    {
      id: 'a80fa4e8-dc38-4f54-b866-d6033fe793eb',
      name: 'organization_management',
      displayName: 'Organization Management',
      description: 'Organization management',
      quota: 1,
    },
    {
      id: 'f082c69b-a9a2-4f37-ad6a-abe5d3765e49',
      name: 'organization_number',
      displayName: 'Organization Number',
      description: 'Organization number account can create',
      quota: 1,
    },
    {
      id: '20a3b2c5-611d-4ecb-94ff-0b1d3122d022',
      name: 'data_management',
      displayName: 'Data Management',
      description: 'Data management',
      quota: 1,
    },
    {
      id: '606fd0db-2f09-4dc9-b9b9-ba0783e09b68',
      name: 'data_transfer',
      displayName: 'Data Transfer',
      description: 'Data transfer',
      quota: 1,
    },
    {
      id: '13502473-749b-4aa5-8671-bfe597e17a51',
      name: 'genome_repo',
      displayName: 'Genome Repository',
      description: 'Genome Repository',
      quota: 1,
    },
    {
      id: '466966dc-685b-452c-b7f3-6181698a1469',
      name: 'basic_workflows',
      displayName: 'Basic Workflows',
      description: 'Run basic workflows',
      quota: 1,
    },
    {
      id: '61f7db9c-0721-4fcc-8947-c101487d55f5',
      name: 'nfcore_workflows',
      displayName: 'NFcore Workflows',
      description: 'Run NF-core workflow',
      quota: 0,
    },
    {
      id: 'bc68b93d-b596-433e-83e4-4fbe3d4f2ccb',
      name: 'developer_tools',
      displayName: 'One-step Analysis Tools',
      description: 'One-step Analysis Tools',
      quota: 1,
    },
    {
      id: 'f3fc7a6a-7b7a-4ec5-ad4d-779dc82dda41',
      name: 'notes',
      displayName: 'Collaborative Notes',
      description: 'Collaborative Notes',
      quota: 1,
    },
    {
      id: '45626211-0962-44bf-b586-152beef55617',
      name: 'batch_ext',
      displayName: 'Batch Execution',
      description: 'Batch Execution',
      quota: 0,
    },
    {
      id: '0d9950f5-d628-439d-b079-cdfa8774a6c1',
      name: 'basic_formbench',
      displayName: 'Data Analysis and Visualization',
      description: 'Jupyter notebook, Rstudio IDE and other visualizations',
      quota: 1,
    },
    {
      id: 'ca6ee1b6-f1b9-4091-a359-87f320a6c9c5',
      name: 'form_shell',
      displayName: 'Web Cloud Shell',
      description: 'Web Cloud Shell',
      quota: 1,
    },
    {
      id: '9830e45b-ce5f-46e5-9478-e44f4f902e40',
      name: 'basic_compute',
      displayName: 'Basic Compute',
      description: 'Limited choices for computing resources',
      quota: 1,
    },
    {
      id: 'c90e5a06-747f-4eab-b07e-5ec79f8eabd3',
      name: 'data_backup_retention',
      displayName: 'Data Backup and Retention',
      description: 'Data Backup and Retention',
      quota: 0,
    },
    {
      id: '589bdcee-aee1-433f-b115-9495576e4048',
      name: 'support',
      displayName: 'Customer Support',
      description: 'Customer support',
      quota: 1,
    },
    {
      id: 'c78ffd31-7f7d-4a7b-8b2e-5fd9c2339700',
      name: 'included_user_seats',
      displayName: 'Included User Seats',
      description: 'Included User Seats',
      quota: 15,
    },
    {
      id: '9cc58417-cbc4-493d-932a-902c81a61b49',
      name: 'max_additional_user_seat_count',
      displayName: 'Maximum Additional User Seat',
      description: 'Maximum user seat account can add',
      quota: 19,
    },
    {
      id: 'dcfadaab-e485-44e6-ad6f-3c91887d790a',
      name: 'additional_user_seat_price',
      displayName: 'Additional Seat Price',
      description: 'Unit price for additional user seat in US dollars',
      quota: 500,
    },
    {
      id: '9aca5e50-cc2c-4712-9724-29431c879266',
      name: 'project_number',
      displayName: 'Project Number',
      description: 'Project number account can create',
      quota: 5,
    },
    {
      id: 'dd99fb54-c8a4-4e56-81bc-25106cb31e85',
      name: 'price',
      displayName: 'Price',
      description: 'Price',
      quota: 7999,
    },
    {
      id: '57cd967d-6f00-4d0d-921c-a335fc8ef9e1',
      name: 'usage_quota',
      displayName: 'Usage Quota',
      description: 'Monthly organization quota',
      quota: 10000,
    },
    {
      id: '04396415-9f45-465c-9893-1a0e7ebe473a',
      name: 'workflow_management',
      displayName: 'Workflow Management',
      description: 'Workflow management',
      quota: 1,
    },
    {
      id: 'd45e11d1-b51d-4b9c-9313-d692b2001990',
      name: 'standard_workflows',
      displayName: 'Standard Workflows',
      description: 'Run standard workflow',
      quota: 1,
    },
    {
      id: '20e457b0-cc64-4a62-b99e-b2d03d2b4026',
      name: 'community_workflows',
      displayName: 'Community Workflows',
      description: 'Run community workflow',
      quota: 1,
    },
    {
      id: 'c48d3351-e7a6-4c67-8e69-32851cc817ab',
      name: 'custom_workflows',
      displayName: 'Custom Workflows',
      description: 'Run custom workflow',
      quota: 1,
    },
    {
      id: 'c48d3351-e7a6-4c67-8e69-32851cc817ab',
      name: 'user_workflows',
      displayName: 'User Workflows',
      description: 'Run user workflow',
      quota: 1,
    },
    {
      id: 'bb9b3cb3-ba58-42fe-b006-01d4677d7c37',
      name: 'custom_formbench',
      displayName: 'Custom Apps',
      description: 'Custom Apps',
      quota: 1,
    },
    {
      id: '07eb18ba-a944-4a96-bcca-5c622261a79a',
      name: 'premium_compute',
      displayName: 'Premium Compute',
      description: 'Full choices for computing resources',
      quota: 1,
    },
    {
      id: '36cf9644-51e2-491b-aa06-cad1a9ff0c15',
      name: 'gpu',
      displayName: 'GPU',
      description: 'GPU support',
      quota: 1,
    },
    {
      id: '7df45654-eb88-488f-b09d-bded6081f52f',
      name: 'onboarding',
      displayName: 'Customer Onboarding',
      description: 'Customer onboarding',
      quota: 2,
    },
    {
      id: '7df45654-eb88-488f-b09d-bded6081f52g',
      name: 'data_provenance',
      displayName: 'Data Provenance',
      description: 'Data Provenance',
      quota: 1,
    },
  ],
};

export const teamQuotas = {
  accountId: 'b4f303e7-3cdf-4cf8-8c63-0502b377e19c',
  tier: {
    name: 'team',
    displayName: '',
    description: 'Team Plan',
  },
  features: [
    {
      id: 'ee5fc69e-8034-4a5c-9dbf-b465f117769b',
      name: 'cloud_native',
      displayName: 'Cloud native, fully managed solution',
      description: 'Cloud native, fully managed solution',
      quota: 1,
    },
    {
      id: '7c672c8c-dafe-4276-869d-961cc031c8de',
      name: 'additional_user_seats',
      displayName: 'Additional Seats',
      description: 'Additional seats purchased',
      quota: 0,
    },
    {
      id: 'a80fa4e8-dc38-4f54-b866-d6033fe793eb',
      name: 'organization_management',
      displayName: 'Organization Management',
      description: 'Organization management',
      quota: 1,
    },
    {
      id: 'f082c69b-a9a2-4f37-ad6a-abe5d3765e49',
      name: 'organization_number',
      displayName: 'Organization Number',
      description: 'Organization number account can create',
      quota: 1,
    },
    {
      id: '20a3b2c5-611d-4ecb-94ff-0b1d3122d022',
      name: 'data_management',
      displayName: 'Data Management',
      description: 'Data management',
      quota: 1,
    },
    {
      id: '606fd0db-2f09-4dc9-b9b9-ba0783e09b68',
      name: 'data_transfer',
      displayName: 'Data Transfer',
      description: 'Data transfer',
      quota: 1,
    },
    {
      id: '13502473-749b-4aa5-8671-bfe597e17a51',
      name: 'genome_repo',
      displayName: 'Genome Repository',
      description: 'Genome Repository',
      quota: 1,
    },
    {
      id: '466966dc-685b-452c-b7f3-6181698a1469',
      name: 'basic_workflows',
      displayName: 'Basic Workflows',
      description: 'Run basic workflows',
      quota: 1,
    },
    {
      id: '61f7db9c-0721-4fcc-8947-c101487d55f5',
      name: 'nfcore_workflows',
      displayName: 'NFcore Workflows',
      description: 'Run NF-core workflow',
      quota: 0,
    },
    {
      id: 'bc68b93d-b596-433e-83e4-4fbe3d4f2ccb',
      name: 'developer_tools',
      displayName: 'One-step Analysis Tools',
      description: 'One-step Analysis Tools',
      quota: 1,
    },
    {
      id: 'f3fc7a6a-7b7a-4ec5-ad4d-779dc82dda41',
      name: 'notes',
      displayName: 'Collaborative Notes',
      description: 'Collaborative Notes',
      quota: 1,
    },
    {
      id: '45626211-0962-44bf-b586-152beef55617',
      name: 'batch_ext',
      displayName: 'Batch Execution',
      description: 'Batch Execution',
      quota: 0,
    },
    {
      id: '0d9950f5-d628-439d-b079-cdfa8774a6c1',
      name: 'basic_formbench',
      displayName: 'Data Analysis and Visualization',
      description: 'Jupyter notebook, Rstudio IDE and other visualizations',
      quota: 1,
    },
    {
      id: 'ca6ee1b6-f1b9-4091-a359-87f320a6c9c5',
      name: 'form_shell',
      displayName: 'Web Cloud Shell',
      description: 'Web Cloud Shell',
      quota: 1,
    },
    {
      id: '9830e45b-ce5f-46e5-9478-e44f4f902e40',
      name: 'basic_compute',
      displayName: 'Basic Compute',
      description: 'Limited choices for computing resources',
      quota: 1,
    },
    {
      id: 'c90e5a06-747f-4eab-b07e-5ec79f8eabd3',
      name: 'data_backup_retention',
      displayName: 'Data Backup and Retention',
      description: 'Data Backup and Retention',
      quota: 0,
    },
    {
      id: '589bdcee-aee1-433f-b115-9495576e4048',
      name: 'support',
      displayName: 'Customer Support',
      description: 'Customer support',
      quota: 1,
    },
    {
      id: 'c78ffd31-7f7d-4a7b-8b2e-5fd9c2339700',
      name: 'included_user_seats',
      displayName: 'Included User Seats',
      description: 'Included User Seats',
      quota: 5,
    },
    {
      id: '9cc58417-cbc4-493d-932a-902c81a61b49',
      name: 'max_additional_user_seat_count',
      displayName: 'Maximum Additional User Seat',
      description: 'Maximum user seat account can add',
      quota: 9,
    },
    {
      id: 'dcfadaab-e485-44e6-ad6f-3c91887d790a',
      name: 'additional_user_seat_price',
      displayName: 'Additional Seat Price',
      description: 'Unit price for additional user seat in US dollars',
      quota: 200,
    },
    {
      id: '9aca5e50-cc2c-4712-9724-29431c879266',
      name: 'project_number',
      displayName: 'Project Number',
      description: 'Project number account can create',
      quota: 3,
    },
    {
      id: 'dd99fb54-c8a4-4e56-81bc-25106cb31e85',
      name: 'price',
      displayName: 'Price',
      description: 'Price',
      quota: 999,
    },
    {
      id: '57cd967d-6f00-4d0d-921c-a335fc8ef9e1',
      name: 'usage_quota',
      displayName: 'Usage Quota',
      description: 'Monthly organization quota',
      quota: 1000,
    },
    {
      id: '04396415-9f45-465c-9893-1a0e7ebe473a',
      name: 'workflow_management',
      displayName: 'Workflow Management',
      description: 'Workflow management',
      quota: 1,
    },
    {
      id: 'd45e11d1-b51d-4b9c-9313-d692b2001990',
      name: 'standard_workflows',
      displayName: 'Standard Workflows',
      description: 'Run standard workflow',
      quota: 1,
    },
    {
      id: '20e457b0-cc64-4a62-b99e-b2d03d2b4026',
      name: 'community_workflows',
      displayName: 'Community Workflows',
      description: 'Run community workflow',
      quota: 1,
    },
    {
      id: 'c48d3351-e7a6-4c67-8e69-32851cc817ab',
      name: 'custom_workflows',
      displayName: 'Custom Workflows',
      description: 'Run custom workflow',
      quota: 0,
    },
    {
      id: 'c48d3351-e7a6-4c67-8e69-32851cc817ab',
      name: 'user_workflows',
      displayName: 'User Workflows',
      description: 'Run user workflow',
      quota: 0,
    },
    {
      id: 'bb9b3cb3-ba58-42fe-b006-01d4677d7c37',
      name: 'custom_formbench',
      displayName: 'Custom Apps',
      description: 'Custom Apps',
      quota: 0,
    },
    {
      id: '07eb18ba-a944-4a96-bcca-5c622261a79a',
      name: 'premium_compute',
      displayName: 'Premium Compute',
      description: 'Full choices for computing resources',
      quota: 0,
    },
    {
      id: '36cf9644-51e2-491b-aa06-cad1a9ff0c15',
      name: 'gpu',
      displayName: 'GPU',
      description: 'GPU support',
      quota: 0,
    },
    {
      id: '7df45654-eb88-488f-b09d-bded6081f52f',
      name: 'onboarding',
      displayName: 'Customer Onboarding',
      description: 'Customer onboarding',
      quota: 0,
    },
    {
      id: '7df45654-eb88-488f-b09d-bded6081f52g',
      name: 'data_provenance',
      displayName: 'Data Provenance',
      description: 'Data Provenance',
      quota: 1,
    },
  ],
};

export const individualQuotas = {
  accountId: 'b4f303e7-3cdf-4cf8-8c63-0502b377e19c',
  tier: {
    name: 'individual',
    displayName: '',
    description: 'Individual Plan',
  },
  features: [
    {
      id: 'ee5fc69e-8034-4a5c-9dbf-b465f117769b',
      name: 'cloud_native',
      displayName: 'Cloud native, fully managed solution',
      description: 'Cloud native, fully managed solution',
      quota: 1,
    },
    {
      id: '7c672c8c-dafe-4276-869d-961cc031c8de',
      name: 'additional_user_seats',
      displayName: 'Additional Seats',
      description: 'Additional seats purchased',
      quota: 0,
    },
    {
      id: 'a80fa4e8-dc38-4f54-b866-d6033fe793eb',
      name: 'organization_management',
      displayName: 'Organization Management',
      description: 'Organization management',
      quota: 1,
    },
    {
      id: 'f082c69b-a9a2-4f37-ad6a-abe5d3765e49',
      name: 'organization_number',
      displayName: 'Organization Number',
      description: 'Organization number account can create',
      quota: 1,
    },
    {
      id: '20a3b2c5-611d-4ecb-94ff-0b1d3122d022',
      name: 'data_management',
      displayName: 'Data Management',
      description: 'Data management',
      quota: 1,
    },
    {
      id: '606fd0db-2f09-4dc9-b9b9-ba0783e09b68',
      name: 'data_transfer',
      displayName: 'Data Transfer',
      description: 'Data transfer',
      quota: 1,
    },
    {
      id: '13502473-749b-4aa5-8671-bfe597e17a51',
      name: 'genome_repo',
      displayName: 'Genome Repository',
      description: 'Genome Repository',
      quota: 1,
    },
    {
      id: '466966dc-685b-452c-b7f3-6181698a1469',
      name: 'basic_workflows',
      displayName: 'Basic Workflows',
      description: 'Run basic workflows',
      quota: 1,
    },
    {
      id: '61f7db9c-0721-4fcc-8947-c101487d55f5',
      name: 'nfcore_workflows',
      displayName: 'NFcore Workflows',
      description: 'Run NF-core workflow',
      quota: 1,
    },
    {
      id: 'bc68b93d-b596-433e-83e4-4fbe3d4f2ccb',
      name: 'developer_tools',
      displayName: 'One-step Analysis Tools',
      description: 'One-step Analysis Tools',
      quota: 1,
    },
    {
      id: 'f3fc7a6a-7b7a-4ec5-ad4d-779dc82dda41',
      name: 'notes',
      displayName: 'Collaborative Notes',
      description: 'Collaborative Notes',
      quota: 1,
    },
    {
      id: '45626211-0962-44bf-b586-152beef55617',
      name: 'batch_ext',
      displayName: 'Batch Execution',
      description: 'Batch Execution',
      quota: 0,
    },
    {
      id: '0d9950f5-d628-439d-b079-cdfa8774a6c1',
      name: 'basic_formbench',
      displayName: 'Data Analysis and Visualization',
      description: 'Jupyter notebook, Rstudio IDE and other visualizations',
      quota: 1,
    },
    {
      id: 'ca6ee1b6-f1b9-4091-a359-87f320a6c9c5',
      name: 'form_shell',
      displayName: 'Web Cloud Shell',
      description: 'Web Cloud Shell',
      quota: 0,
    },
    {
      id: '9830e45b-ce5f-46e5-9478-e44f4f902e40',
      name: 'basic_compute',
      displayName: 'Basic Compute',
      description: 'Limited choices for computing resources',
      quota: 1,
    },
    {
      id: 'c90e5a06-747f-4eab-b07e-5ec79f8eabd3',
      name: 'data_backup_retention',
      displayName: 'Data Backup and Retention',
      description: 'Data Backup and Retention',
      quota: 0,
    },
    {
      id: '589bdcee-aee1-433f-b115-9495576e4048',
      name: 'support',
      displayName: 'Customer Support',
      description: 'Customer support',
      quota: 1,
    },
    {
      id: 'c78ffd31-7f7d-4a7b-8b2e-5fd9c2339700',
      name: 'included_user_seats',
      displayName: 'Included User Seats',
      description: 'Included User Seats',
      quota: 1,
    },
    {
      id: '9cc58417-cbc4-493d-932a-902c81a61b49',
      name: 'max_additional_user_seat_count',
      displayName: 'Maximum Additional User Seat',
      description: 'Maximum user seat account can add',
      quota: 0,
    },
    {
      id: 'dcfadaab-e485-44e6-ad6f-3c91887d790a',
      name: 'additional_user_seat_price',
      displayName: 'Additional Seat Price',
      description: 'Unit price for additional user seat in US dollars',
      quota: 0,
    },
    {
      id: '9aca5e50-cc2c-4712-9724-29431c879266',
      name: 'project_number',
      displayName: 'Project Number',
      description: 'Project number account can create',
      quota: 1,
    },
    {
      id: 'dd99fb54-c8a4-4e56-81bc-25106cb31e85',
      name: 'price',
      displayName: 'Price',
      description: 'Price',
      quota: 0,
    },
    {
      id: '57cd967d-6f00-4d0d-921c-a335fc8ef9e1',
      name: 'usage_quota',
      displayName: 'Usage Quota',
      description: 'Monthly organization quota',
      quota: 100,
    },
    {
      id: '04396415-9f45-465c-9893-1a0e7ebe473a',
      name: 'workflow_management',
      displayName: 'Workflow Management',
      description: 'Workflow management',
      quota: 1,
    },
    {
      id: 'd45e11d1-b51d-4b9c-9313-d692b2001990',
      name: 'standard_workflows',
      displayName: 'Standard Workflows',
      description: 'Run standard workflow',
      quota: 0,
    },
    {
      id: '20e457b0-cc64-4a62-b99e-b2d03d2b4026',
      name: 'community_workflows',
      displayName: 'Community Workflows',
      description: 'Run community workflow',
      quota: 1,
    },
    {
      id: 'c48d3351-e7a6-4c67-8e69-32851cc817ab',
      name: 'custom_workflows',
      displayName: 'Custom Workflows',
      description: 'Run custom workflow',
      quota: 0,
    },
    {
      id: 'c48d3351-e7a6-4c67-8e69-32851cc817ab',
      name: 'user_workflows',
      displayName: 'User Workflows',
      description: 'Run user workflow',
      quota: 0,
    },
    {
      id: 'bb9b3cb3-ba58-42fe-b006-01d4677d7c37',
      name: 'custom_formbench',
      displayName: 'Custom Apps',
      description: 'Custom Apps',
      quota: 0,
    },
    {
      id: '07eb18ba-a944-4a96-bcca-5c622261a79a',
      name: 'premium_compute',
      displayName: 'Premium Compute',
      description: 'Full choices for computing resources',
      quota: 0,
    },
    {
      id: '36cf9644-51e2-491b-aa06-cad1a9ff0c15',
      name: 'gpu',
      displayName: 'GPU',
      description: 'GPU support',
      quota: 0,
    },
    {
      id: '7df45654-eb88-488f-b09d-bded6081f52f',
      name: 'onboarding',
      displayName: 'Customer Onboarding',
      description: 'Customer onboarding',
      quota: 0,
    },
    {
      id: '7df45654-eb88-488f-b09d-bded6081f52g',
      name: 'data_provenance',
      displayName: 'Data Provenance',
      description: 'Data Provenance',
      quota: 0,
    },
  ],
};
