import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  updateFileLabels,
  fetchAllOrgLabels,
  updateOrgLabels,
} from '../../services/labels';
import {
  FetchSignedURLParams,
  Label,
  UpdateFileLabelsParams,
  UpdateOrgLabelsParams,
} from '../../types';
import useInvalidation from '../useInvalidation';

const KEY = 'labels';

export function useUpdateFileLabels(
  { oid, pid }: FetchSignedURLParams,
  options?: UseMutationOptions<unknown, unknown, UpdateFileLabelsParams>,
) {
  return useMutation<unknown, unknown, UpdateFileLabelsParams>({
    mutationFn: ({ path, labels }: UpdateFileLabelsParams) =>
      updateFileLabels({ oid, pid, path, labels }),
    ...options,
  });
}

export function useOrgLabels(
  oid: string,
  options?: Omit<UseQueryOptions<Label[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<Label[]>({
    queryKey: [KEY],
    queryFn: () => fetchAllOrgLabels(oid),
    ...options,
  });
}

export function useUpdateOrgLabels(
  oid: string,
  options?: UseMutationOptions<unknown, unknown, UpdateOrgLabelsParams>,
) {
  const invalidate = useInvalidation(KEY);
  return useMutation<unknown, unknown, UpdateOrgLabelsParams>({
    mutationFn: ({ labels }: UpdateOrgLabelsParams) =>
      updateOrgLabels(oid, labels),
    ...options,
    onSuccess: () => {
      invalidate();
    },
  });
}
