import { VectorBulkUpload, VectorBulkUploadResponse } from '../../types';
import { api } from '../../utils/api';

export function bulkUploadVectors({
  orgId,
  pid,
  geneId,
  file,
}: VectorBulkUpload) {
  const body = new FormData();
  body.append('file', file);
  return api.post<VectorBulkUploadResponse>(
    `/orgs/${orgId}/${pid}/bulk-vector/${geneId}`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}
