import { styled } from '@formbio/ui/components';
import { IconButton } from '@formbio/ui/components/IconButton';
import { X as XIcon } from '@formbio/ui/components/Icons';

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const sp1 = theme.spacing(1);
  const sp2 = theme.spacing(2);
  return {
    marginLeft: 'auto',
    // align CTA button and close icon
    marginRight: `-${sp1}`,
    svg: {
      width: sp2,
      height: sp2,
    },
  };
});

export const CloseIconButton = ({ onClick }: { onClick?: () => void }) => (
  <StyledIconButton aria-label='close' onClick={onClick}>
    <XIcon />
  </StyledIconButton>
);
