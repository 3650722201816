import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { useAuth } from './useAuth';

export function useDoesUserExist() {
  const { auth } = useAuth();

  return async (email: string) => {
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    return !!signInMethods.length;
  };
}
