import { api } from '../../utils/api';

export interface RShinyStartResponse {
  redirectToken: string;
}

export function start(
  oid: string,
  pid: string,
  runid: string,
): Promise<RShinyStartResponse> {
  return api
    .get<RShinyStartResponse>(`/orgs/${oid}/${pid}/rshiny/start/${runid}`)
    .then(({ data }) => data);
}
