import { HttpResponse, http, delay } from 'msw';
import { allJourneys } from './data/journeys';
import { journeyRunHandlers } from './journeys-runs';

const fetchAllJourneys = http.get(`*/api/v1/journeys`, async () => {
  await delay(1000);
  HttpResponse.json(allJourneys);
});

const fetchOneJourney = http.get(`*/api/v1/journeys/:slug`, ({ params }) =>
  HttpResponse.json(allJourneys.find(journey => journey.slug === params.slug)),
);

export const journeyHandlers = [
  fetchAllJourneys,
  fetchOneJourney,
  ...journeyRunHandlers,
];
