import { forwardRef } from 'react';
import {
  List as MuiList,
  ListProps as MuiListProps,
  ListSubheader as MuiListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
  ListItemAvatar as MuiListItemAvatar,
  ListItemAvatarProps as MuiListItemAvatarProps,
} from '@mui/material';
import { OmitComponentProp } from '@formbio/ui/types';

type ListProps = Omit<MuiListProps, 'sx'>;
type ListItemButtonProps = Omit<MuiListItemButtonProps, 'sx'>;
type ListItemIconProps = Omit<MuiListItemIconProps, 'sx'>;

export const List = ({ ...rest }: ListProps) => <MuiList {...rest} />;

export const ListSubheader = ({ ...rest }: MuiListSubheaderProps) => <MuiListSubheader {...rest} />;

export const ListItem = ({ ...rest }: MuiListItemProps) => (
  <MuiListItem {...rest} />
);

export const ListItemButton = forwardRef(
  (
    props: OmitComponentProp<ListItemButtonProps>,
    ref?: React.Ref<HTMLDivElement>,
  ) => {
    const { children, ...rest } = props;
    return (
      <MuiListItemButton ref={ref} {...rest}>
        {children}
      </MuiListItemButton>
    );
  },
);
ListItemButton.displayName = 'ListItemButton';

export const ListItemText = forwardRef(
  (
    props: OmitComponentProp<MuiListItemTextProps>,
    ref?: React.Ref<Element>,
  ) => {
    const { children, ...rest } = props;
    return (
      <MuiListItemText ref={ref} {...rest}>
        {children}
      </MuiListItemText>
    );
  },
);

ListItemText.displayName = 'ListItemText';

export const ListItemIcon = ({ ...rest }: ListItemIconProps) => (
  <MuiListItemIcon {...rest} />
);

export const ListItemAvatar = ({ ...rest }: MuiListItemAvatarProps) => (
  <MuiListItemAvatar {...rest} />
);
