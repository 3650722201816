import { Color } from '@mui/material';

export const info: Partial<Color> = {
  [700]: '#3145AD',
  [600]: '#3D55D2',
  [500]: '#4766F9',
  [400]: '#98A8FC',
  [300]: '#CED4FE',
  [200]: '#E6EAFE',
  [100]: '#F0F3FE',
};
