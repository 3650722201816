const width = 32;
const height = 32;

export default function FormBioLogoLetter({ title }: { title: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      aria-label={title}
    >
      <title>{title}</title>
      <g clipPath='url(#clip0_860_14016)'>
        {/* F */}
        <path
          d='M0 1.31958V25.6732H5.81355V16.3577H15.017V11.5627H5.81355V6.15371H17.9416V1.31958H0Z'
          transform={`translate(8,2)`}
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
