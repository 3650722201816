import {
  SchemaCheckboxField,
  SchemaDirectoryField,
  SchemaDropdownField,
  SchemaFileField,
  SchemaInputs,
  SchemaTextField,
  WorkflowSchemaConditional,
} from '@formbio/workflow-schema';
import { WorkflowSchemaVisibility } from './workflow-version';

export enum ContainerAppCategory {
  EXPLORE = 'exploration',
  VIZ = 'visualization',
}

export type ContainerAppInputs =
  | SchemaTextField
  | SchemaDropdownField
  | SchemaFileField
  | SchemaDirectoryField
  | SchemaCheckboxField;

export enum CONTAINER_APP_NAME {
  rstudio = 'rstudio',
  cellxgene = 'cellxgene',
  tensorboard = 'tensorboard',
  jupyterLab = 'jupyterLab',
  sanger = 'sanger',
  formshell = 'formshell',
  datatransfer = 'datatransfer',
  formsight = 'formsight',
  gaudi = 'gaudi',
}

export enum CONTAINER_APP_TYPE {
  rshiny = 'RSHINY',
  jupyter = 'JUPYTER',
  rstudio = 'RSTUDIO',
  tensorboard = 'TENSORBOARD',
  shell = 'SHELL',
  custom = 'GENERAL',
}

export interface ContainerAppSchema {
  id: CONTAINER_APP_NAME | string;
  category: ContainerAppCategory;
  description: string;
  displayName: string;
  docsLink?: string;
  port: string;
  enableMultipleTags?: boolean;
  container: string;
  tags?: string;
  command?: string;
  mount?: string;
  user?: string;
  param?: string;
  envs?: string;
  inputs: SchemaInputs<ContainerAppInputs>;
  ui: {
    inputs: string[];
  };
  conditionals?: WorkflowSchemaConditional[];
}

export interface ContainerAppConfig {
  gpu: {
    enabled: boolean;
  };
}

export interface ContainerApp {
  appName: CONTAINER_APP_NAME | string;
  schema: ContainerAppSchema;
  config: ContainerAppConfig;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  visibility: WorkflowSchemaVisibility;
  access: 'rw' | 'ro';
  type: CONTAINER_APP_TYPE;
  frn: string;
}

export interface CreateOrgAppPayload {
  appName: string;
  type: CONTAINER_APP_TYPE;
  schema: ContainerAppSchema;
  config: ContainerAppConfig;
}
