import * as snippet from '@segment/snippet';
import { ReactNode, useMemo } from 'react';

export const ANALYTICS_EVENT = {
  LOGOUT: 'logout',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  VERIFY_EMAIL: 'verify-email',
  START_TOOL_RUN: 'start-tool-run',
  START_WORKFLOW_RUN: 'start-workflow-run',
  SUBMIT_WORKFLOW_RUN: 'submit-workflow-run',
  NOTES_OPEN: 'notes-open',
  NOTES_CLOSE: 'notes-close',
  NOTES_MANUAL_RESET: 'notes-manual-reset',
  NOTES_DRAFT_STARTED: 'notes-draft-started',
  NOTES_DRAFT_PUBLISHED: 'notes-draft-published',
  NOTES_DRAFT_UPDATE: 'notes-draft-update',
  NOTES_DELETE: 'notes-deleted',
  NOTES_EDIT: 'notes-edited',
  START_CONTAINER_APP: 'start-container-app',
  FILE_MANAGER_SEARCH: 'file-manager-search',
  FILE_MANAGER_FILE_TYPE_SEARCH: 'file-manager-file-type-search',
  METADATA_DOWNLOAD_FILE: 'metadata-download-file',
  UPLOAD_FILE_COMPLETED: 'upload-file-completed',
  UPLOAD_FILE_CANCEL: 'upload-file-cancel',
  UPLOAD_FILE_RESUME: 'upload-file-resume',
  UPLOAD_FILE_PAUSE: 'upload-file-pause',
  UPLOAD_FILE_PROGRESS: 'upload-file-progress',
  UPLOAD_FILE_STARTED: 'upload-file-started',
  UPLOAD_FILE_FAILED: 'upload-file-failed',
};

type Props = {
  apiKey?: string;
  children: ReactNode;
};

export function AnalyticsProvider({ children, apiKey }: Props) {
  const scriptSnippet = useMemo(() => {
    const options = {
      apiKey,
      page: false,
    };

    return process.env.NODE_ENV === 'development'
      ? snippet.max(options)
      : snippet.min(options);
  }, [apiKey]);

  return (
    <>
      {scriptSnippet && (
        <script
          id='segment-script'
          dangerouslySetInnerHTML={{
            __html: scriptSnippet,
          }}
        />
      )}
      {children}
    </>
  );
}

export function useAnalytics() {
  function page(name: string, options?: Record<string, unknown>) {
    if (window && window.analytics) {
      window.analytics.page(name, options);
    }
  }

  function track(name: string, properties?: Record<string, unknown>) {
    if (window && window.analytics) {
      window.analytics.track(name, properties);
    }
  }

  function identify(id: string, traits: Record<string, unknown>) {
    if (window && window.analytics) {
      window.analytics.identify(id, traits);
    }
  }

  function reset() {
    if (window && window.analytics) {
      window.analytics.reset();
    }
  }

  return {
    page,
    track,
    identify,
    reset,
  };
}
