import { faker } from '@faker-js/faker';
import {
  DATACORE_ENTITY_DATA_TYPE,
  DATACORE_ENTITY_SAMPLE,
  Sample,
} from '@formbio/api';
import { mutableSamples } from './sample-sets';

export const sampleIds = Array.from({ length: 10 }).map(() =>
  faker.string.uuid(),
);

export const samples: Sample[] = sampleIds.map(id => ({
  id,
  frn: `frn://datacore/organization/[orgId]/projects/[projId]/entities/${DATACORE_ENTITY_SAMPLE}/${id}`,
  name: faker.lorem.word(),
  filePath: `formbio://${faker.system.filePath()}`,
  createdAt: faker.date.past().toISOString(),
  fileSize: faker.number.int(50000),
}));

export function createDatacoreSamples() {
  return {
    data: mutableSamples.map(sample => ({
      // changing from `entities` to `core` to avoid
      // a bug in the deserialization of the response
      // see: https://form-bio.slack.com/archives/C06S9SVKK51/p1721754676735169
      type: 'core',
      id: sample.id,
      attributes: {
        name: sample.name,
        createdAt: sample.createdAt,
        frn: sample.frn,
        type: DATACORE_ENTITY_SAMPLE,
        updatedAt: sample.createdAt,
      },
      relationships: {
        data: {
          data: {
            type: DATACORE_ENTITY_DATA_TYPE,
            id: sample.id,
          },
        },
      },
    })),
    included: [
      ...mutableSamples.map(sample => {
        return (
          sample && {
            type: DATACORE_ENTITY_DATA_TYPE,
            id: sample.id,
            attributes: {
              data: {
                file: sample.filePath,
              },
              createdAt: sample.createdAt,
              typeSchemaVersionName: 'v1.0.0',
            },
          }
        );
      }),
    ],
  };
}

function createIncludedSample(sample: Sample) {
  const entitySample = mutableSamples.find(samp => samp.id === sample.id);
  return (
    entitySample && {
      type: DATACORE_ENTITY_DATA_TYPE,
      id: entitySample.id,
      attributes: {
        data: {
          file: entitySample.filePath,
        },
        createdAt: entitySample.createdAt,
        type: DATACORE_ENTITY_SAMPLE,
        typeSchemaVersionName: 'v1.0.0',
      },
    }
  );
}

export function createDatacoreSample(sample: Sample) {
  return {
    data: {
      // changing from `entities` to `core` to avoid
      // a bug in the deserialization of the response
      // see: https://form-bio.slack.com/archives/C06S9SVKK51/p1721754676735169
      type: 'core',
      id: sample.id,
      attributes: {
        name: sample.name,
        createdAt: sample.createdAt,
        type: DATACORE_ENTITY_SAMPLE,
        updatedAt: sample.createdAt,
      },
      relationships: {
        data: {
          data: {
            type: DATACORE_ENTITY_DATA_TYPE,
            id: sample.id,
          },
        },
      },
    },
    included: [createIncludedSample(sample)],
  };
}
