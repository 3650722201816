const hexToRGBA = (hex: string, opacity: number): string => {
  const hexSanitized = hex.replace('#', '');

  const hexString =
    hexSanitized.length === 3
      ? hexSanitized[0] +
        hexSanitized[0] +
        hexSanitized[1] +
        hexSanitized[1] +
        hexSanitized[2] +
        hexSanitized[2]
      : hexSanitized;

  const red = parseInt(hexString.substring(0, 2), 16);
  const green = parseInt(hexString.substring(2, 4), 16);
  const blue = parseInt(hexString.substring(4, 6), 16);

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};

export { hexToRGBA };
