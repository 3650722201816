import {
  InputLabel,
  Stack,
  styled,
  Typography,
  InputBaseProps,
  PasswordField,
  ErrorMessage,
} from '@formbio/ui';
import { ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

/*
 * styled
 */
const HelpTextTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body3?.fontSize,
}));

/**
 *
 * Manages the layout of the label, input and error
 * in a form
 */
export default function FormPasswordItem({
  label,
  defaultValue,
  placeholder,
  errorMessage,
  registerProps,
  required,
  disabled = false,
  description,
  helpText,
  inputProps = {},
}: {
  label: string;
  defaultValue?: string | null;
  placeholder: string;
  errorMessage?: string;
  registerProps: UseFormRegisterReturn;
  required?: boolean;
  disabled?: boolean;
  description?: ReactNode;
  helpText?: string;
  inputProps?: InputBaseProps['inputProps'];
}) {
  const id = `${label.replaceAll(' ', '-')}-input`;
  return (
    <Stack spacing={1}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      {description}
      <PasswordField
        id={id}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        defaultValue={defaultValue}
        inputProps={inputProps}
        {...registerProps}
      />
      {helpText && <HelpTextTypography>{helpText}</HelpTextTypography>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Stack>
  );
}
