// TODO: Allow for conditional phases (there could be more than one phase that satisfies the same step in the journey)
// TODO: Allow for versioning Journeys (e.g. CRISPR v1.0, CRISPR v2.0) - The standards may change over time.
// TODO: Allow for either a "final phase" eg Report Generation, or the ability to have phase the depends on a number of steps being complete in order to run, but should be displayed in the UI as disabled when when the prerequisites are not met.
export interface JourneySchema {
  id: string;
  slug: string;
  title: string;
  description: string;
  phases: JourneyPhase[];
  categories: JourneyCategory[];
  verifiedStatus: VerifiedStatus;
}

// TODO: Define and input / output schema
// TODO: Define schema for mapping to a Form Bio feature - eg a specific Workflow or Form Bench that can be used to satisfy the output of a phase (can be a URL to make initial progress, but may need to be more complex)
// TODO: Add concept of required / optional phases
export interface JourneyPhase {
  id: string;
  slug: string;
  title: string;
  description: string;
}

export enum JourneyCategory {
  GENOME_EDITING = 'Genome Editing',
  GENOMICS = 'Genomics',
  CHARACTERIZATION = 'Characterization',
  ONBOARDING = 'Onboarding',
}

export enum VerifiedStatus {
  VERIFIED = 'Verified',
  BETA = 'Beta',
}
