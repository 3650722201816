import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogActions as MuiDialogActions,
  DialogProps as MuiDialogProps,
  DialogTitleProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogActionsProps,
} from '@mui/material';
import { ElementType } from 'react';

export type DialogProps = MuiDialogProps & {
  wrapper?: boolean;
};
export const Dialog = ({ wrapper = true, children, ...rest }: DialogProps) =>
  wrapper ? <MuiDialog {...rest}>{children}</MuiDialog> : <>{children}</>;
export function DialogTitle<Composed extends ElementType>({
  ...rest
}: DialogTitleProps<Composed>) {
  return <MuiDialogTitle {...rest} />;
}
export const DialogContent = ({ ...rest }: DialogContentProps) => (
  <MuiDialogContent {...rest} />
);
export const DialogContentText = ({ ...rest }: DialogContentTextProps) => (
  <MuiDialogContentText {...rest} />
);
export const DialogActions = ({ ...rest }: DialogActionsProps) => (
  <MuiDialogActions {...rest} />
);
