import { JourneyRun } from '../../types';
import { api } from '../../utils/api';

export interface CreateJourneyRunData {
  oid: string;
  pid: string;
  runName: string;
  journeyId: string;
}
export interface DeletePhaseRunData {
  journeyRunId: string;
  phaseRunId: string;
}

export async function fetchAll(): Promise<JourneyRun[]> {
  return api.get<JourneyRun[]>(`/journey-runs`).then(({ data }) => data);
}

export function fetchOne({
  journeyRunId,
}: {
  journeyRunId: string;
}): Promise<JourneyRun> {
  return api
    .get<JourneyRun>(`/journey-runs/${journeyRunId}`)
    .then(({ data }) => data);
}

export function create({ oid, pid, ...data }: CreateJourneyRunData) {
  const url = `/orgs/${oid}/${pid}/journey-runs`;
  return api.post<JourneyRun>(url, data).then((resp) => resp.data);
}

export function deletePhaseRun({
  journeyRunId,
  phaseRunId,
}: DeletePhaseRunData) {
  return api
    .delete<JourneyRun>(`/journey-runs/${journeyRunId}/${phaseRunId}`)
    .then(({ data }) => data);
}
