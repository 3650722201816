import { WorkflowRun } from '@formbio/api';

const gelImage1 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/SDS-Page/Sample1_gel image.pdf';

const gelImage2 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/SDS-Page/Sample2_gel image.pdf';

const gelImage3 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/SDS-Page/Sample3_gel image.pdf';

const gelImage4 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/SDS-Page/Sample4_gel image.pdf';

const gelImage5 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/SDS-Page/Sample5_gel image.pdf';

const gelImage6 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/SDS-Page/Sample6_gel image.pdf';

// purity
const csv1 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/AUC/AUC_extracted.csv';
const csv2 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/AUC/AUC_extracted.csv';

const csv3 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/AUC/AUC_extracted.csv';

const csv4 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/AUC/AUC_extracted.csv';

const csv5 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/AUC/AUC_extracted.csv';

const csv6 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/AUC/AUC_extracted.csv';

const pdf1 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/CoAs/Sample1_CoA of orig_ in pUC-GW-Kan_redacted.pdf';
const pdf2 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/CoAs/Sample2_CoA of orig_jet in pUC-GW-Kan_redacted.pdf';
const pdf3 =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/CoAs/Sample3_CoA of opt in pUC-GW-Kan_redacted.pdf';

const bed =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/data/aav.bed';

const ngs1 = 'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/data/';
const ngs2 = 'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/data/';
const ngs3 = 'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/data/';

const notebook =
  'gs://formbio-sbx-2e32842d-331f-4281-8186-3805897dcc94/pipeline-outputs/auc-papermill/2024-01-18T23_16_42Z_c12819cc-6347-4140-9b07-b27dcf43ca2c/output/results.html';

const partialParams = {
  gelImage1,
  gelImage2,
  csv1,
  csv2,
  csv3,
  csv4,
  csv5,
  csv6,
  notebook,
};

const allParams = {
  gelImage1,
  gelImage2,
  gelImage3,
  gelImage4,
  gelImage5,
  gelImage6,
  csv1,
  csv2,
  csv3,
  csv4,
  csv5,
  csv6,
  notebook,
  pdf1,
  pdf2,
  pdf3,
  bed,
  ngs1,
  ngs2,
  ngs3,
};

export const allProgramRuns: WorkflowRun[] = [
  {
    createdAt: '2024-01-18T02:23:16.793296Z',
    id: 'synth-run',
    name: '',
    outputDirectory: '',
    nextflowVersion: '23.10.0',
    params: allParams,
    repository: 'characterization',
    repositoryVersion: 'v2.3.0',
    canVisualizeWithRShiny: false,
    executions: [],
    workflowPulledFrom: 'FORM',
    workflowDisplayName: 'Characterization',
    workflowVersionID: 'synthesis',
    workflowRepo: 'nf-adenoassociatedvirus',
    uploadedOrg: 'sbx-uat',
    uploadedProj: 'characterization',
    executionEngine: 'nextflow',
  },
  {
    createdAt: '2024-01-18T02:23:16.793296Z',
    id: 'design-run',
    name: '',
    outputDirectory: '',
    nextflowVersion: '23.10.0',
    params: partialParams,
    repository: 'characterization',
    repositoryVersion: 'v2.3.0',
    canVisualizeWithRShiny: false,
    executions: [],
    workflowPulledFrom: 'FORM',
    workflowDisplayName: 'Characterization',
    workflowVersionID: 'design',
    workflowRepo: 'nf-adenoassociatedvirus',
    uploadedOrg: 'sbx-uat',
    uploadedProj: 'characterization',
    executionEngine: 'nextflow',
  },
  {
    createdAt: '2024-01-18T02:23:16.793296Z',
    id: 'manufacturing-run',
    name: '',
    outputDirectory: '',
    nextflowVersion: '23.10.0',
    params: {},
    repository: 'characterization',
    repositoryVersion: 'v2.3.0',
    canVisualizeWithRShiny: false,
    executions: [],
    workflowPulledFrom: 'FORM',
    workflowDisplayName: 'Characterization',
    workflowVersionID: 'manufacturing',
    workflowRepo: 'nf-adenoassociatedvirus',
    uploadedOrg: 'sbx-uat',
    uploadedProj: 'characterization',
    executionEngine: 'nextflow',
  },
  {
    createdAt: '2024-01-18T02:23:16.793296Z',
    id: 'validation-run',
    name: '',
    outputDirectory: '',
    nextflowVersion: '23.10.0',
    params: {},
    repository: 'characterization',
    repositoryVersion: 'v2.3.0',
    canVisualizeWithRShiny: false,
    executions: [],
    workflowPulledFrom: 'FORM',
    workflowDisplayName: 'Characterization',
    workflowVersionID: 'validation',
    workflowRepo: 'nf-adenoassociatedvirus',
    uploadedOrg: 'sbx-uat',
    uploadedProj: 'characterization',
    executionEngine: 'nextflow',
  },
  {
    createdAt: '2024-01-18T02:23:16.793296Z',
    id: 'safety-run',
    name: '',
    outputDirectory: '',
    nextflowVersion: '23.10.0',
    params: {},
    repository: 'characterization',
    repositoryVersion: 'v2.3.0',
    canVisualizeWithRShiny: false,
    executions: [],
    workflowPulledFrom: 'FORM',
    workflowDisplayName: 'Characterization',
    workflowVersionID: 'safety',
    workflowRepo: 'nf-adenoassociatedvirus',
    uploadedOrg: 'sbx-uat',
    uploadedProj: 'characterization',
    executionEngine: 'nextflow',
  },
  {
    createdAt: '2024-01-18T02:23:16.793296Z',
    id: 'goi2Design-run',
    name: '',
    outputDirectory: '',
    nextflowVersion: '23.10.0',
    params: {},
    repository: 'characterization',
    repositoryVersion: 'v2.3.0',
    canVisualizeWithRShiny: false,
    executions: [],
    workflowPulledFrom: 'FORM',
    workflowDisplayName: 'Characterization',
    workflowVersionID: 'goi2Design',
    workflowRepo: 'nf-adenoassociatedvirus',
    uploadedOrg: 'sbx-uat',
    uploadedProj: 'characterization',
    executionEngine: 'nextflow',
  },
  {
    createdAt: '2024-01-18T02:23:16.793296Z',
    id: 'goi2Synthesis-run',
    name: '',
    outputDirectory: '',
    nextflowVersion: '23.10.0',
    params: {},
    repository: 'characterization',
    repositoryVersion: 'v2.3.0',
    canVisualizeWithRShiny: false,
    executions: [],
    workflowPulledFrom: 'FORM',
    workflowDisplayName: 'Characterization',
    workflowVersionID: 'goi2Synthesis',
    workflowRepo: 'nf-adenoassociatedvirus',
    uploadedOrg: 'sbx-uat',
    uploadedProj: 'characterization',
    executionEngine: 'nextflow',
  },
];
