import { HttpResponse, http } from 'msw';
import { allProcessExecutions } from './data/process-executions';
import { programRunHandlers } from './program-runs';

const fetchAllPrograms = http.get(`*/api/v1/orgs/:oid/:pid/programs`, () => {
  return HttpResponse.json(allProcessExecutions);
});

const fetchOneProgram = http.get(
  `*/api/v1/orgs/:oid/:pid/programs/:indicationId`,
  ({ params }) => {
    const program = allProcessExecutions.find(
      program => program.id === params.indicationId,
    );

    return HttpResponse.json(program);
  },
);

export const programHandlers = [
  fetchAllPrograms,
  fetchOneProgram,
  ...programRunHandlers,
];
