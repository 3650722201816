import { reduceTextToTwoLetters } from '@formbio/ui/utils/formatting';
import {
  Avatar,
  SimplePaletteColorOptions,
} from '@formbio/ui/components/Avatar';
import { styled } from '@formbio/ui/components';
import includes from 'lodash/includes';

export enum AvatarSizes {
  small = '32px',
  medium = '40px',
  large = '48px',
  extraLarge = '80px',
}

/**
 * A decent fontSize should be 3/8th of the avatar size
 */
const AVATAR_FONT_SIZES = new Map<AvatarSizes, string>([
  [AvatarSizes.small, '12px'],
  [AvatarSizes.medium, '15px'],
  [AvatarSizes.large, '18px'],
]);

/**
 * Colors are calculated by summing the char code values of {text}.
 * Then that number is normalized into {index} to fit into {colors} length.
 * The background color is the light shade while the font is the dark shade.
 */

type BgColorOptions = 'light' | 'main';
type StyledAvatarProps = {
  text: string;
  variant?: 'square' | 'circular' | 'rounded';
  size?: AvatarSizes;
  border?: boolean;
  // for people with the same name but 2 email addresses
  // this field will create a higher chance to have 2 distinct colors
  uniqId?: string;
  bgColorMode?: BgColorOptions;
};

export function colorFromText(
  text: string,
  colors: Partial<SimplePaletteColorOptions>[],
  bgMode?: BgColorOptions,
) {
  const sum = (text || ' ')
    .split('')
    .map(i => (i ? i.charCodeAt(0) : 0))
    .reduce((a, b) => a + b);
  const index = sum % colors.length;
  const bgColorMode = bgMode ? bgMode : 'light';
  const colorMode = bgColorMode === 'light' ? 'dark' : 'light';
  return {
    bgColor: colors[index][bgColorMode],
    color: colors[index][colorMode],
  };
}

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop =>
    !includes(['text', 'uniqId', 'size', 'border', 'bgColorMode'], prop),
})<StyledAvatarProps>(({
  theme,
  text,
  uniqId,
  variant,
  size = AvatarSizes.small,
  border = false,
  bgColorMode,
}) => {
  const avatarColor = colorFromText(
    uniqId || text,
    // an array of the possible colors
    theme.palette.tertiaryColors || [theme.palette.primary],
    bgColorMode,
  );

  return {
    width: size,
    height: size,
    fontSize: AVATAR_FONT_SIZES.get(size),
    fontWeight: 'bold',
    backgroundColor: avatarColor.bgColor,
    color: avatarColor.color,
    borderRadius: variant === 'square' ? '4px' : undefined,
    border: border ? `2px solid ${avatarColor.color}` : undefined,
  };
});

export default function TextAvatar({ text, ...props }: StyledAvatarProps) {
  return (
    <StyledAvatar alt={text} text={text} {...props}>
      {reduceTextToTwoLetters(text).toUpperCase()}
    </StyledAvatar>
  );
}
