import * as yup from 'yup';
import { Organization } from '@formbio/api';
import { isUniqueName } from './isUniqueName';

// use these validations to standardize inputs

// Org and project name validation
export function getOrgProjectValidator(dataList?: Organization[]) {
  return yup
    .string()
    .trim()
    .test(
      'unique',
      'This name has already been used. Please try another',
      function (value) {
        return isUniqueName(value!, dataList);
      },
    )
    .matches(/^[a-zA-Z]+/, 'Must start with a letter')
    .matches(
      /^[a-zA-Z0-9 _-]*$/,
      'Only letters, numbers, underscores, spaces, and dashes',
    )
    .min(4, 'Must be at least 4 characters')
    .max(50, 'Must be 50 characters or less')
    .required('A name is required.');
}

export function getEmailValidator(message: string) {
  return yup.string().email(message).trim().lowercase();
}

export function yupBillingId() {
  return yup
    .string()
    .test(
      'valid-characters',
      'Only letters, numbers and hyphens are allowed.',
      value => {
        if (!value) return true;

        const regex = /^[a-zA-Z0-9-]+$/;
        return regex.test(value);
      },
    )
    .test(
      'valid-format',
      'Please enter the ID in the format xxxxxx-xxxxxx-xxxxxx',
      function (value) {
        if (!value) return true;

        const regex = /^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}$/;
        return regex.test(value);
      },
    );
}

export function getPasswordValidator() {
  return yup.string().min(6, 'Password must be at least 6 characters');
}
