export function downloadStringifiedJson({
  stringifiedJson,
  fileName = 'data',
}: {
  stringifiedJson: string;
  fileName?: string;
}) {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    stringifiedJson,
  )}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = `${fileName}.json`;

  link.click();

  link.remove();
}
