import { useEffect, useState } from 'react';

// a hook to manage a countdown timer
export default function useCountDown(seconds: number) {
  const [counter, setCounter] = useState(seconds);
  const [isRunning, setIsRunning] = useState(false);

  function startCount() {
    setIsRunning(true);
    setCounter(seconds);
  }

  function stopCount() {
    setIsRunning(false);
    setCounter(seconds);
  }

  useEffect(() => {
    if (isRunning && counter <= 0) {
      stopCount();
    }
  }, [isRunning, counter]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isRunning) {
      interval = setInterval(() => {
        setCounter(counter => counter - 1);
      }, 1000);
    } else {
      interval && clearInterval(interval);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [isRunning]);

  return { counter, isRunning, startCount, stopCount };
}
