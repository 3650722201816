import { JourneyCategory, JourneySchema, VerifiedStatus } from '@formbio/api';

export const allJourneys: JourneySchema[] = [
  {
    id: 'characterization',
    slug: 'characterization',
    title: 'Characterization',
    description:
      'This Characterization Journey is to guide your team through the steps required to generate high quality data to support your efforts towards FDA approval.',
    categories: [JourneyCategory.CHARACTERIZATION],
    verifiedStatus: VerifiedStatus.VERIFIED,
    phases: [
      {
        id: '1',
        slug: 'purity',
        title: 'Purity - SDS Page',
        description: 'Gel workflow',
      },
      {
        id: '2',
        slug: 'emptyFull',
        title: 'Empty/full: AUC',
        description: ' template notebook',
      },
      {
        id: '3',
        slug: 'ngs',
        title: 'NGS: genomic content of full and partial capsids',
        description: 'Workflow (TBD)',
      },
      {
        id: '4',
        slug: 'elisa',
        title: 'ELISA: Titer for capsid (vp/mL)',
        description: 'template notebook',
      },
      {
        id: '5',
        slug: 'ddPCR-qPCR',
        title: 'ddPCR/qPCR: Titer for GOI (vg/mL)',
        description: 'maybe template notebook',
      },
    ],
  },
  {
    id: '1',
    slug: 'crispr',
    title: 'CRISPR Gene Editing App',
    description:
      'This CRISPR app is used to design a gene editing solution. It provides opportunities to: detect gene variants, design oligos and gRNAs, choose best gRNAs, rank edit methods, and determine final edit outcome with sequencing data.',
    categories: [JourneyCategory.GENOME_EDITING, JourneyCategory.GENOMICS],
    verifiedStatus: VerifiedStatus.VERIFIED,
    phases: [
      {
        id: '1',
        slug: 'optional-variant-detection',
        title: 'Optional Variant Detection',
        description:
          'In this phase you can identify single-nucleotide variants, indels, and structural variants in a genomics resequencing project compared to a reference genome.',
      },
      {
        id: '2',
        slug: 'choose-variants-to-edit',
        title: 'Choose Variants to Edit',
        description:
          'In this phase you can select the genes you’d like to edit from the results of the previous phase or upload a pre-compiled list. ',
      },
      {
        id: '3',
        slug: 'guide-design',
        title: 'Guide Design',
        description:
          'In this phase you will decide what type of edit to make and design guide RNA sequences for the genome editing experiment(s).',
      },
      {
        id: '4',
        slug: 'choose-guide-rnas',
        title: 'Choose Guide RNAs',
        description:
          'In this phase you can explore the suggested gRNAs from Phase 3 to select the most appropriate for your experiment(s).',
      },
      {
        id: '5',
        slug: 'validate-edits',
        title: 'Validate Edits with Sequencing Data',
        description:
          'In this phase you can perform CRISPResso batch analysis on amplicon sequencing data to determine if your CRISPR edit was successful.',
      },
    ],
  },
];
