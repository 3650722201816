export interface Genome {
  id: string;
  name: string;
  commonName: string;
  custom?: boolean;
  visibility?: GenomesVisibility;
}

export interface GenomeMetadata extends Genome {
  taxid: string;
  snpEffver?: string;
  annotations: Array<string>;
  genomeBucket: string;
}

export interface IGVGenome extends Genome {
  aliasURL?: string;
  chromosomeOrder?: string;
  cytobandURL?: string;
  fastaURL?: string;
  indexURL?: string;
  tracks: IGVTrack[];
}

export enum BrowserEvent {
  TrackRemoved = 'trackremoved',
  TrackDrag = 'trackdrag',
  TrackDragEnd = 'trackdragend',
  LocusChange = 'locuschange',
  TrackClick = 'trackclick',
  TrackOrderChanged = 'trackorderchanged',
}

export interface ReferenceFrame {
  getLocusString: () => string;
}

export interface Browser {
  config: IGVSession;
  genome: {
    config: IGVGenome;
  };
  loadGenome: (config: IGVGenome) => void;
  loadTrack: (config: IGVTrack) => void;
  loadSessionObject: (config: IGVSession) => void;
  removeTrackByName: (name: string) => void;
  on: (event: BrowserEvent, handler: (args: ReferenceFrame[]) => void) => void;
  off: (event: BrowserEvent) => void;
}

export interface IGVTrack {
  indexed?: boolean;
  format?: string;
  indexURL?: string | boolean;
  name?: string;
  order?: number;
  removable?: boolean;
  url?: string;
}

export interface IGVSession {
  genome: string;
  locus: string | string[];
  tracks: IGVTrack[];
}

export enum GenomesVisibility {
  PUBLIC = 'PUBLIC',
  ORG = 'ORG',
  PROJECT = 'PROJECT',
  FROM_FILE = 'FROM_FILE',
}
