import { ValidatorsResponse } from '../../types/validator';
import { api } from '../../utils/api';

export function fetchOne({ oid, pid }: { oid: string; pid: string }) {
  return api
    .get<ValidatorsResponse>(
      `/orgs/${oid}/${pid}/vault/validations?key=formats`,
    )
    .then(({ data }) => data);
}
