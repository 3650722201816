import { styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';

const StyledContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

export default function FieldError({ error }: { error?: string }) {
  if (error) {
    return (
      <StyledContainer>
        <Typography variant='body3' color='error' role='alert'>
          {error}
        </Typography>
      </StyledContainer>
    );
  }
  return null;
}

export { FieldError };
