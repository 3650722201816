import { HTMLAttributes } from 'react';
import { styled } from '@formbio/ui/components';

export const SIZES = ['xs', 's', 'm', 'l', 'xl', 'xxl'] as const;
export type SpacerSize = (typeof SIZES)[number];

export type SpacerProps = HTMLAttributes<HTMLDivElement> & {
  size: SpacerSize;
};

const StyledSpacer = styled('div', {
  shouldForwardProp: prop => prop !== 'size',
})<SpacerProps>(({ size, theme }) => ({
  flexShrink: 0,
  blockSize: size === 'xs' ? 1 : theme.spacing(SIZES.indexOf(size)),
}));

export const Spacer = ({ size }: SpacerProps) => <StyledSpacer size={size} />;
