import { Color } from '@mui/material';

export const warning: Partial<Color> = {
  [700]: '#996600',
  [600]: '#EB9C00',
  [500]: '#FFB114',
  [400]: '#FFC95C',
  [300]: '#FFDD99',
  [200]: '#FFEECC',
  [100]: '#FFF6E5',
};
