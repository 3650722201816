import { http, HttpResponse } from 'msw';
import { workflowRuns } from './data/workflow-runs';
import { mockDocs, mockWorkflows } from './data/workflows';

const getAllWorkflows = http.get(
  '*/api/v1/orgs/:orgId/:projectId/workflows',
  () =>
    HttpResponse.json(
      { workflowVersions: mockWorkflows.workflows },
      { status: 200 },
    ),
);

const getWorkflowVersions = http.get(
  '*/api/v1/orgs/:orgId/:projectId/workflows/:uploadOrgId/:uploadedProjectId/:repo/:name',
  async ({ params }) => {
    return HttpResponse.json(
      {
        workflowVersions: mockWorkflows.workflows.filter(
          wf => wf.workflowName === params.name,
        ),
      },
      { status: 200 },
    );
  },
);

const getWorkflowDocs = http.get(
  '*/api/v1/orgs/:orgId/:projectId/workflows/docs/:orgId/:projectId/:repo/:name',
  async ({ request }) => {
    const url = new URL(request.url);
    const version = url.searchParams.get('version');
    // @ts-expect-error - this param should exist
    return HttpResponse.json(mockDocs[version], { status: 200 });
  },
);

const workflowRunsHandler = http.get(
  '*/api/v1/orgs/:oid/:pid/workflow-runs',
  () => {
    return HttpResponse.json(workflowRuns);
  },
);
const workflowRun = http.get('*/api/v1/workflow-run/:runId', () =>
  HttpResponse.json(workflowRuns[1]),
);

export const workflowHandlers = [
  getAllWorkflows,
  getWorkflowVersions,
  getWorkflowDocs,
  workflowRunsHandler,
  workflowRun,
];
