import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '@formbio/ui/components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@formbio/ui/components/Dialog';
import { DialogHeader } from '@formbio/ui/components/Dialog/DialogHeader';
import { InputLabel, styled } from '@formbio/ui/components';
import { DialogLinearProgress } from '@formbio/ui/components/LinearProgress';
import { TextField } from '@formbio/ui/components/TextField';
import { getOrgProjectValidator } from '@formbio/ui/utils/yupHelper';
import { Organization } from '@formbio/api';

type Props = {
  isLoading: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  organizations: Organization[] | undefined;
};

type FormData = {
  name: string;
};

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export default function ChangeOrgNameDialog({
  organizations,
  isLoading,
  open,
  onClose,
  onSubmit,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Same contraints as the project name.
  const schema = yup.object({
    name: getOrgProjectValidator(organizations),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const name = watch('name');

  const handleFormSubmit = (data: FormData) => {
    onSubmit(data.name);
    setIsSubmitting(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogHeader title='Change Organization Name' onClose={onClose} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <StyledInputLabel htmlFor='org-name'>
            New Organization Name
          </StyledInputLabel>
          <TextField
            fullWidth
            inputProps={{ id: 'org-name' }}
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name?.message}
            autoFocus
            placeholder='Type New Name...'
          />
          {isLoading && <DialogLinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isLoading || isSubmitting || !name || !!errors.name}
          >
            Rename
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
