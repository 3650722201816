import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiInputLabel: CustomComponent<'MuiInputLabel'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      color:
        theme.palette.mode === 'light'
          ? theme.palette.primary[900]
          : theme.palette.primary[50],
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 'bold',
    }),
  },
};
