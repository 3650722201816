import {
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

export type RadioProps = Omit<
  MuiRadioProps,
  | 'classes'
  | 'sx'
  | 'centerRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'onFocusVisible'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'LinkComponent'
  | 'action'
  | 'ref'
>;

export const Radio = ({ ...rest }: RadioProps) => <MuiRadio {...rest} />;
export const RadioGroup = ({ ...rest }: MuiRadioGroupProps) => (
  <MuiRadioGroup {...rest} />
);
