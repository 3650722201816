import { http, HttpResponse } from 'msw';
import { individualQuotas, teamQuotas, proQuotas } from './data/quotas';

const indiv = http.get('*/api/v1/orgs/:oid/account/quotas', async () => {
  return HttpResponse.json(individualQuotas);
});
const team = http.get('*/api/v1/orgs/:oid/account/quotas', async () => {
  return HttpResponse.json(teamQuotas);
});
const pro = http.get('*/api/v1/orgs/:oid/account/quotas', async () => {
  return HttpResponse.json(proQuotas);
});

export const accountIndividualHandlers = [indiv];
export const accountTeamHandlers = [team];
export const accountProHandlers = [pro];
