import { isAxiosError } from '@formbio/api';

const ERROR_SOMETHING_WENT_WRONG = 'Something went wrong';
const ERROR_NO_NETWORK =
  'You may be offline. Please check your internet connection and try again.';

export function parseNetworkError(error: unknown) {
  if (isAxiosError(error)) {
    if (error.code === 'ERR_NETWORK') {
      return ERROR_NO_NETWORK;
    }
    return error.response?.statusText || ERROR_SOMETHING_WENT_WRONG;
  }
  return `${ERROR_SOMETHING_WENT_WRONG}: ${String(error)}`;
}
