import { useQueryClient } from '@tanstack/react-query';

function useInvalidation(KEY: string) {
  const queryClient = useQueryClient();
  return (additionalKey?: Record<string, string>) =>
    queryClient.invalidateQueries({
      queryKey: additionalKey ? [KEY, additionalKey] : [KEY],
    });
}

export default useInvalidation;
