import { faker } from '@faker-js/faker';
import {
  DATACORE_ENTITY_SAMPLE,
  DatacorePayload,
  DatacoreRelationshipDataPayload,
} from '@formbio/api';
import { delay, http, HttpResponse, PathParams } from 'msw';
import { mutableSamples, updateMutableSamples } from './data/sample-sets';
import { createDatacoreSample, createDatacoreSamples } from './data/samples';

const allSamples = http.get(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE}`,
  async () => {
    return HttpResponse.json(createDatacoreSamples());
  },
);

const aSample = http.get<PathParams, DatacorePayload>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE}/:id`,
  async ({ params }) => {
    const id = params.id as string;
    const sample = mutableSamples.find(sample => sample.id === id);
    if (!sample) {
      return HttpResponse.json(
        {
          message: `Unhappy path triggered!`,
        },
        { status: 422 },
      );
    }
    return HttpResponse.json(createDatacoreSample(sample));
  },
);

const updateSample = http.post<PathParams, DatacorePayload>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE}/:id`,
  async ({ params, request }) => {
    const id = params.id as string;
    const payload = await request.json();
    const previousSample = mutableSamples.find(sample => sample.id === id);
    if (previousSample) {
      const updatedSample = {
        ...previousSample,
        name: payload.data.attributes.name as string,
      };
      updateMutableSamples([
        ...mutableSamples.filter(sample => sample.id !== id),
        updatedSample,
      ]);
      return HttpResponse.json({ data: { id: updatedSample.id } });
    }
  },
);

const createSample = http.post<PathParams, DatacorePayload>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE}`,
  async ({ request }) => {
    const payload = await request.json();
    const newId = faker.string.uuid();
    const newSample = {
      id: newId,
      frn: `frn://datacore/organization/[orgId]/projects/[projId]/entities/${DATACORE_ENTITY_SAMPLE}/${newId}`,
      createdAt: new Date().toISOString(),
      name: payload.data.attributes.name as string,
    };
    updateMutableSamples([...mutableSamples, newSample]);
    await delay(faker.number.int({ min: 500, max: 2500 }));
    if (payload.data.attributes.name === 'error.fasta')
      return HttpResponse.json(
        {
          message: `Invalid fasta file`,
        },
        { status: 422 },
      );
    return HttpResponse.json({ data: { id: newSample.id } });
  },
);

const updateSampleFile = http.post<PathParams, DatacoreRelationshipDataPayload>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE}/:id/relationships/data`,
  async ({ params, request }) => {
    const id = params.id as string;
    const payload = await request.json();
    const previousSample = mutableSamples.find(sample => sample.id === id);
    if (previousSample) {
      const updatedSample = {
        ...previousSample,
        filePath: payload.data.attributes.data.file as string,
        fileSize: faker.number.int({ min: 100, max: 50000 }),
      };
      updateMutableSamples([
        ...mutableSamples.filter(sample => sample.id !== id),
        updatedSample,
      ]);
      return HttpResponse.json({ data: { id: updatedSample.id } });
    }
  },
);

export const sampleHandlers = [
  allSamples,
  aSample,
  updateSample,
  createSample,
  updateSampleFile,
];
