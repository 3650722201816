import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

type DocsCtx = {
  docs: string;
  setDocs: Dispatch<SetStateAction<string>>;
  clearDocs: () => void;
};

const DEFAULT_DOCS = 'https://docs.formbio.com/';

const DocsContext = createContext<DocsCtx>({} as DocsCtx);

export function DocsContextProvider({ children }: PropsWithChildren) {
  const [docs, setDocs] = useState<string>(DEFAULT_DOCS);

  function clearDocs() {
    setDocs(DEFAULT_DOCS);
  }

  return (
    <DocsContext.Provider value={{ docs, setDocs, clearDocs }}>
      {children}
    </DocsContext.Provider>
  );
}

export function useDocs() {
  const context = useContext(DocsContext);
  if (context) {
    return context;
  }
  throw new Error('useDocs must be within DocsContextProvider');
}
