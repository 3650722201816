import {
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
} from '@mui/material';

export type SnackbarProps = Omit<MuiSnackbarProps, 'sx'>;

export const Snackbar = ({ ...rest }: SnackbarProps) => (
  <MuiSnackbar {...rest} />
);
