import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
  ToggleButtonProps as MuiToggleButtonProps,
} from '@mui/material';

export type TooltipProps = Omit<MuiTooltipProps, 'sx'>;
export type ToggleButtonProps = Omit<MuiToggleButtonProps, 'sx'>;

export const Tooltip = ({ ...rest }: MuiTooltipProps) => (
  <MuiTooltip {...rest} />
);
export { tooltipClasses };
