import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiAlert: CustomComponent<'MuiAlert'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRight: 0,
      borderLeft: 0,
      borderBottom: 0,
      borderTopWidth: 4,
      borderTopStyle: 'solid',
      backgroundColor: theme.palette.primary[100],
      padding: theme.spacing(2),
      minWidth: 400,
      '& .MuiAlertTitle-root': {
        ...theme.typography.body1,
        fontWeight: 600,
        padding: 0,
        color: theme.palette.primary[900],
        margin: 0,
      },
      '& > .MuiButton-root.MuiButton-text': {
        padding: 0,
        color: theme.palette.primary[500],
      },
      '& .MuiLink-root.MuiLink-underlineHover': {
        fontWeight: 600,
        color: theme.palette.info[500],
      },
      '& .MuiSvgIcon-root': {
        alignSelf: 'center',
        fontSize: theme.typography.body1?.fontSize,
      },
    }),
    outlined: {
      boxShadow: '0px 8px 16px rgba(12, 18, 69, 0.08)',
    },
    message: ({ theme }) => ({
      ...theme.typography.body1,
      padding: 0,
      fontWeight: 500,
      color: theme.palette.primary[700],
    }),
    standardSuccess: ({ theme }) => ({
      backgroundColor: theme.palette.success[100],
      borderTopColor: theme.palette.success[500],
      '& .MuiAlert-icon': {
        color: theme.palette.success[500],
      },
    }),
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.error[100],
      borderTopColor: theme.palette.error[500],
      '& .MuiAlert-icon': {
        color: theme.palette.error[500],
      },
    }),
    standardWarning: ({ theme }) => ({
      backgroundColor: theme.palette.warning[100],
      borderTopColor: theme.palette.warning[500],
      '& .MuiAlert-icon': {
        color: theme.palette.warning[500],
      },
    }),
    standardInfo: ({ theme }) => ({
      backgroundColor: theme.palette.info[100],
      borderTopColor: theme.palette.info[500],
      '& .MuiAlert-icon': {
        color: theme.palette.info[500],
      },
    }),
    outlinedSuccess: ({ theme }) => ({
      borderTopColor: theme.palette.success[500],
      '& .MuiAlert-icon': {
        color: theme.palette.success[500],
      },
    }),
    outlinedError: ({ theme }) => ({
      borderTopColor: theme.palette.error[500],
      '& .MuiAlert-icon': {
        color: theme.palette.error[500],
      },
    }),
    outlinedWarning: ({ theme }) => ({
      borderTopColor: theme.palette.warning[500],
      '& .MuiAlert-icon': {
        color: theme.palette.warning[500],
      },
    }),
    outlinedInfo: ({ theme }) => ({
      borderTopColor: theme.palette.info[500],
      '& .MuiAlert-icon': {
        color: theme.palette.info[500],
      },
    }),
    icon: ({ theme }) => ({
      padding: theme.spacing(0.5, 0, 0, 0),
      alignItems: 'flex-start',
      flexDirection: 'column',
    }),
    action: ({ theme }) => ({
      padding: theme.spacing(0, 0, 0, 1),
    }),
  },
};
