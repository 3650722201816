import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  getGitHubImportableWorkflows,
  getGitHubInstallations,
  getGitHubInstalledRepos,
  getGithubAuth,
  importGitHubRepo,
  setGitHubAuth,
} from '../../services/github-auth';
import {
  GitHubAuthResponse,
  GitHubImportConfig,
  GitHubImportRepoParams,
  GitHubImportResponse,
  GitHubImportableWorkflow,
  GitHubInstallation,
  GitHubInstalledRepo,
  SetGitHubAuth,
} from '../../types/github-auth';
import useInvalidation from '../useInvalidation';
import useSetQueryData from '../useSetQueryData';
import { WF_VERSIONS_KEY, WF_VERSIONS_MANAGER_KEY } from '../workflow-versions';

const AUTH_KEY = 'github-auth';

export function useGetGitHubAuth(
  options: Omit<
    UseQueryOptions<GitHubAuthResponse>,
    'queryKey' | 'queryFn'
  > = {},
) {
  return useQuery<GitHubAuthResponse>({
    queryKey: [AUTH_KEY],
    queryFn: getGithubAuth,
    ...options,
  });
}

export function useSetGitHubAuth(
  options: UseMutationOptions<GitHubAuthResponse, unknown, SetGitHubAuth> = {},
) {
  const setAuth = useSetQueryData(AUTH_KEY);

  return useMutation<GitHubAuthResponse, unknown, SetGitHubAuth>({
    mutationKey: [AUTH_KEY],
    mutationFn: setGitHubAuth,
    ...{
      ...options,
      onSuccess: (data) => setAuth(data),
    },
  });
}

const INSTALLATIONS_KEY = 'github-installations';

export function useGitHubInstallations(
  options?: UseQueryOptions<GitHubInstallation[]>,
) {
  return useQuery<GitHubInstallation[]>({
    queryKey: [INSTALLATIONS_KEY],
    queryFn: getGitHubInstallations,
    ...options,
  });
}

const INSTALLED_REPOS_KEY = 'github-installed-repos';

export function useGitHubInstalledRepos(
  orgId: string,
  pid: string,
  installationId: string,
  options?: UseQueryOptions<GitHubInstalledRepo[]>,
) {
  return useQuery<GitHubInstalledRepo[]>({
    queryKey: [INSTALLED_REPOS_KEY, { orgId, pid, installationId }],
    queryFn: () => getGitHubInstalledRepos(orgId, pid, installationId),
    ...{ enabled: !!installationId, ...options },
  });
}

export function useImportGitHubWorkflow(
  params: Omit<GitHubImportRepoParams, 'config'>,
  options?: UseMutationOptions<
    GitHubImportResponse,
    unknown,
    GitHubImportConfig
  >,
) {
  const { orgId: oid, pid } = params;
  const invalidateWorkflowVersions = useInvalidation(WF_VERSIONS_KEY);
  const invalidateWorkflowVersionsManager = useInvalidation(
    WF_VERSIONS_MANAGER_KEY,
  );

  return useMutation({
    mutationKey: [INSTALLED_REPOS_KEY, params],
    mutationFn: (config: GitHubImportConfig) =>
      importGitHubRepo({ ...params, config }),
    ...{
      ...options,
      onSuccess: ({ workflowId }: GitHubImportResponse) => {
        invalidateWorkflowVersions({ oid, pid });
        invalidateWorkflowVersionsManager({ oid, pid });
        invalidateWorkflowVersionsManager({ oid, pid, workflowId });
      },
    },
  });
}

const IMPORTABLE_WORKFLOWS_KEY = 'importable-workflows';

export function useGitHubImportableWorkflows(
  params: {
    orgId: string;
    pid: string;
    installationId: string;
    repoId: string;
  },
  options: Omit<
    UseQueryOptions<GitHubImportableWorkflow[]>,
    'queryKey' | 'queryFn'
  > = {},
) {
  return useQuery<GitHubImportableWorkflow[]>({
    queryKey: [IMPORTABLE_WORKFLOWS_KEY, params],
    queryFn: () => getGitHubImportableWorkflows(params),
    ...{ enabled: !!params.installationId && !!params.repoId, ...options },
  });
}
