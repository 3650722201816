import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CircleLoader(props: SvgIconProps) {
  return (
    <SvgIcon width='40' height='40' viewBox='0 0 40 40' {...props}>
      <path
        d='M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM4 20C4 28.8366 11.1634 36 20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20Z'
        fill='currentColor'
      />
      <path
        d='M20 0C22.6264 3.132e-08 25.2272 0.517315 27.6537 1.52241C30.0802 2.5275 32.285 4.00069 34.1421 5.85787C35.9993 7.71504 37.4725 9.91982 38.4776 12.3463C39.4827 14.7728 40 17.3736 40 20L36 20C36 17.8989 35.5861 15.8183 34.7821 13.8771C33.978 11.9359 32.7994 10.172 31.3137 8.68629C29.828 7.20055 28.0641 6.022 26.1229 5.21793C24.1817 4.41385 22.1012 4 20 4V0Z'
        fill='currentColor'
      >
        <animateTransform
          attributeName='transform'
          attributeType='XML'
          type='rotate'
          dur='750ms'
          from='0 20 20'
          to='360 20 20'
          repeatCount='indefinite'
        />
      </path>
    </SvgIcon>
  );
}
