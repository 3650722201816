import * as Sentry from '@sentry/nextjs';

type CaptureErrorParams = {
  error: Parameters<typeof Sentry.captureException>[0];
  context?: Parameters<typeof Sentry.setContext>[1];
  name?: string;
};

export function captureError({ error, name, context }: CaptureErrorParams) {
  Sentry.withScope(scope => {
    if (name) {
      scope.setTransactionName(name);
    }
    const traceId = error.response?.headers?.['x-cloud-trace-context'];
    if (traceId) {
      scope.setContext('x-cloud-trace-context', {
        id: traceId,
      });
    }
    if (context) {
      // Adds extra context to the Sentry UI. https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
      scope.setContext('extra', context);
    }

    Sentry.captureException(error);
  });
}

type SetUserParams = Parameters<typeof Sentry.setUser>[0];

export function setUser(user: SetUserParams) {
  Sentry.setUser(user);
}
