import { useRouter } from 'next/router';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { MultiFactorError } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { useMFAResolver } from '@/hooks/auth';
import { useAuth } from '@/hooks/auth';
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Spacer,
  styled,
  FormInputItem,
  AuthErrorMessage,
} from '@formbio/ui';
import { captureError } from '@/utils/logging';
import { navRoutes } from '@/utils/routes';
import { AuthUser } from '@/context/auth';

const StyledDialogContent = styled(DialogContent)({
  maxWidth: 500,
});

const schema = yup
  .object({
    code: yup
      .string()
      .required('Verification Code is required')
      .test(
        'len',
        'Must be exactly 6 digits (ex. 101010)',
        val => val.length === 6,
      ),
  })
  .required();

type FormData = {
  code: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  preventOnClose?: boolean;
  onSuccess: (user: AuthUser) => void;
  raisedError: FirebaseError | undefined;
  logoutButton?: boolean;
};

const MFACodeDialog = ({
  open = false,
  onClose,
  preventOnClose = false,
  onSuccess,
  raisedError,
  logoutButton = false,
}: Props) => {
  const router = useRouter();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { user } = useAuth();
  const {
    request: verifyCode,
    error: mfaCodeError,
    loading: isVerificationLoading,
  } = useMFAResolver();

  const onSubmit = async ({ code }: FormData) => {
    try {
      const user = await verifyCode({
        code: code,
        mfaError: raisedError as MultiFactorError,
      });

      if (!isVerificationLoading && user && !mfaCodeError) {
        onSuccess(user);
        onClose();
      }
    } catch (error) {
      captureError({
        name: 'useReAuthentication hook error',
        error: error,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => (preventOnClose ? undefined : onClose())}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogHeader title='Verify your identity' />
        <StyledDialogContent>
          <Typography>
            Use your authenticator app to generate the 6-digit verification
            code.
          </Typography>
          <Spacer size='xl' />
          <input
            aria-hidden='true'
            type='hidden'
            defaultValue={user?.email || ''}
            autoComplete='username'
            autoCapitalize='off'
            name='username'
            disabled
          />
          <FormInputItem
            label='Verification Code'
            placeholder='000000'
            registerProps={register('code')}
            errorMessage={errors.code?.message}
            required
            inputProps={{
              maxLength: 6,
              autoFocus: true,
              inputMode: 'numeric',
              autoComplete: 'one-time-code',
              pattern: '[0–9]*',
            }}
            disabled={isVerificationLoading}
          />

          {mfaCodeError && <AuthErrorMessage error={mfaCodeError} />}
        </StyledDialogContent>
        <DialogActions>
          {logoutButton && (
            <Button
              variant='outlined'
              onClick={() => {
                onClose();
                router.push(navRoutes.logout.pathname);
              }}
            >
              Back to Sign In
            </Button>
          )}
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isSubmitting || isVerificationLoading}
          >
            Verify
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MFACodeDialog;
