import { Typography } from '@formbio/ui/components/Typography';
import { AuthError, AuthErrorCodes } from 'firebase/auth';
import { useMemo } from 'react';

export default function ErrorMessage({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Typography color='error.main'>{children}</Typography>;
}

export function AuthErrorMessage({ error }: { error: AuthError | Error }) {
  const code = 'code' in error && error.code;

  const message = useMemo(() => {
    switch (code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        return 'Email is already in use';
      case AuthErrorCodes.INVALID_EMAIL:
        return 'Email is invalid';
      case AuthErrorCodes.WEAK_PASSWORD:
        return 'Password is too weak';
      case AuthErrorCodes.USER_DELETED:
        return 'Email was not found';
      case AuthErrorCodes.INVALID_PASSWORD:
        return 'Password is invalid';
      case AuthErrorCodes.USER_DISABLED:
        return 'User is disabled';
      case AuthErrorCodes.INVALID_OOB_CODE:
        return 'Code has expired';
      case AuthErrorCodes.MFA_REQUIRED:
        return 'Multi-Factor Authentication (MFA) is required';
      case AuthErrorCodes.EXPIRED_OOB_CODE:
      case AuthErrorCodes.INVALID_CODE:
      case 'auth/invalid-mfa-enrollment-id':
        return 'Code is invalid';
      case AuthErrorCodes.TOKEN_EXPIRED:
        return 'Session token has expired';
      case 'auth/totp-challenge-timeout':
        return 'Session has expired. Please provide your email and password to sign in again.';
      default:
        return 'Something went wrong';
    }
  }, [error]);

  return <ErrorMessage>{message}</ErrorMessage>;
}
