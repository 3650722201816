import { Note, Notes, NoteStatus } from '../../types/note';
import { api } from '../../utils/api';

export type CreateNoteData = Omit<
  Note,
  'id' | 'createdAt' | 'actor' | 'publishedAt'
>;

export async function fetchAll(params?: Partial<Note>): Promise<Notes> {
  return api.get<Notes>(`/notes`, { params }).then(({ data }) => data);
}

// same endpoint as fetchAll, but only returning the draft note
// there should only be one draft note at a time
// can't return undefined so returning null instead
export async function fetchDraft(): Promise<Note> {
  return api
    .get<Notes>(`/notes`, { params: { status: NoteStatus.DRAFT } })
    .then(({ data }) => {
      return (data?.[0] && { ...data[0], text: data[0]?.text || '' }) || null;
    });
}

export function removeNote(id: string) {
  return api.delete<Note>(`/notes/${id}`).then(({ data }) => data);
}

export function addNote(data: CreateNoteData) {
  return api.post(`/notes`, data);
}

export function updateNote(data: Note): Promise<Note> {
  return api.patch<Note>(`/notes/${data.id}`, data).then(({ data }) => data);
}
