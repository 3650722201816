import { WorkflowSchemaV2, WorkflowSchemaV3 } from '@formbio/workflow-schema';
import { WorkflowVersionOverrides } from './workflow';

export enum WorkflowSchemaStatus {
  Ready = 'READY',
  Draft = 'DRAFT',
}

export enum WorkflowSchemaVisibility {
  Organization = 'ORGANIZATION',
  Project = 'PROJECT',
  Public = 'PUBLIC',
}

export interface WorkflowVersion {
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  org: string; // UUID
  project: string; // UUID
  repoName: string;
  workflowId: string; // UUID
  workflowStatus: WorkflowSchemaStatus;
  workflowVersion: string;
  workflowVisibility: WorkflowSchemaVisibility;
  workflowName: string;
  workflowSchema?: WorkflowSchemaV2 | WorkflowSchemaV3;
  updatedAt: string;
  vizappExist: boolean;
  workflowVersionId: string;
  workflowImportId?: string;
  workflowVersionOverrides: WorkflowVersionOverrides;
}

export interface AdminWorkflowVersion extends WorkflowVersion {
  orgName: string;
  projectName: string;
}
