import { ReactNode } from 'react';
import { Typography } from '@formbio/ui/components/Typography';
import { Button } from '@formbio/ui/components/Button';
import { Container, styled } from '@formbio/ui/components';
import { Plugs as PlugsIcon } from '@formbio/ui/components/Icons';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  '.content': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '.back-button': {
      marginTop: theme.spacing(2),
    },
    '.large': {
      color: theme.palette.primary[400],
      width: '100px',
      lineHeight: '150px',
      paddingBottom: theme.spacing(3),
    },
  },
}));

function handleReload() {
  window.location.reload();
}

export default function ErrorPrompt({ children }: { children?: ReactNode }) {
  return (
    <StyledContainer>
      <div className='content'>
        <>
          <PlugsIcon className='large' />
          <Typography variant='h2'>We are having some difficulties.</Typography>
          <Typography variant='body1'>{children}</Typography>
          <Typography variant='body1'>
            Try reloading the page in a bit.
          </Typography>
        </>
        <div className='back-button'>
          <Button variant='contained' color='primary' onClick={handleReload}>
            Reload
          </Button>
        </div>
      </div>
    </StyledContainer>
  );
}
