import { useAuth } from '@/hooks/auth';
import { useGetGitHubAuth, useSetGitHubAuth } from '@formbio/api';
import {
  GitHubAuthResponse,
  SetGitHubAuth,
} from '@formbio/api/src/types/github-auth';
import { featureFlagIds } from '@/config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode, createContext, useContext } from 'react';

interface GitHubAuthCtxValue {
  isAuthed: boolean | undefined;
  authGitHubUser: (payload: SetGitHubAuth) => Promise<GitHubAuthResponse>;
  loading: boolean;
}

const GitHubAuthCtx = createContext<GitHubAuthCtxValue | undefined>(undefined);

export function GitHubAuthCtxProvider({ children }: { children: ReactNode }) {
  const flags = useFlags();
  const { user } = useAuth();
  const enableGithubApp = flags[featureFlagIds.enableGithubApp];

  const { data, isLoading: loading } = useGetGitHubAuth({
    enabled: !!enableGithubApp && !!user,
  });

  const { mutateAsync: authGitHubUser } = useSetGitHubAuth();

  return (
    <GitHubAuthCtx.Provider
      value={{
        isAuthed: data?.authorized,
        authGitHubUser,
        loading,
      }}
    >
      {children}
    </GitHubAuthCtx.Provider>
  );
}

export function useGitHubAuthCtx() {
  const context = useContext(GitHubAuthCtx);
  if (context) {
    return context;
  }
  throw new Error('useGitHubAuth must be within GitHubAuthCtxProvider');
}
