import {
  AuthError,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';

export function useResetPassword(code?: string) {
  const { auth } = useAuth();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (code) {
      setLoading(true);

      verifyPasswordResetCode(auth, code)
        .then(email => setEmail(email))
        .catch(error => setError(error as AuthError))
        .finally(() => setLoading(false));
    }
  }, [code]);

  const request = async (newPassword: string) => {
    if (!email || !code) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      await confirmPasswordReset(auth, code, newPassword);
      setSuccess(true);
    } catch (error) {
      setError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  return { request, loading, error, success, email };
}
