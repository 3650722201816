import { Note, NoteStatus } from '@formbio/api';
import { ActorType } from '@formbio/api/src/types/actor';

const demoDraftNote: Note = {
  id: 'test-response-draft0',
  createdAt: '2022-11-30T11:32:02.496028Z',
  text: 'The state of my draft note',
  actor: {
    type: ActorType.USER,
    id: 'XXXXX',
    name: 'John Doe',
  },
  email: 'john.doe@formbio.com',
  page: {
    path: '/organizations/fake-organization/projects/fake-project/workflow-runs/ffffff-a420-41b2-93bb-38fff3b3fff/?previousPageParams=%7B%22orgId%22%3A%22fake-organization%22%2C%22pid%22%3A%22fake-project%22%2C%22status%22%3A%22%22%2C%22users%22%3A%22%22%7D',
    title: 'Workflow Activity - FormBio',
  },
  contextEntities: [
    {
      type: 'orgId',
      id: 'fake-organization',
      displayName: 'Fake Organization',
    },
    {
      type: 'pid',
      id: 'fake-project',
      displayName: 'Fake Project',
    },
    {
      type: 'runId',
      id: 'ffffff-a420-41b2-93bb-38fff3b3fff',
      displayName: 'Fake Workflow',
    },
    {
      type: 'previousPageParams',
      id: '{"orgId":"fake-organization","pid":"fake-project","status":"","users":""}',
    },
    {
      type: 'pathname',
      id: '/organizations/[orgId]/projects/[pid]/workflow-runs/[runId]',
    },
  ],
  status: NoteStatus.DRAFT,
};

export default demoDraftNote;
