import {
  ProcessExecution,
  ProgramSpreadsheetJSON,
  SeqVizJson,
} from '../../types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  fetchAllByNamespace,
  fetchOneByNamespace,
} from '../../services/project-bucket-data';

const KEY = 'programs';
const PROGRAMS_NAMESPACE = 'programs';
export const VECTORS_NAMESPACE = 'vectors';
const JSON_SOURCE = 'source';

export function usePrograms({
  oid,
  pid,
  options = {},
}: {
  oid: string;
  pid: string;
  options?: Omit<UseQueryOptions<ProcessExecution[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<ProcessExecution[]>({
    queryKey: [KEY, { oid, pid }],
    queryFn: () => {
      return fetchAllByNamespace({ oid, pid, namespace: PROGRAMS_NAMESPACE });
    },
    ...{
      enabled: !!oid && !!pid,
      ...options,
    },
  });
}

export function useProgram({
  oid,
  pid,
  indicationId,
  options = {},
}: {
  oid: string;
  pid: string;
  indicationId: string;
  options?: Omit<UseQueryOptions<ProcessExecution>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<ProcessExecution>({
    queryKey: [KEY, { oid, pid, indicationId }],
    queryFn: () => {
      return fetchOneByNamespace({
        oid,
        pid,
        documentId: indicationId,
        namespace: PROGRAMS_NAMESPACE,
      });
    },
    ...{
      enabled: !!oid && !!pid && !!indicationId,
      ...options,
    },
  });
}

export function useProgramsFromCSV({
  oid,
  pid,
  disabled,
  options = {},
}: {
  oid: string;
  pid: string;
  disabled?: boolean;
  options?: Omit<
    UseQueryOptions<ProgramSpreadsheetJSON[]>,
    'queryKey' | 'queryFn'
  >;
}) {
  return useQuery<ProgramSpreadsheetJSON[]>({
    queryKey: [KEY, { oid, pid, JSON_SOURCE }],
    queryFn: () => {
      return fetchOneByNamespace({
        oid,
        pid,
        documentId: JSON_SOURCE,
        namespace: PROGRAMS_NAMESPACE,
      });
    },
    ...{
      enabled: !!oid && !!pid && !disabled,
      ...options,
    },
  });
}

export function useVectorVizFromFileSystem({
  oid,
  pid,
  filepath,
  options = {},
}: {
  oid: string;
  pid: string;
  filepath: string;
  options?: Omit<UseQueryOptions<SeqVizJson>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<SeqVizJson>({
    queryKey: [KEY, { oid, pid, filepath }],
    queryFn: () => {
      return fetchOneByNamespace({
        oid,
        pid,
        documentId: filepath,
        namespace: VECTORS_NAMESPACE,
      });
    },
    ...{
      enabled: !!oid && !!pid,
      ...options,
    },
  });
}
