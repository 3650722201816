import { ProcessExecution } from '../../types';
import {
  fetchAllByNamespace,
  fetchOneByNamespace,
} from '../project-bucket-data';

const executionNamespace = (slug: string) => `processes/${slug}/executions`;

export function fetchAllByProcess({
  oid,
  pid,
  slug,
}: {
  oid: string;
  pid: string;
  slug: string;
}) {
  // TODO: Enable when the API is ready
  // return api
  //   .get<ProcessExecution[]>(`/orgs/${oid}/${pid}/process/${slug}/executions`)
  //   .then(({ data }) => data);
  return fetchAllByNamespace<ProcessExecution>({
    pid,
    oid,
    namespace: executionNamespace(slug),
  });
}

export function fetchOneByProcessAndId({
  oid,
  pid,
  processSlug,
  executionId,
}: {
  oid: string;
  pid: string;
  processSlug: string;
  executionId: string;
}) {
  // TODO: Enable when the API is ready
  // return api
  //   .get<ProcessExecution>(`/orgs/${oid}/${pid}/process/${slug}/executions/${executionId}`)
  //   .then(({ data }) => data);
  return fetchOneByNamespace<ProcessExecution>({
    pid,
    oid,
    namespace: executionNamespace(processSlug),
    documentId: executionId,
  });
}

// export function createOne({ slug }: { slug: string }) {}
