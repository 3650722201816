import { api } from '../../utils/api';
import {
  PasswordRequest,
  EmailVerificationRequest,
  EmailVerificationResponse,
} from '../../types/user';

export function passwordResetLink(payload: PasswordRequest) {
  return api
    .post(`/public/auth/password_reset`, payload)
    .then(({ data }) => data);
}

export function sendVerificationEmail(
  payload: EmailVerificationRequest,
): Promise<EmailVerificationResponse> {
  return api.post(`/user/verify`, payload).then(({ status, data }) => ({
    status: status,
    data: data,
  }));
}
