import { Stack, InputLabel, FormControl, styled } from '@formbio/ui/components';
import { Button } from '@formbio/ui/components/Button';
import { MenuItem } from '@formbio/ui/components/Menu';
import { TextField } from '@formbio/ui/components/TextField';
import { Typography } from '@formbio/ui/components/Typography';

import { CaretDownFilledBackgroundRoundedIcon } from '@formbio/ui/components/Icons';

import { Role, AwaitingInvitee } from '@formbio/ui/types/member';

import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getEmailValidator } from '@formbio/ui/utils/yupHelper';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'end',
  marginBottom: theme.spacing(2),
  '.small-select .MuiOutlinedInput-root .MuiSelect-select': {
    padding: theme.spacing(1, 1.5),
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minWidth: 'unset',
  '.item-text': {
    paddingRight: theme.spacing(8),
    textTransform: 'capitalize',
    color: 'inherit',
  },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(8),
  textTransform: 'capitalize',
  color: 'inherit',
}));

const schema = yup
  .object({
    email: getEmailValidator(
      'Example of a valid email: myemail@domain.com',
    ).required('Please enter an email address'),
    // role: yup.string().required(),
    role: yup.mixed<Role>().oneOf(Object.values(Role)).required(),
  })
  .required();

type Props = {
  onSubmit: (awaitingInvitee: AwaitingInvitee) => Promise<void>;
  isLoading: boolean;
  roles: Role[];
};

function AddMemberInviteForm({ roles, isLoading, ...props }: Props) {
  const {
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<AwaitingInvitee>({
    resolver: yupResolver(schema),
    defaultValues: {
      role: roles[0],
    },
  });

  const onChange = () => {
    clearErrors();
  };

  const onSubmit = async (data: AwaitingInvitee) => {
    clearErrors();

    try {
      await props.onSubmit(data);
      reset();
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(
          'email',
          { type: 'focus', message: error.message },
          { shouldFocus: true },
        );
      } else {
        setError(
          'email',
          { type: 'focus', message: 'Something went wrong' },
          { shouldFocus: true },
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <StyledContainer>
        <Stack spacing={1} flex={1}>
          <InputLabel htmlFor='input-email-invite-member'>
            Invite Member by Email
          </InputLabel>
          <TextField
            id='input-email-invite-member'
            placeholder='Email address'
            size='small'
            type='email'
            required
            disabled={isLoading}
            {...register('email', {
              onChange,
            })}
          />
        </Stack>
        <Stack spacing={1}>
          <InputLabel id='role-label'>Role</InputLabel>
          <FormControl>
            <Controller
              control={control}
              name='role'
              render={({ field }) => (
                <TextField
                  className='small-select'
                  select
                  aria-labelledby='role-label'
                  disabled={isLoading}
                  SelectProps={{
                    IconComponent: CaretDownFilledBackgroundRoundedIcon,
                  }}
                  inputProps={{
                    'data-cy': `select-role-invite-member`,
                  }}
                  {...field}
                >
                  {roles.map(role => (
                    <StyledMenuItem
                      value={role}
                      key={role}
                      data-cy={`select-option-${role}`}
                    >
                      <StyledTypography>{role}</StyledTypography>
                    </StyledMenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
        </Stack>
        <Button
          id='btn-add-invitee'
          type='submit'
          variant='outlined'
          color='primary'
          disabled={isLoading}
        >
          Add
        </Button>
      </StyledContainer>
      {errors.email?.message && (
        <Typography id='dialog-add-members-errors' color='error'>
          {errors.email?.message}
        </Typography>
      )}
    </form>
  );
}

export default AddMemberInviteForm;
