import { WorkflowSchemaV3 } from '@formbio/workflow-schema';
import { AnnotationProp } from 'seqviz/dist/elements';

export type GroupMethod = {
  id: string;
  title: string;
  description: string;
  path: string;
};

// Need to move away from enums for id's.
// Realistically that's not a practice we can or should implement outside design-mode.
export enum ProgramCheckpointId {
  Design = 'DESIGN',
  Optimize = 'OPTIMIZE',
  InVitro = 'IN VITRO',
  InVivo = 'IN VIVO',
  Manufacturing = 'MANUFACTURING',
}

export type ProgramCheckpoint = {
  id: ProgramCheckpointId;
  workflowSchema: WorkflowSchemaV3;
  // maps by the input group id to a path on the platform
  groupMethods: {
    [key: string]: GroupMethod[];
  };
  label?: VectorLabelValues;
};

export enum VectorStatusValues {
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum VectorLabelValues {
  LEAD = 'LEAD',
  CONTENDER = 'CONTENDER',
  PAUSED = 'PAUSED',
  NO_LABEL = 'NO LABEL',
}

// We should have a mapping from a Construct object to a specific VectorMap file
// and/or a way to request that from the go-api.
// This enum only serves to map hardcoded Vectors to hardcode VectorMaps
export enum VectorMapNames {
  aMHC_GFP,
  CAG_GFP,
  CMV_GFP,
  cTNT_GFP,
  EF1a_GFP,
  MCK_GFP,
  EMPTY,
}

export type Vector = {
  id: string;
  title: string;
  description: string;
  checkpoints: ProgramCheckpoint[];
  // this is temporary until we have a better way to track status
  status: VectorStatusValues;
  mapName: VectorMapNames;
  annotatedFile?: string;
  createdAt: string;
  updatedAt: string;
  currentStateIdx: number;
  isLead?: boolean;
  isContender?: boolean;
  isPaused?: boolean;
  metadata?: {
    [key: string]: string;
  };
  run?: {
    id: string;
    keyResults: {
      [key: string]: string;
    };
  };
};

export enum ProgramType {
  Neuromuscular = 'Neuromuscular',
  Cardiac = 'Cardiac',
}

export type ProcessExecution = {
  id: string;
  title: string;
  description: string;
  goi: string;
  vectors: Vector[];
  type: ProgramType;
};

export type ProgramSpreadsheetJSON = {
  runId: string;
  goi: string;
  vectorDesignSequence: string;
  vectorFastaSequence: string;
  ['Form Bio Annotated Vector Sequence']: string;
  [key: string]: string;
};

export type FeatureGroupType = {
  id: string;
  name: string;
};

export interface SeqVizAnnotation extends AnnotationProp {
  type?: string;
  annotationName?: string;
  id?: string;
  group?: FeatureGroupType;
  notes?: Record<string, string[]>;
  locations?: Array<{ start: number; end: number }>;
}

export type SeqVizJson = {
  name: string;
  seq: string;
  annotations: SeqVizAnnotation[];
};

export enum FeatureGroup {
  Original = 'original',
  Custom = 'custom',
}

export type FormFeatureProps = {
  name: string;
  color: string;
  type: string;
  range: number[];
  group: string;
};
