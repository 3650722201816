import { CreateNoteData } from '@formbio/api/src/services/notes';
import { ActorType } from '@formbio/api/src/types/actor';
import { Notes, Note, NoteStatus } from '@formbio/api/src/types/note';
import { http, HttpResponse, delay, PathParams } from 'msw';
import demoDraftNote from './data/draft-note';
import demoNotes from './data/notes';

// crude way of simulating a database
let mutableNotes: Notes = JSON.parse(
  JSON.stringify([...demoNotes, demoDraftNote]),
);
let mutableDraftNote: Note | undefined = JSON.parse(
  JSON.stringify(demoDraftNote),
);

function filterNotes(params: Partial<CreateNoteData>) {
  const textItems = params.text?.split(' ') || [];
  return mutableNotes.filter(note => {
    if (params.text) {
      const noteText = note.text.toLowerCase();
      return textItems.every(
        item => noteText.includes(item) && note.status !== NoteStatus.DRAFT,
      );
    }
    return note.status !== NoteStatus.DRAFT;
  });
}

const allNotes = http.get('*/api/v1/notes', async ({ request }) => {
  const url = new URL(request.url);
  const text = url.searchParams.get('text') || '';
  const status = url.searchParams.get('status') || '';
  if (status === NoteStatus.DRAFT) {
    return HttpResponse.json([mutableDraftNote]);
  }
  await delay(500);
  return HttpResponse.json(filterNotes({ text: text.toLowerCase() }), {});
});

const aNote = http.get('*/api/v1/notes/:nid', ({ params }) => {
  const nid = params.nid as string;
  return HttpResponse.json(mutableNotes.filter(note => note.id === nid)[0]);
});

const deleteNote = http.delete<PathParams, Note | { errorMessage: string }>(
  '*/api/v1/notes/:nid',
  ({ params }) => {
    const nid = params.nid as string;
    const previousNote = mutableNotes.find(note => note.id === nid);
    if (previousNote) {
      const deletedNote = {
        ...previousNote,
        deletedAt: new Date().toISOString(),
      };
      mutableNotes = [
        ...mutableNotes.filter(note => note.id !== deletedNote.id),
        deletedNote,
      ];
      return HttpResponse.json(deletedNote);
    }
    return HttpResponse.json(
      {
        errorMessage: `Note '${nid}' not found`,
      },
      { status: 403 },
    );
  },
);

// remove this function when the API is ready
function createMockNote(data: CreateNoteData): Note {
  return {
    ...data,
    id: `test-response-added${mutableNotes.length + 1}`,
    createdAt: new Date().toISOString(),
    actor: {
      type: ActorType.USER,
      id: 'XXXXX',
      name: 'John Doe',
    },
    email: 'john.doe@formbio.com',
  };
}

const addNote = http.post<PathParams, CreateNoteData>(
  '*/api/v1/notes',
  async ({ request }) => {
    const data = await request.json();
    const newNote = createMockNote(data);
    mutableNotes = [...mutableNotes, newNote];
    mutableDraftNote = data.status === NoteStatus.DRAFT ? newNote : undefined;
    await delay(2000);
    return HttpResponse.json(mutableDraftNote);
  },
);

const updateNote = http.patch<PathParams, Note>(
  '*/api/v1/notes/:nid',
  async ({ params, request }) => {
    const { nid } = params;
    const data = await request.json();
    const previousNote = mutableNotes.filter(note => note.id === nid)[0];
    const isForPublish =
      data.status === NoteStatus.PUBLISHED &&
      previousNote.status === NoteStatus.DRAFT;
    if (previousNote) {
      const updatedNote = {
        ...previousNote,
        ...data,
        editedAt: isForPublish ? undefined : new Date().toISOString(),
        publishedAt: isForPublish ? new Date().toISOString() : undefined,
      };
      mutableNotes = [
        ...mutableNotes.filter(note => note.id !== nid),
        updatedNote,
      ];
      mutableDraftNote = isForPublish ? undefined : updatedNote;
      await delay(2000);
      return HttpResponse.json(updatedNote);
    }
    return HttpResponse.json(
      {
        errorMessage: `Note '${nid}' not found`,
      },
      { status: 403 },
    );
  },
);

export const noteHandlers = [allNotes, aNote, deleteNote, addNote, updateNote];
