import { http, HttpResponse, passthrough } from 'msw';
import { createProvenanceEvents } from './data/provenance';

const vaultItemSDCProvenance = http.get(
  '*/api/v1/orgs/:oid/:pid/vault/object*',
  () =>
    HttpResponse.json({
      ...passthrough(),
      provenance: createProvenanceEvents(),
    }),
);

export const provenanceHandlers = [vaultItemSDCProvenance];
