import { Project, Role } from '@formbio/api';
import { createMockUser } from './user';
import { getEarlierDate } from '@formbio/ui/utils/datetime';

export const projectData: Project = {
  name: 'Fake Project',
  description: 'Fake Project Description',
  organization: {
    createdAt: '2022-01-01T16:21:31.03613Z',
    updatedAt: '2022-01-01T16:21:31.03613Z',
    name: 'Fake Organization',
    id: 'fake-organization',
    createdBy: createMockUser(),
    members: [],
    invitees: [],
    bucket: '',
  },
  createdAt: getEarlierDate(new Date(), {
    minutes: 3,
  }).toISOString(),
  updatedAt: '2022-01-01T17:04:12.211996Z',
  id: 'fake-project',
  members: [
    {
      user: {
        created_at: '2022-01-01T23:06:19.542516Z',
        updated_at: '2022-01-01T23:06:19.542516Z',
        email: 'john-doe@formbio.com',
        name: 'John Doe',
        email_notification_preferences: {},
      },
      role: Role.Admin,
      createdAt: '0001-01-01T00:00:00Z',
      scopes: [
        'project.membership.create',
        'project.membership.delete',
        'project.membership.view',
        'project.delete',
        'project.update',
        'project.storage.create',
        'project.storage.delete',
        'project.storage.update',
        'project.token.create',
        'project.token.delete',
        'project.token.use',
        'project.workflow_run.create',
        'project.workflow_run.delete',
        'project.workflow_run.update',
        'project.workflow_run.view',
      ],
    },
  ],
  createdBy: {
    email: 'johndoe@formbio.com',
    name: 'string',
    is_verified: true,
    last_login: '2022-01-01T16:22:05.2523Z',
    created_at: '2022-01-01T16:22:05.2523Z',
  },
  invitees: [],
  bucket: '',
  billingEnabled: false,
  executionProject: '',
};

export const projectDataNotProvisioned1 = {
  ...projectData,
  name: 'Not Provisioned Project 1',
  id: 'not-provisioned-project-1',
  executionProject: ' ',
};

export const projectDataNotProvisioned2 = {
  ...projectData,
  createdAt: getEarlierDate(new Date(), {
    minutes: 9,
  }).toISOString(),
  name: 'Not Provisioned Project 2',
  id: 'not-provisioned-project-2',
  executionProject: undefined,
};

export const projectDataProvisioned = {
  ...projectData,
  name: 'Provisioned Project',
  id: 'provisioned-project',
  executionProject: 'hello',
};
