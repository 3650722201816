import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiSnackbar: CustomComponent<'MuiSnackbar'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: '100%',
      '& .MuiAlert-action': {
        color: theme.palette.primary[500],
      },
      '& .MuiSnackbarContent-root': {
        padding: 0,
      },
      '& .MuiSnackbarContent-message': {
        width: '100%',
        padding: 0,
      },
    }),
  },
};
