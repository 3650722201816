import { styled } from '@formbio/ui/components';
import { File as FileIcon } from '@formbio/ui/components/Icons';
import { Tooltip } from '@formbio/ui/components/Tooltip';
import { Typography } from '@formbio/ui/components/Typography';
import { ReactNode } from 'react';

const StyledDiv = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5),
  wordBreak: 'break-all',
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  '.title': {
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '.success': {
    color: theme.palette.success.main,
  },
}));

export default function FormbioTooltip({
  title,
  subTitle,
  formbioUrl,
  children,
}: {
  title: string;
  subTitle?: string;
  formbioUrl: string;
  children: ReactNode;
}) {
  return (
    <Tooltip
      title={
        <StyledDiv>
          <Typography variant='body2' className='title'>
            <FileIcon className='success' />
            {title}
          </Typography>
          {subTitle && (
            <Typography variant='body2' className='title'>
              {subTitle}
            </Typography>
          )}
          <Typography variant='body2' className='link'>
            {formbioUrl}
          </Typography>
        </StyledDiv>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
}
