import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiTextField: CustomComponent<'MuiTextField'> = {
  styleOverrides: {
    root: ({ theme }) => {
      const idle = theme.palette.primary[600];
      const focused = theme.palette.primary[900];
      const disabled = theme.palette.primary[400];
      return {
        // idle
        fieldset: {
          border: `1px solid ${disabled}`,
        },
        color: idle,
        '& .MuiInputAdornment-root': {
          color: idle,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover': {
            fieldset: {
              border: `1px solid ${focused}`,
            },
            '.MuiInputAdornment-root': {
              color: focused,
            },
          },
          '&.Mui-focused': {
            fieldset: {
              border: `1px solid ${focused}`,
              boxShadow: theme.shadows[20],
            },
            color: focused,
            '.MuiInputAdornment-root': {
              color: focused,
            },
          },
          // if active input has a value (no placeholder)
          '&:not(.Mui-disabled)': {
            ':not(:has(input:placeholder-shown))': {
              color: focused,
              '.MuiInputAdornment-root': {
                color: focused,
              },
            },
          },
          '&.Mui-disabled': {
            fieldset: {
              border: `1px solid ${theme.palette.primary[300]}`,
            },
            color: disabled,
            '.MuiInputAdornment-root': {
              color: disabled,
            },
          },
          '& .MuiSelect-select': {
            padding: theme.spacing(1.5),
          },
        },
        '& .MuiFormHelperText-root': {
          margin: theme.spacing(1, 0, 0),
          ...theme.typography.body2,
        },
      };
    },
  },
};
