import { MouseEventHandler } from 'react';
import { alpha, styled } from '@formbio/ui/components';
import { Button } from '@formbio/ui/components/Button';
import { MagnifyingGlass as MagnifyingGlassIcon } from '@formbio/ui/components/Icons';

const StyledSearchButton = styled(Button, {
  shouldForwardProp: propName => propName !== 'btnColor',
})<{ btnColor: string }>(({ btnColor, theme }) => {
  const primaryColor = btnColor === 'light' ? 100 : 900;
  return {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    backgroundColor:
      btnColor === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    color:
      btnColor === 'light'
        ? theme.palette.text.secondary
        : theme.palette.grey[400],
    fontSize: theme.typography.body3.fontSize,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor:
      btnColor === 'light' ? theme.palette.grey[300] : theme.palette.grey[900],
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionDuration: '150ms',
    boxShadow: `inset 0 1px 1px ${
      theme.palette.grey[primaryColor]
    }, 0 1px 0.5px ${alpha(theme.palette.grey[primaryColor], 0.6)}`,
    '&:hover': {
      background:
        btnColor === 'light'
          ? theme.palette.grey[primaryColor]
          : theme.palette.grey[800],
      borderColor:
        btnColor === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[600],
    },
  };
});

const SearchLabel = styled('span')(({ theme }) => {
  return {
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
  };
});

const Shortcut = styled('div')<{ color: string }>(({ color, theme }) => {
  return {
    fontSize: theme.typography.body3.fontSize,
    fontWeight: 'bold',
    lineHeight: '20px',
    marginLeft: theme.spacing(0.5),
    border: `1px solid ${
      color === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
    }`,
    backgroundColor:
      color === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
    padding: theme.spacing(0, 0.5),
    borderRadius: 7,
  };
});

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  btnColor: 'light' | 'dark';
};

const SearchButton = (props: Props) => {
  const macOS = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  return (
    <StyledSearchButton size='small' {...props}>
      <MagnifyingGlassIcon width={16} />
      <SearchLabel id='app-search-label'>Search...</SearchLabel>
      <Shortcut aria-hidden='true' color={props.btnColor}>
        {macOS ? '⌘' : 'Ctrl+'}K
      </Shortcut>
    </StyledSearchButton>
  );
};

export default SearchButton;
