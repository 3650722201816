import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiToggleButton: CustomComponent<'MuiToggleButton'> = {
  defaultProps: {
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => {
      return {
        color: theme.palette.primary[700],
        padding: theme.spacing(1, 3),
        '&.Mui-focusVisible': {
          boxShadow: theme.shadows[20],
        },
      };
    },
  },
};
