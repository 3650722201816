import ReactJson from '@formbio/ui/components/ReactJson';

import { LinearProgressFullWidth } from '../LinearProgress';
import { InteractionProps } from '@microlink/react-json-view';

export default function JsonEditor({
  data,
  isLoading,
  className,
  onEdit,
}: {
  data?: object;
  isLoading: boolean;
  className?: string;
  onEdit?: (data: InteractionProps) => void;
}) {
  return (
    <div className={className}>
      {isLoading ? (
        <LinearProgressFullWidth />
      ) : (
        <ReactJson src={data || {}} onEdit={onEdit} />
      )}
    </div>
  );
}
