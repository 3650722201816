import { api } from '../../utils/api';

export type CreateData = {
  oid: string;
  pid: string;
  validator?: string;
  path: string;
  format: string;
};

export function create({ oid, pid, ...data }: CreateData) {
  return api
    .post(`/orgs/${oid}/${pid}/vault/validations/run`, data)
    .then((res) => res.data);
}
