import { AvatarProps } from '@formbio/ui/types';
import { AvatarPicture, AvatarSizes } from '@formbio/ui';
import { Typography } from '@formbio/ui/components/Typography';
import { styled } from '@formbio/ui/components';

const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  backgroundColor: theme.palette.neutral[100],
  padding: theme.spacing(2),
  maxWidth: '400px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function AvatarMenuHeader({ avatar }: { avatar: AvatarProps }) {
  return (
    <StyledHeader className='avatar-header-background'>
      <AvatarPicture avatar={avatar} size={AvatarSizes.large} border />
      <Typography variant='body2' textAlign='center'>
        {avatar.displayName}
      </Typography>
      <Typography variant='body3' textAlign='center'>
        {avatar.email}
      </Typography>
    </StyledHeader>
  );
}
