const igvLoci = [
  {
    name: 'TP53',
    locus: {
      chromosome: 'chr17',
      start: 7572927,
      end: 7579912,
    },
  },
  {
    name: 'BRCA1',
    locus: {
      chromosome: 'chr17',
      start: 43044295,
      end: 43125482,
    },
  },
  {
    name: 'BRCA2',
    locus: {
      chromosome: 'chr13',
      start: 32314860,
      end: 32400266,
    },
  },
  {
    name: 'EGFR',
    locus: {
      chromosome: 'chr7',
      start: 55086714,
      end: 55249000,
    },
  },
];

export default igvLoci;
