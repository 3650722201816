import { HttpResponse, http } from 'msw';
import igvLoci from './data/igv';

const igvSearch = http.get('*/genomes/igv/search', ({ request }) => {
  const url = new URL(request.url);
  const gene = url.searchParams.get('gene');
  const locus = igvLoci.find(loc => loc.name === gene)?.locus;
  return HttpResponse.json({
    results: locus ? [locus] : [],
  });
});

export const igvHandlers = [igvSearch];
