import {
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
} from '@mui/material';

type CollapseProps = Omit<MuiCollapseProps, 'sx'>;

export const Collapse = ({ ...rest }: CollapseProps) => (
  <MuiCollapse {...rest} />
);
