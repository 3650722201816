import { MarketplaceTier } from '../../types/accounts';
import { api } from '../../utils/api';

export async function fetchAllTiers(): Promise<MarketplaceTier[]> {
  return api
    .get<MarketplaceTier[]>(`/marketplace/tiers`)
    .then(({ data }) => data);
}

export async function fetchOneTier(tierId: string): Promise<MarketplaceTier> {
  return api
    .get<MarketplaceTier>(`/marketplace/tier`, {
      params: {
        id: tierId,
      },
    })
    .then(({ data }) => data);
}
