import {
  GitHubAuthResponse,
  GitHubImportRepoParams,
  GitHubImportResponse,
  GitHubImportableWorkflow,
  GitHubInstallation,
  GitHubInstalledRepo,
  SetGitHubAuth,
} from '../../types/github-auth';
import { api } from '../../utils/api';

export async function getGithubAuth(): Promise<GitHubAuthResponse> {
  return api.get<GitHubAuthResponse>(`/gh/auth`).then(({ data }) => data);
}

export function setGitHubAuth(
  payload: SetGitHubAuth,
): Promise<GitHubAuthResponse> {
  return api
    .post<GitHubAuthResponse>(`/gh/auth`, payload)
    .then(({ data }) => data);
}

export function getGitHubInstallations() {
  return api
    .get<GitHubInstallation[]>(`/gh/installations`)
    .then(({ data }) => data);
}

export function getGitHubInstalledRepos(
  orgId: string,
  pid: string,
  installationId: string,
) {
  return api
    .get<GitHubInstalledRepo[]>(
      `/orgs/${orgId}/${pid}/gh/installations/${installationId}/repos`,
    )
    .then(({ data }) => data);
}

export function importGitHubRepo({
  orgId,
  pid,
  installationId,
  repoId,
  config: { name, ...payload },
}: GitHubImportRepoParams) {
  return api
    .post<GitHubImportResponse>(
      `/orgs/${orgId}/${pid}/gh/import/${installationId}/${repoId}/${name}`,
      payload,
    )
    .then(({ data }) => data);
}

export function getGitHubImportableWorkflows({
  orgId,
  pid,
  installationId,
  repoId,
}: {
  orgId: string;
  pid: string;
  installationId: string;
  repoId: string;
}) {
  return api
    .get<GitHubImportableWorkflow[]>(
      `/orgs/${orgId}/${pid}/gh/installations/${installationId}/repos/${repoId}/workflows`,
    )
    .then(({ data }) => data);
}
