import { admin_api } from '../../../utils/admin-api';

export async function changeAccountQuota({
  accountQuotaId,
  newQuota,
}: {
  accountQuotaId: string;
  newQuota: number;
}) {
  const { data } = await admin_api.put(`/account-quota/${accountQuotaId}`, {
    newQuota,
  });

  return data;
}

export async function changeTierQuota({
  tierQuotaId,
  newQuota,
}: {
  tierQuotaId: string;
  newQuota: number;
}) {
  const { data } = await admin_api.put(`/tier-quota/${tierQuotaId}`, {
    newQuota,
  });

  return data;
}
