import { FeatureNames, FeatureUsage, Quotas } from '../../types/accounts';
import { api } from '../../utils/api';

export async function fetchAllQuotas(oid: string): Promise<Quotas> {
  return api
    .get<Quotas>(`/orgs/${oid}/account/quotas`)
    .then(({ data }) => data);
}

export async function fetchQuotaUsage(
  oid: string,
  features: FeatureNames[],
): Promise<FeatureUsage[]> {
  return api
    .get<FeatureUsage[]>(`/orgs/${oid}/account/quotas-usage`, {
      params: {
        // These are the only options exposed by the API currently, and we only have one use case in the UI, which requires all 3
        features: features.join(','),
      },
    })
    .then(({ data }) => data);
}
