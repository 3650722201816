import { InputAdornment, styled } from '@formbio/ui/components';
import { TextField, TextFieldProps } from '@formbio/ui/components/TextField';
import { IconButton } from '@formbio/ui/components/IconButton';
import {
  Question as QuestionIcon,
  MagnifyingGlass as MagnifyingGlassIcon,
  X as XIcon,
} from '@formbio/ui/components/Icons';
import { useRef } from 'react';

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(0.5),
  minWidth: theme.spacing(5),
  backgroundColor: theme.palette.background.paper,
  '.search-icon': {
    width: theme.spacing(2),
  },
}));

export const SearchField = ({
  clearValue,
  placeholder = 'Search',
  disabled,
  onHelpClick,
  showStartIcon = true,
  autoFocus = false,
  showClearButton = true,
  ...rest // using `ref` for focusing the input field after clearing the value
}: Omit<TextFieldProps, 'ref'> & {
  clearValue?: () => void;
  onHelpClick?: () => void;
  showStartIcon?: boolean;
  showClearButton?: boolean;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  function handleClear(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (clearValue) {
      clearValue();
      ref.current?.focus();
    }
  }

  return (
    <StyledTextField
      {...rest}
      ref={ref}
      placeholder={placeholder}
      disabled={disabled}
      variant='outlined'
      size='small'
      autoFocus={autoFocus}
      name={placeholder}
      InputProps={{
        inputProps: {
          'aria-label': placeholder,
          ...rest.inputProps,
        },
        startAdornment: showStartIcon && (
          <InputAdornment position='start'>
            <MagnifyingGlassIcon className='search-icon' />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {onHelpClick && (
              <IconButton
                title='Help'
                aria-label='Help'
                size='small'
                onClick={onHelpClick}
                disabled={disabled}
              >
                <QuestionIcon />
              </IconButton>
            )}
            {showClearButton && (
              <IconButton
                title='Clear'
                aria-label='Clear'
                size='small'
                onClick={handleClear}
                disabled={disabled}
              >
                <XIcon className='search-icon' />
              </IconButton>
            )}
          </>
        ),
      }}
    />
  );
};
