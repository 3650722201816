/**
 * Utils functions to manipulate dates, format date and times,
 * format time to ETA, etc.
 *
 */

import {
  add,
  sub,
  addMilliseconds,
  differenceInSeconds,
  format,
  formatDistance,
  parseISO,
  isAfter,
  isBefore,
  isToday,
  isYesterday,
  formatDistanceToNow,
  fromUnixTime,
} from 'date-fns';

export { isToday, isThisWeek, isThisMonth, isThisYear } from 'date-fns';

const monthDayYear = 'LLL d, Y';
//locale time format
const timeFormat = 'p';
const dateAndTimeFormat = 'Pp';
export const dateTimeFormatShortYear = 'MM/dd/yy, HH:mm';

export function formatMonthDayYear(datestring: string): string {
  return format(new Date(datestring), monthDayYear);
}

export function formatUnixDateAndTime(timestamp: number): string {
  return format(fromUnixTime(timestamp), dateAndTimeFormat);
}

/*
 * Formats a date and time in a human readable format
 * @param datestring date to format
 * @param dateTimeformat optional format to use
 * @returns a string with human readable date and time
 *
 * format() will render users/viewer time zone
 */
export function formatDateAndTime(
  date?: string | number | Date,
  dateTimeformat?: string,
): string {
  //TODO: can we change the format to K:mm b?
  // return datestring ? `${format(new Date(datestring), 'MM/dd/yy K:mm b')}` : '';
  return date
    ? format(new Date(date), dateTimeformat || dateAndTimeFormat)
    : '';
}

/**
 * Creates a human readable phrase to inform users to how long
 * it will take to accomplish a task (eg. file uploads)
 * @param etaInMillis number of milliseconds between now and then
 * @returns a string with human readable ETA (eg. about 1h)
 */
export function formatTimeLeft(
  etaInMillis: number | undefined,
  prefix?: string,
) {
  const now = new Date();
  const builtPrefix = prefix ? `${prefix} ` : '';
  return `${builtPrefix}${formatDistance(
    now,
    addMilliseconds(now, etaInMillis || 0),
    {
      includeSeconds: true,
    },
  )}`;
}

export function timeDiffInSeconds(earlierDate: Date, laterDate: Date): number {
  return differenceInSeconds(earlierDate, laterDate);
}

export function getEarlierDate(fromDate: Date, duration: Duration) {
  return sub(fromDate, duration);
}
export function getLaterDate(fromDate: Date, duration: Duration) {
  return add(fromDate, duration);
}

export function parseStringISODate(stringDate: string) {
  return parseISO(stringDate);
}

export function isDateAfter(laterDate: Date, earlierDate: Date): boolean {
  return isAfter(laterDate, earlierDate);
}
export function isDateBefore(earlierDate: Date, laterDate: Date): boolean {
  return isBefore(earlierDate, laterDate);
}

export function formatTodayYesterdayOrDistance(datestring: string) {
  const date = new Date(datestring);
  if (isToday(date)) return 'Today';
  if (isYesterday(date)) return 'Yesterday';
  return `${formatDistanceToNow(date)} ago`;
}

export function formatTime(datestring: string): string {
  return format(new Date(datestring), timeFormat);
}
