import { Popover, styled } from '@mui/material';
import { IconButton } from '@formbio/ui/components/IconButton';
import { Button } from '@formbio/ui/components/Button';
import { TextField } from '@formbio/ui/components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  CaretDown as CaretDownIcon,
  X as XIcon,
} from '@formbio/ui/components/Icons';
import { Typography } from '@formbio/ui/components/Typography';
import { HelpInfo } from '../../Layout';

type FormData = {
  fileTypes?: string;
  singleLevel: boolean;
};

const StyledMenu = styled(Popover)(({ theme }) => ({
  marginTop: theme.spacing(1),
  form: {
    padding: theme.spacing(2, 2, 1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  '.input': {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  '.checkbox-wrapper': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '.label': {
    ...theme.typography.body1,
  },
  '.actions': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  '.small-icon': {
    width: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  '.small': {
    width: theme.spacing(2),
  },
}));

const schema = yup.object({
  fileTypes: yup.string(),
  singleLevel: yup.boolean().required(),
});

export default function AdvancedSearchMenu({
  defaultValues = { fileTypes: '', singleLevel: true },
  onSubmit,
}: {
  defaultValues?: { fileTypes: string; singleLevel: boolean };
  onSubmit: ({
    fileTypes,
    singleLevel,
  }: {
    fileTypes?: string;
    singleLevel: boolean;
  }) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { handleSubmit, register, reset } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      fileTypes: defaultValues.fileTypes,
      singleLevel: defaultValues.singleLevel,
    },
  });

  useEffect(() => {
    reset({
      fileTypes: defaultValues.fileTypes,
      singleLevel: defaultValues.singleLevel,
    });
  }, [defaultValues.fileTypes, defaultValues.singleLevel]);

  const handleFormSubmit = (data: FormData) => {
    onSubmit(data);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  function handleClear() {
    reset({ fileTypes: '' });
    onSubmit(defaultValues);
    setAnchorEl(null);
  }

  return (
    <>
      <StyledButton
        variant='outlined'
        onClick={handleClick}
        endIcon={<CaretDownIcon className='small' />}
      >
        Advanced Search
      </StyledButton>
      <StyledMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className='input'>
            <label htmlFor='file-types' className='label'>
              File types:
            </label>
            <TextField
              size='small'
              aria-label='Advanced Search'
              inputProps={{ id: 'file-types' }}
              defaultValue={defaultValues.fileTypes}
              {...register('fileTypes')}
              autoFocus
              fullWidth
              placeholder='Eg. pdf txt'
              InputProps={{
                endAdornment: (
                  <IconButton
                    title='Clear'
                    aria-label='Clear'
                    size='small'
                    onClick={() => reset({ fileTypes: '' })}
                  >
                    <XIcon className='small-icon' />
                  </IconButton>
                ),
              }}
            />
          </div>
          <HelpInfo>
            <Typography variant='body2'>
              Separate file types with spaces or commas.
            </Typography>
            <Typography variant='body2'>
              A file extension dot (.) is optional.
            </Typography>
            <Typography variant='body2'>Eg.: pdf txt</Typography>
            <Typography variant='body2'>Eg.: .bam, .log</Typography>
          </HelpInfo>
          <div className='checkbox-wrapper'>
            <input
              type='checkbox'
              id='singleLevel'
              defaultChecked={defaultValues.singleLevel}
              {...register('singleLevel')}
            />
            <label htmlFor='singleLevel' className='label'>
              Search only in current directory
            </label>
          </div>
          <div className='actions'>
            <Button onClick={handleClear} variant='outlined'>
              Reset Filter
            </Button>
            <Button type='submit' variant='outlined'>
              Apply
            </Button>
          </div>
        </form>
      </StyledMenu>
    </>
  );
}
