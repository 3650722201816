import { ReactNode } from 'react';
import { Grid, styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import { Spacer } from '@formbio/ui/components/Layout/Spacer';

const MainGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: theme.spacing(1),
}));

const LeftGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '0%',
  flexGrow: 1,
  padding: theme.spacing(0.5),
}));

const RightGridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: 'auto',
  flexGrow: 0,
});

const LeftGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

const RightGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

const StyledTypographyH1 = styled(Typography)(({ theme }) => ({
  '&&': {
    fontSize: theme.typography.h1?.fontSize,
    lineHeight: theme.typography.h1?.lineHeight,
  },
}));

export const PageHeader = ({
  pageTitle,
  leftSideItems,
  description,
  rightSideItems,
}: {
  pageTitle: string;
  description?: string;
  leftSideItems?: ReactNode[];
  rightSideItems?: ReactNode[];
}) => {
  return (
    <MainGridContainer container>
      <LeftGridItem item>
        <LeftGridContainer container>
          {leftSideItems &&
            leftSideItems.map((item, index) => (
              <Grid item key={index}>
                {item}
              </Grid>
            ))}
          <Grid item>
            <StyledTypographyH1 variant='h1'>{pageTitle}</StyledTypographyH1>
          </Grid>
        </LeftGridContainer>
        {description && (
          <>
            <Spacer size='l' />
            <Typography variant='body1'>{description}</Typography>
          </>
        )}
      </LeftGridItem>
      <RightGridItem item>
        <RightGridContainer container>
          {rightSideItems &&
            rightSideItems.map((item, index) => (
              <Grid item key={index}>
                {item}
              </Grid>
            ))}
        </RightGridContainer>
      </RightGridItem>
    </MainGridContainer>
  );
};
