import { fetchOne } from '../../services/validators';
import { ValidatorsResponse } from '../../types/validator';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

const KEY = 'validators';

export function useValidatorsByFiletype(
  params: Parameters<typeof fetchOne>[0],
  options?: Omit<UseQueryOptions<ValidatorsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<ValidatorsResponse>({
    queryKey: [KEY, params],
    queryFn: () => fetchOne(params),
    ...options,
  });
}
