import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiLinearProgress: CustomComponent<'MuiLinearProgress'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: theme.spacing(0.5),
      marginBottom: -theme.spacing(0.5),
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.background.default
          : '#2C2A2E', // color not in the palette: Black/750
      borderRadius: '6px',
      '.MuiLinearProgress-bar': {
        borderRadius: '6px',
      },
      '.MuiLinearProgress-barColorPrimary': {
        backgroundColor:
          theme.palette.mode === 'light'
            ? theme.palette.primary[900]
            : theme.palette.info[500],
      },
    }),
  },
};
