import { ConnectedAppSize } from '@formbio/ui/types';
import { ButtonBase, ButtonProps, Typography, styled } from '@mui/material';

const LOGO_SIZE = '128px';

interface ConnectedAppItemProps extends ButtonProps {
  imgSrc: string;
  label: string;
  imgSize?: ConnectedAppSize;
}

const StyledContainer = styled(ButtonBase, {
  shouldForwardProp(propName) {
    return propName !== 'imgSrc' && propName !== 'imgSize';
  },
})<{ imgSrc: string; imgSize?: ConnectedAppSize }>(
  ({ theme, imgSrc, imgSize }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: 'fit-content',
    padding: theme.spacing(1),
    ':hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '.image': {
      backgroundImage: `url(${imgSrc})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: imgSize || LOGO_SIZE,
      height: imgSize || LOGO_SIZE,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

export default function ConnectedAppItem({
  imgSize = ConnectedAppSize.small,
  ...props
}: ConnectedAppItemProps) {
  return (
    <StyledContainer imgSize={imgSize} {...props}>
      <div className='image' />
      <Typography>{props.label}</Typography>
    </StyledContainer>
  );
}
