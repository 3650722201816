import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiIconButton: CustomComponent<'MuiIconButton'> = {
  variants: [
    {
      props: {},
      style: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius,
      }),
    },
  ],
};
