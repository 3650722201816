import { keyframes } from '@mui/material';
import { Dna as DnaIcon } from '@phosphor-icons/react';
import { styled } from '@formbio/ui/components';

export const pulse = keyframes({
  '0%': { opacity: 0 },
  '50%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const StyledDnaIcon = styled(DnaIcon)(() => ({
  animation: `${pulse} 2s infinite`,
}));

export default function PulsingDnaIcon() {
  return <StyledDnaIcon />;
}
