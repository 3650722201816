import {
  GitHubInstallation,
  GitHubInstalledRepo,
} from '@formbio/api/src/types/github-auth';

export const installations: GitHubInstallation[] = [
  {
    id: '1',
    account: {
      login: 'aalcott14',
      avatar_url: 'https://avatars.githubusercontent.com/u/26099455?v=4',
      type: 'User',
    },
    updated_at: '2023-04-11T16:57:47-05:00',
  },
  {
    id: '2',
    account: {
      login: 'formbio',
      avatar_url: 'https://avatars.githubusercontent.com/u/84826452?v=4',
      type: 'Organization',
    },
    updated_at: '2023-04-11T16:57:47-05:00',
  },
];

export const installedRepos: GitHubInstalledRepo[] = [
  {
    id: '1',
    name: 'repo1',
  },
  {
    id: '2',
    name: 'repo2',
  },
  {
    id: '3',
    name: 'repo3',
  },
];
