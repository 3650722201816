import { CheckSuite } from '../../types';
import { api } from '../../utils/api';

export function fetchAllByProject({ oid, pid }: { oid: string; pid: string }) {
  return api
    .get<CheckSuite[]>(`/orgs/${oid}/${pid}/gh/check-suites`)
    .then(({ data }) => data);
}

export function fetchAllByWorkflow({
  oid,
  pid,
  workflowId,
}: {
  oid: string;
  pid: string;
  workflowId: string;
}) {
  return api
    .get<CheckSuite[]>(
      `/orgs/${oid}/${pid}/workflow/${workflowId}/check-suites`,
    )
    .then(({ data }) => data);
}

export function fetchAllByWorkflowVersion({
  oid,
  pid,
  workflowVersionId,
}: {
  oid: string;
  pid: string;
  workflowVersionId: string;
}) {
  return api
    .get<CheckSuite[]>(
      `/orgs/${oid}/${pid}/workflow-version/${workflowVersionId}/check-suites`,
    )
    .then(({ data }) => data);
}

export function fetchOne({
  oid,
  pid,
  csId,
}: {
  oid: string;
  pid: string;
  csId: string;
}) {
  return api
    .get<CheckSuite>(`/orgs/${oid}/${pid}/gh/check-suites/${csId}`)
    .then(({ data }) => data);
}
