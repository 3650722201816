import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ChevronDownBackgroundIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' {...props}>
      <rect width='20' height='20' rx='4' fill='#1C1B1D' />
      <path
        d='M15.4625 7.80625C15.4242 7.71525 15.3599 7.63761 15.2776 7.58311C15.1953 7.52861 15.0987 7.49969 15 7.5H4.99998C4.90126 7.49969 4.80467 7.52861 4.72236 7.58311C4.64006 7.63761 4.57573 7.71525 4.53748 7.80625C4.50145 7.89861 4.49231 7.99927 4.51112 8.09661C4.52992 8.19394 4.5759 8.28396 4.64373 8.35625L9.64373 13.3563C9.7393 13.4487 9.86704 13.5003 9.99998 13.5003C10.1329 13.5003 10.2607 13.4487 10.3562 13.3563L15.3562 8.35625C15.4241 8.28396 15.47 8.19394 15.4888 8.09661C15.5076 7.99927 15.4985 7.89861 15.4625 7.80625Z'
        fill='#908E96'
      />
    </SvgIcon>
  );
}
