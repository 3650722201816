import dynamic from 'next/dynamic';

const BarChart = dynamic(
  () => import('@nivo/bar').then(nivo => nivo.ResponsiveBar),
  {
    ssr: false,
  },
);

export default BarChart;
