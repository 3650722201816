import { styled, useTheme } from '@formbio/ui/components';
import { Tooltip } from '@formbio/ui/components/Tooltip';
import {
  Medal as MedalIcon,
  PauseCircle as PauseCircleIcon,
} from '@formbio/ui/components/Icons';
import { VectorLabelValues } from '@formbio/api';
import { StyledStatusChip } from '../CheckpointStatusChip';

export const StyledGoldMedalIcon = styled(MedalIcon)(({ theme }) => ({
  fill: theme.palette.warning[400],
  color: theme.palette.yellow.dark,
  strokeWidth: 5,
}));

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  display: 'flex',
  '.icon': {
    fontSize: 'large',
    color: theme.palette.warning.main,
  },
}));

export const StyledStack = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

function getLabelIcon(label: VectorLabelValues) {
  switch (label) {
    case VectorLabelValues.LEAD:
      return <MedalIcon weight='fill' />;
    case VectorLabelValues.CONTENDER:
      return <MedalIcon weight='regular' />;
    case VectorLabelValues.PAUSED:
      return <PauseCircleIcon weight='regular' />;
  }
}

function useLabelColors(label: VectorLabelValues) {
  const theme = useTheme();
  switch (label) {
    case VectorLabelValues.LEAD:
      return {
        backgroundColor: theme.palette.yellow.light,
        contrastColor: theme.palette.yellow.dark,
      };
    case VectorLabelValues.PAUSED:
      return {
        backgroundColor: theme.palette.warning.light,
        contrastColor: theme.palette.warning.dark,
      };
    case VectorLabelValues.NO_LABEL:
      return {
        backgroundColor: 'transparent',
        contrastColor: theme.palette.primary.dark,
      };
    default:
      return {
        backgroundColor: theme.palette.primary.light,
        contrastColor: theme.palette.primary.dark,
      };
  }
}

export default function VectorLabelChip({
  label,
  clickable = false,
  size = 'small',
  className,
  noText,
  onDelete,
}: {
  label: VectorLabelValues;
  clickable?: boolean;
  size?: 'small' | 'medium';
  className?: string;
  noText?: boolean;
  onDelete?: () => void;
}) {
  return (
    <StyledStack className={className}>
      <StyledStatusChip
        icon={getLabelIcon(label)}
        clickable={clickable}
        colors={useLabelColors(label)}
        labelText={noText ? undefined : label}
        size={size}
        onDelete={onDelete}
      />
    </StyledStack>
  );
}
