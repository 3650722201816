import { ContainerApp, CreateOrgAppPayload } from '../../types';
import { api } from '../../utils/api';

export function fetchAllAvailable({ oid, pid }: { oid: string; pid: string }) {
  return api
    .get<ContainerApp[]>(`/orgs/${oid}/${pid}/available/apps`)
    .then(({ data }) => data);
}

export function fetchOneAvailable({
  oid,
  pid,
  ownerOrg,
  appName,
}: {
  oid: string;
  pid: string;
  ownerOrg: string;
  appName: string;
}) {
  return api
    .get<ContainerApp>(
      `/orgs/${oid}/${pid}/available/apps/${ownerOrg}/${appName}`,
    )
    .then(({ data }) => data);
}

export function fetchOneOrgApp({
  oid,
  appName,
}: {
  oid: string;
  appName: string;
}) {
  return api
    .get<ContainerApp>(`orgs/${oid}/apps/${appName}`)
    .then(({ data }) => data);
}

export function fetchAllOrgApps({ oid }: { oid: string }) {
  return api.get<ContainerApp[]>(`/orgs/${oid}/apps`).then(({ data }) => data);
}

export function createOrgApp({
  oid,
  payload,
}: {
  oid: string;
  payload: CreateOrgAppPayload;
}) {
  return api
    .post<ContainerApp>(`/orgs/${oid}/apps`, payload)
    .then(({ data }) => data);
}

export function editOrgApp({
  oid,
  payload,
}: {
  oid: string;
  payload: CreateOrgAppPayload;
}) {
  return api
    .patch<ContainerApp>(`orgs/${oid}/apps/${payload.appName}`, payload)
    .then(({ data }) => data);
}

export function deleteOrgApp({
  oid,
  appName,
}: {
  oid: string;
  appName: string;
}) {
  return api.delete(`orgs/${oid}/apps/${appName}`).then(({ data }) => data);
}
