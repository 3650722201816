import {
  Card as MuiCard,
  CardProps as MuiCardProps,
  CardContent as MuiCardContent,
  CardContentProps as MuiCardContentProps,
  CardHeader as MuiCardHeader,
  CardHeaderProps as MuiCardHeaderProps,
  CardActions as MuiCardActions,
  CardActionsProps as MuiCardActionsProps,
  CardActionArea as MuiCardActionArea,
  CardActionAreaProps as MuiCardActionAreaProps,
} from '@mui/material';

type CardProps = Omit<MuiCardProps, 'sx'>;
type CardContentProps = Omit<MuiCardContentProps, 'sx'>;
type CardHeaderProps = Omit<MuiCardHeaderProps, 'sx'>;
type CardActionsProps = Omit<MuiCardActionsProps, 'sx'>;
type CardActionAreaProps = Omit<MuiCardActionAreaProps, 'sx'>;

export const Card = ({ ...props }: CardProps) => <MuiCard {...props} />;
export const CardContent = ({ ...props }: CardContentProps) => (
  <MuiCardContent {...props} />
);
export const CardHeader = ({ ...props }: CardHeaderProps) => (
  <MuiCardHeader {...props} />
);
export const CardActions = ({ ...props }: CardActionsProps) => (
  <MuiCardActions {...props} />
);
export const CardActionArea = ({ ...props }: CardActionAreaProps) => (
  <MuiCardActionArea {...props} />
);
