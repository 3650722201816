import { User } from '../../../types';
import { admin_api } from '../../../utils/admin-api';

export async function fetchAllAdmin() {
  const { data } = await admin_api.get<User[]>('/users');
  return data;
}

export async function fetchOneAdmin({
  email,
  id,
}: {
  email?: string;
  id?: string;
}) {
  return admin_api
    .get<User>('/user', {
      params: { userEmail: email, userID: id },
    })
    .then(({ data }) => data);
}

export async function updateUserVerification(
  userEmail: string,
  verifiedStatus: boolean,
) {
  // TODO switch to user patch - the firebase-specific route is deprecated
  return admin_api.patch(`/users/firebase/${userEmail}/verify`, null, {
    params: { verifiedStatus },
  });
}
