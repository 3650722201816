import { useState, useEffect } from 'react';
import { getInvitationToken } from '@/api/invitation';
import { InvitationToken, InvitationTypes } from '@formbio/api';
import { captureError } from '@/utils/logging';

export function useVerifyInvitationToken(token: string, type: InvitationTypes) {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<InvitationToken>();

  useEffect(() => {
    const fetchData = async (token: string, type: InvitationTypes) => {
      setIsLoading(true);
      setError(undefined);
      try {
        const data = await getInvitationToken(token, type);
        setData(data);
      } catch (error) {
        setError(`invitation token error`);
        captureError({
          error: error,
          name: 'invitation token error',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(token, type);
  }, [token, type]);

  return { data, loading: isLoading, error };
}
