import { AlertColor } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Alert } from '@formbio/ui/components/Alert';
import { Snackbar } from '@formbio/ui/components/Snackbar';
import { Portal } from '@formbio/ui/components/Portal';

export type AlertSnackbarProps = PropsWithChildren<{
  autoHideDuration?: number | null;
  variant?: AlertColor;
  onClose?: () => void;
}>;

export default function AlertSnackbar({
  autoHideDuration = 5000,
  variant = 'info',
  onClose,
  children,
}: AlertSnackbarProps) {
  return (
    <Portal>
      <Snackbar
        open
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        message={
          <Alert
            severity={variant}
            onClose={onClose}
            elevation={3}
            variant='outlined'
          >
            {children}
          </Alert>
        }
      />
    </Portal>
  );
}
