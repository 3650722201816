import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiButtonBase: CustomComponent<'MuiButtonBase'> = {
  defaultProps: {
    color: 'primary',
  },
};

export const MuiButton: CustomComponent<'MuiButton'> = {
  styleOverrides: {
    root: ({ theme }) => {
      const { palette } = theme;
      const { spacing } = theme;
      const smallSpY = spacing(1);
      const smallSpX = spacing(2);
      const mediumSpY = spacing(1.2);
      const mediumSpX = spacing(3);
      const largeSpY = spacing(1.4);
      const largeSpX = spacing(5);

      return {
        boxShadow: 'none',
        // otherwise color is 0.5 opacity
        borderColor: 'currentColor',
        paddingTop: '10px',
        paddingBottom: '10px',
        // prevent multiword labels from wrapping
        width: 'max-content',
        '&.MuiButton-sizeSmall': {
          padding: `${smallSpY} ${smallSpX}`,
        },
        '&.MuiButton-sizeMedium': {
          padding: `${mediumSpY} ${mediumSpX}`,
        },
        '&.MuiButton-sizeLarge': {
          padding: `${largeSpY} ${largeSpX}`,
        },
        '&.Mui-disabled': {
          borderColor: palette.secondary[800],
          backgroundColor: palette.secondary[800],
          color: palette.primary[900],
        },
        '&.Mui-focusVisible ': {
          borderColor: palette.primary[900],
          boxShadow: `0px 0px 5px 3px ${palette.secondary[300]}`,
        },
        '&.MuiButton-containedSecondary:hover': {
          backgroundColor: palette.secondary[700],
          color: palette.primary[900],
        },
        '&.MuiButton-containedSecondary:active': {
          backgroundColor: palette.secondary[800],
        },
        '&.MuiButton-containedSecondary:focus:not(:focus-visible)': {
          outline: 0,
        },
        '&.MuiButton-containedSecondary:focus-visible': {
          boxShadow: `0 0 0 ${spacing(0.5)} ${palette.info[700]}`,
          outline: 0,
        },
        '&.MuiButton-containedSecondary:disabled': {
          backgroundColor: palette.primary[800],
          color: palette.primary[700],
        },
        '&.MuiButton-outlined.MuiButton-sizeLarge': {
          paddingTop: `calc(${largeSpY} - 1px)`,
          paddingBottom: `calc(${largeSpY} - 1px)`,
        },
        // ensures that the outlined button
        // has the same height as the medium contained
        // when adjusting for the border
        '&.MuiButton-outlined.MuiButton-sizeMedium': {
          paddingTop: `calc(${mediumSpY} - 1px)`,
          paddingBottom: `calc(${mediumSpY} - 1px)`,
        },
        // ensures that the outlined button
        // has the same height as the small contained
        // when adjusting for the border
        '&.MuiButton-outlined.MuiButton-sizeSmall': {
          paddingTop: `calc(${smallSpY} - 1px)`,
          paddingBottom: `calc(${smallSpY} - 1px)`,
        },
        '&.MuiButton-outlined': {
          borderColor: palette.primary[500],
          color: palette.text.primary,
        },
        '&.MuiButton-outlined:hover': {
          borderColor: palette.primary[400],
        },
        '&.MuiButton-outlined:active': {
          borderColor: palette.primary[600],
        },
        // Prevents outline styles when clicking / active
        '&.MuiButton-outlined:focus:not(:focus-visible)': {
          borderColor: palette.primary[500],
          outline: '0',
        },
        '&.MuiButton-outlined:focus-visible': {
          boxShadow: `0 0 0 ${spacing(0.5)} ${palette.info[700]}`,
          outline: 0,
        },
        '&.MuiButton-outlined:disabled': {
          backgroundColor: 'transparent',
          borderColor: palette.primary[700],
          color: palette.primary[700],
        },
      };
    },
  },
};
