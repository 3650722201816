import { delay, http, HttpResponse } from 'msw';
import organizationData from './data/organizations.json';
import { Organization, Project } from '@formbio/api';
import { projectData } from './data/project';
import {
  projectDataNotProvisioned1,
  projectDataNotProvisioned2,
  projectDataProvisioned,
} from './data/project';

//simulate a database
let allOrgs: Organization[] = [];
let allProjects: Project[] = [];

const projects = http.get('*/api/v1/user/projects', async () => {
  await delay(1000);
  return HttpResponse.json([
    projectDataNotProvisioned1,
    projectDataNotProvisioned2,
    projectDataProvisioned,
  ]);
});

const project = http.get('*/api/v1/orgs/:oid/:pid', ({ params }) => {
  if (params.oid === 'fake-organization') {
    return HttpResponse.json(projectData);
  } else {
    return HttpResponse.json(null, { status: 400 });
  }
});
const orgs = http.get('*/api/v1/user/orgs', async () => {
  await delay(1000);
  return HttpResponse.json(allOrgs);
});

const org = http.get('*/api/v1/orgs/:oid', async () => {
  await delay(1000);
  return HttpResponse.json(organizationData[0]);
});

const projectAdd = http.post('*/api/v1/orgs/:oid/projects', async () => {
  await delay(1000);
  allProjects.push(projectData);
  allOrgs[0].projects = [projectData];
  return HttpResponse.json(projectData);
});

const projectUpdate = http.patch('*/api/v1/orgs/:oid/:pid', () =>
  HttpResponse.json(null, { status: 500, statusText: 'Cannot update project' }),
);

const orgAdd = http.post('*/api/v1/organizations', async () => {
  await delay(1000);
  allOrgs.push(organizationData[0]);
  return HttpResponse.json(organizationData[0]);
});

const tiers = http.get('*/api/v1/account/tiers', async () => {
  await delay(1000);
  return HttpResponse.json([
    {
      accountId: '123',
      tier: { name: 'individual', description: 'Individual Plan' },
    },
  ]);
});

export const projectHandlers = [
  projects,
  project,
  orgs,
  org,
  projectAdd,
  projectUpdate,
  orgAdd,
  tiers,
];
