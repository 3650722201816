import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { update } from '../../services/workflows';
import { WorkflowVersion, WorkflowVersionOverrides } from '../../types';
import { WF_VERSIONS_KEY, WF_VERSIONS_MANAGER_KEY } from '../workflow-versions';

function applyOverrides(
  versions: WorkflowVersion[],
  workflowId: string,
  overrides: WorkflowVersionOverrides,
) {
  return versions.map((version) => {
    if (version.workflowId === workflowId) {
      return {
        ...version,
        workflowVersionOverrides: {
          ...overrides,
        },
      };
    }
    return version;
  });
}

export function useUpdateWorkflow(
  oid: string,
  pid: string,
  workflowId: string,
  options?: UseMutationOptions<string, Error, WorkflowVersionOverrides>,
) {
  const queryClient = useQueryClient();
  return useMutation<string, Error, WorkflowVersionOverrides>({
    mutationFn: (payload: WorkflowVersionOverrides) =>
      update(oid, pid, workflowId, payload),
    ...options,
    onSuccess: (...args) => {
      // invalidate all versions queries and inactive manager version queries
      queryClient.invalidateQueries({
        queryKey: [WF_VERSIONS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [WF_VERSIONS_MANAGER_KEY],
        refetchType: 'inactive',
      });
      // quickly update the active manager version query
      queryClient.setQueryData(
        [WF_VERSIONS_MANAGER_KEY, { oid, pid }],
        (data?: WorkflowVersion[]) => {
          const overrides = args[1];
          return applyOverrides(data || [], workflowId, overrides);
        },
      );
      options?.onSuccess?.(...args);
    },
  });
}
