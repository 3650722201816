import { PANEL_TRANSITION_DURATION_MS } from '@formbio/ui/theme/constants';
import { styled } from '@mui/material';
import { Panel } from 'react-resizable-panels';
export type { ImperativePanelHandle } from 'react-resizable-panels';
export { Panel, PanelGroup } from 'react-resizable-panels';

export const ResizablePanel = styled(Panel, {
  shouldForwardProp: prop =>
    prop !== 'allowTransition' && prop !== 'backgroundColor',
})<{ allowTransition: boolean; backgroundColor?: string }>(
  ({ theme, allowTransition, backgroundColor }) => ({
    overflow: 'hidden',
    backgroundColor: backgroundColor || theme.palette.grey[100],
    // ease transition on collapse/expand but not on drag
    // or before initial render (so the panel doesn't animate on load)
    transition: !allowTransition
      ? undefined
      : `flex-grow ${PANEL_TRANSITION_DURATION_MS}ms ease-in-out`,
  }),
);
