import type { Project } from '../../types/project';
import { Role } from '../../types/member';
import { api } from '../../utils/api';
import { admin_api } from '../../utils/admin-api';

export interface CreateProjectData {
  oid: string;
  name: string;
  description?: string;
  ignoreVerification?: boolean;
  isIsa?: boolean;
}

export interface UpdateProjectData extends CreateProjectData {
  pid: string;
}

export interface MemberData {
  email: string;
  role: Role;
}

export function fetchAll(oid?: string) {
  return api
    .get<Project[]>(oid ? `/orgs/${oid}/projects` : '/user/projects')
    .then(({ data }) => data);
}

export function fetchOne(oid: string, pid: string) {
  return api.get<Project>(`/orgs/${oid}/${pid}`).then(({ data }) => data);
}

export function create(data: CreateProjectData) {
  const { oid, ...rest } = data;
  return api.post<{ message: string; id: string }>(
    `/orgs/${oid}/projects`,
    rest,
  );
}

export function update(data: UpdateProjectData) {
  const { oid, pid, ...rest } = data;
  return api.patch<{ message: string; id: string }>(
    `/orgs/${oid}/${pid}`,
    rest,
  );
}

export function deleteProject(oid: string, pid: string) {
  return api.delete(`/orgs/${oid}/${pid}`);
}

export function undeleteProject(oid: string, pid: string) {
  return admin_api.patch(`/undelete/organizations/${oid}/projects/${pid}`);
}

export function removeMember(oid: string, pid: string, email: string) {
  return api.delete(`/orgs/${oid}/${pid}/members`, {
    data: { userEmail: email },
  });
}

export function addMember(oid: string, pid: string, data: MemberData) {
  return api.post(`/orgs/${oid}/${pid}/members`, {
    userEmail: data.email,
    role: data.role,
  });
}

export function updateMember(oid: string, pid: string, data: MemberData) {
  return api.patch(`/orgs/${oid}/${pid}/members`, {
    userEmail: data.email,
    role: data.role,
  });
}

export function addMembers(oid: string, pid: string, data: MemberData[]) {
  return api.post(
    `/orgs/${oid}/${pid}/members/bulk`,
    data.map((member) => {
      return {
        userEmail: member.email,
        role: member.role,
      };
    }),
  );
}
