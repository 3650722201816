import { AdminISAVector, ISAVector } from '../../../types';
import { admin_api } from '../../../utils/admin-api';

export async function fetchAllAdmin() {
  const { data } = await admin_api.get<ISAVector[]>('/isa-vectors');
  return data;
}

export async function fetchOneAdmin({ vectorId }: { vectorId: string }) {
  const { data } = await admin_api.get<AdminISAVector>(
    `/isa-vector/${vectorId}`,
  );
  return data;
}

export async function updateApprovalStatus({
  approvalStatus,
  approvalNotes,
  orderId,
  vectorId,
}: {
  approvalNotes: string;
  approvalStatus: string;
  orderId: string;
  vectorId: string;
}) {
  const { data } = await admin_api.put(`stripe-order/${orderId}/status`, {
    // Hack because its hard to look up the vectorId from the orderId
    vectorID: vectorId,
    status: approvalStatus,
    statusNotes: approvalNotes,
  });
  return data;
}
