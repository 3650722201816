import { faker } from '@faker-js/faker';
import {
  DATACORE_ENTITY_SAMPLE_SET,
  DatacorePayload,
  DatacoreRelationshipDataPayload,
  SampleSet,
} from '@formbio/api';
import { delay, http, HttpResponse, PathParams } from 'msw';
import {
  createDatacoreSampleSet,
  createDatacoreSampleSets,
  mutableSamples,
  mutableSampleSets,
  updateMutableSampleSets,
  updateMutableSampleSetSampleFrns,
} from './data/sample-sets';

const allSampleSets = http.get(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE_SET}`,
  async () => {
    await delay(faker.number.int({ min: 500, max: 1500 }));
    return HttpResponse.json(createDatacoreSampleSets());
  },
);

const aSampleSet = http.get<PathParams, DatacorePayload>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE_SET}/:id`,
  async ({ params }) => {
    const id = params.id as string;
    const sampleSet = mutableSampleSets.find(sampleSet => sampleSet.id === id);
    if (!sampleSet) {
      return HttpResponse.json(
        {
          message: `Unhappy path triggered!`,
        },
        { status: 422 },
      );
    }
    return HttpResponse.json(createDatacoreSampleSet(sampleSet));
  },
);

const updateSampleSet = http.post<PathParams, DatacorePayload>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE_SET}/:id`,
  async ({ params, request }) => {
    const id = params.id as string;
    const payload = await request.json();
    const previousSampleSet = mutableSampleSets.find(
      sampleSet => sampleSet.id === id,
    );
    if (previousSampleSet) {
      const updatedSampleSet = {
        ...previousSampleSet,
        name: payload.data.attributes.name as string,
      };
      updateMutableSampleSets([
        ...mutableSampleSets.filter(sample => sample.id !== id),
        updatedSampleSet,
      ]);
      updateMutableSampleSetSampleFrns(updatedSampleSet);
      return HttpResponse.json({ data: { id: updatedSampleSet.id } });
    }
  },
);

const createSampleSet = http.post<PathParams, DatacorePayload>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE_SET}`,
  async ({ request }) => {
    const payload = await request.json();
    const newSampleSet: SampleSet = {
      id: faker.string.uuid(),
      createdAt: new Date().toISOString(),
      name: String(payload.data.attributes.name),
    };
    updateMutableSampleSets([...mutableSampleSets, newSampleSet]);
    await delay(faker.number.int({ min: 500, max: 1500 }));
    if (payload.data.attributes.name === 'error')
      return HttpResponse.json(
        {
          message: `Unhappy path triggered!`,
        },
        { status: 422 },
      );
    return HttpResponse.json({ data: { id: newSampleSet.id } });
  },
);

const updateSampleSetSampleFrns = http.post<
  PathParams,
  DatacoreRelationshipDataPayload
>(
  `*/orgs/:oid/:pid/datacore/entities/${DATACORE_ENTITY_SAMPLE_SET}/:id/relationships/data`,
  async ({ params, request }) => {
    const id = params.id as string;
    const payload = await request.json();
    const previousSampleSet = mutableSampleSets.find(
      sampleSet => sampleSet.id === id,
    );
    if (previousSampleSet) {
      const sampleFrns = payload.data.attributes.data.samples as string[];
      const updatedSampleSet = {
        ...previousSampleSet,
        samples: mutableSamples
          .filter(sample => sampleFrns.includes(sample.frn))
          .map(sample => ({
            id: sample.id,
            frn: sample.frn,
            name: sample.name,
          })),
      };
      updateMutableSampleSets([
        ...mutableSampleSets.filter(sampleSet => sampleSet.id !== id),
        updatedSampleSet,
      ]);
      updateMutableSampleSetSampleFrns(updatedSampleSet);
      return HttpResponse.json({ data: { id: updatedSampleSet.id } });
    }
  },
);

export const sampleSetHandlers = [
  allSampleSets,
  aSampleSet,
  updateSampleSet,
  createSampleSet,
  updateSampleSetSampleFrns,
];
