import { delay, http, HttpResponse } from 'msw';
import { vadrScores } from './data/vadr-scores';

const allVadrScores = http.get(
  '*/api/v1/orgs/:oid/:pid/vadr/scores',
  async () => {
    await delay(500);
    return HttpResponse.json(vadrScores);
  },
);

export const vadrScoresHandlers = [allVadrScores];
