import { Components, Theme } from '@mui/material/styles';
import * as MuiButtonDark from './dark/MuiButton';
import * as MuiDividerDark from './dark/MuiDivider';
import * as MuiLinkDark from './dark/MuiLink';
import * as MuiTextFieldDark from './dark/MuiTextField';
import * as MuiOutlinedInput from './dark/MuiOutlinedInput';

import * as MuiAccordion from './MuiAccordion';
import * as MuiButton from './MuiButton';
import * as MuiChip from './MuiChip';
import * as MuiDataGrid from './MuiDataGrid';
import * as MuiDialog from './MuiDialog';
import * as MuiIconButton from './MuiIconButton';
import * as MuiLink from './MuiLink';
import * as MuiListItemButton from './MuiListItemButton';
import * as MuiSnackbar from './MuiSnackbar';
import * as MuiTab from './MuiTab';
import * as MuiTable from './MuiTable';
import * as MuiTextField from './MuiTextField';
import * as MuiTooltip from './MuiTooltip';
import * as MuiMenuItem from './MuiMenuItem';
import * as MuiCheckboxDark from './dark/MuiCheckbox';
import * as MuiCircularProgress from './MuiCircularProgress';
import * as MuiInputLabel from './MuiInputLabel';
import * as MuiAlert from './MuiAlert';
import * as MuiSwitch from './MuiSwitch';
import * as MuiLinearProgress from './MuiLinearProgress';
import * as MuiBreadcrumbs from './MuiBreadcrumbs';
import * as MuiToggleButtonGroup from './MuiToggleButtonGroup';
import * as MuiToggleButton from './MuiToggleButton';
import * as MuiSvgIcon from './MuiSvgIcon';

const components: Components<Theme> = {
  ...MuiAccordion,
  ...MuiButton,
  ...MuiDataGrid,
  ...MuiTextField,
  ...MuiIconButton,
  ...MuiTooltip,
  ...MuiListItemButton,
  ...MuiChip,
  ...MuiTab,
  ...MuiLink,
  ...MuiSnackbar,
  ...MuiTable,
  ...MuiDialog,
  ...MuiMenuItem,
  ...MuiInputLabel,
  ...MuiAlert,
  ...MuiSwitch,
  ...MuiLinearProgress,
  ...MuiCircularProgress,
  ...MuiBreadcrumbs,
  ...MuiToggleButtonGroup,
  ...MuiToggleButton,
  ...MuiSvgIcon,
};

export default components;

export const componentsDark: Components<Theme> = {
  ...MuiAccordion,
  ...MuiButtonDark,
  ...MuiDataGrid,
  ...MuiLinkDark,
  ...MuiDividerDark,
  ...MuiTextFieldDark,
  ...MuiCheckboxDark,
  ...MuiCircularProgress,
  ...MuiInputLabel,
  ...MuiSnackbar,
  ...MuiLinearProgress,
  ...MuiAlert,
  ...MuiSwitch,
  ...MuiBreadcrumbs,
  ...MuiOutlinedInput,
};
