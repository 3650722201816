import { IndicationConfigs } from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(
  oid: string,
  pid: string,
): Promise<IndicationConfigs> {
  return api
    .get<IndicationConfigs>(`/orgs/${oid}/${pid}/configs`)
    .then(({ data }) => data);
}
