import { HttpResponse, http } from 'msw';
import { allProgramRuns } from './data/program-runs';

const fetchAllProgramRunsByProgram = http.get(
  `*/api/v1/orgs/:oid/:pid/program-runs`,
  ({ request }) => {
    const url = new URL(request.url);
    const indicationId = url.searchParams.get('indicationId');
    return HttpResponse.json(
      allProgramRuns.find(run => run.workflowVersionID === indicationId),
    );
  },
);

const fetchOneProgramRun = http.get(
  `*/api/v1/orgs/:oid/:pid/program-run/:programRunId`,
  async ({ params }) => {
    return HttpResponse.json(
      allProgramRuns.find(run => run.id === params.programRunId),
    );
  },
);

export const programRunHandlers = [
  fetchAllProgramRunsByProgram,
  fetchOneProgramRun,
];
