import {
  Vector,
  VectorBulkUploadResponse,
  VectorLabelValues,
  VectorMapNames,
  VectorStatusValues,
} from '@formbio/api';
import {
  design,
  manufacturing,
  optimize,
  inVitro,
  inVivo,
} from './checkpoints';

const checkpoints = [design, optimize, inVitro, inVivo, manufacturing];
const checkpointsWithLead = [
  design,
  { ...optimize, label: VectorLabelValues.LEAD },
  inVitro,
  inVivo,
  manufacturing,
];
const checkpointsWithContender = [
  design,
  optimize,
  { ...inVitro, label: VectorLabelValues.CONTENDER },
  inVivo,
  manufacturing,
];
const checkpointsWithPaused = [
  design,
  optimize,
  inVitro,
  { ...inVivo, label: VectorLabelValues.PAUSED },
  manufacturing,
];

export const goi1Vectors: Vector[] = [
  {
    id: 'con1',
    title: 'CSY1_v1_apr4_a',
    description: 'v1',
    checkpoints: checkpointsWithLead,
    status: VectorStatusValues.IN_PROGRESS,
    mapName: VectorMapNames.CAG_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 1,
  },
  {
    id: 'con2',
    title: 'CSY1_v2_apr4_a',
    description: 'v2',
    checkpoints,
    status: VectorStatusValues.COMPLETED,
    mapName: VectorMapNames.CMV_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 0,
  },
  {
    id: 'con3',
    title: 'CSY1_v3_apr4_a',
    description: 'v3',
    checkpoints,
    status: VectorStatusValues.FAILED,
    mapName: VectorMapNames.EF1a_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 2,
  },
  {
    id: 'con4',
    title: 'CSY1_v4_apr4_a',
    description: 'v4',
    checkpoints,
    status: VectorStatusValues.COMPLETED,
    mapName: VectorMapNames.MCK_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 3,
  },
  {
    id: 'con5',
    title: 'CSY1_v1_apr5_a',
    description: 'v5',
    checkpoints: checkpointsWithPaused,
    status: VectorStatusValues.IN_PROGRESS,
    mapName: VectorMapNames.aMHC_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 3,
  },
  {
    id: 'con6',
    title: 'CSY1_v2_apr5_a',
    description: 'v5',
    checkpoints,
    status: VectorStatusValues.COMPLETED,
    mapName: VectorMapNames.cTNT_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 1,
  },

  {
    id: 'con7',
    title: 'CSY1_v4_apr7_a',
    description: 'v7',
    checkpoints,
    status: VectorStatusValues.READY,
    mapName: VectorMapNames.CAG_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 0,
  },
  {
    id: 'con8',
    title: 'CSY1_v3_apr8_a',
    description: 'v8',
    checkpoints: checkpointsWithContender,
    status: VectorStatusValues.COMPLETED,
    mapName: VectorMapNames.EF1a_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 2,
  },
  {
    id: 'con9',
    title: 'CSY1_v3_apr9_a',
    description: 'v9',
    checkpoints: checkpointsWithContender,
    status: VectorStatusValues.COMPLETED,
    mapName: VectorMapNames.cTNT_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 2,
  },
  {
    id: 'con10',
    title: 'CSY1_v3_apr10_a',
    description: 'v10',
    checkpoints,
    status: VectorStatusValues.COMPLETED,
    mapName: VectorMapNames.EF1a_GFP,
    createdAt: '2022-07-01T04:00:00.000Z',
    updatedAt: '2024-01-31T04:00:00.000Z',
    currentStateIdx: 3,
  },
];

export const vectorBulkUploadSuccess: VectorBulkUploadResponse = {
  vectorsCreated: 123,
};

export const vectorBulkUploadError: VectorBulkUploadResponse = {
  vectorsCreated: 0,
  errors: {
    '1': ['source file does not exist', 'vector name is empty'],
    '2': ['col 13: vadr.attributes["aavQC"].assay is empty'],
    '3': ['vector sequence is invalid'],
    '4': ['vector map name is missing'],
    '5': [
      'vector status is not recognized',
      'vector title is missing',
      'vector id is duplicated',
    ],
    '6': ['vector description is too long'],
    '7': ['vector checkpoints are incomplete'],
    '8': ['vector creation date is in the future'],
  },
};
