import { ResponseActor } from './actor';

export interface ContextEntity {
  type: string;
  id: string;
  displayName?: string;
}

export enum NoteStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}

export interface Note {
  id: string;
  createdAt: string;
  publishedAt?: string;
  editedAt?: string;
  deletedAt?: string;
  text: string;
  actor: ResponseActor;
  page: {
    path: string;
    title: string;
  };
  contextEntities: ContextEntity[];
  status: NoteStatus;
  email?: string;
}

export type Notes = Note[];
