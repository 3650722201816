import { Tooltip, TooltipProps } from './index';
import { ReactNode } from 'react';

type ConditionalTooltipProps = Omit<TooltipProps, 'title'> & {
  title?: ReactNode;
};

function shouldShowTooltip(
  props: ConditionalTooltipProps,
): props is TooltipProps {
  return !!props.title;
}

export default function ConditionalTooltip(props: ConditionalTooltipProps) {
  return shouldShowTooltip(props) ? (
    <Tooltip {...props}>{props.children}</Tooltip>
  ) : (
    props.children
  );
}
