import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiCircularProgress: CustomComponent<'MuiCircularProgress'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.MuiCircularProgress-colorPrimary': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.primary[900]
            : theme.palette.info[500],
      },
      svg: {
        strokeLinecap: 'round',
      },
    }),
  },
};
