import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiCheckbox: CustomComponent<'MuiCheckbox'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary[500],
      '&.Mui-checked': {
        color: theme.palette.secondary[500],
      },
    }),
  },
};
