import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  createCaasFlowRun,
  fetchAll,
  fetchOne,
  flowRunPat,
  flowRunVectorInsights,
} from '../../services/flow-runs';
import {
  CreateCaasFlowRunParams,
  CreateCaasFromPathParams,
  CreateCaasFromVectorIdParams,
  FlowRun,
  FlowRunPatParams,
} from '../../types';
import useInvalidation from '../useInvalidation';
import { AxiosError, AxiosResponse } from 'axios';

export const FLOW_RUN_KEY = 'flow-run';

export function useFlowRuns({
  orgId,
  pid,
  type,
  options,
}: {
  orgId: string;
  pid: string;
  type?: string;
  options?: Omit<UseQueryOptions<FlowRun[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<FlowRun[]>({
    queryKey: [FLOW_RUN_KEY, orgId, pid, type],
    queryFn: () => fetchAll(orgId, pid, type),
    enabled: !!orgId && !!pid,
    ...options,
  });
}

export function useFlowRun(
  params: Parameters<typeof fetchOne>[0],
  options?: Omit<UseQueryOptions<FlowRun>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<FlowRun>({
    queryKey: [FLOW_RUN_KEY, params],
    queryFn: () => fetchOne(params),
    ...{
      enabled: !!params.id,
      ...options,
    },
  });
}

export function useCreateCaasFlowRun(
  options?: UseMutationOptions<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    | CreateCaasFlowRunParams
    | CreateCaasFromVectorIdParams
    | CreateCaasFromPathParams
  >,
) {
  const invalidate = useInvalidation(FLOW_RUN_KEY);

  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    | CreateCaasFlowRunParams
    | CreateCaasFromVectorIdParams
    | CreateCaasFromPathParams
  >({
    mutationFn: (
      data:
        | CreateCaasFlowRunParams
        | CreateCaasFromVectorIdParams
        | CreateCaasFromPathParams,
    ) => createCaasFlowRun(data),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useFlowRunVectorInsights(
  oid: string,
  pid: string,
  options?: UseMutationOptions<{ json: string }, unknown, string>,
) {
  return useMutation<{ json: string }, unknown, string>({
    mutationFn: (flowRunId: string) =>
      flowRunVectorInsights({ oid, pid, flowRunId }),
    ...options,
  });
}

export function useVectorGeneration({
  orgId,
  pid,
  options,
}: {
  orgId: string;
  pid: string;
  options?: UseMutationOptions<unknown, unknown, FlowRunPatParams>;
}) {
  const invalidate = useInvalidation(FLOW_RUN_KEY);

  return useMutation<unknown, unknown, FlowRunPatParams>({
    mutationFn: (data: FlowRunPatParams) =>
      flowRunPat({
        ...data,
        orgId,
        pid,
      }),
    ...{
      ...options,
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
        return invalidate();
      },
      onError: (err) => {
        return err;
      },
    },
  });
}
