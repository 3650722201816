import { useEffect } from 'react';

import { Stack, styled } from '@formbio/ui/components';
import AvatarPicture from '@formbio/ui/components/Avatar/AvatarPicture';
import { AvatarSizes } from '@formbio/ui/components/Avatar/TextAvatar';
import { Button } from '@formbio/ui/components/Button';
import { IconButton } from '@formbio/ui/components/IconButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@formbio/ui/components/Dialog';
import { TextField } from '@formbio/ui/components/TextField';
import { List, ListItem } from '@formbio/ui/components/List';
import { MenuItem } from '@formbio/ui/components/Menu';
import { Typography } from '@formbio/ui/components/Typography';
import { CloseIconButton } from '@formbio/ui/components/Button/CloseIconButton';
import EmptyPrompt from '@formbio/ui/components/Card/EmptyPrompt';
import AddMemberInviteForm from './AddMemberInviteForm';
import InviteAllMembersForm from './InviteAllMembersForm';
import { Spacer } from '@formbio/ui/components/Layout';
import { DialogLinearProgress } from '@formbio/ui/components/LinearProgress';

import {
  Role,
  AwaitingInvitee,
  Member,
  AddMembersFormData,
} from '@formbio/ui/types/member';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  CaretDownFilledBackgroundRoundedIcon,
  XCircle as XCircleIcon,
} from '@formbio/ui/components/Icons';

/**
 * styled
 */
const StyledInitationHeaderTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary[900],
}));

const StyledListItem = styled(ListItem)({
  paddingLeft: 0,
  paddingRight: 0,
});

const StyledMenuItemTypography = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(8),
  textTransform: 'capitalize',
  color: 'inherit',
}));

const StyledNewMemberContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  spacing: theme.spacing(2),
  width: '100%',
  alignItems: 'center',
}));

const StyledNewMemberAvatar = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  spacing: theme.spacing(1),
  flex: 1,
  alignItems: 'center',
}));

const StyledEmailContainer = styled(Typography)({
  marginLeft: 5,
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

/**
 *
 * @param title
 * @param onClose
 * @param onSubmit
 * @param error
 * @param roles
 * @param onValidateMemberCanBeInvited
 * @param isLoading
 * @param hideDialogComponent wrap in dialog component
 * @param dialogActionButtons custom action buttons
 * @param members access to all org members
 * @param projectWizard component used in the project wizard
 *
 * @returns
 */
type Props = {
  open?: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (data: AddMembersFormData) => void;
  error?: string;
  roles: Role[];
  onValidateMemberCanBeInvited?: (
    awaitingInvitee: AwaitingInvitee,
  ) => Promise<void>;
  isLoading: boolean;
  hideDialogComponent?: boolean;
  dialogActionButtons?: React.ReactNode;
  members?: Member[];
  projectWizard?: boolean;
  initInvitees?: AwaitingInvitee[];
  onInviteesChange?: (invitees: AwaitingInvitee[]) => void;
};

function AddMembersDialog({
  open = true,
  title,
  onClose,
  onSubmit,
  roles,
  onValidateMemberCanBeInvited,
  isLoading,
  hideDialogComponent = false,
  dialogActionButtons,
  members,
  projectWizard = false,
  initInvitees,
  onInviteesChange,
}: Props) {
  const { control, handleSubmit } = useForm<AddMembersFormData>({
    defaultValues: {
      invites: initInvitees || [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invites',
  });

  useEffect(() => {
    onInviteesChange && onInviteesChange(fields);
  }, [fields]);

  const handleAddMemberToInvites = async (awaitingInvitee: AwaitingInvitee) => {
    if (fields.find(mem => mem.email === awaitingInvitee.email)) {
      throw new Error('Email is already in the invited list');
    }

    onValidateMemberCanBeInvited &&
      (await onValidateMemberCanBeInvited(awaitingInvitee));

    append(awaitingInvitee);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      wrapper={!hideDialogComponent}
    >
      <DialogTitle>
        {title}
        <CloseIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {projectWizard ? (
          <>
            <InviteAllMembersForm
              isLoading={isLoading}
              members={members}
              invitees={fields}
              onInviteesUpdate={invitees => {
                append(invitees);
              }}
              roles={roles}
            />
            <Spacer size='l' />
          </>
        ) : null}

        <AddMemberInviteForm
          onSubmit={handleAddMemberToInvites}
          isLoading={isLoading}
          roles={roles}
        />

        <StyledInitationHeaderTypography variant='body1'>
          Send Invitation to:
        </StyledInitationHeaderTypography>

        <form onSubmit={handleSubmit(onSubmit)} id='send-invitations'>
          {fields.length === 0 ? (
            <EmptyPrompt message='No members added yet' />
          ) : (
            <List dense>
              {fields.map((member, index) => (
                <StyledListItem key={member.id}>
                  <StyledNewMemberContainer>
                    <StyledNewMemberAvatar>
                      <AvatarPicture
                        size={AvatarSizes.small}
                        avatar={{
                          displayName: member.email,
                          email: member.email,
                        }}
                      />
                      <StyledEmailContainer>
                        {member.email}
                      </StyledEmailContainer>
                    </StyledNewMemberAvatar>
                    <Controller
                      control={control}
                      name={`invites.${index}.role`}
                      render={({ field }) => (
                        <TextField
                          select
                          aria-label={`Role for ${member.email}`}
                          SelectProps={{
                            IconComponent: CaretDownFilledBackgroundRoundedIcon,
                          }}
                          disabled={isLoading}
                          {...field}
                        >
                          {roles.map(role => (
                            <MenuItem value={role} key={role}>
                              <StyledMenuItemTypography>
                                {role}
                              </StyledMenuItemTypography>
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <StyledIconButton
                      aria-label='Remove invitee'
                      disabled={isLoading}
                      onClick={() => remove(index)}
                    >
                      <XCircleIcon />
                    </StyledIconButton>
                  </StyledNewMemberContainer>
                </StyledListItem>
              ))}
            </List>
          )}
        </form>
        {isLoading && <DialogLinearProgress />}
      </DialogContent>
      <DialogActions>
        {/**
         * custom dialog action buttons
         * used in create project and invite members wizard
         */}
        {dialogActionButtons ? (
          dialogActionButtons
        ) : (
          <>
            <Button
              type='button'
              variant='outlined'
              color='primary'
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              id='form-btn-send-invites'
              variant='contained'
              color='primary'
              disabled={isLoading || !fields.length}
              type='submit'
              form='send-invitations'
            >
              Invite Members
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AddMembersDialog;
