import type { Workflow, WorkflowVersionOverrides } from '../../types/workflow';
import { api } from '../../utils/api';

export function fetchAllForProject(oid: string, pid: string) {
  return api
    .get<{ workflows: Workflow[] }>(`/orgs/${oid}/${pid}/workflows/uploaded`)
    .then(({ data }) => data.workflows);
}

export function fetchOne(oid: string, pid: string, workflowId: string) {
  return api
    .get<Workflow>(`/orgs/${oid}/${pid}/workflow/${workflowId}`)
    .then(({ data }) => data);
}

export function update(
  oid: string,
  pid: string,
  workflowId: string,
  payload: WorkflowVersionOverrides,
) {
  return api
    .patch<string>(`/orgs/${oid}/${pid}/workflow/${workflowId}`, payload)
    .then(({ data }) => data);
}
