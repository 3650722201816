import { AlertColor, styled } from '@formbio/ui';
import { WarningCircle as WarningCircleIcon } from '@formbio/ui/components/Icons';

const StyledMessageWithIcon = styled('div')(({ theme, color }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  '.icon': {
    fontSize: theme.typography.body1?.fontSize,
    color,
  },
  '.error': {
    color: theme.palette.error.main,
  },
  '.warning': {
    color: theme.palette.warning.main,
  },
  '.success': {
    color: theme.palette.success.main,
  },
  '.info': {
    color: theme.palette.info.main,
  },
}));

export default function MessageWithIcon({
  severity,
  message,
}: {
  severity?: AlertColor;
  message: string;
}) {
  return (
    <StyledMessageWithIcon>
      {severity === 'error' && (
        <span className='icon error'>
          <WarningCircleIcon />
        </span>
      )}
      <span>{message}</span>
    </StyledMessageWithIcon>
  );
}
