import axios from 'axios';
import {
  FetchVaultParams,
  VaultItem,
  CopyVaultItemParams,
  FetchSignedURLParams,
  FetchVaultItemParams,
  TransformationParams,
  VectorTransformationParams,
} from '../../types';
import { api } from '../../utils/api';

export function removeItem(oid: string, pid: string, item: string) {
  return api.delete(`/orgs/${oid}/${pid}/vault`, {
    data: {
      objects: [item],
    },
  });
}

export function fetchAll(params: FetchVaultParams) {
  return api
    .get<VaultItem[]>(`/orgs/${params.oid}/${params.pid}/vault`, { params })
    .then(({ data }) => data);
}

export function fetchOne(params: FetchVaultItemParams) {
  const { oid, pid, objectName } = params;
  const searchParams: { [key: string]: string | number | boolean } = {
    objectName,
    includeProvenance: true,
  };

  return api
    .get<VaultItem>(`/orgs/${oid}/${pid}/vault/object`, {
      params: searchParams,
    })
    .then(({ data }) => data);
}

export function renameItem(
  oid: string,
  pid: string,
  oldPath: string,
  newPath: string,
) {
  return api
    .put<VaultItem[]>(`/orgs/${oid}/${pid}/vault/rename`, {
      object: oldPath,
      newName: newPath,
    })
    .then(({ data }) => data);
}

export function copyItem(
  oid: string,
  pid: string,
  params: CopyVaultItemParams,
) {
  const {
    path,
    destination,
    destinationOrg = oid,
    destinationProject = pid,
  } = params;

  return api
    .post<VaultItem[]>(`/orgs/${oid}/${pid}/vault/copy`, {
      destinationOrg,
      destinationProject,
      objects: [path],
      destination,
    })
    .then(({ data }) => data);
}

export function fetchSignedURL(params: FetchSignedURLParams) {
  const { oid, pid, path } = params;
  return api
    .get<{ signedURL: string }>(`/orgs/${oid}/${pid}/vault/download/${path}`)
    .then(({ data }) => data.signedURL);
}

export function fetchFileContent(url: string) {
  return axios
    .get(url, { transformResponse: (res) => res })
    .then(({ data }) => data);
}

export function transformIndication({
  oid,
  pid,
  params,
}: {
  oid: string;
  pid: string;
  params: TransformationParams;
}) {
  return api
    .post<{ json: string }>(
      `/orgs/${oid}/${pid}/vault/transformations/csv-to-json`,
      params,
    )
    .then(({ data }) => data);
}

export function transformVector({
  oid,
  pid,
  params,
}: {
  oid: string;
  pid: string;
  params: VectorTransformationParams;
}) {
  return api
    .post<{ 'seqviz.json': string }>(
      `/orgs/${oid}/${pid}/isa-vector/${params.vectorId}/seqviz`,
      params,
    )
    .then(({ data }) => data);
}

export function runVectorInsights({
  oid,
  pid,
  vectorId,
}: {
  oid: string;
  pid: string;
  vectorId: string;
}) {
  return api
    .post<{ json: string }>(
      `/orgs/${oid}/${pid}/isa-vector/${vectorId}/insights`,
    )
    .then(({ data }) => data);
}
