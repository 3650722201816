import type { WorkflowVersion } from '../../types/workflow-version';
import { api } from '../../utils/api';
import { WorkflowVersionPatch } from '../admin/workflows';

export function fetchAll(oid: string, pid: string) {
  return api
    .get<{ workflowVersions: WorkflowVersion[] }>(
      `/orgs/${oid}/${pid}/workflows`,
    )
    .then(({ data }) => data.workflowVersions);
}

export function fetchOneByKey(params: {
  oid: string;
  pid: string;
  repo: string;
  name: string;
  version: string;
  uploadedOrg: string | undefined;
  uploadedProj: string | undefined;
}) {
  const { oid, pid, repo, name, version, uploadedOrg, uploadedProj } = params;
  return api
    .get<WorkflowVersion>(
      `/orgs/${oid}/${pid}/workflows/version/${uploadedOrg}/${uploadedProj}/${repo}/${name}`,
      {
        params: {
          version,
        },
      },
    )
    .then(({ data }) => data);
}

export function fetchAllByKey(params: {
  oid: string;
  pid: string;
  repo: string;
  name: string;
}) {
  const { oid, pid, repo, name } = params;
  return api
    .get<{ workflowVersions: WorkflowVersion[] }>(
      `/orgs/${oid}/${pid}/workflows/versions/${repo}/${name}`,
    )
    .then(({ data }) => data.workflowVersions);
}

export function fetchOneByVersionId({
  oid,
  pid,
  id,
}: {
  oid: string;
  pid: string;
  id?: string;
}) {
  return api
    .get<WorkflowVersion>(`/orgs/${oid}/${pid}/workflow-version/${id}`)
    .then(({ data }) => data);
}

export function fetchAllForProject(oid: string, pid: string) {
  return api
    .get<{ workflowVersions: WorkflowVersion[] }>(
      `/orgs/${oid}/${pid}/workflows/uploaded/versions`,
    )
    .then(({ data }) => data.workflowVersions);
}

export function fetchAllForWorkflow(
  oid: string,
  pid: string,
  workflowId: string,
) {
  return api
    .get<{ workflowVersions: WorkflowVersion[] }>(
      `/orgs/${oid}/${pid}/workflows/uploaded/${workflowId}/versions`,
    )
    .then(({ data }) => data.workflowVersions);
}

export async function patchWorkflowVersion({
  oid,
  pid,
  versionId,
  payload,
}: {
  oid: string;
  pid: string;
  versionId: string;
  payload: WorkflowVersionPatch;
}) {
  const { data } = await api.patch<WorkflowVersion>(
    `/orgs/${oid}/${pid}/workflow-version/${versionId}`,
    payload,
  );
  return data;
}

export async function deleteWorkflowVersion({
  oid,
  pid,
  versionId,
}: {
  oid: string;
  pid: string;
  versionId: string;
}) {
  const { data } = await api.delete<WorkflowVersion>(
    `/orgs/${oid}/${pid}/workflow-version/${versionId}`,
  );
  return data;
}

export async function undeleteWorkflowVersion({
  oid,
  pid,
  versionId,
}: {
  oid: string;
  pid: string;
  versionId: string;
}) {
  const { data } = await api.patch<WorkflowVersion>(
    `/orgs/${oid}/${pid}/workflow-version/${versionId}/undelete`,
  );
  return data;
}

export function fetchOneByCompoundId(
  oid: string,
  pid: string,
  workflowId?: string,
  versionId?: string,
) {
  return api
    .get<WorkflowVersion>(
      `/orgs/${oid}/${pid}/workflows/uploaded/${workflowId}/versions/${versionId}`,
    )
    .then(({ data }) => data);
}
