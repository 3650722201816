export enum Role {
  Editor = 'editor',
  Admin = 'admin',
  Owner = 'owner',
  Viewer = 'viewer',
  Member = 'member',
}

export const OrganizationRoles = [Role.Owner, Role.Admin, Role.Member];
export const ProjectRoles = [Role.Admin, Role.Editor, Role.Viewer];

export interface Member {
  user: MemberUser;
  role: Role;
  createdAt: string;
  deletedAt?: string;
  scopes?: string[];
}

export interface EmailNotificationPreferences {
  [key: string]: boolean;
}
export interface MemberUser {
  created_at: string;
  updated_at: string;
  email: string;
  name: string;
  email_notification_preferences: EmailNotificationPreferences;
}

// MemberData interface was used in context of inviting new members
export interface MemberRow {
  isMember: boolean;
  email: string;
  role: Role;
  name?: string;
  createdAt?: string;
  expiresAt?: string;
  deletedAt?: string;
}

export interface AwaitingInvitee {
  email: string;
  role: Role;
}
export interface Invitee extends AwaitingInvitee {
  createdAt: string;
  expiresAt: string;
  deletedAt?: string;
}
