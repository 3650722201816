import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import {
  fetchAllIGVGenomes,
  fetchAllMetadata,
  fetchIGVGenome,
} from '../../services/genomes';
import { GenomeMetadata, IGVGenome } from '../../types';

const GENOMES_METADATA_KEY = 'genomes-metadata';

export function useGenomesMetadata<TData = GenomeMetadata[]>(
  oid: string,
  pid: string,
  options?: Omit<UseQueryOptions<GenomeMetadata[], unknown, TData>, 'queryKey'>,
) {
  return useQuery<GenomeMetadata[], unknown, TData>({
    queryKey: [GENOMES_METADATA_KEY, { oid, pid }],
    queryFn: () => fetchAllMetadata(oid, pid),
    ...options,
  });
}

export const GENOMES_KEY = 'genomes';

export function useIGVGenomes(
  oid: string,
  pid: string,
  options?: Omit<UseQueryOptions<IGVGenome[]>, 'queryKey'>,
) {
  return useQuery<IGVGenome[]>({
    queryKey: [GENOMES_KEY, { oid, pid }],
    queryFn: () => fetchAllIGVGenomes(oid, pid),
    ...options,
  });
}

export function useIGVGenome(
  oid: string,
  pid: string,
  genomeId: string,
  options?: Omit<UseQueryOptions<IGVGenome[]>, 'queryKey'>,
) {
  return useQuery<IGVGenome[]>({
    queryKey: [GENOMES_KEY, { oid, pid, genomeId }],
    queryFn: () => fetchIGVGenome(oid, pid, genomeId),
    ...options,
  });
}

export function isGenomeIdFromFile(genomeId: string) {
  return genomeId.startsWith('from-file:');
}
