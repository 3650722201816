import { DragEvent, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

export default function useDragAndDrop(
  dropHandler: (event: DragEvent<HTMLDivElement>) => void,
) {
  const [isDragActive, setIsDragActive] = useState(false);
  // sometimes hovering children will trigger onDragLeave
  // this debounce will prevent flashing of the drag/rest styles
  const debouncedSetIsDragActive = useMemo(() => {
    return debounce(setIsDragActive, 250);
  }, []);
  useEffect(() => {
    return () => {
      debouncedSetIsDragActive.cancel();
    };
  }, []);

  function handleDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    debouncedSetIsDragActive.cancel();
    setIsDragActive(false);
    dropHandler(event);
  }

  function handleDragOver(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    debouncedSetIsDragActive(true);
  }

  function handleDragEnter(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  }

  function handleDragLeave(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    debouncedSetIsDragActive(false);
  }
  return {
    isDragActive,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
  };
}
