import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  addMember,
  addMembers,
  create,
  CreateProjectData,
  deleteProject,
  fetchAll,
  fetchOne,
  MemberData,
  removeMember,
  undeleteProject,
  update,
  updateMember,
  UpdateProjectData,
} from '../../services/projects';
import { AwaitingInvitationToProject } from '../../types';
import useInvalidation from '../useInvalidation';
import { Project } from '../../types';
import { AxiosResponse } from 'axios';
import { KEY as ORGS_KEY } from '../organizations';
import { useInvalidateQuotasUsage } from '../account';

const KEY = 'projects';

export function useProjects(
  options: Omit<UseQueryOptions<Project[]>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<Project[]>({
    queryKey: [KEY],
    queryFn: () => fetchAll(),
    ...{ retry: 1, ...options },
  });
}

export function useProject({
  oid,
  pid,
  options = {},
}: {
  oid: string;
  pid: string;
  options?: Omit<UseQueryOptions<Project>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<Project>({
    queryKey: [KEY, { oid, pid }],
    queryFn: () => {
      return fetchOne(oid, pid);
    },
    ...{
      enabled: !!oid && !!pid,
      ...options,
    },
  });
}

export function useRemoveProjectMember({
  oid,
  pid,
  options = {},
}: {
  oid: string;
  pid: string;
  options?: UseMutationOptions<unknown, unknown, string>;
}) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation<unknown, unknown, string>({
    mutationFn: (email: string) => removeMember(oid, pid, email),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        invalidate({ oid, pid });
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

export function useAddProject(
  options: UseMutationOptions<
    AxiosResponse<{ message: string; id: string }>,
    unknown,
    CreateProjectData
  > = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation<
    AxiosResponse<{ message: string; id: string }>,
    unknown,
    CreateProjectData
  >({
    mutationFn: (data: CreateProjectData) => create(data),
    ...{
      ...options,
      onSuccess: (_data, vars, context) => {
        invalidateOrgs();
        invalidate();
        invalidateUsage(vars.oid);
        options.onSuccess && options.onSuccess(_data, vars, context);
      },
    },
  });
}

export function useEditProject(
  options: UseMutationOptions<
    AxiosResponse<{ message: string; id: string }>,
    unknown,
    UpdateProjectData
  > = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);

  return useMutation<
    AxiosResponse<{ message: string; id: string }>,
    unknown,
    UpdateProjectData
  >({
    mutationFn: (data: UpdateProjectData) => update(data),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        return invalidate();
      },
      ...options,
    },
  });
}

export function useDeleteProject(
  oid: string,
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation<unknown, unknown, string>({
    mutationFn: (pid: string) => deleteProject(oid, pid),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        invalidate();
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

export function useUndeleteProject(
  oid: string,
  options: UseMutationOptions<unknown, unknown, string> = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation<unknown, unknown, string>({
    mutationFn: (pid: string) => undeleteProject(oid, pid),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        invalidate();
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

export function useAddProjectMember({
  oid,
  pid,
  options = {},
}: {
  oid: string;
  pid: string;
  options?: UseMutationOptions<unknown, unknown, MemberData>;
}) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation<unknown, unknown, MemberData>({
    mutationFn: (data: MemberData) => addMember(oid, pid, data),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        invalidate();
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

export function useUpdateProjectMember({
  oid,
  pid,
  options = {},
}: {
  oid: string;
  pid: string;
  options?: UseMutationOptions<unknown, unknown, MemberData>;
}) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);

  return useMutation<unknown, unknown, MemberData>({
    mutationFn: (data: MemberData) => updateMember(oid, pid, data),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        return invalidate({ oid, pid });
      },
      ...options,
    },
  });
}

export function useAddProjectMembers({
  oid,
  pid,
  options = {},
}: {
  oid: string;
  pid: string;
  options?: UseMutationOptions<unknown, unknown, MemberData[]>;
}) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation<unknown, unknown, MemberData[]>({
    mutationFn: (data: MemberData[]) => addMembers(oid, pid, data),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        invalidate({ oid, pid });
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}

/**
 * invite multiple members to a project
 * don't have access to project id when hook is initialized
 */
export function useInviteProjectMembers(
  oid: string,
  options: UseMutationOptions<
    unknown,
    unknown,
    AwaitingInvitationToProject
  > = {},
) {
  const invalidate = useInvalidation(KEY);
  const invalidateOrgs = useInvalidation(ORGS_KEY);
  const invalidateUsage = useInvalidateQuotasUsage();

  return useMutation<unknown, unknown, AwaitingInvitationToProject>({
    mutationFn: (params: AwaitingInvitationToProject) =>
      addMembers(oid, params.pId, params.invitees),
    ...{
      onSuccess: () => {
        invalidateOrgs();
        invalidate({ oid });
        invalidateUsage(oid);
      },
      ...options,
    },
  });
}
