import { faker } from '@faker-js/faker';
import { JourneyRun } from '@formbio/api';
import { allJourneys } from './journeys';
import { timelapseJourneyRun } from './journey-run-timelapse';

const startedAt1 = faker.date.recent({ days: 15 }).toISOString();
const completedAt1 = faker.date.recent({ days: 13 }).toISOString();
const startedAt2 = faker.date.recent({ days: 10 }).toISOString();
const completedAt2 = faker.date.recent({ days: 9 }).toISOString();
const startedAt3 = faker.date.recent({ days: 7 }).toISOString();
const completedAt3 = faker.date.recent({ days: 6 }).toISOString();
const startedAt4 = faker.date.recent({ days: 5 }).toISOString();
const completedAt4 = faker.date.recent({ days: 4 }).toISOString();
const startedAt5 = faker.date.recent({ days: 3 }).toISOString();
const completedAt5 = faker.date.recent({ days: 2 }).toISOString();

export const allJourneyRuns: JourneyRun[] = [
  {
    id: '1',
    journey: allJourneys[0],
    slug: 'crispr-run-1',
    phaseRuns: allJourneys[0].phases.map(phase => ({
      id: phase.id,
      slug: `${phase.slug}-run`,
      phase,
    })),
    runName: 'CRISPR Run 1',
  },
  {
    id: '2',
    journey: allJourneys[0],
    slug: 'crispr-run-phase-1-started',
    phaseRuns: [
      {
        id: allJourneys[0].phases[0].id,
        slug: `${allJourneys[0].phases[0].slug}-run-1`,
        phase: allJourneys[0].phases[0],
        startedAt: startedAt1,
        pctCompleted: 76,
        eta: faker.date.soon().toISOString(),
      },
    ],
    runName: 'CRISPR Run 2',
  },
  {
    id: '3',
    journey: allJourneys[0],
    slug: 'crispr-run-phase-2-completed',
    phaseRuns: [
      {
        id: allJourneys[0].phases[0].id,
        slug: `${allJourneys[0].phases[0].slug}-run-3`,
        phase: allJourneys[0].phases[0],
        startedAt: startedAt1,
        pctCompleted: 100,
        eta: completedAt1,
        completedAt: completedAt1,
      },
      {
        id: allJourneys[0].phases[1].id,
        slug: `${allJourneys[0].phases[1].slug}-run-3`,
        phase: allJourneys[0].phases[1],
        startedAt: startedAt2,
        pctCompleted: 100,
        eta: completedAt2,
        completedAt: completedAt2,
      },
      {
        id: allJourneys[0].phases[2].id,
        slug: `${allJourneys[0].phases[2].slug}-run-3`,
        phase: allJourneys[0].phases[2],
      },
    ],
    runName: 'CRISPR Run 3',
  },
  {
    id: '4',
    journey: allJourneys[0],
    slug: 'crispr-run-phase-3-running',
    phaseRuns: [
      {
        id: allJourneys[0].phases[0].id,
        slug: `${allJourneys[0].phases[0].slug}-run-4`,
        phase: allJourneys[0].phases[0],
        startedAt: startedAt1,
        pctCompleted: 100,
        eta: completedAt1,
        completedAt: completedAt1,
      },
      {
        id: allJourneys[0].phases[1].id,
        slug: `${allJourneys[0].phases[1].slug}-run-4`,
        phase: allJourneys[0].phases[1],
        startedAt: startedAt2,
        pctCompleted: 100,
        eta: completedAt2,
        completedAt: completedAt2,
      },
      {
        id: allJourneys[0].phases[2].id,
        slug: `${allJourneys[0].phases[2].slug}-run-4`,
        phase: allJourneys[0].phases[2],
        startedAt: startedAt2,
        pctCompleted: 32.54,
        eta: completedAt2,
      },
    ],
    runName: 'CRISPR Run 4',
  },
  {
    id: '5',
    journey: allJourneys[0],
    slug: 'crispr-run-phase-5-completed',
    phaseRuns: [
      {
        id: allJourneys[0].phases[0].id,
        slug: `${allJourneys[0].phases[0].slug}-run-5`,
        phase: allJourneys[0].phases[0],
        startedAt: startedAt1,
        pctCompleted: 100,
        eta: completedAt1,
        completedAt: completedAt1,
      },
      {
        id: allJourneys[0].phases[1].id,
        slug: `${allJourneys[0].phases[1].slug}-run-5`,
        phase: allJourneys[0].phases[1],
        startedAt: startedAt2,
        pctCompleted: 100,
        eta: completedAt2,
        completedAt: completedAt2,
      },
      {
        id: allJourneys[0].phases[2].id,
        slug: `${allJourneys[0].phases[2].slug}-run-5`,
        phase: allJourneys[0].phases[2],
        startedAt: startedAt3,
        pctCompleted: 100,
        eta: completedAt3,
        completedAt: completedAt3,
      },
      {
        id: allJourneys[0].phases[3].id,
        slug: `${allJourneys[0].phases[3].slug}-run-5`,
        phase: allJourneys[0].phases[3],
        startedAt: startedAt4,
        pctCompleted: 100,
        eta: completedAt4,
        completedAt: completedAt4,
      },
      {
        id: allJourneys[0].phases[4].id,
        slug: `${allJourneys[0].phases[4].slug}-run-5`,
        phase: allJourneys[0].phases[4],
        startedAt: startedAt5,
        pctCompleted: 100,
        eta: completedAt5,
        completedAt: completedAt5,
      },
    ],
    runName: 'CRISPR Run 5',
  },
  timelapseJourneyRun,
];
