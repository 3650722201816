import { ReactElement } from 'react';
import { UrlObject } from 'url';
import { NavRoute } from './routes';

export enum NavListItemType {
  Leaf,
  Parent,
}

export interface NavListItem {
  label: string;
  startIcon?: ReactElement;
  type?: NavListItemType;
}

export interface NavigableItem<E> extends NavListItem, NavRoute<E> {
  parentId?: string;
  href: UrlObject;
  //an alternate pathname that can be use for matching a nav item to another route (eg. default page for project matches .../workflow-runs/ and .../[pid]/)
  altHref?: UrlObject;
  type: NavListItemType.Leaf;
  disabled?: boolean;
}
export interface AccordionItem<E> extends NavListItem {
  children: NavigableItem<E>[];
  type: NavListItemType.Parent;
}

export type NavItemNode<E> = NavigableItem<E> | AccordionItem<E>;
