import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@mui/material';
import { Typography } from '@formbio/ui/components/Typography';
import { Link, LinkProps } from '@formbio/ui/components/Link';
import NextMuiLink, {
  NextMuiLinkProps,
} from '@formbio/ui/components/Link/NextMuiLink';

export type BreadcrumbsProps = Omit<MuiBreadcrumbsProps, 'sx'>;

export const Breadcrumbs = ({ ...rest }: BreadcrumbsProps) => (
  <MuiBreadcrumbs {...rest} />
);

export const Breadcrumb = ({ children }: { children: React.ReactNode }) => (
  <Typography>{children}</Typography>
);

export const BreadcrumbLink = (props: LinkProps) => (
  <Link underline='hover' color='inherit' {...props} />
);

export const BreadcrumbNextLink = (props: NextMuiLinkProps) => (
  <NextMuiLink underline='hover' color='inherit' {...props} />
);
