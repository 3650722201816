import useInvalidation from '../../useInvalidation';
import {
  changeAccountQuota,
  changeTierQuota,
} from '../../../services/admin/quotas';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { TIERS_KEY } from '../../marketplace';
import { ACCOUNT_KEY } from '../../account';

export function useAccountQuotaChange(
  options?: UseMutationOptions<
    unknown,
    unknown,
    { newQuota: number; accountQuotaId: string }
  >,
) {
  const invalidate = useInvalidation(ACCOUNT_KEY);

  return useMutation<
    unknown,
    unknown,
    { accountQuotaId: string; newQuota: number }
  >({
    mutationFn: ({
      newQuota,
      accountQuotaId,
    }: {
      newQuota: number;
      accountQuotaId: string;
    }) => changeAccountQuota({ newQuota, accountQuotaId }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useTierQuotaChange(
  options?: UseMutationOptions<
    unknown,
    unknown,
    { newQuota: number; tierQuotaId: string }
  >,
) {
  const invalidate = useInvalidation(TIERS_KEY);

  return useMutation<
    unknown,
    unknown,
    { tierQuotaId: string; newQuota: number }
  >({
    mutationFn: ({
      newQuota,
      tierQuotaId,
    }: {
      newQuota: number;
      tierQuotaId: string;
    }) => changeTierQuota({ newQuota, tierQuotaId }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
