import { isAxiosError } from '@formbio/api';
import { capitalize } from '@mui/material';
import bytes, { BytesOptions } from 'bytes';

const SPLITTER_REGEXP = new RegExp(/[ -]+/);
/**
 * Tries to take the first letter of the first 2 words of the text
 * If it can't, uses fallbacks.
 * @param text
 * @returns
 */
export function reduceTextToTwoLetters(text: string) {
  // no text -> empty TextAvatar
  // text is too short (should never happen for projects)
  if (text.length <= 1) {
    return text;
  }
  // split on spaces and hyphens
  const items = text.split(SPLITTER_REGEXP);
  if (items.length > 1) {
    // take the first letter of the first 2 items
    return items[0][0] + items[1][0];
  }
  // no separators, take the first 2 letters of the text
  return text.substring(0, 2);
}

export function pluralize({
  number,
  singular,
  plural,
}: {
  number: number;
  singular: string;
  plural: string;
}) {
  return `${number} ${number === 1 ? singular : plural}`;
}

/**
 * Truncate a text with a middle ellipsis if
 * it's longer than an arbitrary length
 * @returns the text with ellipsis if needed
 */
export function trimStringWithMiddleEllipsis({
  text,
  //arbitrary length. Less messy than trying to calculate the real length
  maxLength = 50,
}: {
  text: string | undefined;
  maxLength?: number;
}) {
  if (!text) return '';
  if (text.length < maxLength) return text;
  const ellipsis = '...';
  const charsOnEitherSide = Math.floor(maxLength / 2) - ellipsis.length;
  return (
    text.slice(0, charsOnEitherSide) + ellipsis + text.slice(-charsOnEitherSide)
  );
}

/**
 * Formatter for a list of items matching i18n standards for English
 * @example ['a', 'b', 'c'] -> 'a, b, and c'
 */
export const listFormatterAnd = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

/**
 * Formatter for a list of items matching i18n standards for English
 * @example ['a', 'b', 'c'] -> 'a, b, or c'
 */
export const listFormatterOr = new Intl.ListFormat('en', {
  style: 'long',
  type: 'disjunction',
});

export function getExtensionFromFileName(name: string) {
  const items = name.split('.');
  return items[items.length - 1];
}

export function formatPct(value: number) {
  return (value * 100).toFixed(2) + '%';
}

export function escapeCharsForRegex(val: string) {
  return val.replace(/[.*+?^${}()[\]\\]/g, '\\$&');
}

export function escapeCharsForRegexPipe(val: string) {
  return val.replace(/[.*+?^${}()[|\]\\]/g, '\\$&');
}

export function formatBytes(size: number, options?: BytesOptions) {
  const defaultOptions = { decimalPlaces: 2 };
  return bytes(
    size,
    options ? { ...defaultOptions, ...options } : defaultOptions,
  );
}

export function replaceFromStartEnd(
  start: number,
  end: number,
  text: string,
  replacement: string,
) {
  return text.substring(0, start) + replacement + text.substring(end);
}

export function formatFBU(value: number) {
  return `${Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  }).format(value)} FBU`;
}

export function formatError(error: unknown) {
  if (!error) return;
  if (isAxiosError(error)) {
    return error.response?.data.message;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return JSON.stringify(error);
}

export function centsToDollarsString(cents: number) {
  return `$${(cents / 100).toFixed(2)}`;
}

export function capitalizeEachWord(text: string) {
  return text
    .split(' ')
    .map(word => capitalize(word))
    .join(' ');
}

// Regex for URL validation
export const URL_REGEXP = /^(https?:\/\/|www\.)\S+$/i;

// Displays formatted number to n decimal places or the whole number
// e.g. 124.2345 to 2 decimal places -> 124.23
// 123 to 2 decimal places -> 123
export function formatNumberDecimalPlaces(
  num: number,
  decimalPlaces = 2,
): string {
  return num % 1 === 0 ? num.toString() : num.toFixed(decimalPlaces);
}

export const CHECKMARK_CHAR = '\u2713';
// not great support for DNA unicode character
// but it's much easier to use in charts than a real icon
export const DNA_CHAR = '🧬';

export function roundToDecimalPlaces(num: number, decimalPlaces: number) {
  return Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}

export function tallyArray(array: number[]) {
  return array.reduce((acc, value) => acc + value, 0);
}
