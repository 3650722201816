import { fetchAllAdmin } from '../../../services/admin/organizations';
import { useQuery } from '@tanstack/react-query';
import { Organization } from '../../../types';

const KEY = 'organizations';

export function useOrganizationsAdmin() {
  const organizationsQuery = useQuery({
    queryKey: [KEY],
    queryFn: fetchAllAdmin,
  });
  const allOrganizations: Organization[] | undefined = organizationsQuery.data;

  return {
    allOrganizations,
    isLoading: organizationsQuery.isLoading,
    isError: organizationsQuery.isError,
  };
}
