import { styled } from '@formbio/ui/components';

const Section = styled('div', {
  shouldForwardProp: propName => propName !== 'border',
})<{ border?: boolean }>(({ theme, border }) => ({
  width: '100%',
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  borderBottom: border ? `1px solid ${theme.palette.grey[300]}` : undefined,
  paddingBlock: theme.spacing(3),
  paddingInline: theme.spacing(3),
}));

export { Section };
