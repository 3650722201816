import { useState, forwardRef } from 'react';
import { OutlinedInput, InputAdornment } from '@formbio/ui/components';
import { IconButton } from '@formbio/ui/components/IconButton';
import {
  EyeSlash as EyeSlashIcon,
  Eye as EyeIcon,
} from '@formbio/ui/components/Icons';
import { OutlinedInputProps } from '@mui/material';

const PasswordField = forwardRef<HTMLDivElement, OutlinedInputProps>(
  function PasswordField(props, ref) {
    const [showPassword, setShowPassword] = useState(false);
    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
    };

    return (
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(showPassword => !showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? (
                <EyeSlashIcon weight='fill' />
              ) : (
                <EyeIcon weight='fill' />
              )}
            </IconButton>
          </InputAdornment>
        }
        {...props}
        inputRef={ref}
      />
    );
  },
);

export default PasswordField;
