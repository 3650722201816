import { forwardRef } from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps,
  InputBaseProps,
} from '@mui/material';

export type { TextFieldProps, InputBaseProps };

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  function TextField(props, ref) {
    return <MuiTextField {...props} inputRef={ref} />;
  },
);
