import { getLaterDate } from '@/utils/datetime';
import { ActorType } from '@formbio/api/src/types/actor';
import { Notes, NoteStatus } from '@formbio/api/src/types/note';

const demoNotes: Notes = [
  {
    id: 'test-response0',
    createdAt: '2022-11-30T11:32:02.496028Z',
    publishedAt: '2022-11-30T11:32:02.496028Z',
    editedAt: '2022-11-30T14:14:02.496028Z',
    text: 'Run has good results but missing data.\nSent results to PI on 01/01/2023.\n\n\nUpdate: PI said to go ahead with results.',
    actor: {
      type: ActorType.USER,
      id: '123456',
      name: 'Guillaume Jimenez',
    },
    email: 'guillaume@formbio.com',
    page: {
      path: '/organizations/fake-organization/projects/fake-project/workflow-runs/ffffff-a420-41b2-93bb-38fff3b3fff/?previousPageParams=%7B%22orgId%22%3A%22fake-organization%22%2C%22pid%22%3A%22fake-project%22%2C%22status%22%3A%22%22%2C%22users%22%3A%22%22%7D',
      title: 'Workflow Activity - FormBio',
    },
    contextEntities: [
      {
        type: 'orgId',
        id: 'fake-organization',
        displayName: 'Fake Organization',
      },
      {
        type: 'pid',
        id: 'fake-project',
        displayName: 'Fake Project',
      },
      {
        type: 'runId',
        id: 'ffffff-a420-41b2-93bb-38fff3b3fff',
        displayName: 'Fake Workflow',
      },
      {
        type: 'previousPageParams',
        id: '{"orgId":"fake-organization","pid":"fake-project","status":"","users":""}',
      },
      {
        type: 'pathname',
        id: '/organizations/[orgId]/projects/[pid]/workflow-runs/[runId]',
      },
    ],
    status: NoteStatus.PUBLISHED,
  },
  {
    id: 'test-response2',
    createdAt: '2022-01-30T16:16:02.496028Z',
    publishedAt: '2022-01-30T16:16:02.496028Z',
    text: 'Staying home',
    actor: {
      type: ActorType.USER,
      id: '1234567',
      name: 'Doug Daniels',
    },
    email: 'doug@formbio.com',
    page: {
      path: '/organizations/fake-organization/projects/fake-project/home/',
      title: 'Project Home - FormBio',
    },
    contextEntities: [
      {
        type: 'orgId',
        id: 'fake-organization',
        displayName: 'Fake Organization',
      },
      {
        type: 'pid',
        id: 'fake-project',
        displayName: 'Fake Project',
      },
      {
        type: 'pathname',
        id: '/organizations/[orgId]/projects/[pid]/home',
      },
    ],
    status: NoteStatus.PUBLISHED,
  },
  {
    id: 'test-response3',
    createdAt: '2022-02-30T07:07:02.496028Z',
    publishedAt: '2022-02-30T07:07:02.496028Z',
    text: "Look at 'em workflow runs!",
    actor: {
      type: ActorType.USER,
      id: '1234567',
      name: 'Doug Daniels',
    },
    email: 'doug@formbio.com',
    page: {
      path: '/organizations/fake-organization/projects/fake-project/workflow-runs/?status=&users=',
      title: 'Workflow Activity - FormBio',
    },
    contextEntities: [
      {
        type: 'orgId',
        id: 'fake-organization',
        displayName: 'Fake Organization',
      },
      {
        type: 'pid',
        id: 'fake-project',
        displayName: 'Fake Project',
      },
      {
        type: 'status',
        id: '',
      },
      {
        type: 'users',
        id: '',
      },
      {
        type: 'pathname',
        id: '/organizations/[orgId]/projects/[pid]/workflow-runs',
      },
    ],
    status: NoteStatus.PUBLISHED,
  },
  {
    id: 'test-response4',
    createdAt: '2023-02-23T18:27:19.474Z',
    publishedAt: '2023-02-23T18:27:19.474Z',
    text: 'My beautiful Fake Org.',
    actor: {
      type: ActorType.USER,
      id: 'XXXXX',
      name: 'John Doe',
    },
    email: 'john+doe@formbio.com',
    page: {
      path: '/organizations/fake-organization/',
      title: 'Projects - FormBio',
    },
    contextEntities: [
      {
        type: 'orgId',
        id: 'fake-organization',
        displayName: 'Fake Organization',
      },
      {
        type: 'pathname',
        id: '/organizations/[orgId]',
      },
    ],
    status: NoteStatus.PUBLISHED,
  },
  {
    id: 'test-response5',
    createdAt: '2023-02-28T18:13:31.207Z',
    publishedAt: '2023-02-28T18:13:31.207Z',
    text: 'an almost empty members list, edited',
    actor: {
      type: ActorType.USER,
      id: 'XXXXX',
      name: 'John Doe',
    },
    email: 'john+doe@formbio.com',
    page: {
      path: '/organizations/fake-organization/projects/fake-project/members/',
      title: 'Members (Fake Project) - FormBio',
    },
    contextEntities: [
      {
        type: 'orgId',
        id: 'fake-organization',
        displayName: 'Fake Organization',
      },
      {
        type: 'pid',
        id: 'fake-project',
        displayName: 'Fake Project',
      },
      {
        type: 'pathname',
        id: '/organizations/[orgId]/projects/[pid]/members',
      },
    ],
    editedAt: '2023-02-28T18:13:54.836Z',
    status: NoteStatus.PUBLISHED,
  },
  //create 10000 new notes
  ...Array.from({ length: 10000 }, (_, i) => ({
    id: `test-response${20000 - i}`,
    createdAt: getLaterDate(new Date('1995-12-17T03:24:00'), {
      hours: i,
    }).toISOString(),
    publishedAt: getLaterDate(new Date('1995-12-17T03:24:00'), {
      hours: i,
    }).toISOString(),
    text: `Note ${20000 - i}`,
    actor: {
      type: ActorType.USER,
      id: 'XXXXX',
      name: 'John Doe',
    },
    email: 'john+doe@formbio.com',
    page: {
      path: '/organizations/fake-organization/projects/fake-project/members/',
      title: 'Members (Fake Project) - FormBio',
    },
    contextEntities: [
      {
        type: 'orgId',
        id: 'fake-organization',
        displayName: 'Fake Organization',
      },
      {
        type: 'pid',
        id: 'fake-project',
        displayName: 'Fake Project',
      },
      {
        type: 'pathname',
        id: '/organizations/[orgId]/projects/[pid]/members',
      },
    ],
    status: NoteStatus.PUBLISHED,
  })),
];

export default demoNotes;
