import {
  Tabs as MuiTabs,
  TabsTypeMap as MuiTabsProps,
  Tab as MuiTab,
  TabProps as MuiTabProps,
} from '@mui/material';

import MuiTabList, { TabListProps as MuiTabListProps } from '@mui/lab/TabList';
import MuiTabPanel, {
  TabPanelProps as MuiTabPanelProps,
} from '@mui/lab/TabPanel';
import type { OverridableComponent } from '@formbio/ui/components';
import React, { forwardRef } from 'react';

export type TabProps = Omit<MuiTabProps, 'sx'>;
export type TabsProps = Omit<
  MuiTabsProps,
  | 'centered'
  | 'classes'
  | 'indicatorColor'
  | 'orientation'
  | 'selectionFollowsFocus'
  | 'TabIndicatorProps'
  | 'TabScrollButtonProps'
  | 'textColor'
  | 'sx'
  | 'centerRipple'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'focusRipple'
  | 'disableTouchRipple'
  | 'TouchRippleProps'
  | 'focusVisibleClassName'
  | 'LinkComponent'
  | 'onFocusVisible'
  | 'touchRippleRef'
>;
export type TabListProps = Omit<MuiTabListProps, 'sx'>;
export type TabPanelProps = Omit<MuiTabPanelProps, 'sx'>;

export const Tab = ({ ...rest }: TabProps) => <MuiTab {...rest} />;
export const TabList = ({ ...rest }: TabListProps) => <MuiTabList {...rest} />;
export const TabPanel = ({ ...rest }: TabPanelProps) => (
  <MuiTabPanel {...rest} />
);

export const Tabs = forwardRef(function Tabs({ ...props }, ref) {
  return <MuiTabs {...props} ref={ref} />;
}) as OverridableComponent<MuiTabsProps>;
