import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@mui/material';

export type ToggleButtonGroupProps = Omit<MuiToggleButtonGroupProps, 'sx'>;

const ToggleButtonGroup = ({ ...props }) => <MuiToggleButtonGroup {...props} />;

export { ToggleButtonGroup };
