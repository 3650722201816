import { CloseIconButton } from '@formbio/ui/components/Button/CloseIconButton';
import { DialogTitle } from '@formbio/ui/components/Dialog';
import { styled } from '@formbio/ui/components';

const RightPaddedBox = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(1),
  alignSelf: 'flex-end',
}));

export type DialogHeaderProps = {
  isAlert?: boolean;
  onClose?: () => void;
  title: string | undefined;
  titleIcon?: React.ReactNode;
};

export default function DialogHeader({
  isAlert,
  onClose,
  title,
  titleIcon,
}: DialogHeaderProps) {
  return (
    <DialogTitle color={isAlert ? 'error' : 'inherit'}>
      {titleIcon && <RightPaddedBox>{titleIcon}</RightPaddedBox>}
      {title}
      {onClose && <CloseIconButton onClick={onClose} />}
    </DialogTitle>
  );
}

export { DialogHeader };
