import axios, { InternalAxiosRequestConfig } from 'axios';
import { commitShaHeader, isAdminHeader, webRouteHeader } from './api';

export const admin_api = axios.create({});

type InitParams = {
  getToken: () => Promise<string | undefined>;
  baseURL: string;
  onError?: (error: unknown) => void;
};

export function initAdmin({ baseURL, getToken, onError }: InitParams) {
  admin_api.defaults.baseURL = baseURL;

  admin_api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      config.headers[commitShaHeader] =
        process.env.NEXT_PUBLIC_COMMIT_SHA || '';
      if (window.location.href) {
        config.headers[webRouteHeader] = window.location.href;
      }
      // header required for auth in admin tool - without this you'd get a 403
      // see: https://formbio.atlassian.net/browse/FORM-2699
      config.headers[isAdminHeader] = 'true';

      return config;
    },
  );

  if (onError) {
    admin_api.interceptors.response.use(undefined, (error: unknown) => {
      onError(error);
      throw error;
    });
  }
}
