import { styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import RoundIconAvatar from '@formbio/ui/components/Avatar/RoundIconAvatar';
import { ReactNode } from 'react';
import { Tray as TrayIcon } from '@formbio/ui/components/Icons';

export const StyledOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export default function NoRowsOverlay({
  message,
  icon = <TrayIcon />,
  children,
}: {
  message: string;
  icon?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <StyledOverlay>
      <RoundIconAvatar>{icon}</RoundIconAvatar>
      <Typography variant='body1'>{message}</Typography>
      {children}
    </StyledOverlay>
  );
}
