import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import {
  fetchAll,
  fetchAllForProject,
  patchWorkflowVersion,
  deleteWorkflowVersion,
  undeleteWorkflowVersion,
  fetchOneByCompoundId,
  fetchOneByVersionId,
  fetchOneByKey,
  fetchAllByKey,
} from '../../services/workflow-versions';
import { WorkflowVersion } from '../../types';
import { fetchAllForWorkflow } from '../../services/workflow-versions';
import useInvalidation from '../useInvalidation';
import { WorkflowVersionPatch } from '../../services/admin/workflows';

export const WF_VERSIONS_KEY = 'workflow-versions';

export type QueryWorkflowVersions<T = WorkflowVersion[]> = Omit<
  UseQueryOptions<T>,
  'queryKey' | 'queryFn'
>;

export function useWorkflowVersions(
  oid: string,
  pid: string,
  options?: QueryWorkflowVersions,
) {
  return useQuery<WorkflowVersion[]>({
    queryKey: [WF_VERSIONS_KEY, { oid, pid }],
    queryFn: () => fetchAll(oid, pid),
    ...options,
  });
}

export function useWorkflowVersionByKey(
  params: Parameters<typeof fetchOneByKey>[0],
  options?: QueryWorkflowVersions<WorkflowVersion>,
) {
  return useQuery<WorkflowVersion>({
    queryKey: [WF_VERSIONS_KEY, params],
    queryFn: () => fetchOneByKey(params),
    ...{
      enabled:
        !!params.oid &&
        !!params.pid &&
        !!params.uploadedOrg &&
        !!params.uploadedProj &&
        !!params.repo &&
        !!params.name &&
        !!params.version,
      ...options,
    },
  });
}

export function useWorkflowVersionsByKey(
  params: Parameters<typeof fetchAllByKey>[0],
  options?: QueryWorkflowVersions,
) {
  return useQuery<WorkflowVersion[]>({
    queryKey: [WF_VERSIONS_KEY, params],
    queryFn: () => fetchAllByKey(params),
    ...{
      enabled: !!params.oid && !!params.pid && !!params.repo && !!params.name,
      ...options,
    },
  });
}

export function useWorkflowVersionById(
  params: {
    oid: string;
    pid: string;
    id: string;
  },
  options?: Omit<UseQueryOptions<WorkflowVersion>, 'queryKey' | 'queryFn'>,
) {
  const { oid, pid, id } = params;
  return useQuery<WorkflowVersion>({
    queryKey: [WF_VERSIONS_KEY, params],
    queryFn: () => fetchOneByVersionId({ oid, pid, id }),
    ...{
      enabled: !!oid && !!pid && !!id,
      ...options,
    },
  });
}

export const WF_VERSIONS_MANAGER_KEY = 'manage-workflow-versions';

export function useProjectWorkflowVersions(
  oid: string,
  pid: string,
  options?: QueryWorkflowVersions,
) {
  return useQuery<WorkflowVersion[]>({
    queryKey: [WF_VERSIONS_MANAGER_KEY, { oid, pid }],
    queryFn: () => fetchAllForProject(oid, pid),
    ...options,
  });
}

export function useProjectWorkflowVersionsForWorkflow(
  oid: string,
  pid: string,
  workflowId: string,
  options?: QueryWorkflowVersions,
) {
  return useQuery<WorkflowVersion[]>({
    queryKey: [WF_VERSIONS_MANAGER_KEY, { oid, pid, workflowId }],
    queryFn: () => fetchAllForWorkflow(oid, pid, workflowId),
    ...options,
  });
}

export function useWorkflowVersionPatch({
  oid,
  pid,
  versionId,
}: {
  oid: string;
  pid: string;
  versionId: string;
}) {
  const invalidate = useInvalidation(WF_VERSIONS_MANAGER_KEY);

  return useMutation({
    mutationKey: [WF_VERSIONS_MANAGER_KEY, versionId],
    mutationFn: (payload: WorkflowVersionPatch) =>
      patchWorkflowVersion({ oid, pid, versionId, payload }),
    ...{
      onSuccess: () => invalidate(),
    },
  });
}

export function useWorkflowVersionDelete({
  oid,
  pid,
  versionId,
}: {
  oid: string;
  pid: string;
  versionId: string;
}) {
  const invalidate = useInvalidation(WF_VERSIONS_MANAGER_KEY);

  return useMutation({
    mutationKey: [WF_VERSIONS_MANAGER_KEY, versionId],
    mutationFn: () => deleteWorkflowVersion({ oid, pid, versionId }),
    ...{
      onSuccess: () => invalidate(),
    },
  });
}

export function useWorkflowVersionUndelete({
  oid,
  pid,
  versionId,
}: {
  oid: string;
  pid: string;
  versionId: string;
}) {
  const invalidate = useInvalidation(WF_VERSIONS_MANAGER_KEY);

  return useMutation({
    mutationKey: [WF_VERSIONS_MANAGER_KEY, versionId],
    mutationFn: () => undeleteWorkflowVersion({ oid, pid, versionId }),
    ...{
      onSuccess: () => invalidate(),
    },
  });
}

export function useProjectWorkflowVersion(
  oid: string,
  pid: string,
  workflowId?: string,
  versionId?: string,
  options: Omit<UseQueryOptions<WorkflowVersion>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<WorkflowVersion>({
    queryKey: [
      WF_VERSIONS_MANAGER_KEY,
      'single',
      { oid, pid, workflowId, versionId },
    ],
    queryFn: () => fetchOneByCompoundId(oid, pid, workflowId, versionId),
    ...options,
  });
}
