import { ValidatorTypes } from './validator';

// backend implementation: https://github.com/formbio/colossal/blob/1546df96735e2f30fee6842f1cb6f1e5dd6617f8/go/api/vault.go#L58
export enum ProvenanceEventType {
  Written = 'written',
  Deleted = 'deleted',
  WorkflowRunInput = 'WORKFLOW_RUN_INPUT',
  WorkflowRunOutput = 'WORKFLOW_RUN_OUTPUT',
  // The preview feature uses our Download API, so content views are currently recorded as download events.
  Download = 'DOWNLOAD',
  ValidatorRunInput = 'VALIDATOR_RUN_INPUT',
}

// Not exhaustive, just a PoC
export enum ProvenanceOriginType {
  User = 'user',
  WorkflowRun = 'workflow_run',
  Unknown = 'unknown',
}

export interface GcsEvent {
  crc32c: number;
  size: number;
  generation: number;
  createdAt: string;
  eventType: ProvenanceEventType.Written | ProvenanceEventType.Deleted;
}

export interface UsageEvent {
  createdAt: string;
  eventType:
    | ProvenanceEventType.WorkflowRunInput
    | ProvenanceEventType.WorkflowRunOutput
    | ProvenanceEventType.Download;
  actor: string;
}

export enum FileValidationStatus {
  Running = 'RUNNING',
  CouldNotComplete = 'ERROR', // Indicates that the something with the execution itself went wrong
  Success = 'SUCCESS',
  Invalid = 'FAILED', // Indicates that the validation executed successfully, but the file did not pass the validation.
  Stale = 'STALE',
  None = 'NONE',
}

// client-side generated statuses
export type ValidatorRunStatus = Exclude<
  FileValidationStatus,
  FileValidationStatus.Stale | FileValidationStatus.None
>;

export interface ValidatorRunEvent {
  id: string;
  createdAt: string;
  status: ValidatorRunStatus;
  format: ValidatorTypes;
  message?: string;
}

export type ProvenanceKnownOrigin = {
  id: string;
  type: Exclude<ProvenanceOriginType, ProvenanceOriginType.Unknown>;
};

export type ProvenanceUnknownOrigin = {
  type: ProvenanceOriginType.Unknown;
};

export type ProvenanceEvent = GcsEvent | UsageEvent;
export type ProvenanceOrigin = ProvenanceKnownOrigin | ProvenanceUnknownOrigin;

export type FileProvenance = {
  validations: ValidatorRunEvent[] | null;
  events: ProvenanceEvent[] | null;
  origin: ProvenanceOrigin;
};

export type Label = {
  title: string;
  group: string;
};

export const UngroupedLabelType = 'Ungrouped';

export interface VaultItem {
  createdAt?: string;
  updatedAt?: string;
  metadata: Record<string, string> | null;
  size: number;
  crc32c: number;
  contentType: string;
  bucket: string;
  name: string;
  provenance?: FileProvenance;
  labels?: Label[];
}

export interface FetchVaultParams {
  oid: string;
  pid?: string;
  prefix?: string;
  singleLevel?: boolean;
  fileType?: string;
}

export interface FetchVaultItemParams {
  oid: string;
  pid: string;
  objectName: string;
}

// flags to navigate naming conflicts during storage operations, i.e. create, upload, copy, move
// starting point for context can be found here: https://formbio.atlassian.net/browse/FORM-1473
export enum ConflictStrategy {
  DEFAULT = 'default',
  MERGE = 'merge',
  REPLACE = 'replace',
}

export interface CopyVaultItemParams {
  path: string;
  destination: string;
  destinationOrg?: string;
  destinationProject?: string;
}

export interface FetchSignedURLParams {
  oid: string;
  pid: string;
  path?: string;
}

export interface UpdateFileLabelsParams {
  path?: string;
  labels: Label[];
}
export interface UpdateOrgLabelsParams {
  labels: Label[];
}

export function isGcsEvent(event: ProvenanceEvent): event is GcsEvent {
  return (
    event.eventType === ProvenanceEventType.Deleted ||
    event.eventType === ProvenanceEventType.Written
  );
}

export function isUsageEvent(event: ProvenanceEvent): event is UsageEvent {
  return !isGcsEvent(event);
}

export function isOriginUnknown(
  origin: ProvenanceKnownOrigin | ProvenanceUnknownOrigin,
): origin is ProvenanceUnknownOrigin {
  return origin.type === 'unknown';
}

export function isProvenanceOrigin(
  event: ProvenanceEvent | ProvenanceOrigin,
): event is ProvenanceOrigin {
  return (
    'type' in event && Object.values(ProvenanceOriginType).includes(event.type)
  );
}

export function isValidatorRunEvent(
  event: ValidatorRunEvent | ProvenanceEvent | ProvenanceOrigin,
): event is ValidatorRunEvent {
  return (
    'status' in event &&
    Object.values(FileValidationStatus).includes(event.status)
  );
}

export function isProvenanceEvent(
  event: ValidatorRunEvent | ProvenanceEvent,
): event is ProvenanceEvent {
  return (
    'eventType' in event &&
    Object.values(ProvenanceEventType).includes(event.eventType)
  );
}

export type VALIDATOR_RUN_EVENT_TYPE = 'VALIDATOR_RUN_EVENT';

export type TransformationParams = {
  sourceObjectName: string;
  targetObjectName?: string;
  conflictStrategy?: ConflictStrategy;
};

export interface VectorTransformationParams extends TransformationParams {
  vectorId: string;
}
