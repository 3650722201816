import { CustomComponent } from '@formbio/ui/theme/type-extension';

export const MuiToggleButtonGroup: CustomComponent<'MuiToggleButtonGroup'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: `1px solid ${theme.palette.primary[200]}`,
      '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        color: theme.palette.primary[700],
        padding: theme.spacing(1, 3),
        '&:not(:first-of-type)': {
          borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
          borderRadius: theme.shape.borderRadius,
        },
        '&.Mui-selected': {
          color: theme.palette.text.primary,
        },
        '&.Mui-disabled': {
          border: 0,
          color: theme.palette.text.disabled,
        },
      },
    }),
  },
};
