import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  copyItem,
  fetchOne,
  fetchAll,
  fetchSignedURL,
  fetchFileContent,
  removeItem,
  renameItem,
  transformIndication,
  transformVector,
  runVectorInsights,
} from '../../services/storage';
import {
  CopyVaultItemParams,
  FetchSignedURLParams,
  FetchVaultItemParams,
  FetchVaultParams,
  TransformationParams,
  VaultItem,
  VectorTransformationParams,
} from '../../types';
import useInvalidation from '../useInvalidation';

export const STORAGE_KEY = 'storage';
export const MS_REFRESH = 30000;

export function useStorage(
  params: FetchVaultParams,
  options?: Omit<UseQueryOptions<VaultItem[]>, 'queryKey'>,
) {
  return useQuery<VaultItem[]>({
    queryKey: [STORAGE_KEY, params],
    queryFn: () => fetchAll(params),
    ...{
      enabled: !!params.pid,
      ...options,
    },
  });
}

export function useStorageMultiplePaths(
  params: FetchVaultParams[],
  options?: Omit<UseQueryOptions<VaultItem[]>, 'queryKey'>,
) {
  return useQueries({
    queries: params.map((param) => ({
      queryKey: [STORAGE_KEY, param],
      queryFn: () => fetchAll(param),
      enabled: !!param.pid,
    })),
    combine: (results) => {
      return {
        data: results.reduce(
          (acc: VaultItem[], cur) => (cur.data ? [...acc, ...cur.data] : acc),
          [],
        ),
        loading: results.some((result) => result.isLoading),
        pending: results.some((result) => result.isPending),
        refetch: () => Promise.all(results.map((result) => result.refetch)),
      };
    },
    ...options,
  });
}

export function useStorageBatch({ oid, pid, prefix }: FetchVaultParams) {
  // build an array of params by splitting the prefix into an array of strings
  // where each string is one all previous levels + the current level
  // eg. prefix = '/a/b/c/d' => ['/a', '/a/b', '/a/b/c', '/a/b/c/d']
  // then map each param to a query
  const params: FetchVaultParams[] = [
    {
      oid,
      pid,
      singleLevel: true,
    },
    ...(prefix
      ? prefix.split('/').map((_, i, arr) => ({
          oid,
          pid,
          prefix: `${arr.slice(0, i + 1).join('/')}/`,
          singleLevel: true,
        }))
      : []),
  ];
  return useQueries({
    queries: params.map((param) => ({
      queryKey: [STORAGE_KEY, param],
      queryFn: () => fetchAll(param),
      enabled: !!param.pid,
    })),
    combine: (results) => {
      return {
        data: results.map(
          (results, index) =>
            results.data?.filter(
              (item, itemIndex) =>
                //  only keep directories
                item.name.endsWith('/') &&
                // remove the first item because somehow the endpoint returns the parent folder
                (index === 0 || (index > 0 && itemIndex !== 0)),
            ),
        ),
        pending: results.some((result) => result.isPending),
      };
    },
  });
}

export function useStorageObject(
  params: FetchVaultItemParams,
  options?: Omit<UseQueryOptions<VaultItem>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<VaultItem>({
    queryKey: [STORAGE_KEY, params],
    queryFn: () => fetchOne(params),
    ...options,
  });
}

export function useRemoveStorageItem(
  oid: string,
  pid: string,
  options?: UseMutationOptions<unknown, unknown, string>,
) {
  return useMutation<unknown, unknown, string>({
    mutationFn: (item: string) => removeItem(oid, pid, item),
    ...options,
  });
}

export function useRenameStorageItem(
  oid: string,
  pid: string,
  options?: UseMutationOptions<
    unknown,
    unknown,
    { oldPath: string; newPath: string }
  >,
) {
  return useMutation<unknown, unknown, { oldPath: string; newPath: string }>({
    mutationFn: ({ oldPath, newPath }: { oldPath: string; newPath: string }) =>
      renameItem(oid, pid, oldPath, newPath),
    ...options,
  });
}

export function useCopyStorageItem(
  oid: string,
  pid: string,
  options?: UseMutationOptions<unknown, unknown, CopyVaultItemParams>,
) {
  return useMutation<unknown, unknown, CopyVaultItemParams>({
    mutationFn: (params: CopyVaultItemParams) => copyItem(oid, pid, params),
    ...options,
  });
}

export function useFetchSignedURL(
  params: FetchSignedURLParams,
  options?: Omit<UseQueryOptions<string>, 'queryKey'>,
) {
  return useQuery<string>({
    queryKey: [STORAGE_KEY, params],
    queryFn: () => fetchSignedURL(params),
    ...{
      enabled: !!params.path && !!params.pid && !!params.oid,
      ...options,
    },
  });
}

export function useFetchFileContent(
  params: { url: string; filePath?: string },
  options?: Omit<UseQueryOptions<string>, 'queryKey'>,
) {
  return useQuery<string>({
    queryKey: [STORAGE_KEY, params],
    queryFn: () => fetchFileContent(params.url),
    ...options,
  });
}

/**
 * Use this hook when controlling when to make the call to fetch
 * is too cumbersome (like for IGV).
 * In most other cases, useFetchSignedURL is preferred.
 * @returns
 */
export function useMutationSignedURL(
  options?: UseMutationOptions<string, unknown, FetchSignedURLParams>,
) {
  return useMutation<string, unknown, FetchSignedURLParams>({
    mutationFn: fetchSignedURL,
    ...options,
  });
}

/**
 * We need to invalidate any storage queries when there's a modification
 * in the vault. Eg. on file rename, delete, move, etc.
 */
export function useInvalidateStorage() {
  const invalidate = useInvalidation(STORAGE_KEY);
  return invalidate;
}

export function useRemoveQueries(params: FetchVaultItemParams) {
  const queryClient = useQueryClient();
  return () => {
    queryClient.removeQueries({ queryKey: [STORAGE_KEY, params] });
  };
}

export function useTransformIndication(
  oid: string,
  pid: string,
  options?: UseMutationOptions<{ json: string }, unknown, TransformationParams>,
) {
  return useMutation<{ json: string }, unknown, TransformationParams>({
    mutationFn: (params: TransformationParams) =>
      transformIndication({ oid, pid, params }),
    ...options,
  });
}

export function useTransformVector(
  oid: string,
  pid: string,
  options?: UseMutationOptions<
    { 'seqviz.json': string },
    unknown,
    VectorTransformationParams
  >,
) {
  return useMutation<
    { 'seqviz.json': string },
    unknown,
    VectorTransformationParams
  >({
    mutationFn: (params: VectorTransformationParams) =>
      transformVector({ oid, pid, params }),
    ...options,
  });
}

export function useRunVectorInsights(
  oid: string,
  pid: string,
  options?: UseMutationOptions<{ json: string }, unknown, string>,
) {
  return useMutation<{ json: string }, unknown, string>({
    mutationFn: (vectorId: string) => runVectorInsights({ oid, pid, vectorId }),
    ...options,
  });
}
