import { styled, Theme } from '@formbio/ui/components';
import { NavListItem, NavListItemType } from '@formbio/ui/types';
import {
  ListItemIcon,
  ListItemText,
  ListItem,
} from '@formbio/ui/components/List';
import { Typography } from '@formbio/ui/components/Typography';
import { NavItemStyleProps } from '@formbio/ui/components/Button/NavListLinkItem';
import {
  CaretDown as CaretDownIcon,
  CaretRight as CaretRightIcon,
  CaretUp as CaretUpIcon,
} from '../../Icons';

const StyledNavListItemContent = styled(ListItem, {
  shouldForwardProp: prop =>
    prop !== 'selected' && prop !== 'getNavItemStyleProps',
})<{
  selected: boolean;
  getNavItemStyleProps: (theme: Theme) => NavItemStyleProps;
}>(({ theme, selected, getNavItemStyleProps }) => {
  const { iconColorSelected, textColorDefault, textColorSelected } =
    getNavItemStyleProps(theme);
  return {
    padding: 0,
    '.icon': {
      color: selected ? iconColorSelected : textColorDefault,
    },
    '.text': {
      color: selected ? textColorSelected : textColorDefault,
    },
    '.hidden': {
      visibility: 'hidden',
    },
    '.caret-container': {
      display: 'flex',
      '.caret-icon': {
        width: theme.spacing(2),
      },
    },
  };
});

export default function NavListItemContent({
  item,
  nested = false,
  selected = false,
  open = false,
  getNavItemStyleProps,
}: {
  item: NavListItem;
  nested?: boolean;
  selected: boolean;
  open?: boolean;
  getNavItemStyleProps: (theme: Theme) => NavItemStyleProps;
}) {
  return (
    <StyledNavListItemContent
      selected={selected}
      getNavItemStyleProps={getNavItemStyleProps}
    >
      {item.startIcon && (
        <ListItemIcon className='icon'>{item.startIcon}</ListItemIcon>
      )}
      {!item.startIcon && nested && (
        <ListItemIcon>
          <CaretRightIcon className='hidden' />
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography
          className='text'
          variant={nested ? 'body3' : 'caption1'}
          fontWeight={nested && selected ? 500 : undefined}
          selected={false}
        >
          {item.label}
        </Typography>
      </ListItemText>

      {item.type === NavListItemType.Parent && (
        <div className='text caret-container'>
          {open ? (
            <CaretUpIcon className='caret-icon' />
          ) : (
            <CaretDownIcon className='caret-icon' />
          )}
        </div>
      )}
    </StyledNavListItemContent>
  );
}
