import { http, HttpResponse } from 'msw';

const removeProjectMember = http.delete('*/api/v1/orgs/:oid/:pid/members', () =>
  HttpResponse.json(null, { status: 500, statusText: 'Cannot remove member' }),
);

const updateProjectMember = http.patch('*/api/v1/orgs/:oid/:pid/members', () =>
  HttpResponse.json(null, { status: 500, statusText: 'Cannot update member' }),
);

const addProjectMembers = http.post(
  '*/api/v1/orgs/:oid/:pid/members/bulk',
  () =>
    HttpResponse.json(null, {
      status: 500,
      statusText: 'Cannot update member',
    }),
);

const removeOrgMember = http.delete('*/api/v1/orgs/:oid/members', () =>
  HttpResponse.json(null, { status: 500, statusText: 'Cannot remove member' }),
);

const updateOrgMember = http.patch('*/api/v1/orgs/:oid/members', () =>
  HttpResponse.json(null, { status: 500, statusText: 'Cannot update members' }),
);
const addOrgMember = http.post('*/api/v1/orgs/:oid/members', () =>
  HttpResponse.json(null, { status: 500, statusText: 'Cannot add member' }),
);
const addOrgMemberBulk = http.post('*/api/v1/orgs/:oid/members/bulk', () =>
  HttpResponse.json(null, {
    status: 500,
    statusText: 'Cannot add members in bulk',
  }),
);

export const memberHandlers = [
  removeProjectMember,
  updateProjectMember,
  addProjectMembers,
  removeOrgMember,
  updateOrgMember,
  addOrgMember,
  addOrgMemberBulk,
];
