import { ProgramCheckpoint, ProgramCheckpointId } from '@formbio/api';
import { WorkflowSchemaInputs } from '@formbio/workflow-schema';

const papermillWorkflow =
  '/workflows/nf-papermill/auc-papermill/params/?&version=main&uploadedOrg=1045298e-a419-4d2a-9dd6-9c84a9f869c6&uploadedProj=2e32842d-331f-4281-8186-3805897dcc94&selectedFile=&supportFile=';
const gelWorkflow =
  '/workflows/nf-imageanalysis/imageanalysis/?version=v1.0.0&uploadedOrg=1045298e-a419-4d2a-9dd6-9c84a9f869c6&uploadedProj=2e32842d-331f-4281-8186-3805897dcc94';
const aavWorkflow =
  '/workflows/nf-adenoassociatedvirus/aavpacbio/?version=v2.2.0&uploadedOrg=1045298e-a419-4d2a-9dd6-9c84a9f869c6&uploadedProj=40c9baa0-9156-4e5e-a784-f3ffffd022ef';

const inputs: WorkflowSchemaInputs = {
  gelImage1: {
    title: 'Gel Image',
    type: 'string',
    format: 'file',
    filetype: '.png|.jpg|.jpeg|.pdf',
  },
  csv1: {
    title: 'Table of AUC Data',
    type: 'string',
    format: 'file',
    filetype: '.csv|.txt|.tsv', // assuming we need to support txt 🙄
  },
  notebook: {
    title: 'Workflow Results',
    type: 'string',
    format: 'file',
    filetype: '.html',
  },
  ngs1: {
    title: 'Sequencing Data Folder',
    type: 'string',
    format: 'dir-path',
  },
  bed: {
    title: 'BED File',
    type: 'string',
    format: 'file',
    filetype: '.bed',
  },
  pdf1: {
    title: 'ELISA Data - PDF',
    type: 'string',
    format: 'file',
    filetype: '.pdf',
  },
  qPCR1: {
    title: 'ddPCR/qPCR Data',
    type: 'string',
    format: 'file',
    filetype: '.pdf',
  },
};

const optimizeUi = {
  inputs: [
    {
      id: 'purity',
      title: 'Purity: SDS-Page',
      description: 'Analysis Workflow',
      fields: ['gelImage1'],
    },
    {
      id: 'emptyFull',
      title: 'Empty/Full: AUC',
      description: 'AUC Template Notebook',
      fields: ['csv1', 'notebook'],
    },
    {
      id: 'ngs',
      title: 'Capsid Genomic Content: NGS',
      description: 'AAV QC Workflow',
      fields: ['ngs1', 'bed'],
    },
    {
      id: 'capsid-titer',
      title: 'Capsid Titer: ELISA',
      description: 'Display Result',
      fields: ['pdf1'],
    },
    {
      id: 'ddPCR-qPCR',
      title: 'GOI or insert titer: qPCR',
      description: 'Display Result',
      fields: ['qPCR1'],
    },
  ],
};

const designUi = {
  inputs: [
    {
      id: 'purity',
      title: 'Insert Size: Restriction Digest',
      description: '',
      fields: ['gelImage1'],
    },
    {
      id: 'emptyFull',
      title: 'Purity: PCR',
      description: 'AUC Template Notebook',
      fields: ['csv1', 'notebook'],
    },
    {
      id: 'ngs',
      title: 'Sequence Check: Sequencing',
      description: 'AAV QC Workflow',
      fields: ['ngs1', 'bed'],
    },
    {
      id: 'capsid-titer',
      title: 'Purity: Spectroscopy',
      description: 'Display Result',
      fields: ['pdf1'],
    },
  ],
};

const groupMethods = {
  purity: [
    {
      id: 'gel',
      title: 'Gel Electrophoresis',
      description: 'Gel Electrophoresis Workflow',
      path: gelWorkflow,
    },
  ],
  emptyFull: [
    {
      id: 'auc',
      title: 'AUC',
      description: 'AUC Template',
      path: papermillWorkflow,
    },
  ],
  ngs: [
    {
      id: 'aav-qc',
      title: 'AAV QC',
      description: 'AAV QC Workflow',
      path: aavWorkflow,
    },
  ],
};

export const design: ProgramCheckpoint = {
  id: ProgramCheckpointId.Design,
  workflowSchema: {
    id: 'synthesis',
    displayName: 'Vector Design',
    description: '',
    schema: 'v3',
    workflowVersion: 'demo',
    title: '',
    categories: [],
    inputs,
    ui: designUi,
  },
  groupMethods,
};

export const optimize: ProgramCheckpoint = {
  id: ProgramCheckpointId.Optimize,
  workflowSchema: {
    id: 'goi2',
    displayName: 'Vector Optimization',
    description:
      'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
    schema: 'v3',
    workflowVersion: 'demo',
    title: '',
    categories: [],
    inputs,
    ui: optimizeUi,
  },
  groupMethods,
};

export const inVitro: ProgramCheckpoint = {
  id: ProgramCheckpointId.InVitro,
  workflowSchema: {
    id: 'validation',
    displayName: 'In vitro Characterization',
    description:
      'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
    schema: 'v3',
    workflowVersion: 'demo',
    title: '',
    categories: [],
    inputs,
    ui: designUi,
  },
  groupMethods,
};

export const inVivo: ProgramCheckpoint = {
  id: ProgramCheckpointId.InVivo,
  workflowSchema: {
    id: 'safety',
    displayName: 'In vivo Validation',
    description:
      'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
    schema: 'v3',
    workflowVersion: 'demo',
    title: '',
    categories: [],
    inputs,
    ui: designUi,
  },
  groupMethods,
};

export const manufacturing: ProgramCheckpoint = {
  id: ProgramCheckpointId.Manufacturing,
  workflowSchema: {
    id: 'manufacturing',
    displayName: 'GxP Manufacturing',
    description:
      'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
    schema: 'v3',
    workflowVersion: 'demo',
    title: '',
    categories: [],
    inputs,
    ui: designUi,
  },
  groupMethods,
};

export const allCheckpoints: ProgramCheckpoint[] = [
  design,
  optimize,
  inVitro,
  inVivo,
  manufacturing,
];
