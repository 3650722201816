import { styled } from '@mui/material';
import { Info as InfoIcon } from '@formbio/ui/components/Icons';
import { ReactNode } from 'react';

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  color: theme.palette.text.disabled,
  '.helper-text': {
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
  },
}));

export default function HelpInfo({ children }: { children: ReactNode }) {
  return (
    <StyledDiv>
      <InfoIcon />
      <div className='helper-text'>{children}</div>
    </StyledDiv>
  );
}
