import type { Organization } from '../../types/organization';
import type { Role } from '../../types/member';
import { api } from '../../utils/api';
import { admin_api } from '../../utils/admin-api';

export interface CreateOrgData {
  name: string;
  ownerEmail: string;
  billingId?: string;
  // account id which is passed around from GCP marketplace
  // to the page that creates a new org on the web-client
  accountId?: string;
  ignoreVerification?: boolean;
  isIsa?: boolean;
}

export interface MemberData {
  email: string;
  role: Role;
}

export function fetchAll() {
  return api.get<Organization[]>('/user/orgs').then(({ data }) => data);
}

export function fetchOne(oid: string) {
  return api.get<Organization>(`/orgs/${oid}`).then(({ data }) => data);
}

export function createOrganization(data: CreateOrgData) {
  return api.post(`/organizations`, data);
}

export function deleteOrganization(oid: string) {
  return api.delete(`/orgs/${oid}`);
}

export function unDeleteOrganization(oid: string) {
  return admin_api.patch(`/undelete/organizations/${oid}`);
}

export function removeMember(
  oid: string,
  email: string,
  params?: { deleteProjectMembership?: boolean },
) {
  return api.delete(`/orgs/${oid}/members`, {
    params,
    data: { userEmail: email },
  });
}

export function addMember(oid: string, data: MemberData) {
  return api.post(`/orgs/${oid}/members`, {
    userEmail: data.email,
    role: data.role,
  });
}

export function addMembers(oid: string, data: MemberData[]) {
  return api.post(
    `/orgs/${oid}/members/bulk`,
    data.map((member) => {
      return {
        userEmail: member.email,
        role: member.role,
      };
    }),
  );
}

export function updateMember(oid: string, data: MemberData) {
  return api.patch(`/orgs/${oid}/members`, {
    userEmail: data.email,
    role: data.role,
  });
}
