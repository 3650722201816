import { pluralize } from '@formbio/ui/utils';
import { ReactNode } from 'react';
import { styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import {
  Hammer as HammerIcon,
  Users as UsersIcon,
} from '@formbio/ui/components/Icons';
import { LinearProgress, keyframes } from '@mui/material';

export const PROVISIONING_TIME_IN_MIN = 10; // 10 minutes

const hammering = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '50%': { transform: 'rotate(30deg)' },
  '100%': { transform: 'rotate(0deg)' },
});

const StyledHammerIcon = styled(HammerIcon)({
  animation: `${hammering} 1s infinite`,
});

const StyledCard = styled('div', {
  shouldForwardProp(propName) {
    return propName !== 'smallPaddingTop' && propName !== 'enabled';
  },
})<{ smallPaddingTop?: boolean; enabled?: boolean }>(
  ({ theme, smallPaddingTop, enabled }) => ({
    //account for action menu button padding.
    padding: theme.spacing(smallPaddingTop ? 2 : 3, 3, 3),
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: `1px solid`,
    borderColor:
      theme.palette.mode === 'light'
        ? theme.palette.divider
        : theme.palette.primary[50],
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    transition: 'border-color 0.1s ease-in-out',
    ':hover': {
      borderColor:
        theme.palette.mode === 'light'
          ? theme.palette.primary[700]
          : theme.palette.primary.main,
    },
    '.title-row': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '.title': {
        flex: 1,
      },
      opacity: enabled ? undefined : '50%',
    },
    '.description-row': {
      flex: 1,
      opacity: enabled ? undefined : '50%',
    },
    '.members-row': {
      marginTop: theme.spacing(2),
      color: theme.palette.info.main,
      display: 'flex',
      gap: theme.spacing(1),
      opacity: enabled ? undefined : '50%',
    },
    '.eta-progress': {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '.progress-bar': {
        flex: 1,
        height: theme.spacing(1),
        backgroundColor: theme.palette.success.light,
      },
    },
  }),
);

const StyledTypography = styled(Typography)({
  overflowWrap: 'anywhere',
});

export default function ProjectCard({
  title,
  description,
  actionsMenu,
  membersCount = 0,
  enabled = true,
  etaInfo,
}: {
  title: string;
  description: string;
  actionsMenu?: ReactNode;
  membersCount: number;
  enabled?: boolean;
  etaInfo?: { etaInSec: number; label: string };
}) {
  return (
    <StyledCard smallPaddingTop={!!actionsMenu} enabled={enabled}>
      <div className='title-row'>
        <StyledTypography variant='h4' className='title'>
          {title}
        </StyledTypography>
        {enabled ? actionsMenu : <StyledHammerIcon />}
      </div>
      <StyledTypography variant='body2' className='description-row'>
        {description}
      </StyledTypography>
      <div className='members-row'>
        <UsersIcon />
        <Typography variant='body2' color='inherit'>
          {pluralize({
            number: membersCount,
            singular: 'Member',
            plural: 'Members',
          })}
        </Typography>
      </div>
      {!enabled && etaInfo && (
        <div className='eta-progress'>
          <LinearProgress
            className='progress-bar'
            value={
              ((PROVISIONING_TIME_IN_MIN * 60 - etaInfo.etaInSec) /
                (PROVISIONING_TIME_IN_MIN * 60)) *
              100
            }
            color='success'
            variant={etaInfo.etaInSec === 0 ? 'indeterminate' : 'determinate'}
          />
          <Typography variant='body2'>Ready in {etaInfo.label}</Typography>
        </div>
      )}
    </StyledCard>
  );
}
