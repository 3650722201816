import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { CheckoutSessionParams } from '../../types/checkout-session';
import { checkoutSession } from '../../services/checkout-session';

export function useCheckoutSession(
  options?: UseMutationOptions<string, unknown, CheckoutSessionParams>,
) {
  return useMutation<string, unknown, CheckoutSessionParams>({
    mutationFn: (data: CheckoutSessionParams) => checkoutSession(data),
    ...options,
  });
}
