import { faker } from '@faker-js/faker';
import {
  GcsEvent,
  ProvenanceEventType,
  ProvenanceKnownOrigin,
  ProvenanceOriginType,
  ProvenanceUnknownOrigin,
} from '@formbio/api';
import { sample, sortBy } from 'lodash';

interface Provenance {
  //TODO for now only Gcs event types. Add more during the mock handler consolidation
  events: GcsEvent[] | null;
  origin: ProvenanceKnownOrigin | ProvenanceUnknownOrigin;
}

//TODO for now only Gcs event types. Add more during the mock handler consolidation
const eventTypes = [ProvenanceEventType.Written, ProvenanceEventType.Deleted];

export function createProvenanceEvents(size = 100): Provenance {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return {
    events: sortBy(
      [
        // get some recent dates
        ...Array.from({ length: 4 }, () => ({
          createdAt: faker.date.recent({ days: 1 }).toISOString(),
          crc32c: faker.number.int(),
          size: 29,
          generation: 1695150839684032,
          eventType: sample(eventTypes) as
            | ProvenanceEventType.Written
            | ProvenanceEventType.Deleted,
        })),
        ...Array.from({ length: size - 4 }, () => ({
          createdAt: faker.date.past({ years: 2 }).toISOString(),
          crc32c: faker.number.int(),
          size: 29,
          generation: 1695150839684032,
          eventType: sample(eventTypes) as
            | ProvenanceEventType.Written
            | ProvenanceEventType.Deleted,
        })),
      ],
      'updated',
    ).reverse(),
    origin: {
      type: ProvenanceOriginType.Unknown,
    },
  };
}
