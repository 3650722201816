import { useEffect, useState } from 'react';
import { useController, useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { styled } from '@formbio/ui/components';
import { Button } from '@formbio/ui/components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@formbio/ui/components/Dialog';
import { DialogHeader } from '@formbio/ui/components/Dialog/DialogHeader';
import { FieldError } from '@formbio/ui/components/FieldError';
import { InputLabel } from '@formbio/ui/components/InputLabel';
import { LinearProgress } from '@formbio/ui/components/LinearProgress';
import { TextField } from '@formbio/ui/components/TextField';
import TextAvatar, {
  AvatarSizes,
} from '@formbio/ui/components/Avatar/TextAvatar';
import { Typography } from '@formbio/ui/components/Typography';

type Props = {
  isLoading: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: ({ name }: { name: string }) => void;
  user: {
    name: string;
    email: string;
  };
};

interface FormData extends FieldValues {
  name: string;
}

const UserInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const UserAvatar = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const UserName = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flex: 1,
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[A-Za-z0-9 '.]+$/, {
      excludeEmptyString: true,
      message: 'Invalid name format.',
    })
    .max(50, 'Name must be under 50 characters.')
    .required('Please enter a name.'),
});

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export default function ChangeMemberNameDialog({
  isLoading,
  open,
  onClose,
  onSubmit,
  user,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formProps = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, reset, watch } = formProps;
  const name = watch('name');

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name: 'name' });

  function handleClose() {
    onClose?.();
  }

  function handleFormSubmit() {
    setIsSubmitting(true);
    onSubmit({ name });
  }

  useEffect(() => {
    if (open) {
      setIsSubmitting(false);
      reset({ name: '' });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      {isLoading ? (
        <>
          <LinearProgress />
        </>
      ) : null}
      <DialogHeader title='Change Member Name' onClose={handleClose} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <UserInfo>
            <UserAvatar>
              <TextAvatar size={AvatarSizes.large} text={user.name} />
            </UserAvatar>
            <UserName>
              <Typography variant='body1'>
                <b>{user.name}</b>
              </Typography>
              <Typography variant='body2'>{user.email}</Typography>
            </UserName>
          </UserInfo>
          <StyledInputLabel>Name</StyledInputLabel>
          <TextField
            fullWidth
            id={name}
            name={name}
            onChange={onChange}
            value={value}
          />
          {error && <FieldError error={error.message} />}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isSubmitting || !name || !!error}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
