import { createGene } from '../../services/genes';
import {
  CreateGeneParams,
  DATACORE_INDICATION_TYPE,
  DatacoreResponse,
} from '../../types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import useInvalidation from '../useInvalidation';

export function useCreateGene(
  options?: UseMutationOptions<
    AxiosResponse<DatacoreResponse>,
    AxiosError<{ message: string }>,
    CreateGeneParams
  >,
) {
  const invalidate = useInvalidation(DATACORE_INDICATION_TYPE);

  return useMutation<
    AxiosResponse<DatacoreResponse>,
    AxiosError<{ message: string }>,
    CreateGeneParams
  >({
    mutationFn: (data: CreateGeneParams) => createGene(data),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
