import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import {
  fetchAll,
  fetchOne,
  remove,
  create,
  fetchAllAdmin,
  fetchWorkflowRunVisualization,
} from '../../services/workflow-runs';
import {
  WorkflowRun,
  WorkflowRunPost,
  WorkflowRunPostResponse,
  WorkflowRunVisualization,
} from '../../types';
import useInvalidation from '../useInvalidation';

const RUNS_KEY = 'workflow-run';
const RUN_KEY = 'workflow-run';
const KEY_VIZ = 'workflow-run-viz';

type RemoveMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  {
    id: string;
    params?: {
      removeOutput: boolean;
    };
  }
>;

type QueryWorkflowRuns = Omit<UseQueryOptions<WorkflowRun[]>, 'queryKey'>;

export function useWorkflowRuns(
  oid: string,
  pid: string,
  options?: QueryWorkflowRuns,
) {
  return useQuery<WorkflowRun[]>({
    queryKey: [RUNS_KEY, oid, pid],
    queryFn: () => fetchAll(oid, pid),
    ...options,
  });
}

export function useAdminWorkflowRuns(
  oid: string,
  pid: string,
  options?: QueryWorkflowRuns,
) {
  return useQuery<WorkflowRun[]>({
    queryKey: [RUNS_KEY, { oid, pid }],
    queryFn: () => fetchAllAdmin(oid, pid),
    ...options,
  });
}

export function useWorkflowRun(
  id: string,
  options?: UseQueryOptions<WorkflowRun>,
) {
  return useQuery<WorkflowRun>({
    queryKey: [RUN_KEY, id],
    queryFn: () => fetchOne(id),
    ...{
      enabled: !!id,
      ...options,
    },
  });
}

export function useRemoveWorkflowRun(
  oid: string,
  pid: string,
  options?: RemoveMutationOptions,
) {
  const invalidate = useInvalidation(RUNS_KEY);

  return useMutation<
    unknown,
    unknown,
    {
      id: string;
      params?: {
        removeOutput: boolean;
      };
    }
  >({
    mutationFn: ({
      id,
      params,
    }: {
      id: string;
      params?: { removeOutput?: boolean };
    }) => remove(id, params),
    ...{
      onSuccess: () => {
        return invalidate({ oid, pid });
      },
      ...options,
    },
  });
}

export function useCreateWorkflowRun(
  oid: string,
  pid: string,
  options?: UseMutationOptions<
    WorkflowRunPostResponse,
    unknown,
    WorkflowRunPost
  >,
) {
  const invalidate = useInvalidation(RUNS_KEY);

  return useMutation<WorkflowRunPostResponse, unknown, WorkflowRunPost>({
    mutationFn: (data: WorkflowRunPost) => create(oid, pid, data),
    ...{
      onSuccess: () => invalidate(),
      ...options,
    },
  });
}

export function useWorkflowRunVisualization(
  id: string,
  options?: UseQueryOptions<WorkflowRunVisualization>,
) {
  return useQuery<WorkflowRunVisualization>({
    queryKey: [KEY_VIZ, id],
    queryFn: () => fetchWorkflowRunVisualization(id),
    ...{
      enabled: !!id,
      ...options,
    },
  });
}
