import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const useIdleTimeout = ({
  onIdle,
  // seconds
  idleTime = 2,
  promptTime = 1,
}: {
  onIdle: () => void;
  idleTime: number;
  promptTime: number;
}) => {
  const [isIdle, setIdle] = useState(false);
  const handleIdle = () => {
    setIdle(true);
  };
  const idleTimer = useIdleTimer({
    timeout: 1000 * idleTime,
    promptBeforeIdle: 1000 * promptTime,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
