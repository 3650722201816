import { NavListItem } from '@formbio/ui/types';
import {
  NavItemStyleProps,
  StyledListItemButton,
} from '@formbio/ui/components/Button/NavListLinkItem';
import NavListItemContent from '@formbio/ui/components/Button/NavListItemContent';
import { Theme } from '@formbio/ui/components';

export interface NavListButtonItemProps {
  item: NavListItem;
  selected?: boolean;
  onClick: () => void;
  className?: string;
  getNavItemStyleProps: (theme: Theme) => NavItemStyleProps;
}

export default function NavListButtonItem({
  item,
  selected = false,
  onClick,
  className,
  getNavItemStyleProps,
}: NavListButtonItemProps) {
  return (
    <StyledListItemButton
      onClick={onClick}
      selected={selected}
      className={className}
      getNavItemStyleProps={getNavItemStyleProps}
    >
      <NavListItemContent
        item={item}
        selected={selected}
        getNavItemStyleProps={getNavItemStyleProps}
      />
    </StyledListItemButton>
  );
}
