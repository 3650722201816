import { useRef, useState } from 'react';

import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Grid,
  styled,
  GrowProps,
  PopperPlacementType,
} from '@formbio/ui/components';
import { Button } from '@formbio/ui/components/Button';
import { ButtonGroup } from '@formbio/ui/components/ButtonGroup';
import { Typography } from '@formbio/ui/components/Typography';
import { MenuItem, MenuList } from '@formbio/ui/components/Menu';
import { Tooltip } from '@formbio/ui/components/Tooltip';
import { CaretDown as CaretDownIcon } from '@formbio/ui/components/Icons';

import { Role, AwaitingInvitee, Member } from '@formbio/ui/types/member';

import includes from 'lodash/includes';
import intersection from 'lodash/intersection';

/**
 * styled
 */
const StyledMenuItem = styled(MenuItem)({
  '.camelCase': {
    textTransform: 'capitalize',
  },
});

const StyledPopper = styled(Popper)({
  zIndex: 1,
});

const StyledHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary[900],
  fontWeight: 'bold',
}));

type StyledGrowProps = GrowProps & {
  placement: PopperPlacementType;
};

const StyledGrow = styled(Grow)<StyledGrowProps>(({ placement }) => ({
  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
}));

const StyledButton = styled(Button)({
  width: '100%',
});

const StyledCaretButton = styled(Button)(() => ({
  svg: {
    fontSize: '1rem',
  },
}));

/**
 * props + component
 */
type Props = {
  onInviteesUpdate: (invitees: AwaitingInvitee[]) => void;
  members?: Member[];
  invitees: AwaitingInvitee[];
  isLoading: boolean;
  roles: Role[];
};

const InviteAllMembersForm = ({
  onInviteesUpdate,
  members,
  invitees,
  isLoading = false,
  roles,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  // disable button if all org members are already in the list
  const disabled =
    intersection(
      invitees.map(invitee => invitee.email),
      members?.map(member => member.user.email),
    ).length === members?.length;

  /**
   * methods
   */
  const handleMenuItemClick = (index: number) => {
    onSelectRole(roles[index]);
    setIsDropdownOpen(false);
  };

  const onSelectRole = (role: Role) => {
    const updatedInvitees =
      members
        ?.filter(
          member =>
            !includes(
              invitees.map(invitee => invitee.email),
              member.user.email,
            ),
        )
        .map(member => ({
          email: member.user.email,
          role,
        })) || [];

    onInviteesUpdate(updatedInvitees);
  };

  const handleToggle = () => {
    setIsDropdownOpen(prevOpen => !prevOpen);
    setIsTooltipOpen(false);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsDropdownOpen(false);
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item xs={12} md={8}>
        <StyledHeader>Add Organization Members</StyledHeader>
        <Typography variant='body1'>
          Invite all members from your Organization
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Tooltip
          title={disabled ? 'No members to invite' : 'Add All as Viewer'}
          open={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
          onOpen={() => setIsTooltipOpen(true)}
        >
          <ButtonGroup
            variant='outlined'
            color='primary'
            ref={anchorRef}
            aria-label='split button to add members'
            fullWidth
            disabled={disabled}
          >
            <StyledButton
              disabled={isLoading}
              onClick={() => onSelectRole(Role.Viewer)}
            >
              Add All
            </StyledButton>
            <StyledCaretButton
              size='small'
              aria-controls={
                isDropdownOpen ? 'split-StyledCaretButton-menu' : undefined
              }
              aria-expanded={isDropdownOpen ? 'true' : undefined}
              aria-label='select member role for all invitees'
              aria-haspopup='menu'
              onClick={handleToggle}
              disabled={isLoading}
            >
              <CaretDownIcon weight='fill' />
            </StyledCaretButton>
          </ButtonGroup>
        </Tooltip>
        <StyledPopper
          open={isDropdownOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <StyledGrow {...TransitionProps} placement={placement}>
              <Paper elevation={12}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu' autoFocusItem>
                    {roles.map((option, index) => (
                      <StyledMenuItem
                        key={option}
                        onClick={() => handleMenuItemClick(index)}
                        disabled={isLoading}
                      >
                        Add as&nbsp;
                        <span className='camelCase'>{option}</span>s
                      </StyledMenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </StyledGrow>
          )}
        </StyledPopper>
      </Grid>
    </Grid>
  );
};

export default InviteAllMembersForm;
